// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pT6O4lnIrHYzNvP5AMpw{min-width:16px;width:16px;height:16px;border:1px solid var(--primary-color-gray-3);border-radius:50%}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Input/InputColor/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,4CAAA,CACA,iBAAA","sourcesContent":[".color{\n  min-width: 16px;\n  width: 16px;\n  height: 16px;\n  border: 1px solid var(--primary-color-gray-3);\n  border-radius: 50%;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"color": `pT6O4lnIrHYzNvP5AMpw`
};
export default ___CSS_LOADER_EXPORT___;
