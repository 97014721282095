import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { FilterGroup } from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/FiltersGroup'
import { useEffect } from 'react'
import segmentStore from 'store/contacts/segment/segmentStore'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'
import { useSegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { Typography } from 'shared/ui/Typography'
import { useContactsPickerContext } from 'widgets/ContactsPicker/context/context'
import { nanoid } from 'nanoid'
import { filterToIListItem } from 'store/contacts/functions'
import { useAddFiltersModalContext } from 'widgets/AddFiltersModal/context/context'

export const SegmentOrFilterContent = observer(() => {
  const { onSelectItem, sourceFilters } = useContactsPickerContext()
  useGetCustomFields()
  const {
    filtersGroup,
    isHasNotEmpty,
    isHasEmpty,
    isEdit,
    filtersInputs,
    isOnlyView,
    onSave,
    loading,
    isFilter,
    item,
  } = useSegmentDetailContext()
  const { onCancel } = useAddFiltersModalContext()

  const onApply = () => {
    if (isEdit) {
      if (isFilter) {
        const updateFilter = filterToIListItem({
          id: item?.id,
          name: item?.item?.name,
          filters: filtersInputs,
        })
        onSelectItem(updateFilter.id, updateFilter)
        onCancel()
      } else {
        onSave(onCancel)
      }
    } else {
      const newItem = filterToIListItem({
        id: nanoid(),
        name: `Filter ${sourceFilters.length + 1}`,
        filters: filtersInputs,
      })
      onSelectItem(newItem.id, newItem)
      onCancel()
    }
  }
  useEffect(() => {
    segmentStore.loadAvailableData()
  }, [])
  return (
    <div className={styles.wrap}>
      {Object.entries(filtersGroup).map(([orIndex, filters]) => (
        <FilterGroup key={orIndex} filters={filters} orIndex={+orIndex} />
      ))}
      {!isOnlyView && (
        <div className={styles.actions}>
          <Typography
            variant={'s1-semiBold'}
            action={'primary'}
            tag={'button'}
            disabled={!isHasNotEmpty || isHasEmpty || loading}
            onClick={onApply}
          >
            {isEdit ? 'Save changes' : 'Apply'}
          </Typography>
          <Typography variant={'s1-regular'} tag={'button'} onClick={onCancel}>
            Cancel
          </Typography>
        </div>
      )}
    </div>
  )
})
