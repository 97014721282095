import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import audioStore from 'store/audioStore/audioStore'
import classNames from 'classnames'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { UnsubscribeDigitOptions } from 'store/voiceStore/types'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { InputText } from 'components/Input/InputText/InputText'
import { VoiceStore } from 'store/voiceStore/voiceStore'
import { AddAudioBtn } from 'components/CreateElement/AddAudioBtn'
import React from 'react'

export const VoiceContent = observer(
  ({ voiceStore }: { voiceStore: VoiceStore }) => {
    const addAudioForActiveAnswer = () => {
      audioStore.onOpenModal({
        disabledLess7Seconds: true,
        disabledDTTS: true,
        successUploadFIleCallback: voiceStore.setliveAnswerEnabledAudio,
      })
    }
    const addAudioForVoiceEmail = () => {
      audioStore.onOpenModal({
        disabledLess7Seconds: true,
        disabledDTTS: true,
        successUploadFIleCallback: voiceStore.setvoiceMailEnabledAudio,
      })
    }
    return (
      <div>
        <div className={styles.wrapCards}>
          <div className={styles.card}>
            <InputCheckbox
              label={
                <div className={styles.label}>
                  <span className={'bold'}>Live answer</span>
                  <span className={'s2 gray1'}>
                    Select the message that is played when the phone is answered
                  </span>
                </div>
              }
              checked={voiceStore.liveAnswerEnabled}
              onChecked={() => voiceStore.setLiveAnswerEnabled()}
              className={styles.input}
              rowClassName={styles.row}
            />
            {voiceStore.liveAnswerEnabled && (
              <AddAudioBtn
                audioFile={voiceStore.liveAnswerEnabledAudio}
                onAddAudio={addAudioForActiveAnswer}
                onRemove={() => voiceStore.setliveAnswerEnabledAudio(null)}
                from={'liveAnswer'}
              />
            )}
          </div>
          {voiceStore.liveAnswerEnabled && (
            <>
              <div className={styles.card}>
                <InputCheckbox
                  label={
                    <div className={styles.label}>
                      <span className={'bold'}>Transfer call</span>
                      <span className={'s2 gray1'}>
                        Transfer listeners to another number or call center you
                        specify
                      </span>
                    </div>
                  }
                  checked={voiceStore.transferCallEnabled}
                  onChecked={() => voiceStore.setTransferCallEnabled()}
                  className={styles.input}
                  rowClassName={styles.row}
                />
                {voiceStore.transferCallEnabled && (
                  <div className={styles.transferContent}>
                    <Dropdown
                      options={UnsubscribeDigitOptions.filter(
                        (opt) =>
                          opt.value !== voiceStore.selectedUnsubscribeDigit
                      )}
                      selectedValue={voiceStore.selectedTransferDigit}
                      onSelectValue={(opt) =>
                        voiceStore.setSelectedTransferDigit(opt)
                      }
                      className={styles.dropdown}
                      leftIcon={<span className={'s2 gray1'}>Digit</span>}
                      columnGap={12}
                      maxHeightDropdown={160}
                    />
                    <InputText
                      patternFormat
                      format={'(###) ###-####'}
                      maskFormat={'_'}
                      leftIconContent={
                        <span className={'s2 gray1'}>Transfer number</span>
                      }
                      paddingLeft={114}
                      placeholder={'(123) 123-1234'}
                      value={voiceStore.transferNumber}
                      onChange={(e) =>
                        voiceStore.setTransferNumber(e.target.value)
                      }
                      onClickRightIcon={() => voiceStore.setTransferNumber('')}
                      // prefix={'Transfer number'}
                    />
                  </div>
                )}
              </div>
              <div className={classNames(styles.card, styles.cardUnsubscribe)}>
                <div className={styles.label}>
                  <span className={'bold'}>Unsubscribe digit</span>
                  <span className={'s2 gray1'}>
                    When the user presses this digit they will be unsubscribed
                  </span>
                </div>
                <Dropdown
                  position={TTPositionEnum.bottomRight}
                  dropDownContentWidth={72}
                  options={UnsubscribeDigitOptions.filter(
                    (opt) => opt.value !== voiceStore.selectedTransferDigit
                  )}
                  selectedValue={voiceStore.selectedUnsubscribeDigit}
                  onSelectValue={(opt) =>
                    voiceStore.setSelectedUnsubscribeDigit(opt)
                  }
                  className={styles.dropdown}
                  maxHeightDropdown={160}
                />
              </div>
            </>
          )}

          <div className={styles.card}>
            <InputCheckbox
              label={
                <div className={styles.label}>
                  <span className={'bold'}>Voicemail</span>
                  <span className={'s2 gray1'}>
                    Select a message that plays when an answering machine picks
                    up your call
                  </span>
                </div>
              }
              checked={voiceStore.voiceMailEnabled}
              onChecked={() => voiceStore.setvoiceMailEnabled()}
              className={styles.input}
              rowClassName={styles.row}
            />

            {voiceStore.voiceMailEnabled && (
              <AddAudioBtn
                audioFile={voiceStore.voiceMailEnabledAudio}
                onAddAudio={addAudioForVoiceEmail}
                onRemove={() => voiceStore.setvoiceMailEnabledAudio(null)}
                from={'voiceEmail'}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
)
