import { makeAutoObservable } from 'mobx'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { ICustomField } from 'src/types/ICustomField'
import { CustomFieldType } from 'src/generated/graphql'

export class PersonalizeSelectStore {
  excludeIds: string[] = []
  disabledTypes: CustomFieldType[] = []
  allowTypes: CustomFieldType[] = []
  private onSelectItemProps: ((item: IListItem<ICustomField>) => void) | null =
    null
  constructor(props?: {
    response?: ICustomField
    excludeIds?: string[]
    disabledTypes?: CustomFieldType[]
    allowTypes?: CustomFieldType[]
    onSelectItem?: (item: IListItem<ICustomField>) => void
  }) {
    if (props?.response) {
      this.setItem(props.response)
    }
    if (props?.excludeIds) {
      this.excludeIds = props?.excludeIds
    }
    if (props?.disabledTypes) {
      this.disabledTypes = props?.disabledTypes
    }
    if (props?.allowTypes) {
      this.allowTypes = props?.allowTypes
    }
    this.onSelectItemProps = props?.onSelectItem || null
    makeAutoObservable(this)
  }

  _item: ICustomField | null = null
  itemId: string | null = null
  get item(): ICustomField | null {
    return this._item
  }
  setItem = (response: ICustomField) => {
    this._item = response
    this.setItemId(response.id)
  }
  setItemId = (id: string) => {
    this.itemId = id
  }

  onSelect = (id: any, item: IListItem<ICustomField>) => {
    if (item.item) {
      this.onSelectItemProps?.(item)
      this.setItem(item.item)
    }
  }
}
