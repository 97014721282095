import { createContext, useContext } from 'react'
import { AddFiltersModalStore } from '../store/AddFiltersModalStore'

export const AddFiltersModalContext =
  createContext<AddFiltersModalStore | null>(null)

export const useAddFiltersModalContext = () => {
  const context = useContext(AddFiltersModalContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with AddFiltersModalContext'
    )

  return context
}
