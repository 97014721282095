import styles from './styles.module.scss'
import {
  FC,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react'
import { InfoTooltipProps } from 'components/InfoTooltip/InfoTooltip'
import { Typography } from 'shared/ui/Typography'
import { Icon } from 'components/Icon'
import classNames from 'classnames'

export type IXSmallButtonProps = {
  text: ReactNode
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  className?: HTMLButtonElement['className']
  variant: 'x-small'
  infoTooltipProps?: InfoTooltipProps
  disabled?: boolean
  LeftIcon?: FC
  rightContent?: ReactNode
  count?: number
}

export const XSmallButton = forwardRef(function xSmall(
  {
    text,
    LeftIcon,
    rightContent,
    className,
    count,
    ...props
  }: IXSmallButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <button ref={ref} className={classNames(styles.wrap, className)} {...props}>
      {LeftIcon && (
        <Icon fontSize={12} color={'--primary-color-gray-1'}>
          <LeftIcon />
        </Icon>
      )}
      <Typography variant={'s1-regular'} isEllipsis>
        {text}
      </Typography>
      {!!count && (
        <Typography variant={'s1-regular'} color={'--primary-color-gray-1'}>
          ({count})
        </Typography>
      )}
      {rightContent}
    </button>
  )
})
