import { createContext, useContext } from 'react'
import { CollectedFieldStore } from '../store/CollectedFieldStore'

export const CollectedFieldContext = createContext<CollectedFieldStore | null>(
  null
)

export const useCollectedFieldContext = () => {
  const context = useContext(CollectedFieldContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with CollectedFieldContext'
    )

  return context
}
