// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LtkjXexugX6LLaPGPf3S{display:flex;gap:4px}.VuMBz0uk9wLTRsKwQZy6{max-height:400px;overflow:auto}.dgAGuhFiGlZ5NXHjm8kw{display:flex;flex-direction:column}.dgAGuhFiGlZ5NXHjm8kw .i90zTRSMeMNhegoeqWYY{margin-top:8px;padding:8px}.dgAGuhFiGlZ5NXHjm8kw .bgQiHq8kFL_YpRGKkyWs{padding:4px}`, "",{"version":3,"sources":["webpack://./src/widgets/ContactsPicker/ui/ContactsPickerUiForTable/InvisibleTooltipBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CAGF,sBACE,gBAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,4CACE,cAAA,CACA,WAAA,CAGF,4CACE,WAAA","sourcesContent":[".wrap{\n  display: flex;\n  gap: 4px;\n}\n\n.body{\n  max-height: 400px;\n  overflow: auto;\n}\n\n.wrapGroup{\n  display: flex;\n  flex-direction: column;\n  .title{\n    margin-top: 8px;\n    padding: 8px;\n\n  }\n  .button{\n    padding: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `LtkjXexugX6LLaPGPf3S`,
	"body": `VuMBz0uk9wLTRsKwQZy6`,
	"wrapGroup": `dgAGuhFiGlZ5NXHjm8kw`,
	"title": `i90zTRSMeMNhegoeqWYY`,
	"button": `bgQiHq8kFL_YpRGKkyWs`
};
export default ___CSS_LOADER_EXPORT___;
