// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.exvqobuw0nKeqeY7xZGc{margin-top:40px;margin-left:32px}`, "",{"version":3,"sources":["webpack://./src/pages/main/campaigns/create/createForm/AddMessage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,gBAAA","sourcesContent":[".btnWrap{\n  margin-top: 40px;\n  margin-left: 32px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnWrap": `exvqobuw0nKeqeY7xZGc`
};
export default ___CSS_LOADER_EXPORT___;
