import { BtnSize, BtnType, Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import useRecorder from 'src/hooks/useRocorder'
import { AudioPlayer } from 'components/AudioPlayer/AudioPlayer'
import { FC, useEffect, useState } from 'react'
import audioStore from 'store/audioStore/audioStore'
import { AudioType } from 'src/generated/graphql'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { SizedBox } from 'components/SizedBox'
import { IOption } from 'src/types/IOption'
import { uniqBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { Icon } from 'components/Icon/Icon'
import { Check, CloseCustom, Microphone } from 'components/Icon'

type Props = {
  closeDropdown: () => void
}

export const RecordFromComputer: FC<Props> = observer(({ closeDropdown }) => {
  const {
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    removeAudio,
    audioDurationString,
    blob,
    audioId,
    audioName,
  } = useRecorder()
  const [loading, setLoading] = useState(false)
  const [microphones, setMicrophones] = useState<IOption[]>([])
  const onApply = async () => {
    if (blob) {
      try {
        setLoading(true)
        await audioStore.uploadAudio(
          blob,
          AudioType.RecordedByComputer,
          audioName
        )
        closeDropdown()
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    if (audioStore.isGranted) {
      navigator.mediaDevices.enumerateDevices().then(function (devices) {
        const microphonesFromComputer = devices.filter(
          (device) => device.kind === 'audioinput' && device.deviceId
        )

        const uniqMicrophones = uniqBy(microphonesFromComputer, 'groupId')
        setMicrophones(
          uniqMicrophones.map((micro) => ({
            value: micro.groupId,
            data: micro.deviceId,
            title: micro.label,
            p8: true,
          }))
        )
        if (microphonesFromComputer?.length) {
          if (!audioStore.selectedMicroGroupId) {
            const defaultMicro =
              microphonesFromComputer.find(
                (micro) => micro.deviceId === 'default'
              ) || microphonesFromComputer[0]
            audioStore.setSelectedMicro(
              defaultMicro.groupId,
              defaultMicro.deviceId
            )
          }
        }
      })
    }
  }, [audioStore.isGranted])

  const onSelectMicro = (groupId: string, deviceId: string) => {
    audioStore.setSelectedMicro(groupId, deviceId)
  }
  return (
    <div className={styles.wrapContent}>
      <MuiTooltip
        title={
          audioStore.isGranted
            ? ''
            : 'Your browser blocks using microphone for this site.'
        }
        arrow
        width={200}
        placement={'top'}
      >
        <div>
          <Dropdown
            options={microphones}
            selectedValue={audioStore.selectedMicroGroupId}
            onSelectValue={onSelectMicro}
            labelDropdown={'Microphone'}
            placeholder={'Select microphone'}
            disabled={!audioStore.isGranted}
          />
        </div>
      </MuiTooltip>

      <SizedBox height={16} />
      {audioURL ? (
        <div className={styles.wrapPlayer}>
          <AudioPlayer
            src={audioURL}
            size={BtnSize.small}
            id={audioId}
            maxCanvasWidth={60}
          />
          <Button
            size={BtnSize.small}
            typeBtn={BtnType.primary}
            onClick={onApply}
            loading={loading}
          >
            <Icon>
              <Check />
            </Icon>
          </Button>
          <Button
            size={BtnSize.small}
            typeBtn={BtnType.secondaryGray}
            close
            onClick={removeAudio}
          >
            <Icon>
              <CloseCustom />
            </Icon>
          </Button>
        </div>
      ) : isRecording ? (
        <Button
          size={BtnSize.small}
          className={styles.btn}
          onClick={() => stopRecording()}
        >
          <div className={styles.stop}>
            <div />
          </div>
          <span>{audioDurationString}</span>
        </Button>
      ) : (
        <Button
          size={BtnSize.small}
          className={styles.btn}
          onClick={() => startRecording()}
        >
          <div className={styles.micro}>
            <Icon fontSize={12} color={'--primary-color-white'}>
              <Microphone />
            </Icon>
          </div>
          <span>Click to record</span>
        </Button>
      )}
    </div>
  )
})
