import React, { ChangeEvent, FC, useMemo } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Checkbox } from 'shared/assets/icons/defaults/Checkbox.svg'
import { ReactComponent as CheckboxRound } from 'shared/assets/icons/defaults/Checkbox-Round.svg'
import { ReactComponent as CheckboxMinus } from 'shared/assets/icons/defaults/CheckboxMinus.svg'
import classNames from 'classnames'

export type ICheckBoxProps = {
  checked: boolean
  disabled?: boolean
  shape?: 'radio' | 'circle' | 'minus' | 'default'
  size?: 'small' | 'medium' | 'large'
  boxSize?: '32'
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onChangeValue?: (value: boolean, event: ChangeEvent<HTMLInputElement>) => void
}

export const CheckBox: FC<ICheckBoxProps> = ({
  checked,
  disabled,
  size = 'medium',
  shape = 'default',
  boxSize,
  onChange,
  onChangeValue,
}) => {
  const content = useMemo(() => {
    if (shape === 'minus') {
      return <CheckboxMinus />
    }
    if (checked && shape !== 'radio') {
      return shape === 'circle' ? <CheckboxRound /> : <Checkbox />
    } else {
      return <div className={styles.content} />
    }
  }, [shape, checked, size])

  const innerContent = (
    <div
      className={classNames(
        styles.wrap,
        checked && styles.checked,
        styles[`size__${size}`],
        styles[`shape__${shape}`]
      )}
    >
      {content}
    </div>
  )

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (!onChange && !onChangeValue) {
      event.stopPropagation()
      event.preventDefault()
      return
    }
    onChange?.(event)
    onChangeValue?.(event.target.checked, event)
  }

  return (
    <label className={classNames(styles.btn, { [styles.disabled]: disabled })}>
      {boxSize ? (
        <div className={styles[`boxSize__${boxSize}`]}>{innerContent}</div>
      ) : (
        innerContent
      )}
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
      />
    </label>
  )
}
