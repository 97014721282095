import { makeAutoObservable } from 'mobx'

export type ICustomSubscribeButtonParams = {
  buttonColor: string
  hoverButtonColor: string
  disabledButtonColor: string
  textButtonColor: string
}

export class CustomSubscribeButtonStore {
  constructor() {
    makeAutoObservable(this)
  }
  init = (params: ICustomSubscribeButtonParams | null) => {
    if (params) {
      this.isActive = true
      this.buttonColor = params.buttonColor
      this.hoverButtonColor = params.hoverButtonColor
      this.disabledButtonColor = params.disabledButtonColor
      this.textButtonColor = params.textButtonColor
    }
  }

  get params(): ICustomSubscribeButtonParams | null {
    if (this.isActive) {
      return {
        buttonColor: this.buttonColor,
        hoverButtonColor: this.hoverButtonColor,
        disabledButtonColor: this.disabledButtonColor,
        textButtonColor: this.textButtonColor,
      }
    }
    return null
  }

  isActive = false
  setIsActive = (value: boolean) => {
    this.isActive = value
  }

  buttonColor = '#1364f9'
  setButtonColor = (value: string) => {
    this.buttonColor = value
  }
  hoverButtonColor = '#1854EA'
  setHoverButtonColor = (value: string) => {
    this.hoverButtonColor = value
  }
  disabledButtonColor = '#A1C1FD'
  setDisabledButtonColor = (value: string) => {
    this.disabledButtonColor = value
  }
  textButtonColor = '#ffffff'
  setTextButtonButtonColor = (value: string) => {
    this.textButtonColor = value
  }
}
