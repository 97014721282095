import { observer } from 'mobx-react-lite'
import { Input } from 'shared/ui/Input/Input'
import { Search } from 'components/Icon'
import { groupBy } from 'lodash'
import { Button } from 'shared/ui/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { useState } from 'react'
import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { IListItem } from 'components/SelectList/ListItem/ListItem'

export const InvisibleTooltipBtn = observer(
  ({ items, count }: { items: IListItem[]; count?: number }) => {
    const [search, setSearch] = useState('')
    return (
      <MuiTooltip
        title={
          <div onClick={(event) => event.stopPropagation()}>
            <Input
              size={'small'}
              LeftIcon={Search}
              value={search}
              setValue={setSearch}
              placeholder={'Search'}
            />
            <div className={styles.body}>
              {Object.entries(groupBy(items, 'group')).map(([group, items]) => (
                <div key={group} className={styles.wrapGroup}>
                  <Typography
                    className={styles.title}
                    tag={'div'}
                    variant={'s2-medium'}
                    color={'--primary-color-gray-1'}
                  >
                    {group}
                  </Typography>
                  {items
                    .filter((item) =>
                      item.text
                        ?.toLocaleString()
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((item) => (
                      <div key={item.id} className={styles.button}>
                        <Button
                          variant={'x-small'}
                          LeftIcon={item.dropdownButtonProps?.LeftIcon}
                          text={item.text}
                        />
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        }
        white
      >
        <Button variant={'x-small'} text={`+${items.length || count}`} />
      </MuiTooltip>
    )
  }
)
