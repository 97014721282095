import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { CreateSegmentStore } from 'store/contacts/segment/createSegmentStore'
import { SegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { SegmentOrFilterContent } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/SegmentOrFilterContent'

export const NewFilter = observer(() => {
  const [createSegmentStore] = useState(
    () => new CreateSegmentStore({ isCompact: true })
  )
  return (
    <SegmentDetailContext.Provider value={createSegmentStore}>
      <SegmentOrFilterContent />
    </SegmentDetailContext.Provider>
  )
})
