// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nYzeL49GZFR14A0C7z2g{width:100%}`, "",{"version":3,"sources":["webpack://./src/components/Fields/DateField/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA","sourcesContent":[".fullWidth{\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidth": `nYzeL49GZFR14A0C7z2g`
};
export default ___CSS_LOADER_EXPORT___;
