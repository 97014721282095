import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { Textarea } from 'components/Textarea/Textarea'
import { useCollectedFieldContext } from 'widgets/CollectedFields/widgets/CollectedField'
import { CollectedFieldAction } from 'widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction'

export const Content = observer(() => {
  const { expand, textareaStore, actions } = useCollectedFieldContext()
  if (!expand) {
    return null
  }
  return (
    <div className={styles.wrap}>
      <div className={styles.textField}>
        <Typography variant={'p0-bold'} color={'--primary-color-gray-1'}>
          Reply
        </Typography>
        <Textarea
          textareaStore={textareaStore}
          placeholder={'Enter reply...'}
        />
      </div>
      {actions.map((action) => (
        <CollectedFieldAction key={action.id} store={action} />
      ))}
    </div>
  )
})
