import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Button } from 'shared/ui/Button/Button'
import { useNewConversionContext } from 'widgets/conversions/NewConversion/context/context'

export const Actions = observer(() => {
  const { onClose, onCreate, onSaveEdit, disabledCreate, loading, isEdit } =
    useNewConversionContext()

  return (
    <div className={styles.actions}>
      <Button
        variant={'primary-medium'}
        text={isEdit ? 'Save' : 'Create'}
        onClick={isEdit ? onSaveEdit : onCreate}
        disabled={disabledCreate}
        loading={loading}
      />
      <Button variant={'secondary-medium'} text={'Cancel'} onClick={onClose} />
    </div>
  )
})
