import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import { PhoneType, VerificationStatus } from 'src/generated/graphql'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import { RoutesEnum } from 'src/pages/routes'
import { Alert } from 'components/Alert/Alert'
import React from 'react'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import classNames from 'classnames'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'
import { numberString } from 'src/util/functions'

export const BroadcastLimitAlert = observer(
  ({
    type,
    newBroadCastStore,
    broadcastAllowedMessage,
  }: {
    type?: AlertTypeEnum
    newBroadCastStore: CreateBroadCastStore
    broadcastAllowedMessage?: string
  }) => {
    const limitMessage = () => {
      if (billingStore.isTrial) {
        return `You’re limited to ${numberString({ val: 25 })} messages`
      }
      if (
        newBroadCastStore.smsPhoneFull?.type === PhoneType.TollFree ||
        newBroadCastStore.smsPhoneFull?.type === PhoneType.Local
      ) {
        if (
          newBroadCastStore.smsPhoneFull?.verificationStatus ===
            VerificationStatus.Verified &&
          newBroadCastStore.smsPhoneFull?.type === PhoneType.Local
        ) {
          return `You’re limited to  ${numberString({
            val: 200000,
          })} messages/day on verified toll-free`
        }
        if (
          newBroadCastStore.smsPhoneFull?.verificationStatus ===
            VerificationStatus.InReview ||
          newBroadCastStore.smsPhoneFull?.verificationStatus ===
            VerificationStatus.Pending
        ) {
          return `You’re limited to  ${numberString({
            val: 2000,
          })} messages/day on ${
            newBroadCastStore.smsPhoneFull?.type === PhoneType.TollFree
              ? 'pending toll-free'
              : 'local numbers'
          }`
        }
      }
      return ''
    }

    const limitMessageText = () => {
      if (billingStore.isTrial) {
        return 'Upgrade to a plan to increase your limit'
      }
      if (
        newBroadCastStore.smsPhoneFull?.verificationStatus ===
          VerificationStatus.InReview ||
        newBroadCastStore.smsPhoneFull?.verificationStatus ===
          VerificationStatus.Pending
      ) {
        return 'After toll-free approval, you’ll be able to send more messages.'
      }
      return broadcastAllowedMessage
    }
    return (
      <Alert
        title={limitMessage()}
        text={limitMessageText()}
        rightContent={
          billingStore.isTrial ? (
            <div className={'flex mt4'} style={{ height: 20 }}>
              <button
                onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
                className={classNames(
                  'link bold s1',
                  type === AlertTypeEnum.error && 'linkError'
                )}
              >
                Upgrade
              </button>
            </div>
          ) : (
            (newBroadCastStore.smsPhoneFull?.verificationStatus ===
              VerificationStatus.Unverified && (
              <div className={'flex mt4'} style={{ height: 20 }}>
                <LinkWrapper
                  to={RoutesEnum.settingsCompliance}
                  className={classNames(
                    's1 flex',
                    type === AlertTypeEnum.error && 'linkError'
                  )}
                >
                  Increase
                </LinkWrapper>
              </div>
            )) || <></>
          )
        }
        type={type}
      />
    )
  }
)
