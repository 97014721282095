import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { SizedBox } from 'components/SizedBox'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useRegisterHubspotMember } from 'src/hooks/useRegisterHubspotMember'
import { RangeBar } from 'components/RangeBar/RangeBar'
import { Typography } from 'shared/ui/Typography'
import { Label } from 'shared/ui/Label/Label'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import { SelectList } from 'components/SelectList/SelectList'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import styles from './styles.module.scss'
import { NoCompleteRoutes } from 'src/pages/routes'
import { useRegisterContext } from 'src/pages/auth/signUp/register/context/context'
import { Input } from 'src/shared/ui/Input/Input'
import { AngleDown } from 'components/Icon'

export const RegisterCompanyInfo = observer(() => {
  const {
    loadHubspotForm,
    saveCompanyData,
    companyName,
    setCompanyName,
    disabledContinue,

    selected_contact_list_size_item,
    contact_list_size_options,
    set_selected_contact_list_size,

    selected_message_frequency_item,
    message_frequency_options,
    set_selected_message_frequency,

    type_of_messages_inputs_checkbox,
  } = useRegisterContext()

  const navigate = useNavigate()
  const onContinue = async () => {
    saveCompanyData()
    navigate(NoCompleteRoutes.register2)
  }

  useRegisterHubspotMember()

  useEffect(() => {
    loadHubspotForm()
  }, [])

  return (
    <>
      <>
        <Typography variant={'h2-semiBold'}>
          Tell us about your company
        </Typography>
        <SizedBox height={8} />
        <p className={'par2 gray1 medium mb20'}>
          This will help customize the app for you
        </p>
        <RangeBar progress={66} style2 />
      </>
      <SizedBox height={32} />
      <div className={styles.wrapFields}>
        <div>
          <Label text={'Company name'} marginBottom={8} />
          <Input
            value={companyName}
            setValue={setCompanyName}
            placeholder={'e.g. Tesla, Inc'}
          />
        </div>
        <div>
          <Label
            text={'How many contacts do you plan to message?'}
            marginBottom={8}
          />
          <DropdownField
            isSameWidth
            isFullWidth
            dropdownContent={() => (
              <SelectList
                withoutMark
                options={contact_list_size_options}
                onSelect={set_selected_contact_list_size}
              />
            )}
          >
            <Input
              isReadOnly
              value={selected_contact_list_size_item?.text}
              placeholder={'Select'}
              rightContent={
                <Icon color={'--primary-color-gray-1'} fontSize={16}>
                  <AngleDown />
                </Icon>
              }
            />
          </DropdownField>
        </div>
        <div>
          <Label
            text={'How many times will you message them?'}
            marginBottom={8}
          />
          <DropdownField
            isSameWidth
            isFullWidth
            dropdownContent={() => (
              <SelectList
                withoutMark
                options={message_frequency_options}
                onSelect={set_selected_message_frequency}
              />
            )}
          >
            <Input
              isReadOnly
              value={selected_message_frequency_item?.text}
              placeholder={'Select'}
              rightContent={
                <Icon color={'--primary-color-gray-1'} fontSize={16}>
                  <AngleDown />
                </Icon>
              }
            />
          </DropdownField>
        </div>
        <div>
          <Label
            text={'What types of messages will you send?'}
            marginBottom={8}
          />
          <div className={styles.wrapInputs}>
            {type_of_messages_inputs_checkbox.map((item) => (
              <InputCheckbox nowrap key={item.label} {...item} />
            ))}
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          size={BtnSize.large}
          typeBtn={BtnType.primary}
          onClick={onContinue}
          disabled={disabledContinue}
        >
          Continue
        </Button>
      </div>
    </>
  )
})
