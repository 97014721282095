import { AddMediaContent } from 'components/Textarea/AddMediaContent/AddMediaContent'
import { Modal } from 'components/Modal/Modal'
import { FC } from 'react'
import { TextareaStore } from 'store/textareaStore'
import { observer } from 'mobx-react-lite'

type Props = {
  textareaStore: TextareaStore
}

export const TextareaMediaModal: FC<Props> = observer(({ textareaStore }) => (
  <Modal
    open={textareaStore.activeMedia}
    title={'Add media'}
    onClose={() => textareaStore.setActiveMedia(false)}
    minWidth={800}
    minHeight={554}
  >
    <AddMediaContent
      activeModalTab={textareaStore.activeMediaTab}
      setActiveModalTab={(index) => textareaStore.setActiveMediaTab(index)}
      uploadSelectedIds={textareaStore.uploadSelectedIds}
      selectedUploadSizeString={textareaStore.selectedUploadSizeString}
      onDeselectUploadImagesAll={() =>
        textareaStore.onDeselectUploadImagesAll()
      }
      onSelectUploadImagesAll={() => textareaStore.onSelectUploadImagesAll()}
      onAddImage={() => {
        textareaStore.setActiveMedia(false)
        textareaStore.onAddImage()
      }}
      onSelectUploadImg={(id: string) => textareaStore.onSelectUploadImg(id)}
      isCheckedUploadImg={(id: string) => textareaStore.isCheckedUploadImg(id)}
      removeAttachImages={(ids: string[]) =>
        textareaStore.removeAttachImages(ids)
      }
    />
  </Modal>
))
