import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { Button } from 'shared/ui/Button/Button'
import { AngleLeft } from 'components/Icon'

export type HeaderDetailProps = {
  title: string
  rightContent?: ReactNode
  style?: CSSProperties
  inContainer?: boolean
  withBreadcrumbs?: boolean
  breadcrumbsContent?: ReactNode
}

export const HeaderDetail = ({
  title,
  rightContent,
  style,
  breadcrumbsContent,
}: HeaderDetailProps) => {
  const navigate = useNavigate()

  return (
    <div className={classNames(styles.wrap)} style={style}>
      <div className={styles.wrapBreadcrumbs}>{breadcrumbsContent}</div>
      <div className={styles.content}>
        <div className={styles.left}>
          <Button
            variant={'tertiary-small'}
            LeftIcon={AngleLeft}
            onClick={() => navigate(-1)}
          />
          {title && <Typography variant="h1-semiBold">{title}</Typography>}
        </div>
        <div className={styles.right}>{rightContent}</div>
      </div>
    </div>
  )
}
