import { makeAutoObservable } from 'mobx'
import {
  ConversionHitProjection,
  GetConversionHitsQuery,
} from 'src/generated/graphql'
import {
  ConversionResponse,
  IConversion,
} from 'src/pages/settings/conversions/conversions/store/type'
import { TableStore } from 'components/NewTable/store/TableStore'
import { getColumns } from 'widgets/conversions/ConversionDetail/store/columns'
import { IConversionHitProjection } from 'widgets/conversions/ConversionDetail/store/types'

export class ConversionDetailStore {
  conversion: IConversion
  tableStore
  constructor(response: ConversionResponse) {
    this.conversion = { id: response.id, ...response }
    this.tableStore = new TableStore({
      withDataRange: true,
      tableName: 'ConversionDetailTable',
      columns: getColumns(),
      orderBy: 'date',
      noIgnoreCaseIds: ['date'],
    })
    makeAutoObservable(this)
  }

  get conversionId() {
    return this.conversion.id || ''
  }
  get name() {
    return this.conversion.name || ''
  }
  onSuccessEdit = (response: ConversionResponse) => {
    this.conversion = { id: response.id, ...response }
  }

  itemsMap: Map<string, IConversionHitProjection> = new Map()
  get items() {
    return Array.from(this.itemsMap.values())
  }

  getItemByResponse = (item: ConversionHitProjection) => {
    return { ...item, id: item.id }
  }

  setTotal = (total: number) => {
    this.tableStore.setTotal(total)
  }

  setData = (data: GetConversionHitsQuery) => {
    this.setTotal(data.getConversionHits?.total || 0)
    this.itemsMap.clear()

    data.getConversionHits?.content?.forEach((response) => {
      if (response) {
        const item = this.getItemByResponse(response)
        this.itemsMap.set(item.id, item)
      }
    })
  }
}
