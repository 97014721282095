import * as React from 'react'
import { FC, ReactNode, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { BreadCrumbs } from 'components/BreadCrumbs/BreadCrumbs'
import { NavItem } from 'components/NavBar/NavItem'
import { navItemsSettings } from 'components/NavBar/navItems'
import user from 'store/user/user'
import uiStore from 'store/uiStore'
import { Header } from 'shared/ui/Header/Header'
import { HeaderDetail } from 'shared/ui/Header/HeaderDetail/HeaderDetail'

export const WrapPageSuperAdmin: FC<{
  title: string
  actions?: ReactNode
  children?: ReactNode
}> = ({ title, children, actions }) => {
  const { id } = useParams()
  useEffect(() => {
    uiStore.setMobilePage(title)
    return () => {
      uiStore.setMobilePage('')
    }
  }, [title])

  return (
    <>
      <>
        {id ? (
          <HeaderDetail
            title={title}
            breadcrumbsContent={
              id &&
              !uiStore.isMobile && (
                <BreadCrumbs paddingLeft={0} paddingTop={0} />
              )
            }
            rightContent={
              <>
                {actions}
                {!id && user.isSysadmin && (
                  <NavItem
                    navItem={navItemsSettings[navItemsSettings.length - 1]}
                  />
                )}
              </>
            }
          />
        ) : (
          !uiStore.isMobile && <Header title={title} />
        )}

        {children}
      </>
    </>
  )
}
