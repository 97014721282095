import { makeAutoObservable } from 'mobx'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'
import {
  getCampaignDelayPeriodTitle,
  getCampaignScheduleTypeTitle,
} from 'components/Schedule/store/mockData'
import {
  CampaignDelayPeriod,
  CampaignDelayType,
  CampaignScheduleType,
  CampaignStepDelayRequestInput,
  CampaignStepDelayResponse,
  CustomFieldType,
} from 'src/generated/graphql'
import { transformCustomFieldsFromBack } from 'store/contacts/customFields/functions'
import { PersonalizeSelectStore } from 'widgets/PersonalizeSelect/store/PersonalizeSelectStore'

export class CampaignScheduleStore {
  constructor() {
    makeAutoObservable(this)
  }
  laterCount: number | string = 1
  laterPeriod = CampaignDelayPeriod.Minute
  laterDate: Date | null = null
  laterAdvancedTime: Date | null = null

  calendarDate: Date | null = null

  sendTypeCampaign: CampaignDelayType = CampaignDelayType.Immediate

  contactDateFieldCount = 1
  contactDateFieldPeriod = CampaignDelayPeriod.Minute
  contactDateFieldOperator = CampaignScheduleType.Before
  contactDateFieldPersonalizeSelectStore: PersonalizeSelectStore =
    new PersonalizeSelectStore({
      allowTypes: [CustomFieldType.Date, CustomFieldType.DateTime],
    })
  get contactDateField() {
    return this.contactDateFieldPersonalizeSelectStore.item
  }
  contactDateFieldDate: Date | null = null

  get laterDateBackend() {
    if (
      this.laterDate &&
      this.laterPeriod !== CampaignDelayPeriod.Hour &&
      this.laterPeriod !== CampaignDelayPeriod.Minute
    ) {
      return this.laterDate
    }
    return null
  }

  get contactDateFieldDateTime() {
    if (!this.activeContactDateField) {
      return null
    }
    if (this.contactDateField?.type === CustomFieldType.Date) {
      return this.contactDateFieldDate
    } else {
      if (this.activeContactDateCustomTime) {
        return this.contactDateFieldDate
      }
    }
    return null
  }

  get delayInput(): CampaignStepDelayRequestInput | null {
    if (this.activeSendLater) {
      return {
        value: +this.laterCount || 1,
        period: this.laterPeriod,
        hour: this.laterDateBackend ? +dayjs(this.laterDate).format('H') : 0,
        minute: this.laterDateBackend ? +dayjs(this.laterDate).format('m') : 0,
      }
    }
    if (this.activeCalendarDate) {
      return {
        value: 0,
        sendDate:
          this.calendarDate && dayjs(this.calendarDate).utc().toISOString(),
      }
    }
    if (this.activeContactDateField) {
      return {
        value: +this.contactDateFieldCount,
        period: this.contactDateFieldPeriod,
        scheduleType: this.contactDateFieldOperator,
        customFieldId: this.contactDateField?.idLong,
        hour: this.contactDateFieldDateTime
          ? +dayjs(this.contactDateFieldDateTime).format('H')
          : undefined,
        minute: this.contactDateFieldDateTime
          ? +dayjs(this.contactDateFieldDateTime).format('m')
          : undefined,
      }
    }
    return null
  }

  get scheduleError() {
    return ''
  }

  get activeSendImmediately() {
    return this.sendTypeCampaign === CampaignDelayType.Immediate
  }
  get activeSendLater() {
    return this.sendTypeCampaign === CampaignDelayType.Delay
  }

  get activeCalendarDate() {
    return this.sendTypeCampaign === CampaignDelayType.Date
  }

  get activeContactDateField() {
    return this.sendTypeCampaign === CampaignDelayType.DelayCustomField
  }

  get sendTimeString() {
    if (this.activeSendImmediately) {
      return 'Immediately'
    }
    if (this.activeSendLater) {
      return `${this.laterCount} ${getCampaignDelayPeriodTitle(
        this.laterPeriod
      )}${Number(this.laterCount) > 1 ? 's' : ''} after ${
        this.laterAdvancedTime
          ? `- ${dayjs(this.laterAdvancedTime).format('h:mm a')}`
          : ''
      }`
    }
    if (this.activeCalendarDate) {
      return (
        (this.calendarDate &&
          `${dayjs(this.calendarDate).format(DayjsFormats.fullWithAt)}`) ||
        ''
      )
    }
    if (this.activeContactDateField) {
      if (this.contactDateFieldOperator === CampaignScheduleType.On) {
        return `On the ${this.contactDateField?.name || ''}`
      }
      return `${this.contactDateFieldCount} ${getCampaignDelayPeriodTitle(
        this.contactDateFieldPeriod
      )}${
        this.contactDateFieldCount > 1 ? 's' : ''
      } ${getCampaignScheduleTypeTitle(this.contactDateFieldOperator)} ${
        this.contactDateField ? `the ${this.contactDateField.name}` : ''
      }`
    }
    return ''
  }

  get suffixString() {
    if (this.activeSendLater) {
      if (this.laterDateBackend) {
        return ` ${AppSymbols.point} ${dayjs(this.laterDateBackend).format(
          'h:mm A'
        )}`
      }
    }
    if (this.contactDateFieldDateTime)
      return ` ${AppSymbols.point} ${dayjs(
        this.contactDateFieldDateTime
      ).format('h:mm A')}`
  }

  setSendTypeCampaign = (sendTyp: CampaignDelayType) => {
    this.sendTypeCampaign = sendTyp
  }

  setCampaignDelay = (
    delayType?: CampaignDelayType,
    delay?: CampaignStepDelayResponse
  ) => {
    this.sendTypeCampaign = delayType || CampaignDelayType.Immediate
    if (delayType === CampaignDelayType.Delay) {
      if (delay?.period) {
        this.laterPeriod = delay?.period
      }
      if (delay?.value) {
        this.laterCount = delay.value
      }
      if (delay?.hour || delay?.minute) {
        this.laterDate = dayjs()
          .startOf('day')
          .add(delay.hour || 0, 'hour')
          .add(delay.minute || 0, 'minute')
          .toDate()
      }
    }
    if (delayType === CampaignDelayType.Date && delay?.sendDate) {
      this.calendarDate = dayjs(delay.sendDate).toDate()
    }
    if (delayType === CampaignDelayType.DelayCustomField && delay) {
      this.contactDateFieldCount = delay.value
      if (delay.period) {
        this.contactDateFieldPeriod = delay.period
      }
      if (delay.scheduleType) {
        this.contactDateFieldOperator = delay.scheduleType
      }
      if (delay.customField) {
        this.contactDateFieldPersonalizeSelectStore.setItem(
          transformCustomFieldsFromBack([delay.customField])[0]
        )
      }
      if (delay.customField?.type === 'DATE') {
        if (delay.hour || delay.minute) {
          this.contactDateFieldDate = dayjs()
            .startOf('day')
            .add(delay.hour || 0, 'hour')
            .add(delay.minute || 0, 'minute')
            .toDate()
        }
      }
      if (delay.customField?.type === 'DATE_TIME') {
        if (delay.hour !== null && delay.hour !== undefined) {
          this.activeContactDateCustomTime = true
          this.contactDateFieldDate = dayjs()
            .startOf('day')
            .add(delay.hour || 0, 'hour')
            .add(delay.minute || 0, 'minute')
            .toDate()
        }
      }
    }
  }
  setLaterCount = (count: number | string) => {
    this.laterCount = count
  }
  setLaterPeriod = (value: CampaignDelayPeriod) => {
    this.laterPeriod = value
  }
  setLaterDate = (date: typeof this.laterDate) => {
    this.laterDate = date
  }

  setCalendarDate = (date: typeof this.calendarDate) => {
    this.calendarDate = date || null
  }

  setContactDateFieldCount = (count: number) => {
    this.contactDateFieldCount = count
  }
  setContactDateFieldPeriod = (value: CampaignDelayPeriod) => {
    this.contactDateFieldPeriod = value
  }
  setContactDateFieldOperator = (value: CampaignScheduleType) => {
    this.contactDateFieldOperator = value
  }
  setContactDateFieldDate = (date: typeof this.contactDateFieldDate) => {
    this.contactDateFieldDate = date
  }

  activeContactDateCustomTime = false
  toggleActiveContactDateCustomTime = () => {
    this.activeContactDateCustomTime = !this.activeContactDateCustomTime
  }
}
