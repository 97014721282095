import { IBroadcast } from 'src/types/IBroadcast'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ContentType, Status } from 'src/generated/graphql'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { numberString } from 'src/util/functions'
import * as React from 'react'
import { ColumnType } from 'components/NewTable/types'
import { ItemsRender } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import { UserDateContent } from 'components/Date/UserDateContent'
import { observer } from 'mobx-react-lite'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import {
  conversionColumns,
  conversionColumnsIds,
} from 'src/pages/settings/conversions/config/columns'
import { ContactsPickerUiForTable } from 'widgets/ContactsPicker/ui/ContactsPickerUiForTable/ContactsPickerUiForTable'

export const getBroadcastsTableColumns = (
  onHoverBroadcast: (id: number) => void
): ColumnType<IBroadcast>[] => [
  { id: 'name', label: 'Name' },
  {
    id: 'simplifiedStatus',
    label: 'Status',
    minWidth: 143,
    format: (value) => <StatusContent status={value as Status} />,
  },
  {
    id: 'listBroadcasts',
    label: 'List',
    format: (_, { contactsPickerStore, id }) =>
      contactsPickerStore ? (
        <ContactsPickerUiForTable
          store={contactsPickerStore}
          onHoverItemsCount={() => onHoverBroadcast(+id)}
        />
      ) : (
        <></>
      ),
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 106,
    format: (value) => <TypeContent type={value as ContentType} />,
  },
  {
    id: 'sendTime',
    label: 'Send time',
    minWidth: 249,
    format: (value) => <UserDateContent value={value as Date} />,
  },
  {
    id: 'messagesStat.totalCount',
    label: 'Total',
    format: (value, broadcast) => <TotalCell broadcast={broadcast} />,
  },
  {
    id: 'messagesStat.successCount',
    label: 'Success',
    format: (value, { messagesStat }) =>
      numberString({ val: messagesStat?.successCount }),
  },
  {
    id: 'shortUrlClickStatistics',
    label: 'Clicks',
    format: (value, { shortUrlClickStatistics }) =>
      numberString({ val: shortUrlClickStatistics?.totalVisits || 0 }),
  },
  {
    id: 'messagesStat.pendingCount',
    label: 'Pending',
    format: (value, { messagesStat }) =>
      numberString({ val: messagesStat?.pendingCount }),
  },
  {
    id: 'messagesStat?.callbackCount',
    label: 'Callbacks',
    format: (value, { messagesStat }) =>
      numberString({ val: messagesStat?.callbackCount }),
  },
  {
    id: 'messagesStat.failedCount',
    label: 'Failed',
    format: (value, { messagesStat }) =>
      numberString({ val: messagesStat?.failedCount }),
  },
  {
    id: 'messagesStat.unsubscribedCount',
    label: 'Unsubscribed',
    format: (value, { messagesStat }) =>
      numberString({ val: messagesStat?.unsubscribedCount }),
  },
  ...conversionColumns,
]

export const noSortsColumns: string[] = [
  'type',
  'listBroadcasts',
  'shortUrlClickStatistics',
  'messagesStat.totalCount',
  'messagesStat.successCount',
  'messagesStat.pendingCount',
  'messagesStat?.callbackCount',
  'messagesStat.unsubscribedCount',
  // 'messagesStat.pending',
  'messagesStat.failedCount',
  ...conversionColumnsIds,
]

const TotalCell = observer(({ broadcast }: { broadcast: IBroadcast }) => {
  return (
    <div className={'row4'}>
      {numberString({ val: broadcast.messagesStat?.totalCount })}
      {broadcast.simplifiedStatus === 'Scheduled' && (
        <InfoTooltip
          marginLeft={0}
          title={[
            'Broadcast is scheduled.',
            <br key={'br1'} />,
            'Please check details to see',
            <br key={'br2'} />,
            'the estimated total.',
          ]}
        />
      )}
    </div>
  )
})
