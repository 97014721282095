import { observer } from 'mobx-react-lite'
import { useCreateBroadcastContext } from 'src/pages/main/broadcasts/createBroadcast/context/CreateBroadcastContext'
import { CollectedFields } from 'widgets/CollectedFields'

export const BroadcastCollectedFields = observer(() => {
  const { type, broadcastWorkflowStore } = useCreateBroadcastContext()
  if (type === 'SMS') {
    return (
      <CollectedFields store={broadcastWorkflowStore.collectedFieldsStore} />
    )
  }
  return null
})
