import { MuiTooltip, MuiTooltipProps } from 'components/Tooltip/MuiTooltip'
import { DropdownContextProvider } from 'components/Dropdown/context/context'
import * as React from 'react'
import { ReactNode, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Modifier } from 'react-popper'
import { useClickAway } from 'src/hooks/useClickAway'
import { ClickAwayListener } from '@mui/material'
import styles from './styles.module.scss'

export type IDropdownFieldProps = {
  children?: ReactNode
  dropdownContent: (
    open: boolean,
    setOpen: (value: boolean) => void
  ) => ReactNode
  withPopperOffset?: boolean
  isSameWidth?: boolean
  isFullWidth?: boolean
  triggerContent?: (open: boolean) => ReactNode
  withToggleOpen?: boolean
  disabled?: boolean
  classNameTrigger?: string
  classNameWrapper?: string
  onToggleOpen?: (open: boolean) => void
} & Omit<MuiTooltipProps, 'children' | 'title'>

export const sameWidth: Partial<Modifier<any, any>>[] = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fn: ({ state }) => {
    state.styles.popper.width = `${state.rects.reference.width}px`
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  effect: ({ state }) => {
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`
  },
}

export const DropdownField = ({
  dropdownContent,
  children,
  withPopperOffset,
  isFullWidth,
  isSameWidth,
  triggerContent,
  withToggleOpen,
  disabled,
  classNameWrapper,
  classNameTrigger,
  onToggleOpen,
  ...muiTooltipProps
}: Omit<IDropdownFieldProps, 'title'>) => {
  const [open, setOpen] = useState(false)
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  const modalRoot = document.getElementById('modal-root')

  const clickAwayRef = useClickAway<HTMLDivElement>(() => {
    if (!open) return

    setOpen(false)
  }, modalRoot)

  const onOpen = () => {
    if (disabled) return
    if (withToggleOpen) {
      setOpen(!open)
    } else {
      setOpen(true)
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  let modifiers: Partial<Modifier<any, any>>[] = []
  if (withPopperOffset) {
    modifiers = [
      {
        name: 'offset',
        options: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          offset: ({ reference }) => {
            return [0, -reference.height - 16]
          },
        },
      },
    ]
  }
  if (isSameWidth) {
    modifiers = modifiers?.concat(sameWidth)
  }

  useEffect(() => {
    onToggleOpen?.(open)
  }, [open])
  return (
    <DropdownContextProvider
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      parentElement={ref}
    >
      <ClickAwayListener onClickAway={onClose}>
        <div
          ref={clickAwayRef}
          style={{ display: 'flex' }}
          className={classNames(isFullWidth && 'fullWidth', classNameWrapper)}
        >
          <MuiTooltip
            padding={0}
            open={open}
            width={'100%'}
            PopperProps={{
              modifiers,
            }}
            title={<div ref={setRef}>{dropdownContent(open, setOpen)}</div>}
            white
            {...muiTooltipProps}
          >
            <button
              onClick={onOpen}
              className={classNames(
                isFullWidth && 'fullWidth',
                {
                  [styles.disabled]: disabled,
                },
                classNameTrigger
              )}
            >
              {children || triggerContent?.(open)}
            </button>
          </MuiTooltip>
        </div>
      </ClickAwayListener>
    </DropdownContextProvider>
  )
}
