import styles from 'components/Textarea/styles.module.scss'
import { Personalize } from 'components/Textarea/Personolize/Personalize'
import { ShortenLink } from 'components/Textarea/ShortenLink/ShortenLink'
import { EmojiPicker } from 'components/Textarea/actions/EmojiPicker/EmojiPicker'
import {
  MessageTemplateAction,
  MessageTemplatesModal,
} from 'widgets/MessageTemplate'
import {
  EnumFutureContent,
  MoreFeatures,
} from 'components/Textarea/MoreFeatures/MoreFeatures'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import shortenLinkStore from 'store/shortenLinkStore'
import { observer } from 'mobx-react-lite'
import { useTextareaContext } from 'components/Textarea/context/context'
import { ShortenLinkTooltip } from 'components/Textarea/ShortenLinkTooltip/ShortenLinkTooltip'
import { PersonalizeTT } from 'components/Textarea/Personolize/PersonalizeTT'
import { AttachmentGallery } from 'components/AttachmentGallery/AttachmentGallery'
import { ContactCardFragment } from 'src/generated/graphql'
import { AddMedia } from 'components/Textarea/actions/AddMedia/AddMedia'
import { Alert } from 'components/Alert/Alert'
import { ErrorsEnum } from 'src/static/errors'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export type ITextAreaActions = 'personalize'

export type ITextAreaActionsProps = {
  fromTemplate?: boolean
  filterFutureOption?: EnumFutureContent
  smallGallery?: boolean
  noActions?: boolean
  disablePersonalize?: boolean
  variant?: 'data-capture'
}

export const Actions = observer(
  ({
    fromTemplate,
    smallGallery,
    filterFutureOption,
    noActions,
    disablePersonalize,
    variant,
  }: ITextAreaActionsProps) => {
    const textareaStore = useTextareaContext()
    const {
      textFieldElement,
      addContent,
      setTriggerFocus,
      setSetTooltips,
      triggerFocus,
      currentPersonalize,
      onAddPersonalize,
      setCurrentPersonalize,
      hoverPersonalizeEvent,
    } = textareaStore

    const [linkTimeoutId, setLinkTimeoutId] = useState<NodeJS.Timeout | null>(
      null
    )

    const refHoverTooltipLink = useRef(false)
    const refHoverLink = useRef(false)

    const [currentHoverLink, setCurrentHoverLink] = useState<
      HTMLAnchorElement | undefined
    >(undefined)

    const onHoverLink = (link: HTMLAnchorElement) => {
      if (link.getAttributeNode('data-shorten')?.value !== 'true') {
        if (linkTimeoutId) {
          clearTimeout(linkTimeoutId)
        }
        refHoverLink.current = true
        setCurrentHoverLink(link)
      }
    }
    const onLeaveLink = () => {
      refHoverLink.current = false
      const id = setTimeout(() => {
        if (refHoverLink.current || refHoverTooltipLink.current) {
          return
        }
        setCurrentHoverLink(undefined)
      }, 1000)
      setLinkTimeoutId(id)
    }

    function hoverLinkEvent(link: HTMLAnchorElement) {
      onHoverLink(link)
    }

    const setTooltips = () => {
      if (textFieldElement) {
        const links = textFieldElement.querySelectorAll('a')
        links.forEach((link) => {
          if (!link.getAttribute('data-events')) {
            link.addEventListener('mouseenter', hoverLinkEvent.bind(this, link))
            link.addEventListener('mouseleave', onLeaveLink)
            link.setAttribute('data-events', 'true')
          }
        })
        const personalizes = textFieldElement.querySelectorAll('.personalize')
        personalizes.forEach((span) => {
          if (!span.getAttribute('data-events')) {
            span.addEventListener(
              'mouseenter',
              hoverPersonalizeEvent.bind(null, span as HTMLSpanElement)
            )
            span.setAttribute('data-events', 'true')
          }
        })
      }
    }

    useEffect(() => {
      setSetTooltips(setTooltips)
    }, [])

    useEffect(() => {
      setTooltips()
      textareaStore.handleHasPersonalizes()
    }, [textareaStore.triggerMessageUpdate])

    useEffect(() => {
      if (!currentPersonalize) {
        setTriggerFocus(false)
      }
      currentPersonalize?.classList.toggle('active')
      return () => {
        currentPersonalize?.classList.toggle('active')
      }
    }, [currentPersonalize])

    const onClickShortenLink = async (link?: string) => {
      if (currentHoverLink && textFieldElement) {
        const originalUrl = link || currentHoverLink.getAttribute('href')
        if (originalUrl) {
          const shortUrl = await shortenLinkStore.getShortenLink(originalUrl)
          if (shortUrl) {
            currentHoverLink?.setAttribute('data-shorten', 'true')
            currentHoverLink?.setAttribute('href', shortUrl)
            currentHoverLink?.setAttribute('target', '_blank')
            currentHoverLink.innerHTML = shortUrl
            textareaStore.setMessageText(textFieldElement.innerHTML)
          }
        }
      }

      setCurrentHoverLink(undefined)
    }

    const onAddShortenLink = async (longUrl: string, withError?: boolean) => {
      const shortUrl = await shortenLinkStore.getShortenLink(longUrl, withError)
      if (shortUrl) {
        const newLink = `<a href='${shortUrl}' data-shorten='true' target='_blank' >${shortUrl}</a>`
        addContent(newLink)
      }
    }

    const onAddEmoji = (emoji: string) => {
      addContent(emoji)
    }

    const onAddVCard = (vCard: ContactCardFragment) => {
      textareaStore.addVCard(vCard)
    }
    const onDeleteVCard = (id: number) => {
      textareaStore.removeVCard(id)
    }

    const onEditPersonalize = (
      span: HTMLSpanElement | undefined,
      value: string
    ) => {
      if (span && textFieldElement) {
        if (!value) {
          span?.removeAttribute('data-fallback')
        } else {
          span?.setAttribute('data-fallback', value)
        }
        textareaStore.setMessageText(textFieldElement.innerHTML)
      }
    }

    if (variant === 'data-capture') {
      return (
        <>
          <ShortenLinkTooltip
            onClickShortenLink={onClickShortenLink}
            currentHoverLink={currentHoverLink}
            refHoverLink={refHoverTooltipLink}
            setCurrentHoverLink={setCurrentHoverLink}
          />
          {!!currentPersonalize && (
            <PersonalizeTT
              visible={!!currentPersonalize}
              trigger={currentPersonalize}
              onClose={() => {
                setCurrentPersonalize(null)
              }}
              onEditPersonalize={onEditPersonalize}
              triggerFocus={triggerFocus}
            />
          )}
          <Personalize
            onAddPersonalize={onAddPersonalize}
            disabled={!!disablePersonalize}
            withTooltip
          />
        </>
      )
    }

    return (
      <>
        {!fromTemplate && <MessageTemplatesModal store={textareaStore} />}
        <ShortenLinkTooltip
          onClickShortenLink={onClickShortenLink}
          currentHoverLink={currentHoverLink}
          refHoverLink={refHoverTooltipLink}
          setCurrentHoverLink={setCurrentHoverLink}
        />
        {!!currentPersonalize && (
          <PersonalizeTT
            visible={!!currentPersonalize}
            trigger={currentPersonalize}
            onClose={() => {
              setCurrentPersonalize(null)
            }}
            onEditPersonalize={onEditPersonalize}
            triggerFocus={triggerFocus}
          />
        )}
        {(textareaStore.vCards.length ||
          !!textareaStore.attachImages?.length ||
          !!textareaStore.attachAudioItems?.length) && (
          <div className={styles.wrapGallery}>
            <AttachmentGallery
              attachImages={textareaStore.attachImages}
              attachAudioItems={textareaStore.attachAudioItems}
              onRemoveAudio={textareaStore.onRemoveAudio}
              removeAttachImages={(ids) =>
                textareaStore.removeAttachImages(ids)
              }
              onDeleteVCard={onDeleteVCard}
              small={smallGallery}
              fromTemplate={fromTemplate}
              vCards={textareaStore.vCards}
            />
          </div>
        )}
        {textareaStore.attachmentLimitError && (
          <Alert
            type={AlertTypeEnum.error}
            title={textareaStore.attachmentLimitError}
            text={ErrorsEnum.fileLimit5Mb}
            marginBottom={12}
          />
        )}
        {!noActions && (
          <div className={styles.elements}>
            <div className={styles.iconsWrap}>
              <>
                <AddMedia />
                <ShortenLink onAddShortenLink={onAddShortenLink} />
                <EmojiPicker onAddEmoji={onAddEmoji} />
                {!fromTemplate && <MessageTemplateAction />}
                {fromTemplate && (
                  <Personalize
                    onAddPersonalize={onAddPersonalize}
                    disabled={!!disablePersonalize}
                    withTooltip
                  />
                )}
                <MoreFeatures
                  filterFutureOption={filterFutureOption}
                  onAddVCard={onAddVCard}
                  textareaStore={textareaStore}
                  fromTemplate={fromTemplate}
                  disablePersonalize={disablePersonalize}
                />
              </>
            </div>
            <div
              className={classNames(
                styles.counter,
                (textareaStore.errorMMSLength ||
                  textareaStore.errorSMSLength) &&
                  styles.error
              )}
            >
              <span className={styles.active}>
                {textareaStore.messageCount}
              </span>
              <span>/</span>
              <span>{textareaStore.messageRemains}</span>
            </div>
          </div>
        )}
      </>
    )
  }
)
