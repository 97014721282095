import {
  ContactFragmentFragment,
  useGetMyContactQuery,
} from 'src/generated/graphql'
import { ContactPreviewUi } from 'widgets/ContactPreview/ui/ContactPreviewUi'
import { ContactPreviewContext } from 'widgets/ContactPreview/context/context'
import { useMemo } from 'react'
import { ContactPreviewStore } from 'widgets/ContactPreview/store/ContactPreviewStore'
import { Loader } from 'components/Loader/Loader'
import { Box } from '@mui/material'

type IContactPreview = {
  id: string | number
}

const SuccessContent = ({ contact }: { contact: ContactFragmentFragment }) => {
  const store = useMemo(() => new ContactPreviewStore(contact), [contact])
  return (
    <ContactPreviewContext.Provider value={store}>
      <ContactPreviewUi />
    </ContactPreviewContext.Provider>
  )
}

export const ContactPreview = ({ id }: IContactPreview) => {
  const { data, loading } = useGetMyContactQuery({
    variables: {
      contactId: id,
    },
  })

  if (loading) {
    return (
      <Box
        display={'flex'}
        flexGrow={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Loader />
      </Box>
    )
  }

  if (data?.getMyContact) {
    return <SuccessContent contact={data.getMyContact} />
  }

  return null
}
