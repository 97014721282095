import {
  Contact,
  ContactCustomField,
  ContactCustomFieldRequestInput,
  ContactCustomFieldResponse,
  ContactLightResponseForTableFragment,
  ContactRequestInput,
  CustomFieldType,
  FilterFragment,
  Listing,
  Maybe,
  Status,
} from 'src/generated/graphql'
import { IContact } from 'src/types/IContact'
import { IContactList } from 'src/types/IContactList'
import { ICustomFieldValue } from 'src/types/ICustomFieldValue'
import { ICustomField } from 'src/types/ICustomField'
import { nanoid } from 'nanoid'
import { IOption } from 'src/types/IOption'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import { ContactsFill, Filter, Segments, User } from 'components/Icon'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { Typography } from 'shared/ui/Typography'

export const customFieldsMapToCustomField = (
  customFieldsMap: Map<string, Partial<ICustomFieldValue>>
): Array<ContactCustomFieldRequestInput> => {
  const customFields: ContactCustomFieldRequestInput[] = []
  customFieldsMap.forEach((customField) => {
    const getValue = () => {
      if (
        customField.type === CustomFieldType.Text ||
        customField.type === CustomFieldType.Url
      ) {
        return customField.value?.trim()
      }
      if (
        customField.type === CustomFieldType.Date ||
        customField.type === CustomFieldType.DateTime
      ) {
        return customField.date
      }
      if (customField.type === CustomFieldType.Number) {
        return customField.value
      }
      return ''
    }

    customFields.push({
      key: customField.id,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: getValue(),
    })
  })

  return customFields
}

const customFieldToCustomFieldsMap = (
  customFields: Maybe<Array<Maybe<ContactCustomField>>>
): Map<string, Partial<ICustomFieldValue>> => {
  const filtered: ContactCustomField[] = []
  customFields?.forEach((customField) => {
    if (customField) {
      filtered.push(customField)
    }
  })
  return new Map<string, Partial<ICustomFieldValue>>(
    filtered.map((customField) => [
      customField.field?.key || '',
      {
        value: customField.text || customField.number,
        id: customField.field?.key || '',
        date: customField.date,
        type: customField.field?.type || CustomFieldType.Text,
      },
    ])
  )
}

const customFieldResponseToCustomFieldsMap = (
  customFields: Maybe<Array<Maybe<ContactCustomFieldResponse>>>
): Map<string, Partial<ICustomFieldValue>> => {
  const filtered: ContactCustomFieldResponse[] = []
  customFields?.forEach((customField) => {
    if (customField) {
      filtered.push(customField)
    }
  })
  return new Map<string, Partial<ICustomFieldValue>>(
    filtered.map((customField) => [
      customField?.key || '',
      {
        value: customField.text || customField.number || '',
        id: customField?.key || '',
        date: customField.date || '',
        type: (customField?.type as CustomFieldType) || CustomFieldType.Text,
      },
    ])
  )
}

export const transformContact = (contact: Contact): IContact => {
  const items: IItem[] = []
  const lists: IContactList[] = []
  contact.listContacts?.forEach((list) => {
    if (list?.listing) {
      lists.push(transformListingToList(list.listing))
      items.push({
        name: list.listing?.name || '',
        id: list.listing?.id,
        IconFC: ContactsFill,
        group: 'list',
      })
    }
  })
  return {
    ...contact,
    id: contact.id,
    items,
    // name: `${contact.firstName || ''} ${contact.lastName || ''}`.trim(),
    firstName: contact.firstName || '',
    lastName: contact.lastName || '',
    email: contact.email || '',
    phoneNumber: contact.formattedPhoneNumber || '',
    listContacts: lists.map((list) => list.id),
    optIn: contact.optIn,
    createdAt: contact.createdAt,
    status: contact.status || Status.Active,
    lists,
    customFieldsMap: contact.customFields
      ? customFieldToCustomFieldsMap(contact.customFields)
      : new Map<string, ICustomFieldValue>(),
  } as IContact
}

export const transformContactLightResponse = (
  contact: ContactLightResponseForTableFragment
): IContact => {
  const items: IItem[] = []
  contact.listings?.forEach((list) => {
    if (list) {
      items.push({
        name: list?.name || '',
        id: list?.id,
        IconFC: ContactsFill,
        group: 'list',
      })
    }
  })
  return {
    ...contact,
    id: contact.id,
    items,
    name: contact.name,
    email: contact.email || '',
    formattedPhoneNumber: contact.phone,
    phoneNumber: contact.phone || '',
    optIn: contact.optIn,
    createdAt: contact.createdAt,
    status: contact.status || Status.Active,
    customFieldsMap: contact.contactCustomFields
      ? customFieldResponseToCustomFieldsMap(contact.contactCustomFields)
      : new Map<string, ICustomFieldValue>(),
  } as IContact
}

export const transformContactToBack = (
  contact: IContact
): ContactRequestInput => ({
  email: contact.email?.trim(),
  firstName: contact.firstName?.trim(),
  id: contact.id,
  lastName: contact.lastName?.trim(),
  phoneNumber: contact.phoneNumber,
  status: contact.status,
  customFields: customFieldsMapToCustomField(contact.customFieldsMap),
  listIds: contact.lists?.map(listToListId),
})

export const listToListId = (list: IContactList) => Number(list.id)

export const transformListingToList = (listing: Listing): IContactList => ({
  ...listing,
  name: listing.name || '',
  id: String(listing.id),
  createdAt: listing.createdAt,
  isNew: false,
  contacts: [],
  listWebFormsNames:
    listing.listWebForms?.map((list) => list?.webForm?.name).join(' ') || '',
  listKeywordsNames:
    listing.listKeywords?.map((list) => list?.keyword?.name).join(' ') || '',
})
export const generateField = (): ICustomField => ({
  name: '',
  type: undefined,
  id: nanoid(),
  used: false,
  idLong: 0,
})
export const contactListToOption = ({
  name,
  id,
  activeContactsCount,
}: {
  name?: string | null
  id?: string
  activeContactsCount?: number
}): IOption => ({
  title: name || '',
  value: id || '',
  count: activeContactsCount,
  leftIcon: {
    children: <ContactsFill />,
    color: '--primary-color-gray-1',
  },
  iconClass: 'ml12 flex',
  group: 'list',
})

export const itemToOption = ({
  name,
  id,
  count,
  IconFC,
  group,
}: IItem): IOption => ({
  title: name || '',
  value: id || '',
  count,
  leftIcon: IconFC && {
    children: <IconFC />,
    color: '--primary-color-gray-1',
  },
  iconClass: 'ml12 flex',
  group: group || 'list',
})

export const segmentToOption = ({
  name,
  id,
}: {
  name?: string | null
  id?: number
}): IOption => ({
  title: name,
  value: id,
  leftIcon: {
    children: <Segments />,
    color: '--primary-color-gray-1',
  },
  iconClass: 'ml12 flex',
  group: 'segment',
})

export type ISimpleContactList = {
  id?: Maybe<any>
  name?: Maybe<string>
  activeContactsCount?: number
}

export const contactListToIListItem = (
  list: ISimpleContactList
): IListItem => ({
  id: list.id,
  item: list,
  group: 'Lists',
  text: list.name,
  dropdownButtonProps: {
    LeftIcon: ContactsFill,
    count: list.activeContactsCount,
  },
})

export type ISimpleContact = {
  id?: Maybe<any>
  name?: Maybe<string>
  formattedPhoneNumber?: Maybe<string>
  phone?: Maybe<string>
  email?: Maybe<string>
}

export const contactToIListItem = (contact: ISimpleContact): IListItem => ({
  id: contact.id,
  item: contact,
  group: 'Contacts',
  text: contact.name?.trim() || contact.formattedPhoneNumber || contact.phone,
  dropdownButtonProps: {
    LeftIcon: User,
    secondaryText: (contact.name?.trim() && contact.formattedPhoneNumber) || '',
    rightContentProps: {
      rightContent: contact.email ? (
        <Typography variant={'s2-regular'} color={'--primary-color-gray-1'}>
          {contact.email}
        </Typography>
      ) : undefined,
    },
  },
})

export type ISimpleSegment = {
  id?: Maybe<any>
  name?: Maybe<string>
}

export const segmentToIListItem = (segment: ISimpleSegment): IListItem => ({
  id: segment.id,
  item: segment,
  group: 'Segments',
  text: segment.name,
  dropdownButtonProps: {
    LeftIcon: Segments,
  },
})

export type ISimpleFilter = {
  id?: Maybe<any>
  name?: Maybe<string>
  filters: FilterFragment[]
}

export const filterToIListItem = (filter: ISimpleFilter): IListItem => ({
  id: filter.id,
  item: filter,
  group: 'Filters',
  text: filter.name,
  dropdownButtonProps: {
    LeftIcon: Filter,
  },
})
