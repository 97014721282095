import { Modal } from 'components/Modal/Modal'
import webFormStore from 'store/webForms/webFormStore'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { DatePicker } from 'components/DatePicker/DatePicker'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { AppWebForm, DataItem } from 'src/app_external/webform/AppWebform'
import { useMemo } from 'react'

export const TestWebFormModal = observer(() => {
  const onClose = () => {
    webFormStore.setTestWebForm(null)
  }
  const data = useMemo(() => {
    const data: DataItem[] = []
    webFormStore.testWebForm?.fieldsToCollect.forEach((field) => {
      data.push({
        key: field.field?.key || '',
        type: field.field?.type || '',
        required: !!field.required,
        name: field.field?.name || '',
        placeholder: '',
      })
    })
    return data
  }, [webFormStore.testWebForm])
  return (
    <Modal
      open={!!webFormStore.testWebForm}
      onClose={onClose}
      title={'Test web form'}
      minWidth={440}
      centered2
    >
      {webFormStore.testWebForm && (
        <div className={styles.wrapWebForm}>
          <AppWebForm
            testWebForm={webFormStore.testWebForm}
            data={data}
            fromApp
          />
        </div>
      )}
    </Modal>
  )
})

export const DateField = ({
  title,
  required,
  date = null,
  onSetDate,
}: {
  title: string
  required?: boolean
  date?: Date | null
  onSetDate: (date: Date | null) => void
}) => {
  return (
    <div>
      <span className={'s2 medium mb8 gray1'}>{title}</span>
      &nbsp;
      {required && <span className={'s2 medium error1'}>*</span>}
      <DatePicker startDate={date} onChange={onSetDate} />
    </div>
  )
}

export const DateTimeField = ({
  title,
  required,
  date = null,
  time = null,
  onSetDate,
  onSetTime,
}: {
  title: string
  required?: boolean
  date?: Date | null
  time?: Date | null
  onSetTime: (date: Date | null) => void
  onSetDate: (date: Date | null) => void
}) => {
  return (
    <div>
      <span className={'s2 medium mb8'}>{title}</span>
      &nbsp;
      {required && <span className={'s2 medium error1'}>*</span>}
      <div className={styles.dateTime}>
        <div>
          <DatePicker startDate={date} onChange={onSetDate} />
        </div>
        <div>
          <TimePicker startTime={time} onChange={onSetTime} />
        </div>
      </div>
    </div>
  )
}
