import 'react-datepicker/dist/react-datepicker.css'
import 'styles/all-webform.scss'
import { useState } from 'react'
import { ThankYouPageContent } from 'src/app_external/webform/ThankYouPageContent'
import { SubscribeContent } from 'src/app_external/webform/SubscribeContent'
import styles from './styles.module.scss'
import { ITestWebForm } from 'src/types/IWebForm'

export interface DataItem {
  type: string
  required: boolean
  key: string
  name: string
  placeholder: string
}

export const AppWebForm = ({
  fromApp,
  env,
  testWebForm,
  data,
}: {
  testWebForm: Omit<ITestWebForm, 'fieldsToCollect'>
  data: DataItem[]
  fromApp?: boolean
  env?: string
}) => {
  const [isThankYouPage, setIsThankYouPage] = useState(false)

  const { thankYouPage } = testWebForm

  const openThank = () => {
    if (thankYouPage) {
      const href = thankYouPage.startsWith('http')
        ? thankYouPage
        : '//' + thankYouPage
      const link = document.createElement('a')
      link.setAttribute('href', href)
      if (fromApp) {
        link.setAttribute('target', '_blank')
      }
      link.click()
    } else {
      setIsThankYouPage(true)
    }
  }

  return (
    <div className={styles.wrap}>
      {isThankYouPage ? (
        <ThankYouPageContent />
      ) : (
        <SubscribeContent
          testWebForm={testWebForm}
          data={data}
          openThank={openThank}
          fromApp={fromApp}
          env={env}
        />
      )}
    </div>
  )
}
