import {
  AccountIntegrationType,
  ContactOptIn,
  ContactSource,
} from 'src/generated/graphql'
import { Link } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { getAccountIntegrationTypeIIcon } from 'src/pages/main/triggers/create/components/getAccountIntegrationTypeIcon'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { Icon } from 'components/Icon/Icon'

export const SourceLink = ({
  id,
  name,
  source,
  variant2,
  fromDropdown,
  integrationType,
}: {
  id: string
  name: string
  source: string
  variant2?: boolean
  fromDropdown?: boolean
  integrationType?: AccountIntegrationType
}) => {
  const icon = integrationType
    ? getAccountIntegrationTypeIIcon(integrationType)
    : null
  return fromDropdown ? (
    <Link
      to={`${source}/${id}`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={styles.wrapLink}>
        {icon && (
          <MuiTooltip title={integrationType} placement={'top'} arrow>
            <div className={'box24'}>
              <Icon fontSize={'unset'}>{icon}</Icon>
            </div>
          </MuiTooltip>
        )}
        <div className={classNames(styles.link, variant2 && styles.variant2)}>
          {name}
        </div>
      </div>
    </Link>
  ) : (
    <Link
      className={classNames(styles.link, variant2 && styles.variant2)}
      to={`${source}/${id}`}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {name}
    </Link>
  )
}

export const OptInRender = ({
  optIn,
  contactSource,
}: {
  optIn: ContactOptIn
  contactSource?: ContactSource | null
}) => {
  if (optIn === ContactOptIn.Integrated) {
    return <span>Integration</span>
  }
  if (optIn === ContactOptIn.AddedManually) {
    return <span>Added manually</span>
  }
  if (optIn === ContactOptIn.AddedByKeyword) {
    return (
      <div className={styles.wrap}>
        <span>Keyword</span>
        {contactSource && (
          <SourceLink
            id={contactSource.id}
            name={contactSource.name || ''}
            source={RoutesEnum.keywords}
          />
        )}
      </div>
    )
  }
  if (optIn === ContactOptIn.AddedByWebForm) {
    return (
      <div className={styles.wrap}>
        <span>Web form</span>
        {contactSource && (
          <SourceLink
            id={contactSource.id}
            name={contactSource.name || ''}
            source={RoutesEnum.webForms}
          />
        )}
      </div>
    )
  }
  return <span>{optIn}</span>
}
