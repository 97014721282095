import styles from 'components/Dropdown/styles.module.scss'
import React from 'react'
import classNames from 'classnames'
import { IOption } from 'src/types/IOption'
import { AngleDown, AngleUp, Icon } from 'components/Icon'

export const RightContent = ({
  rightBtn,
  selectedOption,
  showTT,
  setShowTT,
  disabled,
}: {
  selectedOption?: IOption
  rightBtn?: JSX.Element
  showTT: boolean
  disabled?: boolean
  setShowTT?: (val: boolean) => void
}) =>
  rightBtn ? (
    rightBtn
  ) : (
    <div className={styles.checkWrap}>
      {selectedOption?.rightContent}
      <button
        onClick={() => setShowTT && setShowTT(!showTT)}
        className={classNames(styles.arrowBtn, disabled && styles.disabled)}
      >
        <Icon color={'--primary-color-gray-1'}>
          {showTT ? <AngleUp /> : <AngleDown />}
        </Icon>
      </button>
    </div>
  )
