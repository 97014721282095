import { observer } from 'mobx-react-lite'
import { CreateTagInput } from 'components/Input/CreateTagInput/CreateTagInput'
import { TextFilter } from 'store/contacts/segment/filters/TextFilter'

interface Props {
  filter: TextFilter
  readonly: boolean
}

export const TextFilterContent = observer(({ filter, readonly }: Props) => {
  return (
    <CreateTagInput
      number={filter.isNumber}
      options={filter.options || []}
      onCreateTag={filter.addValue}
      onDeleteTag={(id) => filter.onDeleteId(id as string)}
      readonly={readonly}
    />
  )
})
