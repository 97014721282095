import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { useDataCaptureContext } from 'widgets/DataCapture/context/context'
import { CaptureField } from 'widgets/DataCapture/widtets/CaptureField'
import { Button } from 'shared/ui/Button/Button'
import { DragList } from 'components/DragList/DragList'
import React, { useMemo } from 'react'
import { ComplianceContent } from 'components/CreateElement/SMSContent/ComplianceContent/ComplianceContent'
import { Textarea } from 'components/Textarea/Textarea'
import { Label } from 'shared/ui/Label/Label'
import { appLinks } from 'src/util/links'

export const DataCaptureUi = observer(() => {
  const {
    items,
    addItem,
    orderItems,
    setOrderItems,
    complianceStore,
    thankYouMessageStore,
    deleteItem,
  } = useDataCaptureContext()

  const elements = useMemo(() => {
    return items.map((filed, index) => ({
      id: filed.id,
      component: (
        <CaptureField
          key={filed.id}
          store={filed}
          index={index}
          onDelete={deleteItem}
          itemLength={items.length}
        />
      ),
    }))
  }, [items])
  return (
    <div className={styles.wrap}>
      <DragList
        elements={elements}
        order={orderItems}
        setOrder={setOrderItems}
        wrapClassName={styles.wrapFields}
      />

      <Button variant={'add'} text={'Add question'} onClick={addItem} />
      <div>
        <ComplianceContent
          store={complianceStore}
          complianceMessageTT={
            <>
              Append compliance language <br /> to your first question{' '}
              <a
                className={'s1 medium'}
                href={appLinks.callLoopCompliance}
                target={'_blank'}
                onClick={(e) => e.stopPropagation()}
                rel="noreferrer"
              >
                Learn more.
              </a>
            </>
          }
        />
      </div>
      <div>
        <Label
          text={'Thank you message'}
          marginBottom={8}
          infoTooltipProps={{
            title: [
              'The last automated message sent',
              <br key={'br'} />,
              'once all data has been collected',
            ],
          }}
        />
        <Textarea
          textareaStore={thankYouMessageStore}
          className={styles.thankYouMessage}
          noBorder
          textareaStyle={{
            height: 64,
          }}
        />
      </div>
      {/*<SmartRetry />*/}
    </div>
  )
})
