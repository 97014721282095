import { Tabs } from 'components/Tabs/Tabs'
import styles from './styles.module.scss'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'
import { observer } from 'mobx-react-lite'
import { NewLabel } from 'src/shared/ui/NewLabel/NewLabel'
import { Header } from 'shared/ui/Header/Header'

export const Contacts = observer(() => {
  useGetCustomFields()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isLists = pathname.includes(RoutesEnum.lists)
  const isSegments = pathname.includes(RoutesEnum.segments)
  const getActiveIndex = () => {
    if (isLists) {
      return 1
    }
    if (isSegments) {
      return 2
    }
    return 0
  }

  const onTabClick = (index: number) => {
    if (index === 0) {
      navigate(RoutesEnum.contacts)
    }
    if (index === 1) {
      navigate(RoutesEnum.contacts + '/' + RoutesEnum.lists)
    }
    if (index === 2) {
      navigate(RoutesEnum.contacts + '/' + RoutesEnum.segments)
    }
  }

  return (
    <>
      <Header title={'Contacts'} />
      <div className={styles.wrapTabs}>
        <Tabs
          tabs={[
            'Contacts',
            'Lists',
            <>
              Segments
              <NewLabel text={'Beta'} />
            </>,
          ]}
          activeTabIndex={getActiveIndex()}
          onTabClick={onTabClick}
        />
      </div>
      <Outlet />
    </>
  )
})
