// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YRfh5GDWqwLg_N73ftm3{display:flex;flex-direction:column;gap:24px;margin-bottom:32px}.KZrOvaTfuFlhTZlbRQN7{margin-top:12px}.htG3alMsvd_dI2YCL6HU{display:flex;align-items:center;gap:8px;height:24px}.ZSBwtUpd1KdVmGDgIB3f{margin-top:16px;display:grid;grid-template-columns:1fr 1fr;gap:16px}`, "",{"version":3,"sources":["webpack://./src/pages/main/webForms/create/createForm/Settings/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CACA,kBAAA,CAEF,sBACE,eAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CACA,OAAA,CACA,WAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,6BAAA,CACA,QAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  margin-bottom: 32px;\n}\n.wrapSubscribeSettings{\n  margin-top: 12px;\n}\n.wrapSwitch{\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  height: 24px;\n}\n.subscribeSettings{\n  margin-top: 16px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `YRfh5GDWqwLg_N73ftm3`,
	"wrapSubscribeSettings": `KZrOvaTfuFlhTZlbRQN7`,
	"wrapSwitch": `htG3alMsvd_dI2YCL6HU`,
	"subscribeSettings": `ZSBwtUpd1KdVmGDgIB3f`
};
export default ___CSS_LOADER_EXPORT___;
