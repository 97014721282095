import { observer } from 'mobx-react-lite'
import { TextareaStore } from 'store/textareaStore'
import parse from 'html-react-parser'
import { textToHtml } from 'src/util/functions'
import { UiPreviewSMSContent } from 'components/Preview/PreviewSMSContent/UiPreviewSMSContent'

type IPreviewSMSContent = {
  phone: string
  store: TextareaStore
  placeholder?: string
  customCompliance?: {
    compliance: boolean
    complianceText: string
  }
}
export const PreviewSMSContent = observer(
  ({
    store,
    phone,
    placeholder = 'Write your message...',
    customCompliance,
  }: IPreviewSMSContent) => {
    const {
      vCards,
      attachImages,
      attachAudioItems,
      text,
      compliance,
      complianceText,
    } = store

    return (
      <UiPreviewSMSContent
        phone={phone}
        text={
          <>
            {parse(textToHtml(text, true, true))}
            {customCompliance
              ? `${
                  customCompliance.compliance
                    ? ` ${customCompliance.complianceText}`
                    : ''
                }`
              : `${compliance ? ` ${complianceText}` : ''}`}
          </>
        }
        placeholder={placeholder}
        vCards={vCards}
        attachImages={attachImages}
        attachAudioItems={attachAudioItems}
      />
    )
  }
)
