// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NJjXhH6VoP8_oIQQbUwt{display:flex;gap:24px;overflow:auto;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/widgets/AddFiltersModal/ui/Include/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,aAAA,CACA,WAAA","sourcesContent":[".wrap{\n  display: flex;\n  gap: 24px;\n  overflow: auto;\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `NJjXhH6VoP8_oIQQbUwt`
};
export default ___CSS_LOADER_EXPORT___;
