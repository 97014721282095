// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drRsKjHUyDeho4m_OC7G{display:flex;padding:16px 16px 16px;flex-direction:column;align-items:flex-start;gap:12px}.drRsKjHUyDeho4m_OC7G .RWhTsy20tI9NiNGuovyi{width:100%;display:flex;overflow:hidden;align-items:flex-start}.gKQfgsPdkAPukeU_v9jQ{display:flex;gap:4px;flex-wrap:wrap}`, "",{"version":3,"sources":["webpack://./src/widgets/ContactPreview/ui/InfoCard/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,qBAAA,CACA,sBAAA,CACA,QAAA,CACA,4CACE,UAAA,CACA,YAAA,CACA,eAAA,CACA,sBAAA,CAKJ,sBACE,YAAA,CACA,OAAA,CACA,cAAA","sourcesContent":[".infoCard{\n  display: flex;\n  padding: 16px  16px 16px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 12px;\n  .row{\n    width: 100%;\n    display: flex;\n    overflow: hidden;\n    align-items: flex-start;\n\n  }\n}\n\n.lists{\n  display: flex;\n  gap: 4px;\n  flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoCard": `drRsKjHUyDeho4m_OC7G`,
	"row": `RWhTsy20tI9NiNGuovyi`,
	"lists": `gKQfgsPdkAPukeU_v9jQ`
};
export default ___CSS_LOADER_EXPORT___;
