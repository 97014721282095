// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vpVpznf5KkTms71voERS{background:var(--primary-color-gray-4);border-radius:0 0 20px 20px;height:48px;display:flex;align-items:center;justify-content:center}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectedFields/widgets/CollectedField/ui/Actions/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,sCAAA,CACA,2BAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".wrap{\n  background: var(--primary-color-gray-4);\n  border-radius: 0 0 20px 20px;\n  height: 48px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `vpVpznf5KkTms71voERS`
};
export default ___CSS_LOADER_EXPORT___;
