import { Button } from 'shared/ui/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import * as React from 'react'
import { MouseEventHandler, useEffect, useState } from 'react'
import {
  PhoneNumber,
  useDeletePhoneNumberMutation,
} from 'src/generated/graphql'
import numbersStore from 'store/settings/numbers/numbersStore'
import { Delete } from 'components/Icon'

export const DeleteCallerIdNumberBtn = ({ phone }: { phone: PhoneNumber }) => {
  const [deletePhoneNumber, { loading }] = useDeletePhoneNumberMutation()
  const [isConfirm, setIsConfirm] = useState(false)
  const [idTimeout, setIdTimeout] = useState<NodeJS.Timeout | null>(null)
  const onDelete: MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.stopPropagation()
    if (isConfirm) {
      try {
        await deletePhoneNumber({
          variables: {
            id: phone.id,
          },
        })
        numbersStore.deleteNumber(phone)
      } catch (e) {
        console.error(e)
      }
    } else {
      setIsConfirm(true)
      setIdTimeout(
        setTimeout(() => {
          setIsConfirm(false)
        }, 3000)
      )
    }
  }
  useEffect(() => {
    return () => {
      if (idTimeout) {
        clearTimeout(idTimeout)
      }
    }
  }, [])
  return (
    <MuiTooltip title={isConfirm ? 'Sure?' : 'Delete'} arrow placement={'top'}>
      <Button
        variant={'inactive-small'}
        onClick={onDelete}
        LeftIcon={Delete}
        loading={loading}
      />
    </MuiTooltip>
  )
}
