import { observer } from 'mobx-react-lite'
import { EmptyContent } from 'widgets/AddFiltersModal/ui/EmptyContent/EmptyContent'
import { useEvaluateFilterQuery } from 'src/generated/graphql'
import contactStore from 'store/contacts/contactStore'
import * as React from 'react'
import { useSetData } from 'src/hooks/useSetData'
import styles from './styles.module.scss'
import { ContactsTable } from 'src/pages/main/contacts/ContactsTable/ContactsTable'
import { BtnSize } from 'components/Button/Button'
import { useAddFiltersModalContext } from 'widgets/AddFiltersModal/context/context'

export const Contacts = observer(() => {
  const { contactsPickerStore } = useAddFiltersModalContext()

  const queryResult = useEvaluateFilterQuery({
    variables: {
      page: contactStore.tableStoreContacts.paginationInput,
      filterValue: contactsPickerStore.filterValue,
    },
    fetchPolicy: 'network-only',
  })

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => contactStore.setSearchContactsData(data),
    refreshDeps: [contactStore.tableStoreContacts.refreshTrigger],
    disabledRefetch: !contactStore.tableStoreContacts.refreshTrigger,
  })

  if (!queryResult.data?.evaluateFilter?.total && !firstLoading && !loading) {
    return <EmptyContent text={'No matched contacts'} />
  }

  return (
    <div className={styles.wrap}>
      <ContactsTable
        noAdditionalContent
        noSelectContent={null}
        pageLoading={loading || refetchLoading}
        firstLoading={
          firstLoading ||
          (loading &&
            contactStore.tableStoreContacts.paginationInput.pageNumber === 0)
        }
        tableStore={contactStore.tableStoreContacts}
        withSearch={false}
        extraBtnSize={BtnSize.small}
        noSortColumnsProps={contactStore.tableStoreContacts.columns
          .map((column) => column.id)
          .filter((id) => id.startsWith('custom'))}
        noPadding
        withoutEmptyState
        overrideWrapStickyClass={styles.overrideWrapStickyClass}
        noStickyHeader={!contactStore.tableStoreContacts.checkedRowsIds.length}
      />
    </div>
  )
})
