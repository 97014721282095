import { CancellationReasonType } from 'src/generated/graphql'
import {
  FeedbackContent,
  IFeedbackContentProps,
} from 'components/Feedbackontent/FeedbackContent'

const cancellationReasonTypeAnswers: Map<CancellationReasonType, string> =
  new Map([
    [CancellationReasonType.SoftwareIssue, 'Issues or bugs in the software'],
    [
      CancellationReasonType.DifficultToUse,
      'It was too hard or difficult to use',
    ],
    [CancellationReasonType.SupportQuality, 'Quality of support'],
    [
      CancellationReasonType.TemporaryUsage,
      'I only needed Call Loop temporarily',
    ],
    [CancellationReasonType.AnotherSolution, 'I found another solution'],
    [
      CancellationReasonType.BudgetIssue,
      'I can no longer afford it / budget reasons',
    ],
    [CancellationReasonType.Other, 'Other'],
  ])

type ICancellationFeedbackContentProps = Omit<
  IFeedbackContentProps,
  'selectedAnswer' | 'setSelectedAnswer' | 'answers' | 'title'
> & {
  selectedAnswer: CancellationReasonType | null
  setSelectedAnswer: (id: CancellationReasonType) => void
  withoutBudgetReason?: boolean
  isRequiredComment?: boolean
}

export const CancellationFeedbackContent = ({
  selectedAnswer,
  setSelectedAnswer,
  withoutBudgetReason,
  ...props
}: ICancellationFeedbackContentProps) => {
  const handleSelectedAnswer = (id: string) => {
    setSelectedAnswer(id as CancellationReasonType)
  }

  const map = new Map(cancellationReasonTypeAnswers)
  if (withoutBudgetReason) {
    map.delete(CancellationReasonType.BudgetIssue)
  }

  const answers = Array.from(map.entries())

  return (
    <FeedbackContent
      {...props}
      title={"We'd like to know why you're cancel your subscription?"}
      selectedAnswer={selectedAnswer}
      setSelectedAnswer={handleSelectedAnswer}
      answers={answers}
    />
  )
}
