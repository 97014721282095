import styles from 'src/pages/main/triggers/TriggersTable/styles.module.scss'
import { ReactNode } from 'react'
import { Icon, IIconProps } from 'components/Icon'

type Props = {
  leftIcon?: IIconProps
  iconClass?: string
  title: string | ReactNode
}

export const AppRender = ({ leftIcon, iconClass, title }: Props) => (
  <div className={styles.appWrap}>
    {leftIcon && (
      <div className={iconClass}>
        <Icon fontSize={'unset'} {...leftIcon} />
      </div>
    )}
    {title}
  </div>
)
