import styles from './styles.module.scss'
import { Loader } from 'components/Loader/Loader'
import { Typography } from 'shared/ui/Typography'

const emptyText =
  'You don’t have any contacts. Click on the right icon to import or add manually contacts.'

export type ITopContentProps = {
  loading: boolean
  isEmpty: boolean
  search: string
}

export const TopContent = ({ loading, isEmpty, search }: ITopContentProps) => {
  if (loading) {
    return (
      <div className={styles.wrap}>
        {loading && <Loader size={16} />}
        {!loading && (
          <Typography variant={'s1-regular'} color={'--primary-color-gray-1'}>
            {isEmpty}
          </Typography>
        )}
      </div>
    )
  }
  if (isEmpty) {
    return (
      <div className={styles.wrap}>
        <Typography variant={'s1-regular'} color={'--primary-color-gray-1'}>
          {search ? `No results found` : emptyText}
        </Typography>
      </div>
    )
  }
  return null
}
