// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aA7s_P37Wv676qkvgcw1{padding:8px}.mhpFBRKaAa79K5ZuWd47{display:flex;padding:4px 8px 8px 8px}`, "",{"version":3,"sources":["webpack://./src/components/AddContactsBy/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CAEF,sBACE,YAAA,CACA,uBAAA","sourcesContent":[".wrapTT{\n  padding: 8px;\n}\n.label{\n  display: flex;\n  padding: 4px 8px 8px 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapTT": `aA7s_P37Wv676qkvgcw1`,
	"label": `mhpFBRKaAa79K5ZuWd47`
};
export default ___CSS_LOADER_EXPORT___;
