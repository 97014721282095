import { ContactsPickerStore } from 'widgets/ContactsPicker'
import { Button } from 'shared/ui/Button/Button'
import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { InvisibleTooltipBtn } from './InvisibleTooltipBtn/InvisibleTooltipBtn'

export const ContactsPickerUiForTable = observer(
  ({
    store,
    onHoverItemsCount,
  }: {
    store: ContactsPickerStore
    onHoverItemsCount?: () => void
  }) => {
    const { selectedItems, count } = store

    const firstItem = selectedItems.length && selectedItems[0]
    const otherItems = selectedItems.slice(1)

    const invisibleCount = count || otherItems.length

    return (
      <div className={styles.wrap}>
        {!!firstItem && (
          <Button
            variant={'x-small'}
            LeftIcon={firstItem.dropdownButtonProps?.LeftIcon}
            text={firstItem.text}
            count={firstItem.dropdownButtonProps?.count}
          />
        )}
        {invisibleCount > 0 && (
          <div onMouseEnter={onHoverItemsCount}>
            <InvisibleTooltipBtn items={otherItems} count={count} />
          </div>
        )}
      </div>
    )
  }
)
