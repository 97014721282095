import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { IKeyword } from 'src/types/IKeyword'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { Maybe, PhoneNumber, Status } from 'src/generated/graphql'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

export const KeywordTestBtn = (
  props: ActionBtnProps<IKeyword> & {
    isNeedSave?: boolean
    fromTable?: boolean
    fromCreate?: boolean
    phonesProps?: Maybe<PhoneNumber>[]
    status?: Status
  }
) => {
  const { row, setHover, isNeedSave, phonesProps } = props

  let phones: Maybe<PhoneNumber>[] = []

  const name = row?.name

  if (row) {
    row.keywordPhoneNumbers?.forEach((phone) => {
      if (phone?.phoneNumber) {
        phones.push(phone?.phoneNumber)
      }
    })
  } else if (phonesProps) {
    phones = phonesProps
  }

  const status = row?.status || props.status

  const getTextTooltipTest = () => {
    if (
      status === Status.Draft ||
      status === Status.Disabled ||
      status === Status.Archived
    ) {
      return 'Enable keyword before testing'
    }
    if (isNeedSave) {
      return 'Save keyword before testing'
    }
    if (name) {
      if (phones?.length === 1) {
        return (
          <span className={'gray2'}>
            Text <span className={'white'}>{name}</span> to{' '}
            <span className={'white'}>{phones[0]?.friendlyName}</span>
            <br /> to test the keyword
          </span>
        )
      }
      if (phones?.length) {
        return (
          <span className={'gray2'} style={{ width: 147 }}>
            Text <span className={'white'}>{name}</span> to one of the phone
            numbers to test the keyword
          </span>
        )
      }
    } else {
      return ''
    }
  }

  if (props.menu) {
    return (
      <MuiTooltip title={getTextTooltipTest()} arrow placement={'top'}>
        <div>
          <MenuBtn type={MenuEnum.testMessage} />
        </div>
      </MuiTooltip>
    )
  }

  return (
    <TestItBtn
      fromCreate={props.fromCreate}
      fromTable={props.fromTable}
      setHover={setHover}
      textTooltip={getTextTooltipTest()}
      withoutAction
    />
  )
}
