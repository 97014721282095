import {
  action,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { CreateStore } from 'components/CreateElement/store/createStore'
import { IBroadcastFull } from 'src/types/IBroadcast'
import {
  BroadcastEstimatedDurationResponse,
  BroadcastInput,
  ContentType,
  GetBroadcastEstimatedDurationQuery,
  PhoneType,
  Status,
  VerificationStatus,
} from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'
import { IOption } from 'src/types/IOption'
import numbersStore from 'store/settings/numbers/numbersStore'
import { BroadcastScheduleStore } from 'src/widgets/BroadcastSchedule'
import { CommonApi } from 'src/api/api'
import { BroadcastApi } from 'src/api/broadcasts/api'
import { getEstimatedDurationResponse } from 'store/broadcasts/getEstimatedInfo'
import { ReviewBroadcastStore } from 'src/pages/main/broadcasts/createBroadcast/ReviewBroadcast/store/reviewBroadcastStore'
import { BroadcastWorkflowStore } from 'store/broadcasts/broadcastWorkflowStore'
import { ContactsPickerStore } from 'widgets/ContactsPicker/store/ContactsPickerStore'

export class CreateBroadCastStore extends CreateStore {
  contactsPickerStore: ContactsPickerStore
  scheduleStore: BroadcastScheduleStore
  broadcastWorkflowStore: BroadcastWorkflowStore

  disposeReactionBroadcastInput
  disposeReactionIsUnVerifyPhone
  disposeReactionSendToInput
  disposeReactionAdvancedSchedulingConfigInput

  dispose = () => {
    this.disposeReactionBroadcastInput()
    this.disposeReactionIsUnVerifyPhone()
    this.disposeReactionSendToInput()
    this.disposeReactionAdvancedSchedulingConfigInput()
  }

  constructor(broadcast?: IBroadcastFull, isDuplicate?: boolean) {
    super({
      createBtnText: 'Create broadcast',
    })
    this.contactsPickerStore = new ContactsPickerStore({
      disabledCloseDropdown: true,
    })
    this.scheduleStore = new BroadcastScheduleStore()
    this.broadcastWorkflowStore = new BroadcastWorkflowStore(this)

    makeObservable(this, {
      initInput: observable,
      contactsPickerStore: observable,
      edit: observable,
      editId: observable,
      duplicate: observable,
      status: observable,
      selectedContactListOptions: observable,
      isHiddenVerifyInfoTollFree: observable,
      isHiddenVerifyInfoLocal: observable,
      estimatedDurationResponse: observable,
      activeContactsCount: observable,
      broadcastWorkflowStore: observable,

      disabledCreate: computed,
      broadcastInput: computed,
      isVerifyInfoTollFree: computed,
      isVerifyInfoLocal: computed,
      isUnVerifyPhone: computed,
      isLimitInfo: computed,
      estimatedInfo: computed,
      advancedSchedulingConfigInput: computed,
      isSendingSpeedError: computed,

      setEdit: action.bound,

      setHiddenVerifyInfoTollFree: action.bound,
      setHiddenVerifyInfoLocal: action.bound,
    })

    if (broadcast) {
      this.setEdit(broadcast, isDuplicate || false)
    }

    this.disposeReactionBroadcastInput = reaction(
      () => this.broadcastInput,
      (input) => {
        if (!this.initInput) {
          this.initInput = input
        }
        const initInputString = JSON.stringify(this.initInput)
        const inputString = JSON.stringify(input)
        runInAction(() => {
          this.isHaveChanges = initInputString !== inputString
        })
      }
    )

    this.disposeReactionIsUnVerifyPhone = reaction(
      () => this.isUnVerifyPhone,
      (value) => {
        this.textareaStore.setUnVerifyToolFree(value)
      }
    )
    this.disposeReactionSendToInput = reaction(
      () => this.contactsPickerStore.filterValue,
      this.getActiveContactsCount
    )
    this.disposeReactionAdvancedSchedulingConfigInput = reaction(
      () => [this.advancedSchedulingConfigInput, this.activeContactsCount],
      this.getBroadcastEstimatedDuration
    )
  }

  element = 'Broadcast'
  edit = false
  editId = ''
  duplicate = false
  selectedContactListOptions: IOption[] = []
  status = Status.Scheduled
  initInput: BroadcastInput | null = null
  isHiddenVerifyInfoTollFree = false
  isHiddenVerifyInfoLocal = false

  activeContactsCount = 0
  setActiveContactsCount = (value: number) => {
    this.activeContactsCount = value
  }

  getActiveContactsCount = async () => {
    try {
      if (!this.contactsPickerStore.isEmpty) {
        const { data } = await CommonApi.getActiveContactsCount({
          input: this.broadcastInput,
        })
        this.setActiveContactsCount(data.getActiveContactsCount)
      } else {
        this.setActiveContactsCount(0)
      }
    } catch (e) {
      console.error(e)
    }
  }
  getBroadcastEstimatedDuration = async () => {
    if (this.isSendingSpeedError) return
    try {
      if (
        this.activeContactsCount &&
        this.advancedSchedulingConfigInput?.contactsPerDay
      ) {
        const { data } = await BroadcastApi.getBroadcastEstimatedDuration({
          broadcastInput: this.broadcastInput,
          expectedContactsCount: this.activeContactsCount,
        })
        this.setEstimatedDuration(data)
      } else {
        this.setEstimatedDuration(null)
      }
    } catch (e) {
      console.error(e)
    }
  }

  get isUnVerifyPhone() {
    return !(
      this.smsPhoneFull?.verificationStatus === VerificationStatus.Verified ||
      this.smsPhoneFull?.verificationStatus === VerificationStatus.Pending ||
      this.smsPhoneFull?.verificationStatus === VerificationStatus.InReview
    )
  }

  get isVerifyInfoTollFree() {
    return (
      this.isUnVerifyPhone &&
      !this.isHiddenVerifyInfoTollFree &&
      this.smsPhoneFull?.type === PhoneType.TollFree
    )
  }

  get isVerifyInfoLocal() {
    return (
      this.isUnVerifyPhone &&
      !this.isHiddenVerifyInfoLocal &&
      this.smsPhoneFull?.type === PhoneType.Local
    )
  }

  get isLimitInfo() {
    if (this.activeTab === 0) {
      if (billingStore.isTrial) {
        return true
      }
      if (this.smsPhoneFull?.type === PhoneType.ShortCode) {
        return false
      }
      if (
        this.smsPhoneFull?.type === PhoneType.TollFree &&
        this.smsPhoneFull.verificationStatus === VerificationStatus.Verified
      ) {
        return false
      }
      if (
        this.smsPhoneFull?.type === PhoneType.Local &&
        this.smsPhoneFull?.verificationStatus === VerificationStatus.Verified
      ) {
        return false
      }
      return !!this.smsPhoneFull?.type
    }
    return false
  }

  get disabledCreate() {
    if (!this.name) {
      return true
    }
    if (this.scheduleStore.scheduleError) {
      return true
    }
    if (!this.broadcastInput.sendFromPhoneNumber) {
      return true
    }
    if (this.type === ContentType.Sms) {
      if (!this.textareaStore.smsMessageInput.text) {
        return true
      }
      if (this.textareaStore.isError) {
        return true
      }
    }
    if (this.type === ContentType.Voice) {
      if (this.voiceStore.disabledCreate) {
        return true
      }
    }
    if (this.type === ContentType.Ringless) {
      if (this.ringlessStore.disabledCreate) {
        return true
      }
    }
    if (this.contactsPickerStore.isEmpty) {
      return true
    }
    if (this.isSendingSpeedError) {
      return true
    }
    return false
  }

  get advancedSchedulingConfigInput() {
    return this.scheduleStore.advancedSchedulingConfigInput
  }

  get broadcastInput(): BroadcastInput {
    return {
      id: this.edit ? this.editId : undefined,
      name: this.name,
      status: this.status,
      filterValue: this.contactsPickerStore.filterValue,
      smsMessage:
        this.type === ContentType.Sms && !this.broadcastWorkflowStore.input
          ? this.textareaStore.smsMessageInput
          : undefined,
      voiceMessage:
        this.type === ContentType.Voice
          ? this.voiceStore.voiceMessageInput
          : undefined,
      ringlessMessage:
        this.type === ContentType.Ringless
          ? this.ringlessStore.ringlessMessageInput
          : undefined,
      sendFromPhoneNumber: this.phonePull,
      schedulingConfig: this.scheduleStore.schedulingConfigInput,
      advancedSchedulingConfig: this.advancedSchedulingConfigInput
        ? {
            ...this.advancedSchedulingConfigInput,
            durationDays: this.estimatedDurationResponse?.durationDays || 0,
            durationHours: this.estimatedDurationResponse?.durationHours || 0,
            durationMinutes:
              this.estimatedDurationResponse?.durationMinutes || 0,
          }
        : null,
      conversion:
        this.type === ContentType.Sms
          ? this.trackConversionStore.conversionInput
          : null,
      workflowConfig: this.broadcastWorkflowStore.input,
    }
  }

  setOpenReviewModal = () => {
    new ReviewBroadcastStore(this)
  }

  setEdit = (broadcast: IBroadcastFull, isDuplicate: boolean) => {
    const newBroadcast: IBroadcastFull = {
      ...broadcast,
      name: `${broadcast.name}${isDuplicate ? ' copy' : ''}`,
    }
    if (isDuplicate) {
      this.duplicate = true
      this.status = Status.Scheduled
    } else {
      this.edit = true
      this.editId = broadcast.id
      this.status = broadcast.status || Status.Draft
    }
    this.name = newBroadcast.name || ''
    if (!broadcast.type || broadcast.type === 'None') {
      this.type = ContentType.Sms
    } else {
      this.type = broadcast.type
    }

    if (broadcast.sendFromPhoneNumber) {
      numbersStore.setNumbers([broadcast.sendFromPhoneNumber])
    }

    if (broadcast.type === ContentType.Sms) {
      if (
        !broadcast.sendFromPhoneNumber?.verificationStatus ||
        broadcast.sendFromPhoneNumber?.verificationStatus ===
          VerificationStatus.Unverified
      ) {
        this.smsPhone = ''
      } else {
        this.smsPhone = broadcast.sendFromPhoneNumber?.friendlyName || ''
      }
    }
    if (broadcast.type !== ContentType.Sms) {
      if (broadcast.type === ContentType.Voice) {
        this.voiceStore.setInit(broadcast.voiceMessage)
      }
      if (
        broadcast.type === ContentType.Ringless &&
        broadcast.ringlessMessage
      ) {
        this.ringlessStore.setInit(broadcast.ringlessMessage)
      }
      this.voicePhone = broadcast.sendFromPhoneNumber?.friendlyName || ''
    }
    this.textareaStore.setSmsMessage(broadcast.smsMessage)
    this.textareaStore.setTriggerMessageUpdate()

    this.contactsPickerStore.setInitItems(broadcast)

    if (broadcast.schedulingConfig) {
      this.scheduleStore.setSchedulingConfig(broadcast.schedulingConfig)
    }
    if (broadcast.advancedSchedulingConfig) {
      this.scheduleStore.setAdvancedSchedulingConfig(
        broadcast.advancedSchedulingConfig
      )
    }
    if (broadcast.conversion) {
      this.setAdvanced(true)
      this.trackConversionStore.init(broadcast.conversion)
    }
    if (broadcast.workflowConfig) {
      if (broadcast.workflowConfig.actions?.[0]?.properties.messageConfig) {
        this.textareaStore.setSmsMessage(
          broadcast.workflowConfig.actions[0]?.properties.messageConfig
        )
      }

      this.broadcastWorkflowStore.init(broadcast.workflowConfig)
    }
    this.initInput = this.broadcastInput
  }

  setHiddenVerifyInfoTollFree() {
    this.isHiddenVerifyInfoTollFree = true
  }
  setHiddenVerifyInfoLocal() {
    this.isHiddenVerifyInfoLocal = true
  }

  estimatedDurationResponse: BroadcastEstimatedDurationResponse | null = null
  get estimatedInfo() {
    if (!this.estimatedDurationResponse) {
      return null
    }

    const info = getEstimatedDurationResponse(this.estimatedDurationResponse)
    return {
      completion: `Estimated completion: ${info.duration}`,
      by: `By ${info.by}`,
    }
  }
  setEstimatedDuration = (data: GetBroadcastEstimatedDurationQuery | null) => {
    this.estimatedDurationResponse = data?.getBroadcastEstimatedDuration || null
  }

  get isSendingSpeedError() {
    const contactsPerDayActiveInputError =
      this.scheduleStore.advancedSchedulingStore.activeAdvancedScheduleStore
        ?.contactsPerDayActiveInputError
    if (contactsPerDayActiveInputError) {
      return contactsPerDayActiveInputError
    }
    if (this.advancedSchedulingConfigInput?.speedConfig) {
      const startTimeHour = this.advancedSchedulingConfigInput.startTimeHour
      const startTimeMinute = this.advancedSchedulingConfigInput.startTimeMinute

      const lastMinutesInDay = 24 * 60 - startTimeHour * 60 - startTimeMinute

      const unitAmount =
        this.advancedSchedulingConfigInput.speedConfig.unitAmount

      const parts = Math.floor(lastMinutesInDay / unitAmount)

      const contactsAmountBySpeed =
        this.advancedSchedulingConfigInput.speedConfig.contactsAmount

      const contactsPerDayBySpeed = contactsAmountBySpeed * parts

      const contactsPerDay = this.advancedSchedulingConfigInput?.contactsPerDay

      if (contactsPerDay > contactsPerDayBySpeed) {
        return 'Sending configuration is incorrect'
      }
    }
  }
}
