import { Portal } from 'components/Portal'
import React from 'react'
import { Overflow } from 'components/Modal/components/Overflow/Overflow'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'
import {
  Actions,
  IActionsProps,
} from 'components/Modal/variants/NotificationModal/Actions'

export type INotificationModalProps = {
  variant: 'notification'
  type: 'info' | 'infoError1'
  id: string
  title: string
  subTitle?: string
  actions?: IActionsProps
}

export const NotificationModal = ({
  id,
  title,
  subTitle,
  type,
  actions,
}: INotificationModalProps) => {
  const onOverlayClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    modalStore.closeModal(id)
  }
  return (
    <Portal>
      <Overflow onOverlayClick={onOverlayClick}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={styles.wrap}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <div className={'pModalInfo'}>
            <ModalIcon type={type} fontSize={20} />
            <Typography variant={'h3-regular'}>{title}</Typography>
            {subTitle && <p className={'par2 gray1'}>{subTitle}</p>}
            <Actions id={id} {...actions} />
          </div>
        </div>
      </Overflow>
    </Portal>
  )
}
