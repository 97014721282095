import { makeAutoObservable, reaction } from 'mobx'
import {
  bytesToSize,
  htmlToText,
  placeCaretAtEnd,
  replaceSpace,
  textToHtml,
} from 'src/util/functions'
import { nanoid } from 'nanoid'
import user from 'store/user/user'
import mediaStore from 'store/mediaStore'
import { SIZE_LIMIT_5_MB } from 'src/static/constants'
import { RoutesEnum } from 'src/pages/routes'
import { count } from 'sms-length'
import {
  Asset,
  AssetFragmentFragment,
  ContactCard,
  ContactCardFragment,
  Maybe,
  SmsMessage,
  SmsMessageDetailsFragment,
  SmsMessageInput,
  SmsMessageRequestInput,
  WorkflowSmsConfigInput,
} from 'src/generated/graphql'
import { isHttps } from 'src/util/validators'
import { IAudioItem } from 'src/types/IAudioItem'
import audioStore from 'store/audioStore/audioStore'
import { ComplianceStore } from 'components/CreateElement/SMSContent/ComplianceContent/store/ComplianceStore'

export class TextareaStore {
  mediaStore: typeof mediaStore
  complianceStore
  user: typeof user
  from: RoutesEnum | undefined
  constructor() {
    this.mediaStore = mediaStore
    this.user = user
    this.complianceStore = new ComplianceStore()

    makeAutoObservable(this)

    reaction(
      () => this.messageRemains,
      (count) => {
        this.errorMMSLength = this.isMMS && count < 0
      }
    )
    reaction(
      () => this.messageCount,
      (count) => {
        this.errorSMSLength = !this.isMMS && count > 10
      }
    )
  }
  messageId = nanoid()
  messageHtml = ''
  errorMMSLength = false
  errorSMSLength = false
  isUnVerifyToolFree = false
  additionalText = ''
  presentationImageId = ''
  attachImagesIds: string[] = []
  audioItemsMap: Map<IAudioItem['id'], IAudioItem> = new Map()
  get attachAudioItems() {
    return Array.from(this.audioItemsMap.values())
  }
  get attachAudioItemsIds() {
    return this.attachAudioItems.map((a) => a.id)
  }

  vCards: ContactCardFragment[] = []
  uploadSelectedIds: string[] = []
  triggerMessageUpdate = ''
  triggerMessageUpdateText = ''
  activeMediaTab = 0
  get compliance() {
    return this.complianceStore.compliance
  }
  get complianceText() {
    return this.complianceStore.complianceText
  }

  textFieldElement: HTMLDivElement | null = null
  setTextFieldElement = (textFieldElement: HTMLDivElement | null) => {
    this.textFieldElement = textFieldElement
    const onScroll = () => {
      this.setCurrentPersonalize(null)
    }
    this.textFieldElement?.addEventListener('scroll', onScroll)
  }
  addContent: (content: string) => void = () => undefined
  setAddContent = (fn: typeof this.addContent) => {
    this.addContent = fn
  }

  setTooltips: () => void = () => undefined
  setSetTooltips = (fn: typeof this.setTooltips) => {
    this.setTooltips = fn
  }

  triggerFocus = false
  setTriggerFocus = (value: boolean) => {
    this.triggerFocus = value
  }

  get messageRemainsInfoSMS() {
    return count(
      `${this.text}${this.compliance ? ' ' + this.complianceText : ''}`.trim()
    )
  }

  get isError() {
    if (this.attachmentLimitError) {
      return true
    }
    return (
      (this.isMMS ? this.errorMMSLength : this.errorSMSLength) ||
      this.errorVerifyToolFree
    )
  }

  get errorVerifyToolFree() {
    return (this.isMMS || isHttps(this.messageHtml)) && this.isUnVerifyToolFree
  }

  get text() {
    return this.compliance
      ? htmlToText(this.messageHtml).trimStart()
      : htmlToText(this.messageHtml).trim()
  }

  get smsMessage(): SmsMessage {
    return {
      messageCards: this.vCards?.map((card) => ({
        contactCard: card as ContactCard,
      })),
      assetIds: this.attachImagesIds,
      cardIds: this.cardIds,
      compliance: this.compliance,
      text: this.text,
    }
  }

  get isNotEmpty() {
    return (
      !!this.attachImagesIds.length ||
      !!this.cardIds.length ||
      this.compliance ||
      this.text
    )
  }

  get smsMessageInput(): SmsMessageInput {
    return {
      assetIds: this.attachImagesIds,
      cardIds: this.cardIds,
      compliance: this.compliance,
      text: this.text,
      complianceText: this.complianceStore.nonRequiredComplianceText,
      audioIds: this.attachAudioItemsIds,
    }
  }

  get workflowSmsConfigInput(): WorkflowSmsConfigInput {
    return {
      assetIds: this.attachImagesIds,
      cardIds: this.cardIds,
      compliance: this.compliance,
      text: this.text,
      complianceText: this.complianceStore.nonRequiredComplianceText,
      audioIds: this.attachAudioItemsIds,
    }
  }

  get smsMessageRequestInput(): SmsMessageRequestInput {
    return {
      ...this.smsMessageInput,
      text: this.text,
    }
  }

  get messageCount() {
    if (this.isMMS) {
      return `${this.text}${this.compliance ? this.complianceText : ''}`.trim()
        .length
        ? 1
        : 0
    } else {
      return this.messageRemainsInfoSMS.messages
    }
  }
  get messageRemains() {
    if (this.isMMS) {
      return (
        1600 -
        `${this.text}${this.compliance ? ' ' + this.complianceText : ''}`.trim()
          .length
      )
    } else {
      return this.messageRemainsInfoSMS.remaining
    }
  }
  get isMMS() {
    return (
      !!this.attachImages.length ||
      !!this.audioItemsMap.size ||
      !!this.vCards.length
    )
  }

  get attachImages() {
    const images: Asset[] = []
    this.attachImagesIds.forEach((id) => {
      const findImg = this.mediaStore.assetsMap.get(id)
      if (findImg) {
        images.push(findImg)
      }
    })
    return images
  }
  get cardIds() {
    return this.vCards.map((card) => card.id)
  }
  get selectedAttachmentSize() {
    let size = 0
    this.uploadSelectedIds.forEach((id) => {
      const img = this.mediaStore.assetsMap.get(id)
      if (img) {
        size += img.sourceSize
      }
    })
    this.attachAudioItems.forEach((audio) => {
      size += audio.size
    })
    return size
  }
  get selectedUploadSizeString() {
    return bytesToSize(this.selectedAttachmentSize)
  }

  setUnVerifyToolFree = (value: boolean) => {
    this.isUnVerifyToolFree = value
  }

  setTriggerMessageUpdateText = () => {
    this.triggerMessageUpdateText = nanoid()
  }

  setText = (text: string) => {
    this.setMessageText(textToHtml(text, true))
    this.setTriggerMessageUpdateText()
  }

  setSmsMessage = (
    smsMessage?:
      | Maybe<SmsMessageDetailsFragment>
      | Maybe<SmsMessage>
      | Maybe<SmsMessageInput>
  ) => {
    if (smsMessage) {
      if (smsMessage.complianceText) {
        this.complianceStore.setNoneRequiredComplianceText(
          smsMessage.complianceText
        )
      }
      this.complianceStore.setCompliance(smsMessage.compliance)

      this.setText(smsMessage?.text || '')

      if (smsMessage.messageAssets || smsMessage.assetIds) {
        const assets: AssetFragmentFragment[] = []
        if (smsMessage.messageAssets) {
          smsMessage.messageAssets.forEach((item) => {
            if (item?.asset) {
              assets.push(item?.asset)
            }
          })

          mediaStore.setAssets(assets)
          this.attachImagesIds = assets.map((asset) => asset.id)
        } else if (smsMessage.assetIds) {
          this.attachImagesIds = smsMessage.assetIds
        }
      }
      if (smsMessage.messageCards) {
        const vCards: ContactCardFragment[] = []
        smsMessage.messageCards.forEach((smsCard) => {
          if (smsCard?.contactCard) {
            vCards.push(smsCard.contactCard)
          }
        })
        this.vCards = vCards
      }
      if (smsMessage.messageAudios) {
        smsMessage.messageAudios.forEach((card) => {
          if (card?.audio) {
            const audioItem: IAudioItem = {
              id: card.audio.id,
              ...card.audio,
            } as IAudioItem
            this.audioItemsMap.set(audioItem.id, audioItem)
          }
        })
      }
      if ('audioIds' in smsMessage) {
        smsMessage.audioIds?.forEach((id) => {
          if (id) {
            const audioItem = audioStore.audioFilesMap.get(id)
            if (audioItem) {
              this.audioItemsMap.set(audioItem.id, audioItem)
            }
          }
        })
      }
    }
  }

  setMessageText = (html: string) => {
    this.messageHtml = html
  }

  removeAttachImages = (ids: string[]) => {
    this.attachImagesIds = this.attachImagesIds.filter(
      (id) => !ids.includes(id)
    )
    this.uploadSelectedIds = this.uploadSelectedIds.filter(
      (id) => !ids.includes(id)
    )
  }

  onSelectUploadImg = (id: string) => {
    if (this.uploadSelectedIds.includes(id)) {
      this.uploadSelectedIds = this.uploadSelectedIds.filter(
        (uid) => id !== uid
      )
    } else {
      this.uploadSelectedIds.push(id)
    }
  }

  onSelectUploadImagesAll = () => {
    this.uploadSelectedIds = this.mediaStore.uploadImagesIds
  }

  onDeselectUploadImagesAll = () => {
    this.uploadSelectedIds = []
  }

  isCheckedUploadImg = (id: string) => this.uploadSelectedIds.includes(id)
  onAddImage = () => {
    this.attachImagesIds = this.uploadSelectedIds
  }

  setTriggerMessageUpdate = () => {
    this.triggerMessageUpdate = nanoid()
  }

  setActiveMediaTab = (index: number) => {
    this.activeMediaTab = index
  }

  activeMedia = false
  activeShortenLink = false
  activeEmoji = false
  activeTemplate = false

  setActiveMedia = (state: boolean) => {
    if (state) {
      this.uploadSelectedIds = [...this.attachImagesIds]
    }
    this.activeMedia = state
  }
  onClose() {
    this.activeMedia = false
    this.activeShortenLink = false
    this.activeEmoji = false
    this.activeTemplate = false
  }
  onClear() {
    this.messageHtml = ''
    this.attachImagesIds = []
  }
  addVCard = (vCard: ContactCardFragment) => {
    if (!this.vCards.find((card) => card.id === vCard.id)) {
      this.vCards = [...this.vCards, vCard]
    }
  }
  removeVCard = (id: number) => {
    this.vCards = this.vCards.filter((vCard) => vCard.id !== id)
  }
  setAdditionalText = (text: string) => {
    this.additionalText = text
  }

  currentPersonalize: HTMLSpanElement | null = null
  setCurrentPersonalize = (element: HTMLSpanElement | null) => {
    this.currentPersonalize = element
  }
  hoverPersonalizeEvent = (span: HTMLSpanElement) => {
    this.setCurrentPersonalize(span)
  }

  getPersonalizes = () => {
    return this.textFieldElement?.querySelectorAll('.personalize')
  }
  hasPersonalizes = false
  handleHasPersonalizes = () => {
    this.hasPersonalizes = !!this.getPersonalizes()?.length
  }

  onAddPersonalize = (name: string, key: string) => {
    const textFieldElement = this.textFieldElement
    if (textFieldElement) {
      const newElement = `<span class=\'personalize\' contenteditable='false' data-key='${key}' data-merge='${key.startsWith(
        'merge.'
      )}'>${name}</span>`
      this.addContent(newElement)
      const personalizes = this.getPersonalizes()
      personalizes?.forEach((span) => {
        span.addEventListener(
          'mouseenter',
          this.hoverPersonalizeEvent.bind(null, span as HTMLSpanElement)
        )
      })

      const lastElement = personalizes?.[personalizes.length - 1]
      if (lastElement) {
        setTimeout(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.setCurrentPersonalize(lastElement)
          this.setTriggerFocus(true)
        }, 50)
      }
    }
  }

  addAudio = (audio: IAudioItem) => {
    this.audioItemsMap.set(audio.id, audio)
  }
  onRemoveAudio = (audio: IAudioItem) => {
    this.audioItemsMap.delete(audio.id)
  }

  setFocus = () => {
    if (this.textFieldElement) {
      placeCaretAtEnd(this.textFieldElement)
    }
  }

  get attachmentCount() {
    return this.audioItemsMap.size + this.attachImagesIds.length
  }

  get attachmentLimitError() {
    if (
      this.attachmentCount > 5 ||
      this.selectedAttachmentSize > SIZE_LIMIT_5_MB
    ) {
      return `${this.attachmentCount} files selected - ${this.selectedUploadSizeString}`
    }
    return ''
  }

  get hasShortLinks() {
    let isShortLink = false
    this.textFieldElement?.querySelectorAll('a').forEach((item) => {
      const dataShorten = item.getAttribute('data-shorten')
      if (dataShorten === 'true') {
        isShortLink = true
      }
    })
    return isShortLink
  }
}

export function smsMessageToSmsMessageInput(
  smsMessage: SmsMessageDetailsFragment
): SmsMessageInput {
  return {
    assetIds: smsMessage.assetIds,
    compliance: smsMessage.compliance,
    text: smsMessage.text,
  }
}
