import classNames from 'classnames'
import styles from './styles.module.scss'

import { CSSProperties, PropsWithChildren, useState } from 'react'
import { IColor } from 'styles/colors'

export type IIconProps = PropsWithChildren<{
  fontSize?: CSSProperties['fontSize']
  color?: IColor
  hoverColor?: IColor
  className?: string
}>

export const Icon = ({
  fontSize = 16,
  color,
  hoverColor,
  className,
  children,
}: IIconProps) => {
  const [hover, setHover] = useState(false)
  const styleColor = hoverColor && hover ? hoverColor : color

  return (
    <i
      className={classNames(styles.wrap, 'app-icon', className)}
      style={{ fontSize, height: fontSize }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      color={styleColor}
    >
      {children}
    </i>
  )
}
