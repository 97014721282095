import { createContext, useContext } from 'react'
import { ContactsPickerStore } from '../store/ContactsPickerStore'

export const ContactsPickerContext = createContext<ContactsPickerStore | null>(
  null
)

export const useContactsPickerContext = () => {
  const context = useContext(ContactsPickerContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with ContactsPickerContext'
    )

  return context
}
