import { observer } from 'mobx-react-lite'
import { Typography } from 'shared/ui/Typography'
import styles from './styles.module.scss'
import { Input } from 'shared/ui/Input/Input'
import { useCollectedFieldActionContext } from 'widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import { SelectList } from 'components/SelectList/SelectList'
import * as React from 'react'
import { Textarea } from 'components/Textarea/Textarea'
import { WorkflowActionType } from 'src/generated/graphql'
import { Separator } from 'components/Separator/Separator'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { AngleDown, AngleUp, ArrowRight } from 'components/Icon'

export const CollectedFieldActionUi = observer(() => {
  const {
    waitReplay,
    setWaitReplay,
    actionOptions,
    setAction,
    actionTitle,
    action,
    textareaStore,
    disabled,
    checkLimitError,
    isLimitError,
  } = useCollectedFieldActionContext()
  return (
    <div>
      <Typography variant={'s2-medium'} color={'--primary-color-gray-1'}>
        If Contact Doesn’t Reply within
      </Typography>
      <div className={styles.wrapFields}>
        <Input
          value={waitReplay}
          isNumber
          setValue={setWaitReplay}
          rightContent={
            <Typography color={'--primary-color-gray-2'}>hours</Typography>
          }
          error={isLimitError}
          onBlur={checkLimitError}
        />
        <Icon color={'--primary-color-gray-1'}>
          <ArrowRight />
        </Icon>
        <DropdownField
          isFullWidth
          isSameWidth
          dropdownContent={() => (
            <SelectList options={actionOptions} onSelect={setAction} />
          )}
          disabled={disabled}
          triggerContent={(open) => (
            <Input
              isReadOnly
              disabled={disabled}
              value={actionTitle}
              placeholder={'Select'}
              rightContent={
                <Icon color={'--primary-color-gray-1'} fontSize={16}>
                  {open ? <AngleUp /> : <AngleDown />}
                </Icon>
              }
            />
          )}
        />
      </div>
      {isLimitError && (
        <ValidItem
          text={'The limit is between 1 and 48 hours'}
          mt={8}
          typographyProps={{
            variant: 's2-regular',
          }}
        />
      )}
      {action === WorkflowActionType.SendMessage && (
        <>
          <Separator marginBlock={24} />
          <Typography
            variant={'p0-bold'}
            color={'--primary-color-gray-1'}
            style={{ marginBottom: 16 }}
          >
            Follow up message
          </Typography>
          <Textarea
            textareaStore={textareaStore}
            placeholder={'Enter follow up message...'}
          />
        </>
      )}
    </div>
  )
})
