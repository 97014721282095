// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Js5d5lMOpqTCNVuQ9ck3{display:grid;row-gap:12px}.npssY497W6ViXjVj2fsN{display:grid;grid-template-columns:200px 500px;align-items:center}@media(max-width: 800px){.npssY497W6ViXjVj2fsN{grid-template-columns:1fr 1fr}}@media(max-height: 600px){.npssY497W6ViXjVj2fsN{grid-template-columns:1fr 1fr}}.JKGDbnwXXQqarf4ebNuK{display:flex;gap:8px}`, "",{"version":3,"sources":["webpack://./src/pages/superAdmin/modals/CustomPlan/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CAEF,sBACE,YAAA,CACA,iCAAA,CACA,kBAAA,CACA,yBAJF,sBAKI,6BAAA,CAAA,CAEF,0BAPF,sBAQI,6BAAA,CAAA,CAGJ,sBACE,YAAA,CACA,OAAA","sourcesContent":[".content{\n  display: grid;\n  row-gap: 12px;\n}\n.row{\n  display: grid;\n  grid-template-columns: 200px 500px;\n  align-items: center;\n  @media (max-width: 800px) {\n    grid-template-columns: 1fr 1fr;\n  }\n  @media (max-height: 600px) {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n.inputs{\n  display: flex;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `Js5d5lMOpqTCNVuQ9ck3`,
	"row": `npssY497W6ViXjVj2fsN`,
	"inputs": `JKGDbnwXXQqarf4ebNuK`
};
export default ___CSS_LOADER_EXPORT___;
