// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myOn9ah_c8sYy9p_uPdy{display:flex;gap:8px;color:var(--primary-color-gray-1);align-items:center}`, "",{"version":3,"sources":["webpack://./src/widgets/ContactPreview/ui/LabelField/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,iCAAA,CACA,kBAAA","sourcesContent":[".wrap{\n  display: flex;\n  gap: 8px;\n  color: var(--primary-color-gray-1);\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `myOn9ah_c8sYy9p_uPdy`
};
export default ___CSS_LOADER_EXPORT___;
