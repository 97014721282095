import { TextareaStore } from 'store/textareaStore'
import { VCardGallery } from 'components/VCard/VCardGallery/VCardGallery'
import { ImagesPreview } from 'components/Preview/ImagesPreview'
import { AudioCard } from 'components/CreateElement/AudioCard/AudioCard'
import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { ReactNode } from 'react'

type IUiPreviewSMSContent = {
  phone: string
  text?: ReactNode
  vCards?: TextareaStore['vCards']
  attachImages?: TextareaStore['attachImages']
  attachAudioItems?: TextareaStore['attachAudioItems']
  placeholder?: string
}
export const UiPreviewSMSContent = ({
  text,
  vCards,
  attachImages,
  attachAudioItems,
  phone,
  placeholder = 'Write your message...',
}: IUiPreviewSMSContent) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.message}>
        <Typography variant={'s2-regular'} className={styles.phone}>
          {phone}
        </Typography>
        <Typography
          variant={'s3-medium'}
          className={styles.text}
          placeholder={placeholder}
          tag={'div'}
          color={'--primary-color-black'}
        >
          {text}
        </Typography>
      </div>
      {!!vCards?.length &&
        vCards.map((vCard) => (
          <VCardGallery key={vCard.id} width={155} vCard={vCard} />
        ))}
      <ImagesPreview images={attachImages} />
      {attachAudioItems?.map((audio) => (
        <div key={audio.id} style={{ width: 200 }}>
          <AudioCard
            audio={audio}
            from={'preview'}
            fullWidth
            maxCanvasWidth={'100%'}
          />
        </div>
      ))}
    </div>
  )
}
