import contactStore from 'store/contacts/contactStore'
import { observer } from 'mobx-react-lite'
import importContactStore from 'store/contacts/importContactStore'
import manualContactStore from 'store/contacts/manualContactStore'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import {
  useCreateListMutation,
  useGetShortMyOrganizationListsQuery,
} from 'src/generated/graphql'
import { runInAction } from 'mobx'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { useEffect, useMemo, useState } from 'react'
import { IOption } from 'src/types/IOption'
import { contactListToOption } from 'store/contacts/functions'

export const AddContactsToListDropdown = observer(
  ({
    store,
    required,
    noLabel,
    tagsNoWrap,
    withCreateTag = true,
    readonly,
  }: {
    required?: boolean
    noLabel?: boolean
    tagsNoWrap?: boolean
    withCreateTag?: boolean
    readonly?: boolean
    store:
      | typeof manualContactStore
      | typeof importContactStore
      | DropdownContactListsStore
  }) => {
    const [createList] = useCreateListMutation()
    const [search, setSearch] = useState('')
    const { data, loading, refetch } = useGetShortMyOrganizationListsQuery({
      variables: {
        page: {
          pageNumber: 0,
          pageSize: 20,
        },
        listingSearch: {
          searchPattern: search,
        },
      },
    })

    const options: IOption[] = useMemo(() => {
      const options: IOption[] = []
      data?.getMyOrganizationLists?.content?.forEach((list) => {
        if (list) {
          options.push(contactListToOption(list))
        }
      })

      return options
    }, [data])

    useEffect(() => {
      refetch()
    }, [])

    const onCreate = async (title: string) => {
      try {
        const { data } = await createList({
          variables: {
            listInput: {
              name: title.trim(),
            },
          },
        })
        runInAction(() => {
          if (data?.createList) {
            contactStore.addListing([data?.createList])
            store.onSelectOptions([
              ...store.selectedOptions,
              {
                title: data?.createList.name || '',
                value: String(data?.createList.id),
                group: 'list',
              },
            ])
          }
        })
      } catch (e) {
        console.error(e)
      }
    }
    return (
      <Dropdown
        search={search}
        setSearch={setSearch}
        multi
        labelInput={noLabel ? '' : 'Add contacts to list'}
        loadingSearchData={loading}
        placeholder={
          options ? 'Select or create new list' : 'Type to create a list'
        }
        labelDropdown={
          options ? 'Select or create new list' : 'Type to create a list'
        }
        withCreateTag={withCreateTag}
        onCreate={onCreate}
        options={options}
        selectedOptions={store.selectedOptions}
        onMultiSelect={(options) => store.onSelectOptions(options)}
        minHeightOption={40}
        selectedTag
        withNoResults={!withCreateTag}
        onDeleteTag={store.onDeleteTag}
        required={required}
        withSelectALl
        withSearch
        tagsNoWrap={tagsNoWrap}
        readonly={readonly}
      />
    )
  }
)
