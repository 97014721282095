import React from 'react'
import { Flag } from 'shared/ui/Flag/Flag'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

export const FlagByLangCode = ({ langCode }: { langCode?: string }) => {
  if (!langCode) return null
  const lang = langCode.split('-')[0]
  const country = langCode.split('-')[1]
  const languageNamesInEnglish = new Intl.DisplayNames(['en'], {
    type: 'language',
  })
  return (
    <MuiTooltip title={languageNamesInEnglish.of(lang)} placement={'top'} arrow>
      <div style={{ display: 'flex' }}>
        <Flag code={country} />
      </div>
    </MuiTooltip>
  )
}
