import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Counter } from 'components/Counter/Counter'
import {
  getCampaignDelayPeriodTitle,
  getCampaignScheduleTypeTitle,
  operatorsItems,
  periodsItems,
} from 'components/Schedule/store/mockData'
import classNames from 'classnames'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { CampaignScheduleType, CustomFieldType } from 'src/generated/graphql'
import { TimePicker } from 'components/TimePicker/TimePicker'
import dayjs from 'lib/dayjs'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'
import { CheckBoxBtn } from 'components/CheckBoxBtn/CheckBoxBtn'
import { SelectList } from 'components/SelectList/SelectList'
import { Input } from 'shared/ui/Input/Input'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import { PersonalizeSelect } from 'widgets/PersonalizeSelect'
import { AngleDown, AngleUp } from 'components/Icon'

export const ContactDateFieldContent = observer(() => {
  const {
    activeContactDateField,
    contactDateFieldDate,
    contactDateField,
    setContactDateFieldDate,
    contactDateFieldOperator,
    contactDateFieldCount,
    setContactDateFieldCount,
    contactDateFieldPeriod,
    setContactDateFieldPeriod,
    setContactDateFieldOperator,
    activeContactDateCustomTime,
    toggleActiveContactDateCustomTime,
    contactDateFieldPersonalizeSelectStore,
  } = useCampaignScheduleContext()

  useGetCustomFields(
    !!customFieldsStore.customFieldsOptions.length || !activeContactDateField
  )
  useEffect(() => {
    if (activeContactDateField && !contactDateFieldDate) {
      setContactDateFieldDate(dayjs().startOf('day').add(12, 'hours').toDate())
    }
  }, [activeContactDateField, contactDateField?.type])
  if (!activeContactDateField) {
    return <></>
  }

  const timePicker = (
    <TimePicker
      startTime={contactDateFieldDate}
      onChange={(time) => setContactDateFieldDate(time)}
    />
  )

  return (
    <>
      <div className={classNames('col16', styles.wrap)}>
        {contactDateFieldOperator !== CampaignScheduleType.On && (
          <div className={styles.counter}>
            <Counter
              count={contactDateFieldCount}
              onMinus={() =>
                setContactDateFieldCount(+contactDateFieldCount - 1)
              }
              onPlus={() =>
                setContactDateFieldCount(+contactDateFieldCount + 1)
              }
              onChange={(value) => setContactDateFieldCount(+value)}
            />
            <DropdownField
              isSameWidth
              isFullWidth
              dropdownContent={() => (
                <SelectList
                  options={periodsItems}
                  onSelect={setContactDateFieldPeriod}
                />
              )}
              triggerContent={(open) => (
                <Input
                  isReadOnly
                  value={getCampaignDelayPeriodTitle(contactDateFieldPeriod)}
                  placeholder={'Select'}
                  rightContent={
                    <Icon color={'--primary-color-gray-1'} fontSize={16}>
                      {open ? <AngleUp /> : <AngleDown />}
                    </Icon>
                  }
                />
              )}
            />
          </div>
        )}
        <DropdownField
          isSameWidth
          isFullWidth
          dropdownContent={() => (
            <SelectList
              options={operatorsItems}
              onSelect={setContactDateFieldOperator}
            />
          )}
          triggerContent={(open) => (
            <Input
              isReadOnly
              value={getCampaignScheduleTypeTitle(contactDateFieldOperator)}
              placeholder={'Select'}
              rightContent={
                <Icon color={'--primary-color-gray-1'} fontSize={16}>
                  {open ? <AngleUp /> : <AngleDown />}
                </Icon>
              }
            />
          )}
        />
        <PersonalizeSelect
          store={contactDateFieldPersonalizeSelectStore}
          triggerType={'input'}
        />

        {contactDateField?.type === CustomFieldType.Date && timePicker}
      </div>
      {contactDateField?.type === CustomFieldType.DateTime && (
        <div className={styles.time}>
          <div className={'p8'}>
            <CheckBoxBtn
              active={activeContactDateCustomTime}
              toggleActive={toggleActiveContactDateCustomTime}
              title={'Set custom time'}
            />
          </div>

          {activeContactDateCustomTime && (
            <div className={styles.wrapTimePicker}>{timePicker}</div>
          )}
        </div>
      )}
    </>
  )
})
