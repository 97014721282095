import { observer } from 'mobx-react-lite'
import { Schedule } from 'components/Schedule/Schedule'
import { ImmediatelyCard } from 'components/Schedule/ImmediatelyCard/ImmediatelyCard'
import { LaterCard } from 'components/Schedule/LaterCard/LaterCard'
import { useBroadcastScheduleContext } from 'src/widgets/BroadcastSchedule'
import { LaterContent } from 'src/widgets/BroadcastSchedule/ui/LaterContent/LaterContent'
import { AdvancedScheduling } from 'src/widgets/AdvancedScheduling'
import { Separator } from 'components/Separator/Separator'
import styles from './styles.module.scss'
import { useCreateBroadcastContext } from 'src/pages/main/broadcasts/createBroadcast/context/CreateBroadcastContext'
import { Typography } from 'shared/ui/Typography'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { NewLabel } from 'src/shared/ui/NewLabel/NewLabel'

export const BroadcastScheduleContent = observer(() => {
  const { estimatedInfo, isSendingSpeedError } = useCreateBroadcastContext()
  const {
    broadcastScheduleLaterStore,
    advancedSchedulingStore,
    onActiveLater,
    onActiveImmediately,
    activeSendImmediately,
    activeSendLater,
    sendTimeString,
    suffixString,
    scheduleError,
    advancedInfo,
  } = useBroadcastScheduleContext()

  return (
    <>
      <Typography
        variant={'s2-medium'}
        color={'--primary-color-gray-1'}
        tag={'div'}
        style={{ marginBottom: 8 }}
      >
        Schedule
        <NewLabel marginLeft={8} />
      </Typography>
      <Schedule
        width={368}
        placement={'top-start'}
        sendTimeString={sendTimeString}
        suffixString={suffixString}
        scheduleError={scheduleError}
        scheduleContent={
          <>
            <ImmediatelyCard
              onActive={onActiveImmediately}
              isActive={activeSendImmediately}
            />
            <LaterCard
              onActive={onActiveLater}
              isActive={activeSendLater}
              content={
                broadcastScheduleLaterStore && (
                  <LaterContent store={broadcastScheduleLaterStore} />
                )
              }
            />
            <AdvancedScheduling store={advancedSchedulingStore} />
          </>
        }
      />
      {advancedInfo && (
        <div className={styles.wrapScheduleInfo}>
          <Typography variant={'s2-regular'} color={'--primary-color-gray-1'}>
            {advancedInfo.sendOnlyStr}
          </Typography>
          <br />
          <Typography variant={'s2-regular'} color={'--primary-color-gray-1'}>
            {advancedInfo.sendAtStr}
          </Typography>
          <br />
          <Typography variant={'s2-regular'} color={'--primary-color-gray-1'}>
            {advancedInfo.sendToContactsStr}
          </Typography>
          {estimatedInfo && (
            <>
              <Separator marginBlock={7.5} />
              <Typography
                variant={'s2-regular'}
                color={'--primary-color-gray-1'}
              >
                {estimatedInfo.completion}
              </Typography>
              <br />
              <Typography
                variant={'s2-regular'}
                color={'--primary-color-gray-1'}
              >
                {estimatedInfo.by}
              </Typography>
            </>
          )}
        </div>
      )}
      {isSendingSpeedError && (
        <ValidItem
          text={isSendingSpeedError}
          style={{ marginTop: 8 }}
          typographyProps={{
            variant: 's2-regular',
          }}
        />
      )}
    </>
  )
})
