import styles from './styles.module.scss'
import { NavItem } from 'components/NavBar/NavItem'
import {
  HelpNavItem,
  navItems,
  navItemsAdmin,
  navItemsSettings,
  ProductUpdatesNavItem,
  SwitchOrganizationNavItem,
} from 'components/NavBar/navItems'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Plan } from 'components/Plan/Plan'
import user from 'store/user/user'
import { RoutesEnum } from 'src/pages/routes'
import { observer } from 'mobx-react-lite'
import { MigrationCard } from 'components/NavBar/components/MigrationCard/MigrationCard'
import { useMemo } from 'react'

export const NavBar = observer(() => {
  const { pathname } = useLocation()

  const navItemsSettingsFiltered = useMemo(() => {
    let items = navItemsSettings
    if (!user.isOwner) {
      items = navItemsSettings.filter(
        (item) => item.route !== RoutesEnum.billing
      )
    }
    return items
  }, [user.isOwner, navItemsSettings])
  return (
    // <div
    //   className={styles.wrap}
    //   // style={{ visibility: uiStore.visibleSidebar ? 'visible' : 'hidden' }}
    // >
    <div className={styles.content}>
      <div className={styles.main}>
        <Routes>
          <Route
            path={`${RoutesEnum.settings}/*`}
            element={
              <>
                {navItemsSettingsFiltered.map((navItem) => (
                  <NavItem key={navItem.text} navItem={navItem} />
                ))}
              </>
            }
          />
          {user.isAdmin && (
            <Route
              path={`${RoutesEnum.admin}/*`}
              element={
                <>
                  {navItemsAdmin.map((navItem) => (
                    <NavItem key={navItem.text} navItem={navItem} />
                  ))}
                </>
              }
            />
          )}
          <Route
            path={'/*'}
            element={
              <>
                {(user.isAdmin
                  ? navItems
                  : navItems.filter((item) => !item.route?.includes('admin'))
                ).map((navItem) => (
                  <NavItem key={navItem.text} navItem={navItem} />
                ))}
              </>
            }
          />
        </Routes>
      </div>

      <MigrationCard />
      {!pathname.includes(RoutesEnum.admin) && (
        <div>
          <NavItem
            key={ProductUpdatesNavItem.text}
            navItem={ProductUpdatesNavItem}
          />
          <NavItem key={HelpNavItem.text} navItem={HelpNavItem} />
          <NavItem
            key={SwitchOrganizationNavItem.text}
            navItem={SwitchOrganizationNavItem}
          />
        </div>
      )}
      <Plan />
    </div>
    // </div>
  )
})
