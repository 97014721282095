import NewTable from 'components/NewTable/NewTable'
import * as React from 'react'
import { useEffect } from 'react'
import { Filters } from 'src/pages/main/broadcasts/BroadcastsTable/Filters/Filters'
import { useNavigate } from 'react-router-dom'
import { IBroadcast } from 'src/types/IBroadcast'
import { observer } from 'mobx-react-lite'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import {
  BroadcastFullResponse,
  Status,
  useGetAllBroadcastsQuery,
  useGetBroadcastByIdQuery,
} from 'src/generated/graphql'
import { EmptyState } from 'components/EmptyState/EmptyState'
import { noSortsColumns } from 'components/NewTable/columns/getBroadcastsTableColumns'
import { useSetData } from 'src/hooks/useSetData'
import { BroadcastDetailModal } from 'src/pages/main/broadcasts/modals/BroadcastDetailModal'
import { getBroadcastActions } from 'src/pages/main/broadcasts/functions/getBroadcastActions'
import { AppElements } from 'src/enums/appElements'

export const BroadcastsTable = observer(() => {
  const { tableStore, broadcasts, broadcastId, updateFullBroadcast } =
    broadcastsStore

  const { data } = useGetBroadcastByIdQuery({
    variables: {
      broadcastId,
    },
    skip: !broadcastId,
  })

  useEffect(() => {
    if (data?.getBroadcastById) {
      updateFullBroadcast(data.getBroadcastById as BroadcastFullResponse)
    }
  }, [data])

  const queryResult = useGetAllBroadcastsQuery({
    variables: {
      page: tableStore.paginationInput,
    },
  })
  const navigate = useNavigate()
  const onRowClick = (id: string, row: IBroadcast) => {
    if (row.status === Status.Draft) {
      navigate(id + '/edit')
    } else {
      navigate(id)
    }
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => broadcastsStore.setData(data),
    refreshDeps: [broadcastsStore.tableStore.refreshTrigger],
  })

  if (!firstLoading && !broadcasts.length) {
    return (
      <EmptyState
        element={AppElements.Broadcast}
        description={'Send mass message to drive sales or for reminders'}
      />
    )
  }

  return (
    <>
      <BroadcastDetailModal />
      <NewTable<IBroadcast>
        noSortsColumns={noSortsColumns}
        withCheckbox
        columns={tableStore.visibleColumns}
        rows={broadcasts.map((broadcast) => {
          return {
            ...broadcast,
            actions: getBroadcastActions(broadcast),
          }
        })}
        leftStickyContent={<Filters />}
        onRowClick={onRowClick}
        withCalcMaxHeight
        tableStore={tableStore}
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
      />
    </>
  )
})
