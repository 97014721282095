import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { Typography } from 'shared/ui/Typography'
import { Actions } from 'widgets/conversions/NewConversion/ui/Actions/Actions'
import { Name } from 'widgets/conversions/NewConversion/ui/fields/Name/Name'
import { ConversionUrls } from 'widgets/conversions/NewConversion/ui/fields/ConversionUrls/ConversionUrls'
import { DefaultValue } from 'widgets/conversions/NewConversion/ui/fields/DefaultValue/DefaultValue'
import { ConversionAttribution } from 'widgets/conversions/NewConversion/ui/fields/ConversionAttribution/ConversionAttribution'
import { appLinks } from 'src/util/links'

export const NewConversionUi = observer(() => {
  return (
    <div className={styles.wrap}>
      <Alert
        type={AlertTypeEnum.notify2}
        text={
          <>
            <Typography variant={'p2-regular'} tag={'span'}>
              Measure the ROI of your SMS marketing campaigns.
            </Typography>{' '}
            <a
              href={appLinks['conversion-and-revenue-tracking']}
              target={'_blank'}
              rel="noreferrer"
            >
              <Typography variant={'p2-semiBold'} tag={'span'} isLink>
                Learn more
              </Typography>
            </a>
          </>
        }
      />
      <div className={styles.fields}>
        <Name />
        <ConversionUrls />
        {/*<Currency />*/}
        <DefaultValue />
        <ConversionAttribution />
      </div>
      <Actions />
    </div>
  )
})
