import { ClickAwayListener, Popper } from '@mui/material'
import React, { FC, MutableRefObject, ReactNode, useState } from 'react'
import classNames from 'classnames'
import { TooltipProps } from '@mui/material/Tooltip'
import styles from './styles.module.scss'
import { Modifier } from 'react-popper'

export type ITriggerTooltipProps = {
  trigger:
    | HTMLSpanElement
    | HTMLLinkElement
    | HTMLButtonElement
    | undefined
    | null
  onLeaveTooltip?: () => void
  refVisibleTooltip?: MutableRefObject<boolean>
  visible?: boolean
  component?: ReactNode
  color?: 'black' | 'white'
  arrow?: boolean
  parent?: HTMLDivElement | null
  open?: boolean
  placement?: TooltipProps['placement']
  onClickAway?: () => void
  marginBottom?: number | string
  modifiers?: Partial<Modifier<any, any>>[]
}

export const TriggerTooltip: FC<ITriggerTooltipProps> = ({
  trigger,
  refVisibleTooltip,
  onLeaveTooltip,
  component,
  color = 'black',
  arrow,
  parent,
  open,
  placement = 'top',
  onClickAway,
  marginBottom,
  visible,
  modifiers = [],
}) => {
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null)

  const openPopper = open ?? Boolean(trigger)

  const onMouseEnter = () => {
    setTimeout(() => {
      if (refVisibleTooltip) {
        refVisibleTooltip.current = true
      }
    })
  }

  if (!trigger || visible !== undefined ? !visible : false) {
    return <></>
  }

  const content = (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onLeaveTooltip}
      className={classNames(styles.wrap, color && styles[color])}
      style={{ marginBottom }}
    >
      {arrow && <span className={styles.arrow} ref={setArrowRef} />}
      {component}
    </div>
  )

  return (
    <Popper
      open={openPopper}
      anchorEl={trigger}
      placement={placement}
      sx={{ zIndex: 2000 }}
      disablePortal={false}
      modifiers={[
        {
          name: 'arrow',
          enabled: arrow,
          options: {
            element: arrowRef,
          },
        },
        {
          name: 'preventOverflow',
          options: {
            boundary: parent,
          },
        },
        ...modifiers,
      ]}
    >
      <div>
        {onClickAway ? (
          <ClickAwayListener onClickAway={onClickAway}>
            {content}
          </ClickAwayListener>
        ) : (
          content
        )}
      </div>
    </Popper>
  )
}
