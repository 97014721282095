import styles from './styles.module.scss'
import { CSSProperties, FC } from 'react'
import { Icon } from 'components/Icon'
import { Typography } from 'shared/ui/Typography'
import { AppSymbols } from 'src/util/symbols'

export type ILabelFieldProps = {
  LeftIcon: FC
  text: string
  textWidth?: CSSProperties['width']
  marginTop?: CSSProperties['marginTop']
}

export const LabelField = ({
  LeftIcon,
  text,
  textWidth,
  marginTop,
}: ILabelFieldProps) => {
  return (
    <div className={styles.wrap} style={{ marginTop }}>
      <Icon>
        <LeftIcon />
      </Icon>
      <Typography isUnsetColor style={{ width: textWidth }}>
        {text || AppSymbols.dash}
      </Typography>
    </div>
  )
}
