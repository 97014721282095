// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mpBFWQFywqkMJ6GxfTp7{display:flex;flex-direction:column;flex-grow:1;height:100%;overflow:auto}.mpBFWQFywqkMJ6GxfTp7.KmT8tlVSZDAAsuvgA3Dq{background:var(--primary-color-gray-4)}.mpBFWQFywqkMJ6GxfTp7.NUwNEXzbTfe72LhhKaAw{align-items:center}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Page/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,WAAA,CACA,aAAA,CACA,2CACE,sCAAA,CAEF,2CACE,kBAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  height: 100%;\n  overflow: auto;\n  &.gray{\n    background: var(--primary-color-gray-4);\n  }\n  &.center{\n    align-items: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `mpBFWQFywqkMJ6GxfTp7`,
	"gray": `KmT8tlVSZDAAsuvgA3Dq`,
	"center": `NUwNEXzbTfe72LhhKaAw`
};
export default ___CSS_LOADER_EXPORT___;
