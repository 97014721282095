import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { ReactNode } from 'react'

export const EmptyContent = ({ text }: { text: ReactNode }) => {
  return (
    <div className={styles.wrap}>
      <Typography variant={'p2-regular'} color={'--primary-color-gray-1'}>
        {text}
      </Typography>
    </div>
  )
}
