import { NewBroadcast } from 'src/pages/main/broadcasts/createBroadcast/newBroadcast/newBroadcast'
import { PreviewBroadcast } from './PreviewBroadcast/PreviewBroadcast'
import { CreatePage } from 'components/Page/CreatePage'
import React, { useEffect, useMemo, useState } from 'react'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'
import { useLocation, useParams } from 'react-router-dom'
import { useGetBroadcastByIdLazyQuery } from 'src/generated/graphql'
import { transformFullBroadcast } from 'src/pages/main/broadcasts/functions/transformBroadcast'
import { useGetBroadcast } from 'src/pages/main/broadcasts/hooks/useGetBroadcast'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { CreateBroadcastContext } from './context/CreateBroadcastContext'
import { IBroadcastFull } from 'src/types/IBroadcast'
import { observer } from 'mobx-react-lite'

const Success = ({ broadcast }: { broadcast: IBroadcastFull }) => {
  const { pathname } = useLocation()
  const isDuplicate = pathname.includes('duplicate')
  const [store] = useState(
    () => new CreateBroadCastStore(broadcast, isDuplicate)
  )
  useEffect(() => store.dispose, [])
  return (
    <CreateBroadcastContext.Provider value={store}>
      <CreatePage
        store={store}
        createElement={<NewBroadcast />}
        previewElement={<PreviewBroadcast />}
      />
    </CreateBroadcastContext.Provider>
  )
}

export const EditBroadcast = observer(() => {
  useGetBroadcast()
  const { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [getBroadcastById] = useGetBroadcastByIdLazyQuery()
  const [broadcast, setBroadcast] = useState<IBroadcastFull | null>(null)

  useEffect(() => {
    if (id) {
      getBroadcastById({
        variables: {
          broadcastId: +id,
        },
      })
        .then(({ data }) => {
          if (data?.getBroadcastById) {
            setBroadcast(transformFullBroadcast(data.getBroadcastById))
          }
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id])

  return useMemo(() => {
    if (loading) {
      return <AbsoluteLoader />
    }
    if (broadcast) {
      return <Success broadcast={broadcast} />
    } else {
      return <div />
    }
  }, [broadcast, loading])
})
