import { observer } from 'mobx-react-lite'
import { Input } from 'shared/ui/Input/Input'
import { useNewConversionContext } from 'widgets/conversions/NewConversion/context/context'
import { Label } from 'shared/ui/Label/Label'
import { Button } from 'shared/ui/Button/Button'
import styles from './styles.module.scss'
import { DropdownButton } from 'shared/ui/DropdownButton/DropdownButton'
import { Delete, Plus } from 'components/Icon'

export const ConversionUrls = observer(() => {
  const { urls, setUrlValue, addUrl, removeUrl, disabledAddUrl } =
    useNewConversionContext()
  return (
    <div>
      <Label
        text={'Conversion url'}
        marginBottom={8}
        infoTooltipProps={{
          width: 302,
          title: 'The site where conversions will be tracked.',
        }}
      />
      <div className={styles.wrapUrls}>
        {urls.map(([key, value]) => (
          <div key={key} className={styles.wrapField}>
            <Input
              value={value}
              setValue={(value) => setUrlValue(key, value)}
              placeholder={'Enter URL'}
              withClear
            />
            <Button
              disabled={urls.length === 1 && !value}
              variant={'tertiary-small'}
              LeftIcon={Delete}
              onClick={() => removeUrl(key)}
            />
          </div>
        ))}
        <DropdownButton
          fullWidth
          primary
          LeftIcon={Plus}
          text={'Add url'}
          onClick={addUrl}
          disabled={disabledAddUrl}
        />
      </div>
    </div>
  )
})
