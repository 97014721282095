// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WQ_mx50R3IkPFh5OheRc{margin-top:40px;padding-left:32px;display:flex;gap:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectedFields/ui/AddCollectField/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,iBAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".wrap{\n  margin-top: 40px;\n  padding-left: 32px;\n  display: flex;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `WQ_mx50R3IkPFh5OheRc`
};
export default ___CSS_LOADER_EXPORT___;
