import { Outlet } from 'react-router-dom'
import { Container } from 'shared/ui/Container/Container'

export const SmallLayout = () => {
  return (
    <Container small>
      <Outlet />
    </Container>
  )
}
