import * as React from 'react'
import { Button } from 'shared/ui/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { openEditConversionModal } from 'src/features/conversions/openEditConversionModal'
import {
  ConversionResponse,
  IConversion,
} from 'src/pages/settings/conversions/conversions/store/type'
import { Edit } from 'components/Icon'

export const EditBtn = ({
  conversion,
  onSuccess,
  size,
}: {
  conversion: IConversion
  onSuccess: (response: ConversionResponse) => void
  size?: 'small' | 'medium'
}) => {
  const onEdit = () => {
    openEditConversionModal(conversion, onSuccess)
  }

  if (size === 'medium') {
    return (
      <Button
        variant={'secondary-medium'}
        onClick={onEdit}
        LeftIcon={Edit}
        text={'Edit'}
      />
    )
  }

  return (
    <MuiTooltip title={'Edit'} arrow placement={'top'}>
      <Button
        variant={'tertiary-small'}
        onClick={onEdit}
        LeftIcon={Edit}
        advanced
      />
    </MuiTooltip>
  )
}
