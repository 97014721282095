import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { CreateStore } from 'components/CreateElement/store/createStore'
import { DropdownContactListsStore } from 'store/contacts/DropdownContactListsStore'
import contactStore from 'store/contacts/contactStore'
import CustomFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { nanoid } from 'nanoid'
import {
  ContentType,
  GetExistingKeywordPhoneNumbersQuery,
  KeywordInput,
  PhoneNumber,
  Status,
} from 'src/generated/graphql'
import keywordsStore from 'store/keywords/keywordsStore'
import { disabledTooltipText } from 'src/pages/main/keywords/create/DisabledTooltipText'
import { IKeyword } from 'src/types/IKeyword'
import { IOption } from 'src/types/IOption'
import { ReserveKeywordWords } from 'src/static/reserveKeywordWords'
import numbersStore from 'store/settings/numbers/numbersStore'
import { DataCaptureStore } from 'widgets/DataCapture'
import { IKeywordType } from 'store/keywords/type'

export class CreateKeywordStore extends CreateStore {
  dropdownContactListsStore
  customFieldsStore
  contactStore
  keywordsStore
  dataCaptureStore
  constructor() {
    super({
      createBtnText: 'Create keyword',
      placeholder: 'Auto-confirmation message...',
    })
    this.dropdownContactListsStore = new DropdownContactListsStore()
    this.contactStore = contactStore
    this.customFieldsStore = CustomFieldsStore
    this.keywordsStore = keywordsStore
    this.dataCaptureStore = new DataCaptureStore()
    makeObservable(this, {
      initInput: observable,
      dropdownContactListsStore: observable,
      activeKeyword: observable,
      keywordsStore: observable,
      edit: observable,
      duplicate: observable,
      editId: observable,
      smsPhones: observable,
      keywordsPairsMap: observable,
      keywordType: observable,
      dataCaptureStore: observable,
      status: observable,

      keywordInput: computed,
      disabledCreate: computed,
      editKeyword: computed,
      isAlreadyUse: computed,

      phoneOptions: computed,

      setEdit: action.bound,
      setActiveKeyword: action.bound,
      setSMSPhones: action.bound,
      setKeywordsPairsMapData: action.bound,
    })

    reaction(
      () => this.keywordInput,
      (input) => {
        if (!this.initInput) {
          this.initInput = input
        }
        const initInputString = JSON.stringify(this.initInput)
        const inputString = JSON.stringify(input)
        this.isHaveChanges = initInputString !== inputString
      }
    )
  }

  noGenerateName = true

  element = 'Keyword'

  createId = nanoid()
  activeKeyword = true
  status: Status = Status.Active

  keywordsPairsMap: Map<string, string[]> = new Map<string, string[]>()

  edit = false
  duplicate = false
  editId = ''
  smsPhones: string[] = []
  initInput: KeywordInput | null = null

  keywordType: IKeywordType = 'simple'
  setKeywordType = (type: IKeywordType) => {
    this.keywordType = type
  }

  get keywordInput(): KeywordInput {
    return {
      id: this.edit ? this.editId : undefined,
      name: this.name,
      listIds: this.dropdownContactListsStore.selectedContactListsIds,
      smsMessage:
        this.keywordType === 'simple'
          ? this.textareaStore.smsMessageInput
          : undefined,
      status: this.status,
      phoneNumberIds: this.smsPhones.map(
        (phone) => numbersStore.numbersMapByFriendly.get(phone)?.id
      ),
      conversion:
        this.type === ContentType.Sms
          ? this.trackConversionStore.conversionInput
          : null,
      dataCaptureConfig:
        this.keywordType === 'data_capture'
          ? this.dataCaptureStore.dataCaptureConfigInput
          : undefined,
    }
  }

  get editKeyword(): IKeyword | undefined {
    return this.keywordsStore.keywordsMap.get(this.editId)
  }

  get isAlreadyUse() {
    let isUse = false
    const phonesForKeyword =
      this.keywordsPairsMap.get(this.name.toLowerCase()) || []
    if (this.edit) {
      if (this.editKeyword?.name === this.name) {
        return false
      }
    }
    phonesForKeyword.forEach((p) => {
      if (p && this.smsPhones.includes(p)) {
        isUse = true
      }
    })

    return isUse
  }

  get isReserve() {
    return ReserveKeywordWords.includes(this.name.toUpperCase())
  }

  get phoneOptions(): IOption[] {
    const phonesForKeyword =
      this.keywordsPairsMap.get(this.name.toLowerCase()) || []
    return numbersStore.smsPhoneNumbersOptions.map((opt) => ({
      ...opt,
      disabled: phonesForKeyword.includes(opt.value),
      disabledTooltip: disabledTooltipText,
    }))
  }

  get phones(): PhoneNumber[] {
    const phones: PhoneNumber[] = []
    this.smsPhones.forEach((phone) => {
      const p = numbersStore.numbersMapByFriendly.get(phone)
      if (p) {
        phones.push(p)
      }
    })

    return phones
  }

  get disabledCreate() {
    if (this.keywordType === 'simple') {
      if (!this.textareaStore.smsMessageInput.text) {
        return true
      }
      if (this.textareaStore.isError) {
        return true
      }
    }
    if (this.keywordType === 'data_capture') {
      if (this.dataCaptureStore.disabledCreate) {
        return true
      }
    }
    return !(
      this.name &&
      this.smsPhones.length &&
      this.dropdownContactListsStore.selectedOptions.length &&
      !this.isAlreadyUse &&
      !this.isReserve
    )
  }

  setActiveKeyword(state: boolean) {
    this.status = state ? Status.Active : Status.Disabled
    this.activeKeyword = state
  }

  setEdit = (keyword: IKeyword, isDuplicate: boolean) => {
    const phones: string[] = []
    keyword.keywordPhoneNumbers?.forEach((p) => {
      if (p?.phoneNumber?.friendlyName) {
        phones.push(p?.phoneNumber?.friendlyName)
      }
    })
    if (isDuplicate) {
      this.duplicate = true
      this.status = Status.Active
      this.activeKeyword = true
    } else {
      this.edit = true
      this.status = keyword.status || Status.Active
      this.activeKeyword = keyword.status === Status.Active
    }

    this.editId = keyword.id
    if (keyword.dataCaptureConfig) {
      this.keywordType = 'data_capture'
      this.dataCaptureStore.init(keyword.dataCaptureConfig)
    } else {
      this.keywordType = 'simple'
      this.textareaStore.setSmsMessage(keyword.smsMessage)
      this.textareaStore.setTriggerMessageUpdate()
    }

    this.name = keyword.name
    // this.name = `${keyword.name}${isDuplicate ? ' copy' : ''}`
    this.smsPhones = phones
    if (keyword.contactsListsIds) {
      this.dropdownContactListsStore.setInitListsIds(keyword.contactsListsIds)
    }
    if (keyword.conversion) {
      this.setAdvanced(true)
      this.trackConversionStore.init(keyword.conversion)
    }
    this.initInput = this.keywordInput
  }

  setSMSPhones(phones: string[]) {
    this.smsPhones = phones
  }

  setKeywordsPairsMapData({
    getExistingKeywordPhoneNumbers,
  }: GetExistingKeywordPhoneNumbersQuery) {
    this.keywordsPairsMap = new Map(
      Object.entries(getExistingKeywordPhoneNumbers)
    )
  }

  checkIsShowShortLinkRequiredModal = () => {
    let isRequired = false
    if (this.trackConversionStore.conversionInput?.id) {
      if (this.keywordType === 'simple') {
        if (!this.textareaStore.hasShortLinks) {
          isRequired = true
        }
      }
      if (
        this.keywordType === 'data_capture' &&
        !this.dataCaptureStore.hasShortLinks
      ) {
        isRequired = true
      }
    }

    return isRequired
  }
}
