// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rpTnbv3SC0s6bypxwiH6{display:grid;gap:8px}.pV7nTkv2zxDR0mXxhMo2{display:flex;gap:8px;align-items:center}`, "",{"version":3,"sources":["webpack://./src/widgets/conversions/NewConversion/ui/fields/ConversionUrls/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CAEF,sBACE,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".wrapUrls{\n  display: grid;\n  gap: 8px;\n}\n.wrapField{\n  display: flex;\n  gap: 8px;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapUrls": `rpTnbv3SC0s6bypxwiH6`,
	"wrapField": `pV7nTkv2zxDR0mXxhMo2`
};
export default ___CSS_LOADER_EXPORT___;
