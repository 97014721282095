import { observer } from 'mobx-react-lite'
import { LeftContent } from 'widgets/AddFiltersModal/ui/Include/LeftContent/LeftContent'
import { RightContent } from 'widgets/AddFiltersModal/ui/Include/RightContent/RightContent'
import styles from './styles.module.scss'

export const Include = observer(() => {
  return (
    <div className={styles.wrap}>
      <LeftContent />
      <RightContent />
    </div>
  )
})
