import styles from 'components/Modal/variants/DefaultModal/styles.module.scss'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { NewLabel } from 'shared/ui/NewLabel/NewLabel'
import { Steps } from 'components/Modal/variants/DefaultModal/Steps/Steps'
import { Close20, Icon } from 'components/Icon'
import React, { ReactNode } from 'react'
import { Button } from 'shared/ui/Button/Button'

export type IHeaderProps = {
  subTitle?: ReactNode
  title: ReactNode
  titleTooltip?: ReactNode
  isNew?: boolean
  steps?: string[]
  activeStep?: number
  headerContent?: ReactNode
  withoutClose?: boolean
  onClose?: () => void
}

export const Header = ({
  title,
  subTitle,
  titleTooltip,
  isNew,
  steps,
  activeStep = 0,
  headerContent,
  withoutClose,
  onClose,
}: IHeaderProps) => {
  return (
    <div className={styles.header}>
      {title && (
        <div className={'fullWidth'}>
          {subTitle && <span className={'gray1 mb28'}>{subTitle}</span>}

          <h3 className={'bold'}>
            {title}
            {titleTooltip && <InfoTooltip title={titleTooltip} large />}
            {isNew && <NewLabel marginLeft={14} />}
          </h3>
        </div>
      )}
      <div className={styles.headerSteps}>
        {steps && <Steps steps={steps} activeStep={activeStep} />}
        {headerContent}
      </div>

      {!withoutClose && (
        <Button variant={'icon'} onClick={onClose}>
          <Icon fontSize={24}>
            <Close20 />
          </Icon>
        </Button>
      )}
    </div>
  )
}
