import React from 'react'

export const Flag = ({ code }: { code?: string }) => {
  return (
    <img
      width={16}
      alt={code}
      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
    />
  )
}
