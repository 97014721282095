import { ColumnType } from 'components/NewTable/types'
import { numberString } from 'src/util/functions'
import { IConversionHitProjection } from 'widgets/conversions/ConversionDetail/store/types'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'

export const getColumns = (): ColumnType<IConversionHitProjection>[] => [
  {
    id: 'date',
    label: 'Converted at',
    format: (value) => dayjs(value as Date).format(DayjsFormats.fullWithAt),
  },
  { id: 'name', label: 'Name' },
  {
    id: 'phone',
    label: 'Phone',
  },
  {
    id: 'amount',
    label: 'Revenue',
    format: (_, { amount }) =>
      typeof amount === 'number'
        ? numberString({ val: amount || 0, currency: 'USD' })
        : AppSymbols.dash,
  },
]
