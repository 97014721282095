import styles from './styles.module.scss'
import { Button } from 'shared/ui/Button/Button'
import {
  ITriggerTooltipProps,
  TriggerTooltip,
} from 'shared/ui/TriggerTooltip/TriggerTooltip'
import React, { useEffect, useState } from 'react'
import { Input } from 'shared/ui/Input/Input'
import { ValidItem } from 'components/Input/InputText/ValidItem'
import { Typography } from 'shared/ui/Typography'
import { Switch } from 'components/Switch/Switch'
import {
  customUtmInitParams,
  CustomUtmParams,
} from 'components/Textarea/ShortenLink/ShortenLinkTriggerTooltip/CustomUtmParams/CustomUtmParams'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'

export type IShortenLinkTriggerTooltipProps = {
  onAddShortenLink: (link: string, withError?: boolean) => Promise<void>
  setVisible: (value: boolean) => void
  initLink?: string
} & ITriggerTooltipProps

export const ShortenLinkTriggerTooltip = ({
  onAddShortenLink,
  visible,
  setVisible,
  initLink = '',
  ...props
}: IShortenLinkTriggerTooltipProps) => {
  const [loading, setLoading] = useState(false)
  const [link, setLink] = useState(initLink)
  const [error, setError] = useState('')

  const [isCustomParams, setIsCustomParams] = useState(false)
  const [paramsValues, setParamsValues] = useState(customUtmInitParams)

  useEffect(() => {
    if (!visible) {
      setLink('')
      setError('')
      setIsCustomParams(false)
    }
  }, [visible])

  useEffect(() => {
    if (initLink) {
      setLink(initLink)
    }
  }, [initLink])

  const handleSetIsCustomParams = (value: boolean) => {
    if (value) {
      if (link) {
        try {
          const url = new URL(link)
          setParamsValues({
            utm_source: url.searchParams.get('utm_source') || '',
            utm_medium: url.searchParams.get('utm_medium') || '',
            utm_campaign: url.searchParams.get('utm_campaign') || '',
            utm_content: url.searchParams.get('utm_content') || '',
          })
        } catch (e) {}
      }
    } else {
      setParamsValues(customUtmInitParams)
    }
    setIsCustomParams(value)
  }

  const onConfirm = async () => {
    try {
      setLoading(true)
      const url = new URL(link)
      Object.entries(paramsValues).forEach(([key, value]) => {
        if (value) {
          url.searchParams.set(key, value)
        }
      })
      await onAddShortenLink(url.toString(), true)
      setVisible(false)
      setLink('')
    } catch (e) {
      setError('Invalid url provided')
    } finally {
      setLoading(false)
    }
  }
  return (
    <TriggerTooltip
      onClickAway={() => setVisible(false)}
      visible={visible}
      color={'white'}
      placement={'bottom-start'}
      component={
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography variant={'s1-semiBold'}>Add link</Typography>
            <InfoTooltip title={'Info'} zIndex={2000} />
          </div>
          <Input
            value={link}
            setValue={setLink}
            placeholder={'website.com'}
            withClear
            error={error}
            onFocus={() => setError('')}
          />
          {error && <ValidItem text={error} mt={8} />}
          <div className={styles.wrapSwitch}>
            <Switch
              active={isCustomParams}
              onChange={handleSetIsCustomParams}
            />
            <Typography variant={'s1-regular'}>
              Customize UTM parameters
            </Typography>
          </div>
          {isCustomParams && (
            <CustomUtmParams
              paramsValues={paramsValues}
              setParamsValues={setParamsValues}
            />
          )}
          <Button
            variant={'primary-small'}
            text={'Confirm'}
            disabled={!link}
            onClick={onConfirm}
            loading={loading}
          />
        </div>
      }
      {...props}
    />
  )
}
