import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { useState } from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { DetailsModalContent } from 'components/DetailsModalContent/DetailsModalContent'
import { Modal } from 'components/Modal/Modal'
import { IKeyword } from 'src/types/IKeyword'

export const KeywordDetailsBtn = (props: ActionBtnProps<IKeyword>) => {
  const { row } = props
  const [open, setOpen] = useState(false)
  const onClose = () => {
    setOpen(false)
  }
  const onOpen = () => {
    setOpen(true)
  }
  if (!row) {
    return <></>
  }
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={'Details'}
        minWidth={526}
        topRight
      >
        <DetailsModalContent element={row} />
      </Modal>
      <TableBtn {...props} action={ActionsEnum.details} onAction={onOpen} />
    </>
  )
}
