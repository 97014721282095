// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QiBh3Fh5yZj0QeUo2iRQ{border-radius:4px;display:flex;gap:4px;background:var(--primary-color-gray-3);min-height:24px;align-items:center;padding:4px 8px}.QiBh3Fh5yZj0QeUo2iRQ:hover{background:var(--primary-color-gray-2-disabled)}`, "",{"version":3,"sources":["webpack://./src/shared/ui/Button/XSmallButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,OAAA,CACA,sCAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA,CACA,4BACE,+CAAA","sourcesContent":[".wrap{\n  border-radius: 4px;\n  display: flex;\n  gap: 4px;\n  background: var(--primary-color-gray-3);\n  min-height: 24px;\n  align-items: center;\n  padding: 4px 8px;\n  &:hover{\n    background: var(--primary-color-gray-2-disabled);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `QiBh3Fh5yZj0QeUo2iRQ`
};
export default ___CSS_LOADER_EXPORT___;
