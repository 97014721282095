import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import { columnsKeywords } from 'components/NewTable/columns/keywords/columnsKeywords'
import { GetAllKeywordsQuery, Keyword } from 'src/generated/graphql'
import { transformKeyword } from 'src/pages/main/keywords/functions/transformKeyword'
import { IKeyword } from 'src/types/IKeyword'
import { IOption } from 'src/types/IOption'

class KeywordsStore {
  tableStore: TableStore<IKeyword>
  constructor() {
    this.tableStore = new TableStore({
      orderBy: 'createdAt',
      tableName: 'KeywordsTable',
      columns: columnsKeywords,
      defaultHiddenColumn: ['contactStatusStatistics.unsubscribedCount'],
    })
    makeAutoObservable(this)
  }
  keywordsMap: Map<string, IKeyword> = new Map()
  testKeyword: IKeyword | null = null
  openNewKeywordModal = false
  keywordModalCB: ((options: IOption[]) => void) | null = null

  get keywords() {
    return Array.from(this.keywordsMap.values())
  }

  get keywordsOptions(): IOption[] {
    return this.keywords.map((keyword) => ({
      title: keyword.name,
      value: keyword.id,
    }))
  }

  setTestKeyword(form: IKeyword | null) {
    this.testKeyword = form
  }

  setOpenKeywordModal(
    state: boolean,
    cb: ((options: IOption[]) => void) | null = null
  ) {
    this.openNewKeywordModal = state
    this.keywordModalCB = cb
  }

  delete(ids: string[]) {
    ids.forEach((id) => {
      this.keywordsMap.delete(id)
    })
  }
  setKeyword(keyword: IKeyword) {
    this.keywordsMap.set(keyword.id, keyword)
  }

  setData = (data: GetAllKeywordsQuery) => {
    this.tableStore.setTotal(data.getAllKeywords?.total || 0)

    const iKeywords: IKeyword[] = []

    data.getAllKeywords?.content?.forEach((keyword) => {
      if (keyword) {
        iKeywords.push(transformKeyword(keyword as Keyword))
      }
    })

    this.keywordsMap = new Map(
      iKeywords.map((keyword) => [keyword.id, keyword])
    )
  }
}

export default new KeywordsStore()
