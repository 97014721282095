import styles from './styles.module.scss'
import { ReactComponent as PreviewReq } from 'shared/assets/images/PreviewReq.svg'
import { ReactComponent as PreviewReqLarge } from 'shared/assets/images/PreviewReqLarge.svg'
import { ReactComponent as Canada } from 'icons/16pxNoMask/Canada.svg'
import { ReactComponent as USA } from 'icons/16pxNoMask/USA.svg'
import { appLinks } from 'src/util/links'
import classNames from 'classnames'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Close } from 'icons/16px/CloseCustom.svg'
import companyStore from 'store/settings/company/companyStore'

export const NewRequirements = ({ fromCompany }: { fromCompany?: boolean }) => {
  return (
    <div className={classNames(styles.wrap, fromCompany && styles.fromCompany)}>
      <div className={styles.left}>
        <div>
          <div className={styles.title}>
            New requirements for sending messages to
          </div>
          <p className={'par2 medium row8 mb4'}>
            <USA />
            United States
          </p>
          <p className={'par2 medium row8 mb4'}>
            <Canada /> Canada
          </p>
        </div>

        <div className={styles.seeBtn}>
          <a
            className={'btn secondary small fit-content mt24'}
            href={appLinks.callLoopCompliance}
            target={'_blank'}
            rel="noreferrer"
          >
            See details
          </a>
        </div>
      </div>
      {fromCompany ? (
        <div className={styles.right}>
          <PreviewReqLarge />
          <Button
            size={BtnSize.small}
            typeBtn={BtnType.secondaryGray}
            close
            onClick={() => companyStore.setShowNewReq(false)}
            className={styles.btnClose}
          >
            <Close />
          </Button>
        </div>
      ) : (
        <PreviewReq />
      )}
    </div>
  )
}
