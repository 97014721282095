import { PersonalizeSelectContext } from 'widgets/PersonalizeSelect/context/context'
import {
  PersonalizeSelectUi,
  PersonalizeSelectUiProps,
} from 'widgets/PersonalizeSelect/ui/PersonalizeSelectUi'
import { PersonalizeSelectStore } from 'widgets/PersonalizeSelect/store/PersonalizeSelectStore'

type IPersonalizeSelectProps = {
  store: PersonalizeSelectStore
} & PersonalizeSelectUiProps

export const PersonalizeSelect = ({
  store,
  ...props
}: IPersonalizeSelectProps) => {
  return (
    <PersonalizeSelectContext.Provider value={store}>
      <PersonalizeSelectUi {...props} />
    </PersonalizeSelectContext.Provider>
  )
}
