import { Tag } from 'components/Tag/Tag'
import React, { MouseEventHandler, useState } from 'react'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import styles from './styles.module.scss'
import { SearchInput } from 'components/Input/InputText/SearchInput'
import classNames from 'classnames'
import { groupBy } from 'lodash'
import { IOption } from 'src/types/IOption'
import { observer } from 'mobx-react-lite'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'

export type PlusItemsProps = {
  items?: IItem[]
  options?: IOption[]
  itemsCount?: number
  withoutSearch?: boolean
  withoutLabel?: boolean
  onHoverPlus?: () => void
  onDeleteTag?: (id: string | number, group?: string) => void
}

export const PlusItems = observer(
  ({
    items: itemsProps,
    options,
    onHoverPlus,
    itemsCount,
    onDeleteTag,
    withoutSearch,
    withoutLabel,
  }: PlusItemsProps) => {
    const { showTT, trigger, setTrigger, onTriggerClick } = useFixedTooltip()

    const onOpen: MouseEventHandler<HTMLDivElement> = (e) => {
      e.stopPropagation()
      onTriggerClick()
    }

    const items: IItem[] =
      itemsProps ||
      options?.map(
        (option) =>
          ({
            ...option,
            name:
              typeof option.title === 'string' ? option.title : option.value,
            id: option.id || option.value,
            count: option.count,
            IconFC: option.LeftIcon,
            iconProps: option.leftIcon,
            group: option.group || 'list',
          } as IItem)
      ) ||
      []

    const [search, setSearch] = useState('')

    const groups = Object.entries(groupBy(items, 'group'))
    const getGroupTitle = (key: IItem['group']) => {
      if (key === 'list') {
        return 'Lists'
      }
      if (key === 'contact') {
        return 'Contacts'
      }
      if (key === 'segment') {
        return 'Segments'
      }
      if (key === 'status') {
        return 'Statuses'
      }
      return key
    }
    return (
      <>
        <FixedTooltip
          visible={showTT}
          trigger={trigger}
          white
          noArrow
          position={TTPositionEnum.bottomLeft}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            onClick={(event) => event.stopPropagation()}
            className={styles.wrap}
          >
            {!withoutSearch && (
              <div className={styles.wrapSearch}>
                <SearchInput value={search} onSearch={setSearch} small />
              </div>
            )}

            <div className={styles.wrapListsTooltip}>
              {groups.map(([key, values]) => (
                <div key={key}>
                  {!withoutLabel && (
                    <span
                      className={classNames('s2 medium gray1', styles.label)}
                    >
                      {getGroupTitle(key as IItem['group'])}
                    </span>
                  )}
                  <div className={classNames(styles.wrapTags)}>
                    {!!values.length &&
                      values
                        .filter((value) =>
                          value.name
                            .toLocaleLowerCase()
                            .replace(/\(|\)|\-| /g, '')
                            .includes(
                              search
                                .toLocaleLowerCase()
                                .replace(/\(|\)|\-| /g, '')
                            )
                        )
                        .map((value) => (
                          <div key={value?.id} className={styles.wrapTagButton}>
                            <Tag
                              backgroundColor={value.backgroundColor}
                              text={
                                (value?.title as string) ||
                                value?.name ||
                                'Customers'
                              }
                              LeftIcon={value?.IconFC}
                              leftIcon={value?.iconProps}
                              close={!!onDeleteTag}
                              onDeleteTag={
                                onDeleteTag
                                  ? () => {
                                      onDeleteTag(value.id, value.group)
                                    }
                                  : undefined
                              }
                            />
                          </div>
                        ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </FixedTooltip>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          data-name={'tag'}
          className={'appTag'}
          style={{ visibility: 'visible' }}
          onMouseEnter={onHoverPlus}
          onClick={onOpen}
          ref={setTrigger}
        >
          <Tag
            text={`+${
              (itemsCount && itemsCount > 0 && itemsCount) || items.length
            }`}
          />
        </div>
      </>
    )
  }
)
