import { observer } from 'mobx-react-lite'
import { SelectList } from 'components/SelectList/SelectList'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import React, { useCallback, useEffect } from 'react'
import { DropdownButton } from 'shared/ui/DropdownButton/DropdownButton'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { usePersonalizeSelectContext } from 'widgets/PersonalizeSelect/context/context'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { ICustomField } from 'src/types/ICustomField'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'
import styles from './styles.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/ui/Typography'
import { staticContactFieldsOptions } from 'store/contacts/mockData'
import { Input } from 'shared/ui/Input/Input'
import { AngleDown, AngleUp, Plus } from 'components/Icon'
import { CustomFieldTypeMapText } from 'store/contacts/customFields/functions'

export type PersonalizeSelectUiProps = {
  triggerType: 'input' | 'button' | 'text'
}

export const PersonalizeSelectUi = observer(
  ({ triggerType }: PersonalizeSelectUiProps) => {
    useGetCustomFields()
    const {
      onSelect,
      item,
      itemId,
      excludeIds,
      allowTypes,
      setItem,
      disabledTypes,
    } = usePersonalizeSelectContext()
    const { customFields } = customFieldsStore

    const options = [
      ...staticContactFieldsOptions.map(
        (field): IListItem<ICustomField> => ({
          id: field.value,
          group: 'Default fields',
          item: {
            id: field.value,
            name: field.title as string,
            type: field.data.type,
            idLong: 0,
          },
          text: field.title as string,
          isActive: field.value === item?.id,
        })
      ),
      ...customFields.map(
        (field): IListItem<ICustomField> => ({
          group: 'Custom fields',
          id: field.id,
          item: field,
          text: field.name,
          isActive: field.id === item?.id,
          dropdownButtonProps:
            field.type && disabledTypes.includes(field.type)
              ? {
                  disabled: field.type && disabledTypes.includes(field.type),
                  muiTooltipProps: {
                    placement: 'top',
                    arrow: true,
                    title: `${
                      CustomFieldTypeMapText[field.type]
                    } field is not supported by Data Capture`,
                  },
                }
              : undefined,
        })
      ),
    ]
      .filter((option) => !excludeIds.includes(option.id))
      .filter((option) =>
        allowTypes.length && option.item?.type
          ? allowTypes.includes(option.item.type)
          : true
      )

    useEffect(() => {
      if (!item && itemId) {
        const findItem = options.find((option) => option.id === itemId)
        if (findItem?.item) {
          setItem(findItem?.item)
        }
      }
    }, [options, item])

    const label =
      item?.name || options.find((option) => option.id === itemId)?.text

    const triggerContent = useCallback(
      (open: boolean) => {
        if (triggerType === 'button') {
          return (
            <button className={styles.trigger}>
              {label ? (
                <Typography
                  variant={'h3-regular'}
                  color={'--primary-color-black'}
                >
                  {label}
                </Typography>
              ) : (
                <Typography
                  variant={'h3-regular'}
                  color={'--primary-color-gray-2'}
                >
                  Select field
                </Typography>
              )}
              <Icon color={'--primary-color-gray-1'}>
                {open ? <AngleUp /> : <AngleDown />}
              </Icon>
            </button>
          )
        }
        if (triggerType === 'input') {
          return (
            <Input
              isReadOnly
              value={label}
              placeholder={'Select field'}
              rightContent={
                <Icon color={'--primary-color-gray-1'} fontSize={16}>
                  {open ? <AngleUp /> : <AngleDown />}
                </Icon>
              }
            />
          )
        }
        return (
          <div className={styles.text}>
            {label ? (
              <Typography variant={'s1-regular'}>{label}</Typography>
            ) : (
              <Typography
                variant={'s1-regular'}
                color={'--primary-color-gray-2'}
              >
                Select
              </Typography>
            )}
          </div>
        )
      },
      [label, triggerType]
    )

    return (
      <DropdownField
        isSameWidth
        isFullWidth
        withToggleOpen
        dropdownContent={(_: boolean, setOpen) => (
          <SelectList
            isLocalSearch
            options={options}
            onSelect={onSelect}
            bottomContent={
              <DropdownButton
                text={'Add custom field'}
                fullWidth
                primary
                LeftIcon={Plus}
                onClick={() => {
                  setOpen(false)
                  customFieldsStore.setOpenCustomFieldsModalModal(true)
                }}
              />
            }
          />
        )}
        triggerContent={triggerContent}
      />
    )
  }
)
