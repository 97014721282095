import styles from './styles.module.scss'
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { BtnSize, Button } from 'components/Button/Button'
import classNames from 'classnames'

import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'

import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { Icon } from 'components/Icon/Icon'

import { Portal } from 'components/Portal'
import {
  IInfoModal,
  InfoModal,
} from 'components/Modal/variants/DefaultModal/ui/InfoModal/InfoModal'
import {
  DeleteModal,
  IDeleteModal,
} from 'components/Modal/variants/DefaultModal/ui/DeleteModal/DeleteModal'
import { Steps } from 'components/Modal/variants/DefaultModal/Steps/Steps'
import { Close } from 'components/Icon'
import { NewLabel } from 'shared/ui/NewLabel/NewLabel'
import {
  Header,
  IHeaderProps,
} from 'components/Modal/variants/DefaultModal/ui/Header/Header'

export type IDefaultModalProps = {
  variant?: 'default'
  open?: boolean
  onClose?: () => void
  title?: ReactNode
  isNewTitle?: boolean
  subTitle?: string | JSX.Element
  titleTooltip?: string
  minWidth?: number
  minHeight?: number
  withoutHeader?: boolean
  headerContent?: JSX.Element
  centered?: boolean
  centered2?: boolean
  topRight?: boolean
  steps?: string[]
  activeStep?: number
  staticContent?: boolean
  loading?: boolean
  triggerHeight?: string
  infoModalProps?: Omit<IInfoModal, 'onClose'>
  deleteModalProps?: Omit<IDeleteModal, 'onClose'>
  children?: ReactNode
  size?: 'small' | 'medium'
  leftNavBar?: boolean
  headerProps?: IHeaderProps
}

export const DefaultModal: FC<IDefaultModalProps> = observer(
  ({
    open,
    onClose,
    title,
    subTitle,
    minHeight,
    minWidth,
    withoutHeader,
    centered,
    headerContent,
    steps,
    activeStep = 0,
    topRight,
    titleTooltip,
    staticContent,
    centered2,
    loading,
    triggerHeight,
    children,
    infoModalProps,
    deleteModalProps,
    size,
    leftNavBar,
    isNewTitle,
    headerProps,
  }) => {
    const refContent = useRef<HTMLDivElement>(null)
    const onOverlayClick = (e: React.MouseEvent) => {
      e.stopPropagation()
      onClose && onClose()
    }
    const [isCentered, setIsCentered] = useState(false)
    useEffect(() => {
      if (open && refContent.current && centered2) {
        setIsCentered(
          window.document.body.offsetHeight -
            (refContent.current?.offsetHeight || 0) -
            240 <
            0
        )
      }
    }, [open, refContent, triggerHeight])

    const content = (
      <div
        className={classNames(
          styles.wrap,
          uiStore.isMobile && styles.mobile,
          centered && styles.centered,
          centered2 && isCentered && styles.centered,
          topRight && styles.topRight,
          staticContent && styles.staticContent,
          leftNavBar && styles.leftNavBar
        )}
        onClick={onOverlayClick}
        style={{ minHeight: uiStore.minRootHeight }}
      >
        <div
          className={styles.content}
          onClick={(e) => {
            e.stopPropagation()
          }}
          style={{ minHeight }}
          ref={refContent}
          id={'modal_content'}
        >
          {headerProps && (
            <Header
              withoutClose={staticContent}
              onClose={onClose}
              {...headerProps}
            />
          )}
          {!infoModalProps &&
            !deleteModalProps &&
            !withoutHeader &&
            !leftNavBar &&
            !headerProps && (
              <Header
                title={title}
                subTitle={subTitle}
                titleTooltip={titleTooltip}
                isNew={isNewTitle}
                withoutClose={staticContent}
                onClose={onClose}
                steps={steps}
                activeStep={activeStep}
                headerContent={headerContent}
              />
            )}
          {loading ? (
            <div
              style={{
                height: 528,
                width: minWidth || 800,
                position: 'relative',
              }}
            >
              {/*<SizedBox height={528} />*/}
              <AbsoluteLoader />
            </div>
          ) : (
            <div className={styles.wrapContent}>
              {infoModalProps && (
                <InfoModal {...infoModalProps} onClose={onClose} />
              )}
              {deleteModalProps && (
                <DeleteModal {...deleteModalProps} onClose={onClose} />
              )}
              <div
                className={classNames(
                  size === 'small' && 'smallModal',
                  size === 'medium' && 'mediumModal'
                )}
              >
                {children}
              </div>
            </div>
          )}
        </div>
      </div>
    )
    return (
      <>
        {open && !staticContent && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          <Portal>{content}</Portal>
        )}
        {open && staticContent && content}
      </>
    )
  }
)
