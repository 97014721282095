import { observer } from 'mobx-react-lite'
import { ListFilter } from 'store/contacts/segment/filters/ListFilter'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'

type Props = {
  filter: ListFilter
  readonly: boolean
}

export const ListsFilterContent = observer(({ filter, readonly }: Props) => {
  return (
    <AddContactsToListDropdown
      store={filter.dropdownContactListsStore}
      noLabel
      withCreateTag={false}
      readonly={readonly}
    />
  )
})
