// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FTDLGCcnEB2sx1XEJsYK{display:flex;flex-direction:column;overflow:auto;border-radius:16px;gap:48px}.WWZcUuPsOni103AzKE8Q{display:flex;gap:24px;margin-top:-24px;padding-left:16px}`, "",{"version":3,"sources":["webpack://./src/widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,kBAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,QAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  border-radius: 16px;\n  gap: 48px;\n}\n\n.actions{\n  display: flex;\n  gap: 24px;\n  margin-top: -24px;\n  padding-left: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `FTDLGCcnEB2sx1XEJsYK`,
	"actions": `WWZcUuPsOni103AzKE8Q`
};
export default ___CSS_LOADER_EXPORT___;
