import { observer } from 'mobx-react-lite'
import { Preview } from 'components/Preview/Preview'
import createCampaignStore from 'store/campaigns/createCampaignStore'
import { DisabledMessage } from 'components/Preview/DisabledMessage/DisabledMessage'
import { Fragment } from 'react'
import { PreviewSchedule } from 'components/Preview/PreviewSchedule/PreviewSchedule'
import { PreviewSMSContent } from 'components/Preview/PreviewSMSContent/PreviewSMSContent'
import { PreviewVoiceContent } from 'components/Preview/PreviewVoiceContent'
import { PreviewRinglessContent } from 'components/Preview/PreviewRinglessContent/PreviewRinglessContent'

export const PreviewCampaign = observer(() => {
  const { activeCampaign, messages } = createCampaignStore
  return (
    <Preview
      customContent={
        activeCampaign ? (
          <>
            {messages.map((message) => (
              <Fragment key={message.id}>
                <PreviewSchedule text={message.scheduleStore.sendTimeString} />
                {message?.type === 'SMS' && (
                  <PreviewSMSContent
                    phone={message.phonePull?.friendlyName || ''}
                    store={message.textareaStore}
                  />
                )}
                {message?.type === 'Voice' && (
                  <PreviewVoiceContent
                    phone={message.phonePull?.friendlyName || ''}
                    store={message.voiceStore}
                  />
                )}
                {message?.type === 'Ringless' && (
                  <PreviewRinglessContent
                    phone={message.phonePull?.friendlyName || ''}
                    store={message.ringlessStore}
                  />
                )}
              </Fragment>
            ))}
          </>
        ) : (
          <DisabledMessage />
        )
      }
    />
  )
})
