// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GO7urYldLWedHkUigwXy{display:grid;padding:8px 32px 32px 32px;gap:24px}.gS6IQrLeOf0h1drItkEf{width:100%;max-width:536px;display:grid;gap:16px}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectedFields/widgets/CollectedField/ui/Content/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,0BAAA,CACA,QAAA,CAEF,sBACE,UAAA,CACA,eAAA,CACA,YAAA,CACA,QAAA","sourcesContent":[".wrap{\n  display: grid;\n  padding: 8px 32px 32px 32px;\n  gap: 24px;\n}\n.textField{\n  width:100%;\n  max-width: 536px;\n  display: grid;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `GO7urYldLWedHkUigwXy`,
	"textField": `gS6IQrLeOf0h1drItkEf`
};
export default ___CSS_LOADER_EXPORT___;
