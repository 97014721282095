import { createContext, useContext } from 'react'
import { CollectedFieldActionStore } from 'widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction/store/collectedFieldActionStore'

export const CollectedFieldActionContext =
  createContext<CollectedFieldActionStore | null>(null)

export const useCollectedFieldActionContext = () => {
  const context = useContext(CollectedFieldActionContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with CollectedFieldActionContext'
    )

  return context
}
