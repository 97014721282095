import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'
import { CreateTriggerStore } from 'store/triggers/createTriggerStore'
import { CreateWebFormStore } from 'store/webForms/createWebFormStore'
import { CreateKeywordStore } from 'store/keywords/createKeywordStore'
import { CampaignMessageStore } from 'store/campaigns/campaignMessageStore'
import { ContentType } from 'src/generated/graphql'
import { ITabItem } from 'components/Tabs/Tabs'

export type CreateStoreTypes =
  | CreateBroadCastStore
  | CreateTriggerStore
  | CreateWebFormStore
  | CreateKeywordStore
  | CampaignMessageStore

export const contentTypeTabs: Array<ITabItem<ContentType>> = [
  { id: ContentType.Sms, label: 'SMS' },
  { id: ContentType.Voice, label: 'Voice' },
  { id: ContentType.Ringless, label: 'Ringless' },
]
