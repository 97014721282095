import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui/Button/Button'
import { Delete } from './Delete/Delete'
import { useCollectedFieldsStoreContext } from 'widgets/CollectedFields/context/context'
import { useCollectedFieldContext } from 'widgets/CollectedFields/widgets/CollectedField'
import { AngleDown, AngleUp, Duplicate } from 'components/Icon'

export const Actions = observer(() => {
  const { expand, toggleExpand, id } = useCollectedFieldContext()
  const { onDuplicate } = useCollectedFieldsStoreContext()

  const handleDuplicate = () => {
    onDuplicate(id)
  }
  return (
    <div className={styles.wrap}>
      <Button
        variant={'inactive-small'}
        text={expand ? 'Collapse' : 'Expand'}
        LeftIcon={expand ? AngleUp : AngleDown}
        onClick={toggleExpand}
      />
      <Button
        variant={'inactive-small'}
        text={'Duplicate'}
        LeftIcon={Duplicate}
        onClick={handleDuplicate}
      />
      <Delete />
    </div>
  )
})
