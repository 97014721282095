import { ContactsPickerStore } from 'widgets/ContactsPicker/store/ContactsPickerStore'
import { makeAutoObservable } from 'mobx'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import { AddFiltersModal } from 'widgets/AddFiltersModal/AddFiltersModal'
import { nanoid } from 'nanoid'
import { IListItem } from 'components/SelectList/ListItem/ListItem'

export class AddFiltersModalStore {
  contactsPickerStore
  constructor(private parentContactsPickerStore: ContactsPickerStore) {
    this.contactsPickerStore = new ContactsPickerStore({
      withoutAddFilters: true,
    })
    this.contactsPickerStore.setSelectedItemsMap(
      new Map(parentContactsPickerStore.selectedItemsMap)
    )
    makeAutoObservable(this)
    this.openModal()
  }
  modalId = nanoid()
  openModal = () => {
    modalStore.addModal({
      id: this.modalId,
      variant: 'default',
      headerProps: {
        title: 'Add filters',
        isNew: true,
      },
      children: <AddFiltersModal store={this} />,
    })
  }

  onClose = () => {
    modalStore.closeModal(this.modalId)
  }
  get disabledAddFilters() {
    return this.contactsPickerStore.isEmpty
  }
  onAddFilters = () => {
    this.parentContactsPickerStore.setSelectedItemsMap(
      this.contactsPickerStore.selectedItemsMap
    )
    this.onClose()
  }

  activeItem: IListItem | null = null
  editItem: IListItem | null = null
  setEditItem = (item: IListItem) => {
    this.activeItem = item
    this.editItem = item
    this.selectItem = null
  }
  selectItem: IListItem | null = null
  setSelectItem = (item: IListItem) => {
    this.activeItem = item
    this.selectItem = item
    this.editItem = null
  }
  isNew = false
  onAddFilter = () => {
    this.isNew = true
    this.activeItem = null
    this.selectItem = null
    this.editItem = null
  }

  onCancel = () => {
    this.isNew = false
    this.activeItem = null
    this.selectItem = null
    this.editItem = null
  }
}
