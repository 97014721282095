import { CaptureFieldStore } from 'widgets/DataCapture/widtets/CaptureField/store/CaptureFieldStore'
import { CaptureFieldContext } from 'widgets/DataCapture/widtets/CaptureField/context/context'
import {
  CaptureFieldUi,
  ICaptureFieldUiProps,
} from 'widgets/DataCapture/widtets/CaptureField/ui/CaptureFieldUi'

type ICaptureFieldProps = {
  store: CaptureFieldStore
} & ICaptureFieldUiProps
export const CaptureField = ({ store, ...props }: ICaptureFieldProps) => {
  return (
    <CaptureFieldContext.Provider value={store}>
      <CaptureFieldUi {...props} />
    </CaptureFieldContext.Provider>
  )
}
