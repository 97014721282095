import { CollectedFieldsStore } from './store/CollectedFieldsStore'
import { CollectedFieldsStoreContext } from './context/context'
import { CollectedFieldsUi } from './ui/CollectedFieldsUi'

type ICollectedFieldsProps = {
  store: CollectedFieldsStore
}

export const CollectedFields = ({ store }: ICollectedFieldsProps) => {
  return (
    <CollectedFieldsStoreContext.Provider value={store}>
      <CollectedFieldsUi />
    </CollectedFieldsStoreContext.Provider>
  )
}
