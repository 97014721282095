import classNames from 'classnames'
import styles from 'shared/ui/DropdownButton/styles.module.scss'
import { Icon, IIconProps } from 'components/Icon'
import React, { FC, ReactNode } from 'react'

export type IRightContentProps = {
  RightIcon?: FC
  rightIconProps?: Partial<IIconProps>
  showRightContentOnlyByHover?: boolean
  rightContent?: ReactNode
}

export const RightContent = ({
  rightContent,
  RightIcon,
  rightIconProps,
}: IRightContentProps) => {
  return (
    <div className={classNames(styles.right)}>
      {rightContent}
      {RightIcon && (
        <Icon className={styles.icon} {...rightIconProps}>
          <RightIcon />
        </Icon>
      )}
    </div>
  )
}
