import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { useState } from 'react'
import { InputPhone } from 'components/Input/InputText/InputPhone'
import { CustomFieldType } from 'src/generated/graphql'
import { InputText } from 'components/Input/InputText/InputText'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { DataItem } from 'src/app_external/webform/AppWebform'
import styles from './styles.module.scss'
import { appLinks } from 'src/util/links'
import classNames from 'classnames'
import { MuiDateEndTimePickerWithoutTZ } from 'components/DatePicker/MuiDateEndTimePickerWithoutTZ'
import { DateEndTimePickerWithoutTZ } from 'components/DatePicker/DateEndTimePickerWithoutTZ'
import { FiledTypeEnum } from 'src/enums/FiledTypeEnum'
import { isHttps } from 'src/util/validators'
import { ScrollWrapper } from 'components/Wrappers/ScrollWrapper/ScrollWrapper'
import { ITestWebForm } from 'src/types/IWebForm'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { NOT_REQUIRED_WEB_FORM_COMPLIANCE_TEXT } from 'src/static/constants'
import { ICustomSubscribeButtonParams } from 'src/pages/main/webForms/create/createForm/Settings/CustomSubscribeButton/CustomSubscribeButtonStore'

export const SubscribeContent = ({
  openThank,
  fromApp,
  env = 'dev',
  testWebForm,
  data,
}: {
  testWebForm: Omit<ITestWebForm, 'fieldsToCollect'>
  data: DataItem[]
  openThank: () => void
  fromApp?: boolean
  env?: string
}) => {
  const {
    subscribeBtnText = 'Subscribe',
    complianceMessage,
    uuid,
    termsUrl,
    complianceUrl,
    webFormConfig: webFormConfigProps,
  } = testWebForm

  const [hover, setHover] = useState(false)

  const webFormConfig: ICustomSubscribeButtonParams | null = webFormConfigProps
    ? JSON.parse(webFormConfigProps)
    : null

  const [agree, setAgree] = useState(false)

  const [loading, setLoading] = useState(false)
  const init = new Map<string, string | Date | null>(
    data.map((item) => [item.key, ''])
  )
  const [mapValues, setValues] = useState(init)
  const initErrors = new Map<string, boolean>(
    data.map((item) => [item.key, false])
  )
  const [mapErrors, setErrors] = useState(initErrors)
  const getApi = () => {
    if (env === 'dev') {
      return 'https://api.dev.callloop.com'
    }
    if (env === 'app') {
      return 'https://api.callloop.com'
    }
    if (env === 'qa') {
      return 'https://api.qa.callloop.com'
    }
    return 'https://api.dev.callloop.com'
  }

  const api = fromApp ? process.env.API : getApi()

  const onSubscribe = async () => {
    if (uuid) {
      setLoading(true)
      const data: { [key: string]: string | Date | null } = {}
      mapValues.forEach((value, key) => {
        data[key] = typeof value === 'string' ? value.trim() : value
      })
      const body = {
        contact: data,
      }
      try {
        const res = await fetch(`${api}/webhook/webform/${uuid}`, {
          method: 'POST',
          body: JSON.stringify(body),
        })
        if (res.status === 200) {
          openThank()
          setValues(init)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
  }

  const onChangeField = (
    key: string,
    value: string | Date | null,
    type?: string,
    event?: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      (type === FiledTypeEnum.number || type === CustomFieldType.Number) &&
      !value &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      event?.nativeEvent.data
    ) {
      setErrors((prevState) => {
        prevState.set(key, true)
        return new Map(prevState)
      })
      setValues((prevState) => {
        prevState.set(key, value)
        return new Map(prevState)
      })
    } else {
      setErrors((prevState) => {
        prevState.set(key, false)
        return new Map(prevState)
      })
      setValues((prevState) => {
        prevState.set(key, value)
        return new Map(prevState)
      })
    }
  }
  const onBlur = (key: string) => {
    const value = mapValues.get(key) as string
    if (value && !isHttps(value)) {
      setErrors((prevState) => {
        prevState.set(key, true)
        return new Map(prevState)
      })
    } else {
      setErrors((prevState) => {
        prevState.set(key, false)
        return new Map(prevState)
      })
    }
  }
  const getFieldByData = ({
    type,
    required,
    placeholder,
    name,
    key,
  }: DataItem) => {
    if (type === FiledTypeEnum.phone || key === 'phoneNumber') {
      return (
        <InputPhone
          key={key}
          required={required || key === 'phoneNumber'}
          label={name || 'Phone'}
          value={mapValues.get(key) as string}
          onChangeValue={(value) => onChangeField(key, value)}
        />
      )
    }
    if (type === FiledTypeEnum.text || type === CustomFieldType.Text) {
      return (
        <InputText
          key={key}
          required={required}
          label={name}
          placeholder={placeholder || `Enter ${name}`}
          value={mapValues.get(key) as string}
          onChangeValue={(value) => onChangeField(key, value)}
        />
      )
    }
    if (type === FiledTypeEnum.url || type === CustomFieldType.Url) {
      return (
        <InputText
          key={key}
          error={
            (mapErrors.get(key) &&
              'Please enter a full url (e.g. https://www.site.com)') ||
            ''
          }
          required={required}
          label={name}
          placeholder={placeholder || `Enter ${name}`}
          value={mapValues.get(key) as string}
          onChangeValue={(value) => onChangeField(key, value)}
          onBlur={() => onBlur(key)}
        />
      )
    }
    if (type === FiledTypeEnum.number || type === CustomFieldType.Number) {
      return (
        <InputText
          key={key}
          error={(mapErrors.get(key) && 'Please enter a number.') || ''}
          numericFormat
          required={required}
          label={name}
          placeholder={placeholder || `Enter ${name}`}
          value={mapValues.get(key) as string}
          onChangeValue={(value, event) =>
            onChangeField(key, value, type, event)
          }
        />
      )
    }
    if (
      type === FiledTypeEnum.date ||
      type === CustomFieldType.Date ||
      type === FiledTypeEnum.dateTime ||
      type === CustomFieldType.DateTime
    ) {
      return fromApp ? (
        <DateEndTimePickerWithoutTZ
          key={key}
          label={String(name)}
          startDate={mapValues.get(key) as Date}
          onChange={(date) => onChangeField(key, date)}
          position={TTPositionEnum.bottomLeft}
          required={required}
          withoutTime={
            type === FiledTypeEnum.date || type === CustomFieldType.Date
          }
        />
      ) : (
        <MuiDateEndTimePickerWithoutTZ
          key={key}
          label={String(name)}
          startDate={mapValues.get(key) as Date}
          onChange={(date) => onChangeField(key, date)}
          position={TTPositionEnum.bottomLeft}
          required={required}
          withoutTime={
            type === FiledTypeEnum.date || type === CustomFieldType.Date
          }
          width={'100%'}
        />
      )
    }
    return <></>
  }

  const isDisabledSubscribe = () => {
    let disabled = false
    if (!agree) {
      disabled = true
    }
    const requiredData = data.filter((item) => item.required)
    requiredData.forEach((item) => {
      const value = mapValues.get(item.key)
      if (
        !value ||
        ((item.type === 'PHONE' || item.key === 'phoneNumber') &&
          String(value).includes('_'))
      ) {
        disabled = true
      }
    })

    return disabled
  }

  const getBackgroundButton = () => {
    if (!webFormConfig) return
    if (isDisabledSubscribe()) {
      return webFormConfig.disabledButtonColor
    }
    if (hover) {
      return webFormConfig.hoverButtonColor
    }
    return webFormConfig.buttonColor
  }
  return (
    <>
      {fromApp ? (
        <ScrollWrapper
          maxHeight={'calc(100vh - 88px - 64px - 64px - 44px - 64px - 112px)'}
        >
          <div className={classNames(styles.col8, fromApp && styles.fromApp)}>
            {data.map(getFieldByData)}
          </div>
        </ScrollWrapper>
      ) : (
        <div className={styles.col8}>{data.map(getFieldByData)}</div>
      )}
      <div className={fromApp && styles.fromApp}>
        <div
          className={classNames(
            styles.wrapSubscribe,
            'callLoopForm-Subscribe-Text'
          )}
        >
          <CheckBox
            checked={agree}
            onChangeValue={() => setAgree(!agree)}
            size={'small'}
          />
          <span className={styles.footerText}>
            By checking this box and clicking “{subscribeBtnText}” you agree
            with{' '}
            <a
              target={'_blank'}
              className={styles.link}
              href={termsUrl || appLinks.tos}
              rel="noreferrer"
            >
              Terms
            </a>{' '}
            and{' '}
            <a
              target={'_blank'}
              className={styles.link}
              href={complianceUrl || appLinks.privacy}
              rel="noreferrer"
            >
              Privacy
            </a>
            {complianceMessage ?? NOT_REQUIRED_WEB_FORM_COMPLIANCE_TEXT}
          </span>
        </div>

        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.large}
          onClick={onSubscribe}
          disabled={isDisabledSubscribe()}
          className={styles.wrapBtn}
          loading={loading}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{
            color: webFormConfig?.textButtonColor || undefined,
            background: getBackgroundButton(),
          }}
        >
          {subscribeBtnText}
        </Button>
        <div className={classNames(styles.footer, 'callLoopForm-Footer')}>
          <span className={styles.footerText}>
            SMS Marketing powered by{' '}
            <a
              target={'_blank'}
              className={styles.link}
              href={appLinks.callLoop}
              rel="noreferrer"
            >
              Call Loop
            </a>
          </span>
        </div>
      </div>
    </>
  )
}
