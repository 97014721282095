// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E8xhC8j7KznBY_YVl4Eq{margin-top:40px;display:flex;justify-content:space-between}.E8xhC8j7KznBY_YVl4Eq.z46NWFlNflqFyhZBaQwZ{margin-top:0;padding:40px 32px}.E8xhC8j7KznBY_YVl4Eq .G04uUyLVdeneYpDnpJFz{display:flex;column-gap:12px}.LvlU3g_y4sOBFwmaxjg5{padding:8px}`, "",{"version":3,"sources":["webpack://./src/pages/main/broadcasts/createBroadcast/newBroadcast/ActionContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAKA,YAAA,CACA,6BAAA,CALA,2CACE,YAAA,CACA,iBAAA,CAIF,4CACE,YAAA,CACA,eAAA,CAGJ,sBACE,WAAA","sourcesContent":[".actionsWrap{\n  margin-top: 40px;\n  &.isDataCollection{\n    margin-top: 0;\n    padding: 40px 32px;\n  }\n  display: flex;\n  justify-content: space-between;\n  .left{\n    display: flex;\n    column-gap: 12px;\n  }\n}\n.menuWrap{\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsWrap": `E8xhC8j7KznBY_YVl4Eq`,
	"isDataCollection": `z46NWFlNflqFyhZBaQwZ`,
	"left": `G04uUyLVdeneYpDnpJFz`,
	"menuWrap": `LvlU3g_y4sOBFwmaxjg5`
};
export default ___CSS_LOADER_EXPORT___;
