import { FC } from 'react'
import { useTimeZoneDate } from 'lib/dayjs'
import { DatePickerWithoutTZ } from 'components/DatePicker/DatePickerWithoutTZ'

type Props = {
  startDate: Date | null
  onChange: (val: Date | null) => void
  dateFormat?: string
  readonly?: boolean
  wrapperClassName?: string
}

export const DatePicker: FC<Props> = ({
  startDate,
  onChange,
  dateFormat = 'MMM dd, yyyy',
  readonly,
  wrapperClassName,
}) => {
  const { startDateTz, handleChange } = useTimeZoneDate({
    startDate,
    onChange,
  })
  return (
    <DatePickerWithoutTZ
      startDate={startDateTz}
      onChange={handleChange}
      dateFormat={dateFormat}
      readonly={readonly}
      wrapperClassName={wrapperClassName}
    />
  )
}
