import { makeAutoObservable } from 'mobx'
import { TextareaStore } from 'store/textareaStore'
import { nanoid } from 'nanoid'
import { SmsMessageInput, WorkflowActionType } from 'src/generated/graphql'
import type { IListItem } from 'components/SelectList/ListItem/ListItem'

export type ICollectedFieldActionInput = {
  type: WorkflowActionType
  waitReplay: number
  message?: SmsMessageInput
}

export class CollectedFieldActionStore {
  textareaStore: TextareaStore
  constructor(
    private onAddNextAction: (id: string) => void,
    private onRemoveNextActions: (id: string) => void,
    public disabled: boolean,
    input?: ICollectedFieldActionInput
  ) {
    makeAutoObservable(this)
    this.textareaStore = new TextareaStore()
    if (input) {
      this.init(input)
    }
  }

  get input(): ICollectedFieldActionInput {
    return {
      type: this.action,
      waitReplay: this.waitReplay,
      message: this.textareaStore.smsMessageInput,
    }
  }

  init = (input: ICollectedFieldActionInput) => {
    this.action = input.type
    this.waitReplay = input.waitReplay
    this.textareaStore.setSmsMessage(input.message)
  }

  id = nanoid()

  isLimitError = false
  setLimitError = (value: boolean) => {
    this.isLimitError = value
  }
  checkLimitError = () => {
    this.setLimitError(!this.waitReplay || this.waitReplay > 48)
  }
  waitReplay = 4
  setWaitReplay = (value: number) => {
    this.setLimitError(false)
    this.waitReplay = value
  }

  action: WorkflowActionType = WorkflowActionType.StopWorkflow
  setAction = (action: WorkflowActionType) => {
    this.action = action
    if (action === WorkflowActionType.StopWorkflow) {
      this.onRemoveNextActions(this.id)
    }
    if (action === WorkflowActionType.SendMessage) {
      this.onAddNextAction(this.id)
    }
  }

  actionOptionsMap: Map<WorkflowActionType, Omit<IListItem, 'isActive'>> =
    new Map([
      [
        WorkflowActionType.StopWorkflow,
        {
          id: WorkflowActionType.StopWorkflow,
          text: 'Stop data collection',
        },
      ],
      [
        WorkflowActionType.SendMessage,
        {
          id: WorkflowActionType.SendMessage,
          text: 'Send follow up message ',
        },
      ],
    ])

  get actionOptions(): IListItem[] {
    return Array.from(this.actionOptionsMap.values()).map((item) => ({
      ...item,
      isActive: item.id === this.action,
    }))
  }
  get actionTitle() {
    if (this.action) {
      return this.actionOptionsMap.get(this.action)?.text
    }
  }
}
