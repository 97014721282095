import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { useAddFiltersModalContext } from 'widgets/AddFiltersModal/context/context'
import { Tabs } from 'components/Tabs/Tabs'
import { useState } from 'react'
import { Button } from 'shared/ui/Button/Button'
import { Include } from 'widgets/AddFiltersModal/ui/Include/Include'
import { Contacts } from 'widgets/AddFiltersModal/ui/Contacts/Contacts'

export const AddFiltersModalUi = observer(() => {
  const { disabledAddFilters, onAddFilters, onClose } =
    useAddFiltersModalContext()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  return (
    <div className={styles.wrap}>
      <Tabs
        onTabClick={setActiveTabIndex}
        tabs={['Include', 'Contacts']}
        activeTabIndex={activeTabIndex}
      />
      <div className={styles.content}>
        {activeTabIndex === 0 && <Include />}
        {activeTabIndex === 1 && <Contacts />}
      </div>
      <div className={styles.actions}>
        <Button
          variant={'primary-medium'}
          text={'Add filters'}
          onClick={onAddFilters}
          disabled={disabledAddFilters}
        />
        <Button
          variant={'secondary-medium'}
          text={'Cancel'}
          onClick={onClose}
        />
      </div>
    </div>
  )
})
