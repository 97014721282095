import styles from 'components/Preview/styles.module.scss'
import { MessageCardContent } from 'components/Preview/MessageCardContent'
import { FC } from 'react'
import { RinglessStore } from 'store/ringlessStore/ringlessStore'
import { observer } from 'mobx-react-lite'

type IPreviewRinglessContent = {
  store: RinglessStore
  phone: string
}

export const PreviewRinglessContent: FC<IPreviewRinglessContent> = observer(
  ({ phone, store }) => {
    const { ringlessVoicemailAudio } = store
    return (
      <div className={styles.audioMessage}>
        <MessageCardContent
          title={'Ringless'}
          from={'ringlessPreview'}
          audio={ringlessVoicemailAudio}
          phone={phone}
        />
      </div>
    )
  }
)
