import {
  AccountIntegrationType,
  useSetupIntegrationMutation,
} from 'src/generated/graphql'
import { useEffect, useState } from 'react'
import integrationsStore from 'store/settings/integrationsStore'
import alertStore from 'store/alertStore'
import { capitalize } from 'lodash'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { ObservableQuery } from '@apollo/client'

export function useSetupIntegrations(
  refetchFields?: ObservableQuery['refetch']
) {
  const [setupIntegration, { loading, error }] = useSetupIntegrationMutation()

  useEffect(() => {
    if (
      error?.graphQLErrors?.length &&
      error?.message?.includes('This HubSpot account is already connected')
    ) {
      integrationsStore.setHubspotErrorConnect(error?.graphQLErrors[0])
    }
  }, [error])
  const [accountIntegrationType, setAccountIntegrationType] =
    useState<AccountIntegrationType | null>(null)

  useEffect(() => {
    const storageHandler = (event: StorageEvent) => {
      if (event.key === 'OAuthData' && event.newValue) {
        const value = JSON.parse(event.newValue)
        if (value) {
          const { code, oAuthType } = value
          setAccountIntegrationType(oAuthType)
          if (
            !integrationsStore.oAuth2IntegrationsMap.get(oAuthType)
              ?.connected ||
            integrationsStore.oAuth2IntegrationsMap.get(oAuthType)?.invalidated
          ) {
            setupIntegration({
              variables: {
                input: {
                  oAuth2Integration: {
                    code,
                    type: oAuthType,
                  },
                },
              },
            })
              .then((res) => {
                if (res.data?.setupIntegration) {
                  integrationsStore.setAccountIntegrationsData(
                    res.data?.setupIntegration
                  )
                }
                if (
                  res.data?.setupIntegration?.OAuth2Integrations?.find(
                    (item) => item?.type === oAuthType
                  )?.connected
                ) {
                  alertStore.setAlert({
                    title: `${capitalize(oAuthType)} connected!`,
                    type: AlertTypeEnum.success,
                  })
                  refetchFields && refetchFields()
                }
              })
              .catch((e) => {
                if (
                  e instanceof Error &&
                  e.message.includes(
                    'This HubSpot account is already connected'
                  )
                ) {
                  alertStore.disabledErrorAlert(e.message)
                } else {
                  console.error(e)
                }
              })
          }
        }
      }
    }
    window.addEventListener('storage', storageHandler)
    return () => {
      window.removeEventListener('storage', storageHandler)
    }
  }, [])

  return { loading, accountIntegrationType }
}
