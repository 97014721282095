import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { useCaptureFieldContext } from 'widgets/DataCapture/widtets/CaptureField/context/context'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/ui/Typography'
import { PersonalizeSelect } from 'widgets/PersonalizeSelect'
import { Delete, DragDrop } from 'components/Icon'
import { Textarea } from 'components/Textarea/Textarea'
import { useEffect } from 'react'
import { Button } from 'shared/ui/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'

export type ICaptureFieldUiProps = {
  index: number
  itemLength: number
  onDelete: (id: string) => void
}

export const CaptureFieldUi = observer(
  ({ index, itemLength, onDelete }: ICaptureFieldUiProps) => {
    const { personalizeSelectStore, textareaStore, setIndex, id } =
      useCaptureFieldContext()
    useEffect(() => {
      setIndex(index)
    }, [index])
    return (
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.draggable}>
            <Icon color={'--primary-color-gray-2'}>
              <DragDrop />
            </Icon>
            <Typography
              variant={'s1-semiBold'}
              className={styles.title}
              tag={'div'}
            >
              {index === 0 ? 'Capture' : 'Then capture'}
            </Typography>
          </div>
          {itemLength > 1 && (
            <MuiTooltip title={'Remove'} arrow placement={'top'}>
              <Button
                variant={'tertiary-small'}
                LeftIcon={Delete}
                onClick={() => onDelete(id)}
              />
            </MuiTooltip>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.filed}>
            <div className={styles.left}>
              <Typography
                variant={'s2-regular'}
                color={'--primary-color-gray-1'}
              >
                Property
              </Typography>
            </div>
            <div className={styles.right}>
              <PersonalizeSelect
                store={personalizeSelectStore}
                triggerType={'text'}
              />
            </div>
          </div>
          <div className={styles.filed}>
            <div className={styles.left}>
              <Typography
                variant={'s2-regular'}
                color={'--primary-color-gray-1'}
              >
                Question
              </Typography>
            </div>
            <div className={styles.right}>
              <Textarea
                textareaStore={textareaStore}
                variant={'data-capture'}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
)
