import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { FC } from 'react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/ui/Typography'
import { Minus, Plus12 } from 'components/Icon'

type Props = {
  count: string | number
  onMinus: () => void
  onPlus: () => void
  onChange?: (value: string | number) => void
}

export const Counter: FC<Props> = ({ count, onMinus, onPlus, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '')
    if (value.length > 3) return
    onChange && onChange(value)
  }
  const onBlur = () => {
    if (!count) {
      onChange && onChange(1)
    }
  }
  return (
    <div className={styles.wrap}>
      <Button
        size={BtnSize.mini}
        typeBtn={BtnType.secondaryGray}
        onClick={onMinus}
        disabled={+count <= 1}
      >
        <Icon fontSize={12} color={'--primary-color-gray-1'}>
          <Minus />
        </Icon>
      </Button>
      {onChange ? (
        <input
          type="text"
          value={count || ''}
          onChange={handleChange}
          onBlur={onBlur}
        />
      ) : (
        <Typography>{count}</Typography>
      )}

      <Button
        size={BtnSize.mini}
        typeBtn={BtnType.secondaryGray}
        onClick={onPlus}
      >
        <Icon fontSize={12} color={'--primary-color-gray-1'}>
          <Plus12 />
        </Icon>
      </Button>
    </div>
  )
}
