import GoogleSheets from 'shared/assets/images/GoogleSheets.png'

import { FC, ReactNode } from 'react'
import { AccountIntegrationType } from 'src/generated/graphql'
import { appLinks } from 'src/util/links'
import { IIconProps } from 'components/Icon'
import {
  ActiveCampaign,
  AWeber,
  Drip,
  GoHighLevel,
  GravityForms,
  HubSpotLogo,
  KeapLogo,
  Leadpages,
  Logo,
  Mailchimp,
  Stripe,
  Typeform,
  Unbounce,
  Zapier,
} from 'components/Icon'

export interface IIntegration {
  title: string
  subTitle?: string
  docsLink?: string
  exploreLink?: string
  disabled?: boolean
  authUrl?: string
  automationsCount?: number
  zapsCount?: number
  Icon?: FC
  iconProps?: Omit<IIconProps, 'icon'>
  logoContent?: ReactNode
  type: AccountIntegrationType | 'Mock'
  noDefaultSvgWidth?: boolean
  isCustomSvg?: boolean
  isNew?: boolean
}

export type IAccountIntegrationType = AccountIntegrationType | 'Mock'

export const integrationCards: IIntegration[] = [
  {
    type: AccountIntegrationType.ActiveCampaign,
    Icon: ActiveCampaign,
    title: 'ActiveCampaign',
    docsLink: appLinks.activeCampaignDocs,
  },
  {
    type: AccountIntegrationType.Keap,
    isCustomSvg: true,
    iconProps: {
      fontSize: 30,
    },
    Icon: KeapLogo,
    title: 'Keap',
    subTitle: 'Infusionsoft',
    docsLink: appLinks.keapDocs,
  },
  {
    type: AccountIntegrationType.HubSpot,
    Icon: HubSpotLogo,
    title: 'HubSpot',
    docsLink: appLinks.hubSpotDocs,
  },
  {
    type: AccountIntegrationType.LeadConnector,
    Icon: GoHighLevel,
    title: 'GoHighLevel',
    noDefaultSvgWidth: true,
    docsLink: appLinks.goHighLevelDocs,
    isNew: true,
  },
  {
    type: AccountIntegrationType.Zapier,
    Icon: Zapier,
    title: 'Zapier',
    docsLink: appLinks.zapierDocs,
    authUrl: 'https://zapier.com/app/connections/call-loop',
  },
  {
    type: AccountIntegrationType.Custom,
    Icon: Logo,
    title: 'Webhook',
    docsLink: appLinks.webhookDocs,
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/aweber/integrations/call-loop',
    Icon: AWeber,
    title: 'AWeber',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/drip/integrations/call-loop',
    Icon: Drip,
    title: 'Drip',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/mailchimp/integrations/call-loop',
    Icon: Mailchimp,
    title: 'Mailchimp',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/google-sheets/integrations/call-loop',
    logoContent: (
      <img src={GoogleSheets} alt={'GoogleSheets'} width={22.531} height={30} />
    ),
    title: 'Google Sheets',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/typeform/integrations/call-loop',
    Icon: Typeform,
    title: 'Typeform',
    noDefaultSvgWidth: true,
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/unbounce/integrations/call-loop',
    Icon: Unbounce,
    title: 'Unbounce',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/gravity-forms/integrations/call-loop',
    Icon: GravityForms,
    title: 'Gravity Forms',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/stripe/integrations/call-loop',
    Icon: Stripe,
    title: 'Stripe',
  },
  {
    type: 'Mock',
    exploreLink: 'https://zapier.com/apps/leadpages/integrations/call-loop',
    Icon: Leadpages,
    title: 'Leadpages',
  },
]

export const integrationCardsMap: Map<IAccountIntegrationType, IIntegration> =
  new Map(
    integrationCards.map((integrationCard) => [
      integrationCard.type,
      integrationCard,
    ])
  )
