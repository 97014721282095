import styles from '../styles.module.scss'
import { observer } from 'mobx-react-lite'
import { useCustomPlanContext } from 'src/pages/superAdmin/modals/CustomPlan/context/context'
import { Typography } from 'shared/ui/Typography'
import { Switch } from 'components/Switch/Switch'
import { CustomPrice } from 'src/pages/superAdmin/modals/CustomPlan/ui/CustomPrices/CustomPrice'

export const CustomPrices = observer(() => {
  const { isCustomPrices, setIsCustomPrices, customPriceFields } =
    useCustomPlanContext()
  return (
    <>
      <div className={styles.row}>
        <Typography>Use custom prices</Typography>
        <Switch active={isCustomPrices} onChange={setIsCustomPrices} />
      </div>
      {isCustomPrices && (
        <>
          {customPriceFields.map(([type, title]) => (
            <CustomPrice key={type} type={type} title={title} />
          ))}
        </>
      )}
    </>
  )
})
