import { observer } from 'mobx-react-lite'
import { Button } from 'shared/ui/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { useCollectedFieldsStoreContext } from 'widgets/CollectedFields/context/context'
import { useEffect, useState } from 'react'
import { useCollectedFieldContext } from 'widgets/CollectedFields/widgets/CollectedField'
import { Delete as DeleteIcon } from 'components/Icon'

export const Delete = observer(() => {
  const { id } = useCollectedFieldContext()
  const { onDelete } = useCollectedFieldsStoreContext()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [idDeleteTimeout, setIdDeleteTimeout] = useState<NodeJS.Timeout | null>(
    null
  )

  useEffect(() => {
    return () => {
      setConfirmDelete(false)
      if (idDeleteTimeout) {
        clearTimeout(idDeleteTimeout)
      }
    }
  }, [])

  const handleDelete = () => {
    if (confirmDelete) {
      onDelete(id)
    } else {
      setConfirmDelete(true)
      setIdDeleteTimeout(
        setTimeout(() => {
          setConfirmDelete(false)
        }, 2000)
      )
    }
  }
  return (
    <MuiTooltip title={confirmDelete ? 'Sure?' : ''} arrow placement={'top'}>
      <Button
        variant={'inactive-small'}
        text={'Delete'}
        LeftIcon={DeleteIcon}
        onClick={handleDelete}
      />
    </MuiTooltip>
  )
})
