import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Alert } from 'components/Alert/Alert'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { Table, TableVariant } from 'components/Table/Table'
import React, { useEffect, useMemo, useState } from 'react'
import { Column } from 'react-table'
import { SizedBox } from 'components/SizedBox'
import {
  Status,
  useCreateBroadcastMutation,
  useGetBroadcastEstimatedPriceQuery,
  useUpdateBroadcastMutation,
} from 'src/generated/graphql'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import broadcastsStore from 'store/broadcasts/broadcastsStore'
import { numberString } from 'src/util/functions'
import billingStore from 'store/settings/billing/billingStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { CreateTestItBroadcastBtn } from 'src/pages/main/broadcasts/components/CreateTestItBroadcastBtn'
import { BroadcastLimitAlert } from 'src/pages/main/broadcasts/createBroadcast/newBroadcast/BroadcastLimitAlert'
import upgradePlanStore from 'src/Modals/UpgradePlanModal/store/upgradePlanStore'
import classNames from 'classnames'
import { openBuyCreditsModal } from 'store/settings/billing/actions'
import { useApolloError } from 'src/hooks/useApolloError'
import { ReviewBroadcastStore } from 'src/pages/main/broadcasts/createBroadcast/ReviewBroadcast/store/reviewBroadcastStore'

interface IReviewTable {
  sendFrom: string
  sendTime: string
  totalContacts: number
  totalCredits: number
}

type IReviewBroadcastProps = {
  store: ReviewBroadcastStore
}

export const ReviewBroadcast = observer(({ store }: IReviewBroadcastProps) => {
  const [openTest, setOpenTest] = useState(false)
  const navigate = useNavigate()
  const [createBroadcast, { loading: loadingCreate, error: errorCreate }] =
    useCreateBroadcastMutation()
  const [updateBroadcast, { loading: loadingUpdate, error: errorUpdate }] =
    useUpdateBroadcastMutation()

  const { errorMessage, ErrorAlert } = useApolloError(
    errorCreate || errorUpdate
  )

  const {
    broadcastInput,
    setEstimate,
    totalContacts,
    totalCredits,
    sendTimeString,
    broadcastAllowed,
    onClose,
    neededCredits,
    isDisableByLimit,
    newBroadCastStore,
    broadcastAllowedMessage,
  } = store

  const { data, loading } = useGetBroadcastEstimatedPriceQuery({
    variables: {
      broadcastInput: broadcastInput,
    },
  })

  useEffect(() => {
    if (data) {
      setEstimate(data)
    }
  }, [data])

  const dataTable: IReviewTable[] = useMemo(() => {
    return [
      {
        sendFrom: broadcastInput.sendFromPhoneNumber?.friendlyName || '',
        sendTime: sendTimeString,
        totalContacts: totalContacts,
        totalCredits: totalCredits,
      },
    ]
  }, [
    broadcastInput.sendFromPhoneNumber?.friendlyName,
    sendTimeString,
    totalContacts,
    totalCredits,
  ])

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const columns: Column<IReviewTable>[] = useMemo(() => {
    return [
      {
        Header: 'Send from',
        accessor: 'sendFrom',
      },
      {
        Header: 'Send time',
        accessor: 'sendTime',
      },
      {
        Header: 'Total Contacts',
        accessor: 'totalContacts',
        Cell: ({ value }) => numberString({ val: value }),
      },
      {
        Header: 'Total Credits',
        accessor: 'totalCredits',
        Cell: ({ value }) => numberString({ val: value }),
      },
    ]
  }, [broadcastInput.sendFromPhoneNumber])

  const onSchedule = async () => {
    try {
      if (broadcastInput.id) {
        await updateBroadcast({
          variables: {
            broadcastInput: {
              ...broadcastInput,
              status:
                broadcastInput.status === Status.Draft
                  ? Status.Scheduled
                  : broadcastInput.status,
            },
          },
        })
      } else {
        await createBroadcast({
          variables: {
            broadcastInput,
          },
        })
      }
      onClose()

      broadcastsStore.tableStore.onRefresh()
      navigate(RoutesEnum.broadcasts)
    } catch (e) {
      console.error(e)
    }
  }

  const info = `To send your broadcast you need at least ${numberString({
    val: neededCredits,
  })} credits more`

  return (
    <div className={styles.wrap}>
      {<ErrorAlert />}
      {!broadcastAllowed && (
        <BroadcastLimitAlert
          type={AlertTypeEnum.error}
          newBroadCastStore={newBroadCastStore}
          broadcastAllowedMessage={broadcastAllowedMessage}
        />
      )}
      {neededCredits > 0 && (
        <Alert
          title={info}
          type={AlertTypeEnum.notify2}
          rightContent={
            billingStore.isTrial ? (
              <button
                className={classNames(styles.upgrade, 's1 link bold')}
                onClick={() => upgradePlanStore.setOpenUpgradePlanModal(true)}
              >
                Upgrade
              </button>
            ) : (
              <button
                className={classNames(styles.upgrade, 's1 link bold')}
                onClick={() => openBuyCreditsModal(true, info)}
              >
                Buy credits
              </button>
            )
          }
        />
      )}
      <SizedBox height={12} />
      <Table<IReviewTable>
        columns={columns}
        data={dataTable}
        variant={TableVariant.variant1}
      />
      <div className={styles.actions}>
        <Button
          typeBtn={BtnType.primary}
          size={BtnSize.medium}
          onClick={onSchedule}
          loading={loadingCreate || loadingUpdate}
          disabled={
            !!errorMessage ||
            isDisableByLimit ||
            loading ||
            neededCredits > 0 ||
            !broadcastAllowed
          }
        >
          Schedule
        </Button>
        <Button
          typeBtn={BtnType.secondaryGray}
          size={BtnSize.medium}
          onClick={onClose}
        >
          Cancel
        </Button>
        <div />
        <div className={styles.wrapTest}>
          <CreateTestItBroadcastBtn
            setOpenTest={() => setOpenTest(!openTest)}
            openTest={openTest}
            newBroadCastStore={newBroadCastStore}
            widthInput={390}
          />
        </div>
      </div>
    </div>
  )
})
