import { Textarea } from 'components/Textarea/Textarea'
import React, { ReactNode } from 'react'
import styles from './style.module.scss'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { ReactComponent as Phone } from 'icons/16px/Phone.svg'
import { SizedBox } from 'components/SizedBox'
import { AddNumber } from 'components/CreateElement/AddNumber'
import { observer } from 'mobx-react-lite'
import { Separator } from 'components/Separator/Separator'
import { CreateWebFormStore } from 'store/webForms/createWebFormStore'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { RoutesEnum } from 'src/pages/routes'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import numbersStore from 'store/settings/numbers/numbersStore'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import { CreateKeywordStore } from 'store/keywords/createKeywordStore'
import { useParams } from 'react-router-dom'
import { ComplianceContent } from 'components/CreateElement/SMSContent/ComplianceContent/ComplianceContent'
import { CreateTriggerStore } from 'store/triggers/createTriggerStore'

export const SMSContent = observer(
  ({
    store,
    noCompliance,
    noShortCode,
    noAutoFocus,
    additionalContent,
    withoutSendFrom,
    withoutToolFreeError,
  }: {
    store: CreateStoreTypes
    noCompliance?: boolean
    noShortCode?: boolean
    noAutoFocus?: boolean
    additionalContent?: ReactNode
    withoutSendFrom?: boolean
    withoutToolFreeError?: boolean
  }) => {
    const { id } = useParams()
    const onSelectNumber = (number: string) => {
      store.setSMSPhone(number)
    }

    const BottomDropdownButton = (props: IBottomDropdownButtonProps) => {
      return (
        <div className={'stickyBottom'}>
          {numbersStore.isSMSNumber && !numbersStore.isFirstNumber && (
            <Separator marginBlock={8} />
          )}
          <AddNumber
            {...props}
            setPhone={(phone) => store.setSMSPhone(phone)}
          />
        </div>
      )
    }

    return (
      <>
        <Textarea
          textareaStore={store.textareaStore}
          placeholder={
            store instanceof CreateWebFormStore
              ? 'Write your auto-reply message... (Not required)'
              : 'Write your message...'
          }
          focusOnMount={
            !store.edit &&
            !(store instanceof CreateKeywordStore) &&
            !noAutoFocus &&
            !id
          }
          disablePersonalize={
            store instanceof CreateTriggerStore && !store.selectedApp?.value
          }
          // tabIndex={2}
        />
        {!noCompliance && (
          <ComplianceContent store={store.textareaStore.complianceStore} />
        )}
        {additionalContent}
        {store.textareaStore.errorMMSLength && (
          <span className={'s2 error1 mt12'}>
            Max 1600 characters for MMS. Shorten the message or breakdown it
            into multiple pieces.
          </span>
        )}
        {store.textareaStore.errorSMSLength && (
          <span className={'s2 error1 mt12'}>
            Max 10 segments for SMS. Shorten the message or breakdown it into
            multiple pieces.
          </span>
        )}
        {!withoutToolFreeError && store.textareaStore.errorVerifyToolFree && (
          <span className={'s2 error1 mt12'}>
            Messages that include links, images (MMS) will be blocked by the
            carriers from unverified toll-free numbers. To avoid carrier
            filtering, please submit your company for{' '}
            <LinkWrapper to={RoutesEnum.settingsCompliance}>
              toll-free verification.
            </LinkWrapper>
          </span>
        )}
        {!withoutSendFrom && (
          <>
            <SizedBox height={40} />
            <div className={styles.sendWrap}>
              <Dropdown
                defaultOption={
                  (numbersStore.smsPhoneNumbersVerificationsOptions.length ===
                    1 &&
                    numbersStore.smsPhoneNumbersVerificationsOptions[0]) ||
                  undefined
                }
                selectedValue={store.smsPhone}
                onSelectValue={onSelectNumber}
                labelInput={'Send from'}
                options={
                  noShortCode
                    ? numbersStore.smsPhoneNumbersOptionsNoShortCode
                    : numbersStore.smsPhoneNumbersOptions
                }
                BottomDropdownButton={BottomDropdownButton}
                leftIcon={
                  <AppIcon>
                    <Phone />
                  </AppIcon>
                }
                dropDownContentWidth={450}
                grouping
              />
            </div>
          </>
        )}
      </>
    )
  }
)
