import styles from './styles.module.scss'
import { Button } from 'components/Button/Button'
import { ReactComponent as Microphone } from 'icons/16px/Microphone.svg'
import { ReactComponent as Dial } from 'icons/16px/Dial.svg'
import { ReactComponent as Phone } from 'icons/16px/Phone Ring.svg'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { FC, useState } from 'react'
import { RecordFromComputer } from 'src/Modals/AudioModal/RecordDropdown/RecordFromComputer'
import { DealInToRecord } from 'src/Modals/AudioModal/RecordDropdown/DealInToRecord'
import classNames from 'classnames'
import { ReceivePhoneCall } from 'src/Modals/AudioModal/RecordDropdown/ReceivePhoneCall'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { NewLabel } from 'src/shared/ui/NewLabel/NewLabel'

enum AudioChooseEnum {
  recordFromComputer = 'Record from computer',
  dealInToRecord = 'Dial-in to record',
  receivePhoneCall = 'Receive phone call',
}

interface RecordOption {
  title: AudioChooseEnum
  icon: JSX.Element
  comingSoon?: boolean
  isNew?: boolean
}

const options: RecordOption[] = [
  {
    title: AudioChooseEnum.recordFromComputer,
    icon: <Microphone />,
  },
  {
    title: AudioChooseEnum.dealInToRecord,
    icon: <Dial />,
    isNew: true,
  },
  {
    title: AudioChooseEnum.receivePhoneCall,
    icon: <Phone />,
    comingSoon: true,
  },
]

type Props = {
  closeDropdown: () => void
}

export const RecordDropdown: FC<Props> = ({ closeDropdown }) => {
  const [chooseContent, setChooseContent] = useState<AudioChooseEnum | null>(
    null
  )
  return (
    <div className={classNames(styles.dropdown, chooseContent && styles.inner)}>
      {chooseContent && (
        <>
          <div className={styles.header}>
            <Button menuBtn hoverGray onClick={() => setChooseContent(null)}>
              <Angle />
              <span className={'bold'}>{chooseContent}</span>
            </Button>
          </div>
          <div className={styles.wrapOptions}>
            {chooseContent === AudioChooseEnum.recordFromComputer && (
              <RecordFromComputer closeDropdown={closeDropdown} />
            )}
            {chooseContent === AudioChooseEnum.dealInToRecord && (
              <DealInToRecord />
            )}
            {chooseContent === AudioChooseEnum.receivePhoneCall && (
              <ReceivePhoneCall />
            )}
          </div>
        </>
      )}
      {!chooseContent && (
        <div className={styles.wrapOptions}>
          {options.map((option) => (
            <MuiTooltip
              title={option.comingSoon ? 'Coming soon' : ''}
              key={option.title}
              arrow
              placement={'top'}
            >
              <div>
                <Button
                  menuBtn
                  hoverGray
                  onClick={() => setChooseContent(option.title)}
                  disabled={option.comingSoon}
                >
                  {option.icon}
                  <div className={'row-between fullWidth'}>
                    <span>{option.title}</span>
                    {option.isNew && <NewLabel />}
                  </div>
                </Button>
              </div>
            </MuiTooltip>
          ))}
        </div>
      )}
    </div>
  )
}
