import * as React from 'react'
import { useDeleteConversionsMutation } from 'src/generated/graphql'
import { Button } from 'shared/ui/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { nanoid } from 'nanoid'
import { Delete } from 'components/Icon'

export const DeleteBtn = ({
  ids,
  onSuccess,
  size,
}: {
  ids: string[]
  onSuccess: () => void
  size?: 'small' | 'medium'
}) => {
  const [deleteConversions] = useDeleteConversionsMutation()

  const id = nanoid()

  const onDelete = async () => {
    modalStore.addModal({
      variant: 'notification',
      type: 'infoError1',
      title: 'Delete conversion?',
      subTitle: 'This action cannot be undone',
      id,
      actions: {
        isSecondaryClose: true,
        buttons: [
          {
            variant: 'primary-medium',
            text: 'Delete',
            withUiLoading: true,
            onClick: async () => {
              try {
                await deleteConversions({
                  variables: {
                    conversionIds: ids,
                  },
                })
                alertStore.setAlert({
                  type: AlertTypeEnum.success,
                  title: 'Conversion deleted',
                })
                onSuccess()
                modalStore.closeModal(id)
              } catch (e) {
                console.error(e)
              }
            },
          },
        ],
      },
    })
  }

  if (size === 'medium') {
    return (
      <Button
        variant={'quaternary-medium'}
        onClick={onDelete}
        LeftIcon={Delete}
        text={'Delete'}
      />
    )
  }

  return (
    <MuiTooltip title={'Delete'} arrow placement={'top'}>
      <Button
        variant={'tertiary-small'}
        onClick={onDelete}
        LeftIcon={Delete}
        advanced
      />
    </MuiTooltip>
  )
}
