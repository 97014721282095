// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eA5u_QlVmbdU1Xy3kWOw{display:grid;grid-template-columns:160px 16px 1fr;gap:12px;margin-top:8px;align-items:center;width:100%}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,oCAAA,CACA,QAAA,CACA,cAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".wrapFields{\n  display: grid;\n  grid-template-columns: 160px 16px 1fr;\n  gap: 12px;\n  margin-top: 8px;\n  align-items: center;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapFields": `eA5u_QlVmbdU1Xy3kWOw`
};
export default ___CSS_LOADER_EXPORT___;
