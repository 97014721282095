import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { CheckBox, ICheckBoxProps } from 'components/CheckBox/CheckBox'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'

type InputCheckboxProps = {
  label: ReactNode
  checked: boolean
  disabled?: boolean
  tooltip?: string | JSX.Element
  fromDropdown?: boolean
  noBordered?: boolean
  noBordered2?: boolean
  noBordered3?: boolean
  onChecked: () => void
  rightIcon?: JSX.Element
  expandedContent?: JSX.Element
  maxWidth?: number
  className?: string
  rowClassName?: string
  fitHeight?: boolean
  marginLeft?: number
  width?: string
  fullWidthLabel?: boolean
  nowrap?: boolean
  alignCenter?: boolean
} & ICheckBoxProps

export const InputCheckbox: FC<InputCheckboxProps> = ({
  label,
  checked,
  onChecked,
  tooltip,
  fromDropdown,
  rightIcon,
  noBordered,
  noBordered2,
  noBordered3,
  maxWidth,
  expandedContent,
  className,
  rowClassName,
  disabled,
  fitHeight,
  marginLeft,
  width,
  fullWidthLabel,
  nowrap,
  alignCenter,
  size,
  shape,
}) => {
  return (
    <label
      className={classNames(
        styles.wrap,
        'inputCheckboxWrap',
        fromDropdown && styles.fromDropdown,
        noBordered && styles.noBordered,
        alignCenter && styles.alignCenter,
        noBordered2 && styles.noBordered2,
        noBordered3 && styles.noBordered3,
        size && styles[size],
        fitHeight && styles.fitHeight,
        disabled && styles.disabled,
        expandedContent && checked && styles.expanded,
        className
      )}
      style={{ maxWidth, marginLeft, width }}
    >
      <div className={classNames(styles.row, rowClassName)}>
        <CheckBox
          size={size}
          checked={checked}
          disabled={disabled}
          shape={shape}
          onChangeValue={() => onChecked()}
        />
        <div
          className={classNames(
            fullWidthLabel && 'fullWidth',
            nowrap && 'nowrap'
          )}
        >
          {label}
        </div>
        {tooltip && <InfoTooltip title={tooltip} marginLeft={-4} />}
        {rightIcon}
      </div>

      {checked && expandedContent}
    </label>
  )
}
