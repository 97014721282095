import { observer } from 'mobx-react-lite'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { useMemo } from 'react'
import { CreateSegmentStore } from 'store/contacts/segment/createSegmentStore'
import { SegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { SegmentOrFilterContent } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/SegmentOrFilterContent'

export const EditSegmentOrFilter = observer(({ item }: { item: IListItem }) => {
  const store = useMemo(
    () => new CreateSegmentStore({ isCompact: true, item }),
    [item]
  )
  return (
    <SegmentDetailContext.Provider value={store}>
      <SegmentOrFilterContent />
    </SegmentDetailContext.Provider>
  )
})
