// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XjNANVMasExZw7u4aFKT{border-radius:20px;background:var(--primary-color-white);box-shadow:0px 0px 2px 0px rgba(83,102,137,.2),0px 10px 20px 0px rgba(83,102,137,.05),0px 30px 50px 0px rgba(83,102,137,.1)}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectedFields/widgets/CollectedField/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,qCAAA,CACA,2HAAA","sourcesContent":[".wrap{\n  border-radius: 20px;\n  background: var(--primary-color-white);\n  box-shadow: 0px 0px 2px 0px rgba(83, 102, 137, 0.20), 0px 10px 20px 0px rgba(83, 102, 137, 0.05), 0px 30px 50px 0px rgba(83, 102, 137, 0.10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `XjNANVMasExZw7u4aFKT`
};
export default ___CSS_LOADER_EXPORT___;
