import classNames from 'classnames'
import styles from './styles.module.scss'
import { Icon } from 'components/Icon/Icon'
import { IStatusProps } from 'components/Status/type'
import { getCssColor } from 'styles/colors'

export const Status = ({ title, IconFC, color, status }: IStatusProps) => {
  return (
    <div
      className={classNames(styles.wrap)}
      style={{ color: getCssColor(color) }}
    >
      {IconFC && (
        <Icon fontSize={16}>
          <IconFC />
        </Icon>
      )}
      <span className={classNames('s1', styles.title)}>{title || status}</span>
    </div>
  )
}
