import { ContactsPickerUi } from 'widgets/ContactsPicker/ui/ContactsPickerUi'
import { ContactsPickerContext } from 'widgets/ContactsPicker/context/context'
import { ContactsPickerStore } from 'widgets/ContactsPicker/store/ContactsPickerStore'

export const ContactsPicker = ({ store }: { store: ContactsPickerStore }) => {
  return (
    <ContactsPickerContext.Provider value={store}>
      <ContactsPickerUi />
    </ContactsPickerContext.Provider>
  )
}
