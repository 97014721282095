import React, {
  ButtonHTMLAttributes,
  FC,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react'
import { Icon } from 'components/Icon'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { ITypographyProps, Typography } from 'shared/ui/Typography'
import { NewLabel } from 'shared/ui/NewLabel/NewLabel'
import { MuiTooltip, MuiTooltipProps } from 'components/Tooltip/MuiTooltip'
import {
  IRightContentProps,
  RightContent,
} from 'shared/ui/DropdownButton/RightContent/RightContent'

export type IDropdownButtonProps = {
  text?: ReactNode
  secondaryText?: string
  LeftIcon?: FC
  rightContentProps?: IRightContentProps
  leftContent?: ReactNode
  loading?: boolean
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  className?: ButtonHTMLAttributes<HTMLButtonElement>['className']
  classNameLeft?: ButtonHTMLAttributes<HTMLButtonElement>['className']
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled']
  fullWidth?: boolean
  primary?: boolean
  active?: boolean
  isNew?: boolean
  muiTooltipProps?: Omit<MuiTooltipProps, 'children'>
  count?: number
  textTypographyProps?: ITypographyProps
}

// eslint-disable-next-line react/display-name
export const DropdownButton = forwardRef(
  (
    {
      LeftIcon,
      text,
      secondaryText,
      rightContentProps,
      onClick,
      className,
      fullWidth,
      primary,
      isNew,
      leftContent,
      muiTooltipProps,
      classNameLeft,
      count,
      active,
      textTypographyProps,
      ...props
    }: IDropdownButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const content = (
      <button
        onClick={onClick}
        className={classNames(
          styles.wrap,
          {
            [styles.fullWidth]: fullWidth,
            [styles.primary]: primary,
            [styles.primary]: primary,
            [styles.active]: active,
            [styles.showRightContentOnlyByHover]:
              rightContentProps?.showRightContentOnlyByHover,
          },
          className
        )}
        {...props}
        ref={ref}
      >
        <div className={classNames(styles.left, classNameLeft)}>
          {leftContent}
          {LeftIcon && (
            <Icon className={styles.icon}>
              <LeftIcon />
            </Icon>
          )}
          <div className={styles.info}>
            {text && (
              <Typography
                variant={primary ? 's1-semiBold' : 's1-regular'}
                isUnsetColor
                {...textTypographyProps}
              >
                {text}
                {!!count && (
                  <Typography
                    variant={'s1-regular'}
                    color={'--primary-color-gray-1'}
                    className={styles.count}
                  >
                    ({count})
                  </Typography>
                )}
              </Typography>
            )}
            {secondaryText && (
              <Typography
                variant={'s1-regular'}
                color={'--primary-color-gray-1'}
                className={styles.secondaryText}
              >
                {secondaryText}
              </Typography>
            )}
          </div>
        </div>
        {isNew && <NewLabel />}
        {rightContentProps && <RightContent {...rightContentProps} />}
      </button>
    )
    if (muiTooltipProps) {
      return <MuiTooltip {...muiTooltipProps}>{content}</MuiTooltip>
    }
    return content
  }
)
