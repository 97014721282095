import { FC } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'

type InputCheckboxCardProps = {
  label: string | JSX.Element
  info1: string | JSX.Element
  info2: string | JSX.Element
  checked: boolean
  disabled?: boolean
  tooltip?: string | JSX.Element
  onChecked: () => void
  rightContent?: JSX.Element
  recommended?: boolean
}

export const InputCheckboxCard: FC<InputCheckboxCardProps> = ({
  label,
  checked,
  onChecked,
  tooltip,
  rightContent,
  disabled,
  info1,
  info2,
  recommended,
}) => {
  return (
    <label className={classNames(styles.wrap, disabled && styles.disabled)}>
      <CheckBox
        checked={checked}
        shape={'circle'}
        size={'large'}
        disabled={disabled}
        onChangeValue={onChecked}
      />
      <div className={'grid'}>
        <div className={'row8 mb12'}>
          <p className={'bold'}>{label}</p>
          {recommended && (
            <div className={styles.recommended}>
              <span className={'s2 medium'}>Recommended</span>
            </div>
          )}
        </div>

        <div className={'flex mb4'}>
          <span className={'s2 '}>{info1}</span>
          {tooltip && <InfoTooltip title={tooltip} />}
        </div>

        <span className={'s2 gray1'}> {info2}</span>
      </div>
      {rightContent}
    </label>
  )
}
