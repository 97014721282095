import { createContext, useContext } from 'react'
import { CaptureFieldStore } from '../store/CaptureFieldStore'

export const CaptureFieldContext = createContext<CaptureFieldStore | null>(null)

export const useCaptureFieldContext = () => {
  const context = useContext(CaptureFieldContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with CaptureFieldContext'
    )

  return context
}
