import audioStore from 'store/audioStore/audioStore'
import { onRenameTableCell } from 'components/NewTable/functions'
import { IAudioItem } from 'src/types/IAudioItem'
import {
  useCheckAudioItemExistsLazyQuery,
  useDeleteAudioItemsMutation,
  useUpdateAudioItemMutation,
} from 'src/generated/graphql'
import { ErrorsEnum } from 'src/static/errors'
import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'

export function downloadBlob(blob: string, filename: string) {
  const a = document.createElement('a')
  a.download = filename
  a.href = blob
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export function useAudioFunctions({
  audioItem,
  tr,
  // setHover,
  audioItems,
}: {
  audioItem?: IAudioItem | null
  audioItems?: IAudioItem[]
  // ids?: string[]
  tr?: HTMLTableRowElement | null
  setHover?: (val: boolean) => void
}) {
  const [deleteAudioItems] = useDeleteAudioItemsMutation()
  const [updateAudioItem] = useUpdateAudioItemMutation()
  const [checkAudioItemExists] = useCheckAudioItemExistsLazyQuery()

  const manyIds = audioItems && audioItems.map((item) => item.id)

  const audioIds: string[] = (audioItem ? [audioItem.id] : manyIds) || []

  const onDelete = async (propsIds?: string[]) => {
    const ids = propsIds || audioIds
    const usagesIds: string[] = []
    const checkItem = (item: IAudioItem) => {
      const isUsage =
        !!item.usageStatistics?.broadcastCount ||
        !!item.usageStatistics?.triggerCount ||
        !!item.usageStatistics?.campaignCount
      if (isUsage) {
        usagesIds.push(item.id)
      }
    }
    if (audioItem) {
      checkItem(audioItem)
    }
    if (audioItems) {
      audioItems.forEach(checkItem)
    }
    const isAvailableDeleteIds: string[] =
      ids?.filter((id) => !usagesIds.includes(id)) || []
    if (usagesIds.length) {
      alertStore.setAlert({
        type: AlertTypeEnum.warning1,
        title:
          'Audios that are connected to Broadcasts, Campaigns and Triggers can’t be deleted',
      })
    }
    if (isAvailableDeleteIds?.length) {
      try {
        await deleteAudioItems({
          variables: {
            ids: isAvailableDeleteIds,
          },
        })
        audioStore.deleteAudios(isAvailableDeleteIds)
      } catch (e) {
        console.error(e)
      }
    }
  }
  const onDownload = () => {
    const downloadItem = (item: IAudioItem) => {
      if (item.name && item.url) {
        fetch(item.url)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = window.URL.createObjectURL(blob)
            downloadBlob(blobUrl, item.name || '')
          })
          .catch((e) => console.error(e))
      }
    }
    if (audioItems) {
      audioItems?.forEach(downloadItem)
    } else {
      if (audioItem?.url) {
        downloadItem(audioItem)
      }
    }
  }
  const onRename = () => {
    if (tr && audioItem) {
      onRenameTableCell({
        tr,
        row: audioItem,
        onSave: async (id, name) => {
          if (audioItem.name === name) {
            return
          }
          const { data } = await checkAudioItemExists({
            variables: {
              name,
            },
          })
          if (data?.checkAudioItemExists) {
            throw new Error(ErrorsEnum.existAudio)
          }
          if (!data?.checkAudioItemExists) {
            const { data: dataUpdate } = await updateAudioItem({
              variables: {
                input: {
                  id,
                  name,
                },
              },
            })
            if (dataUpdate?.updateAudioItem) {
              audioStore.updateAudioItem(dataUpdate.updateAudioItem)
            }
          }
        },
      })
    }
  }

  const onEdit = () => {
    if (audioItem) {
      createTextToSpeechStore.onEditTextToSpeech(audioItem)
    }
  }
  const onDuplicate = () => {
    if (audioItem) {
      createTextToSpeechStore.onDuplicateTextToSpeech(audioItem)
    }
  }

  return {
    onDelete,
    onDownload,
    onRename,
    onEdit,
    onDuplicate,
  }
}
