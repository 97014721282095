import user from 'store/user/user'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import { useMainInfo } from 'src/hooks/useMainInfo'
import { RegisterRoutes } from 'src/app/AuthorizedApp/RegisterRoutes'
import { MainRoutes } from 'src/app/AuthorizedApp/MainRoutes'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import { CanceledRoutes } from 'src/pages/canceled/CanceledRoutes'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { useInitAnalytics } from 'src/hooks/useInitAnalytics'
import { Migration } from 'src/pages/auth/migration/migration'
import auth from 'store/auth'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { ReopenPage } from 'src/pages/canceled/reopen/ReopenPage'
import migrationStore from 'store/migration/migrationStore'
import { NoOrganization } from 'src/app/AuthorizedApp/NoOrganization'

export const AuthorizedAppContent = observer(() => {
  useMainInfo()
  useInitAnalytics()

  if (auth.loadingMainInfo) {
    return <AbsoluteLoader />
  }
  if (!user.member?.registrationFinished && migrationStore.migrationStep) {
    return <Migration />
  }
  if (!user.member?.registrationFinished) {
    return <RegisterRoutes />
  }
  if (!user.member?.organization) {
    return <NoOrganization />
  }
  if (billingStore.activeSubscription) {
    return <MainRoutes />
  }
  if (billingStore.canceledSubscription) {
    return <CanceledRoutes />
  }

  return (
    <Routes>
      <Route element={<AuthPage fromReopen />}>
        <Route index element={<ReopenPage />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  )
})
