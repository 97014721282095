import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { Typography } from 'shared/ui/Typography'
import { EditBtn } from 'widgets/conversions/ConversionsTable/actions/EditBtn'
import { DeleteBtn } from 'widgets/conversions/ConversionsTable/actions/DeleteBtn'
import { HeaderDetail } from 'shared/ui/Header/HeaderDetail/HeaderDetail'
import { useConversionDetailContext } from 'widgets/conversions/ConversionDetail/context/context'

export const ConversionDetailHeader = observer(() => {
  const { name, conversionId, conversion, onSuccessEdit } =
    useConversionDetailContext()
  const navigate = useNavigate()
  return (
    <HeaderDetail
      breadcrumbsContent={
        <Link to={`${RoutesEnum.settings}/${RoutesEnum.conversions}`}>
          <Typography>Conversions</Typography>
        </Link>
      }
      title={name}
      rightContent={
        <>
          <EditBtn
            conversion={conversion}
            onSuccess={onSuccessEdit}
            size={'medium'}
          />
          <DeleteBtn
            ids={[conversionId]}
            onSuccess={() => {
              navigate(-1)
            }}
            size={'medium'}
          />
        </>
      }
    />
  )
})
