export enum ErrorsEnum {
  verifyEmail = 'Please verify your email',
  validPhoneNumber = 'Please enter a valid phone number',
  alreadyUsePhoneNumber = 'This number is already in use',
  alreadyUseEmail = 'Email is already in use',
  einError = 'EIN should contain 9 digits. Please enter correct EIN.',
  fileLimit5Mb = 'You can send up to 5 files or total less than 5 MB',
  existAudio = 'Audio with this name already exists',
  existContact = 'Contact already exists',
}
