import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Button } from 'shared/ui/Button/Button'
import { useCollectedFieldsStoreContext } from 'widgets/CollectedFields/context/context'

export const AddCollectField = observer(() => {
  const { addCollectField, collectFields } = useCollectedFieldsStoreContext()
  return (
    <div className={styles.wrap}>
      {collectFields?.length ? (
        <Button variant={'add'} text={'Add field'} onClick={addCollectField} />
      ) : (
        <Button
          variant={'add'}
          text={'Collect contact data'}
          infoTooltipProps={{ title: 'Info' }}
          onClick={addCollectField}
        />
      )}
    </div>
  )
})
