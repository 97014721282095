import { makeAutoObservable } from 'mobx'
import {
  NOT_REQUIRED_COMPLIANCE_TEXT,
  REQUIRED_COMPLIANCE_TEXT,
} from 'src/static/constants'

export class ComplianceStore {
  constructor() {
    makeAutoObservable(this)
  }
  compliance = false
  setCompliance = (state: boolean) => {
    this.compliance = state
  }
  REQUIRED_COMPLIANCE_TEXT = REQUIRED_COMPLIANCE_TEXT
  NOT_REQUIRED_COMPLIANCE_TEXT = NOT_REQUIRED_COMPLIANCE_TEXT

  get complianceText() {
    return this.REQUIRED_COMPLIANCE_TEXT + this.nonRequiredComplianceText
  }

  nonRequiredComplianceText = NOT_REQUIRED_COMPLIANCE_TEXT
  setNoneRequiredComplianceText = (text: string) => {
    this.nonRequiredComplianceText = text
  }
}
