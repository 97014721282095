import { observer } from 'mobx-react-lite'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { ISimpleFilter, ISimpleSegment } from 'store/contacts/functions'
import { useMemo } from 'react'
import { CreateSegmentStore } from 'store/contacts/segment/createSegmentStore'
import { SegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { SegmentOrFilterContent } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/SegmentOrFilterContent'

export const ViewSegmentOrFilter = observer(
  ({ item }: { item: IListItem<ISimpleSegment | ISimpleFilter> }) => {
    const store = useMemo(
      () => new CreateSegmentStore({ isCompact: true, isOnlyView: true, item }),
      [item]
    )
    return (
      <SegmentDetailContext.Provider value={store}>
        <SegmentOrFilterContent />
      </SegmentDetailContext.Provider>
    )
  }
)
