import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import styles from 'src/widgets/MessageTemplate/MessageTemplatesModal/MyTemplatesTab/styles.module.scss'
import { Templates } from 'src/widgets/MessageTemplate/MessageTemplatesModal/Templates/Templates'
import { NoTemplates } from 'src/widgets/MessageTemplate/MessageTemplatesModal/MyTemplatesTab/NoTemplates'
import messageTemplateStore from 'src/widgets/MessageTemplate/store/MessageTemplateStore'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { TextareaStore } from 'store/textareaStore'

export const MyTemplatesList = observer(
  ({ store }: { store: TextareaStore }) => {
    const { myTemplates, loadData, loading, loadMore } = messageTemplateStore

    useEffect(() => {
      loadData({ ignoreCash: true })
    }, [])

    const onScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
      const target = e.target as HTMLDivElement
      const scrollHeight = target.scrollHeight
      const scrollTop = target.scrollTop
      const offsetHeight = target.offsetHeight

      if (loading) return
      if (scrollHeight <= scrollTop + offsetHeight + 100) {
        loadMore()
      }
    }

    return (
      <div className={styles.wrapContent}>
        {myTemplates.length ? (
          <Templates
            templates={myTemplates}
            store={store}
            onScroll={onScroll}
          />
        ) : (
          <NoTemplates />
        )}
      </div>
    )
  }
)
