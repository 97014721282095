import { makeAutoObservable } from 'mobx'
import { MemberResponseFragment, MemberRole } from 'src/generated/graphql'
import auth from 'store/auth'
import { timezoneToOption } from 'store/settings/accountStore'
import companyStore from 'store/settings/company/companyStore'
import migrationStore from 'store/migration/migrationStore'

const initialState = {
  name: '',
  email: '',
  firstName: '',
  lastName: '',
  imageUrl: '',
  phone: '',
  cardNumber: '',
  dateCard: '',
  cvvCard: '',
  zipCodeCard: '',
  company: '',
  isDeleteAccount: false,
}

class User {
  constructor() {
    makeAutoObservable(this)
  }

  member: MemberResponseFragment | null = null
  imageUrl = initialState.imageUrl
  isDeleteAccount = initialState.isDeleteAccount

  get isOwner() {
    return (
      this.member?.role === MemberRole.Owner ||
      this.member?.role === MemberRole.Admin
    )
  }

  get isAdmin() {
    return this.member?.isAdmin
  }

  get isSysadmin() {
    return this.member?.role === MemberRole.Sysadmin
  }
  get timezone() {
    return this.member?.userTimeZone || null
  }
  get timezoneShortName() {
    return this.member?.userTimeZone?.shortName || ''
  }

  get timezoneOption() {
    return timezoneToOption(this.member?.userTimeZone) || undefined
  }

  get name() {
    return this.member?.name || ''
  }

  get firstName() {
    return this.member?.firstName || ''
  }

  get lastName() {
    return this.member?.lastName || ''
  }

  get phone() {
    return this.member?.phone || ''
  }

  get email() {
    return this.member?.email || ''
  }

  get role() {
    return this.member?.role
  }

  get isEmailAuth() {
    return !auth.withGoogle
  }

  setMemberEmail(email: string) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    this.member = { ...this.member, email }
  }

  setIsDeleteAccount(state: boolean) {
    this.isDeleteAccount = state
  }

  setMember(member: MemberResponseFragment) {
    this.member = member
    if (member.organization) {
      companyStore.setOrganization(member.organization)
    }
    if (member.memberRegistration) {
      migrationStore.setMemberRegistration(member.memberRegistration)
    }
  }
}

export default new User()
