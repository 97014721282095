import { observer } from 'mobx-react-lite'
import { Input } from 'shared/ui/Input/Input'
import { useNewConversionContext } from 'widgets/conversions/NewConversion/context/context'
import { Label } from 'shared/ui/Label/Label'

export const DefaultValue = observer(() => {
  const { defaultAmount, setDefaultAmount } = useNewConversionContext()
  return (
    <div>
      <Label
        text={'Default value'}
        marginBottom={8}
        infoTooltipProps={{ title: 'The value recorded for each conversion' }}
        isOptional
      />
      <Input
        isNumber
        value={defaultAmount}
        setValue={setDefaultAmount}
        placeholder={'$0.00'}
        numericFormatProps={{ prefix: '$' }}
        withClear
      />
    </div>
  )
})
