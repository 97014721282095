import { Button } from 'shared/ui/Button/Button'
import React, { FC, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import {
  IShortenLinkTriggerTooltipProps,
  ShortenLinkTriggerTooltip,
} from 'components/Textarea/ShortenLink/ShortenLinkTriggerTooltip/ShortenLinkTriggerTooltip'
import { Link } from 'components/Icon'

type Props = {
  onAddShortenLink: IShortenLinkTriggerTooltipProps['onAddShortenLink']
}
export const ShortenLink: FC<Props> = observer(({ onAddShortenLink }) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const [visible, setVisible] = useState(false)

  return (
    <>
      <ShortenLinkTriggerTooltip
        onAddShortenLink={onAddShortenLink}
        visible={visible}
        setVisible={setVisible}
        trigger={ref.current}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-32, 8],
            },
          },
        ]}
      />
      <MuiTooltip title={visible ? '' : 'Shorten link'} placement={'top'} arrow>
        <Button
          ref={ref}
          variant={'tertiary-small'}
          LeftIcon={Link}
          onClick={() => {
            setVisible(true)
          }}
        />
      </MuiTooltip>
    </>
  )
})
