import { observer } from 'mobx-react-lite'
import { useAddFiltersModalContext } from 'widgets/AddFiltersModal/context/context'
import { EditItem } from 'widgets/AddFiltersModal/ui/Include/RightContent/EditItem'
import { ViewItem } from 'widgets/AddFiltersModal/ui/Include/RightContent/ViewItem'
import { EmptyContent } from 'widgets/AddFiltersModal/ui/EmptyContent/EmptyContent'
import { NewFilter } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/NewFilter'

export const RightContent = observer(() => {
  const { editItem, selectItem, isNew } = useAddFiltersModalContext()
  if (editItem) {
    return <EditItem item={editItem} />
  }
  if (selectItem) {
    return <ViewItem item={selectItem} />
  }
  if (isNew) {
    return <NewFilter />
  }
  return <EmptyContent text={'Nothing selected'} />
})
