// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KmxvzJ5weV7DKOegZ26v{display:grid;column-gap:8px;overflow:auto;grid-auto-flow:column;justify-content:flex-start;border-radius:8px;position:relative}`, "",{"version":3,"sources":["webpack://./src/components/AttachmentGallery/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,aAAA,CACA,qBAAA,CACA,0BAAA,CACA,iBAAA,CACA,iBAAA","sourcesContent":[".imagesWrap{\n  display: grid;\n  column-gap: 8px;\n  overflow: auto;\n  grid-auto-flow: column;\n  justify-content: flex-start;\n  border-radius: 8px;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imagesWrap": `KmxvzJ5weV7DKOegZ26v`
};
export default ___CSS_LOADER_EXPORT___;
