import { observer } from 'mobx-react-lite'
import { TopContent } from 'widgets/ContactsPicker/ui/TopContent/TopContent'
import { SelectList, SelectListProps } from 'components/SelectList/SelectList'
import { useContactsPickerContext } from 'widgets/ContactsPicker/context/context'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { DropdownButton } from 'shared/ui/DropdownButton/DropdownButton'
import { Plus } from 'components/Icon'
import React from 'react'
import { useDropdownContext } from 'components/Dropdown/context/context'
import styles from './styles.module.scss'

type IPickerSelectListProps = {
  items: IListItem[]
  loading: boolean
  groupContent: SelectListProps['groupContent']
}

export const PickerSelectList = observer(
  ({ items, loading, groupContent }: IPickerSelectListProps) => {
    const {
      search,
      onSelectItem,
      onAddNewFilters,
      withoutAddFilters,
      disabledCloseDropdown,
    } = useContactsPickerContext()
    const dropdownContext = useDropdownContext()
    return (
      <SelectList
        options={items}
        onSelect={onSelectItem}
        disabledCloseDropdown={disabledCloseDropdown}
        topContent={
          <TopContent
            loading={loading}
            isEmpty={!items.length}
            search={search}
          />
        }
        groupContent={groupContent}
        bottomContent={
          !withoutAddFilters && (
            <DropdownButton
              text={'Add filters'}
              fullWidth
              primary
              LeftIcon={Plus}
              isNew
              className={styles.newFilterBtn}
              classNameLeft={styles.left}
              onClick={() => {
                dropdownContext?.onClose()
                onAddNewFilters()
              }}
            />
          )
        }
      />
    )
  }
)
