import { Currencies, numberString } from 'src/util/functions'
import { AppSymbols } from 'src/util/symbols'
import { ColumnType } from 'components/NewTable/types'
import { ConversionSourceStatistics } from 'src/generated/graphql'

export const conversionColumns: ColumnType<{
  conversionStatistics?: ConversionSourceStatistics | null
}>[] = [
  {
    id: 'conversionStatistics.total',
    label: 'Conversion',
    format: (value, { conversionStatistics }) =>
      conversionStatistics
        ? numberString({ val: conversionStatistics?.total })
        : AppSymbols.dash,
  },
  {
    id: 'conversionStatistics.Revenue',
    label: 'Revenue',
    format: (value, { conversionStatistics }) =>
      conversionStatistics && typeof conversionStatistics?.revenue === 'number'
        ? numberString({
            val: conversionStatistics?.revenue || 0,
            currency: Currencies.usd,
          })
        : AppSymbols.dash,
  },
]

export const conversionColumnsIds = conversionColumns.map((column) => column.id)
