import { EveryPeriodEnum } from 'components/Schedule/store/types'
import {
  CampaignDelayPeriod,
  CampaignScheduleType,
  CustomMonthRepeatType,
  DayOfWeek,
  OnMonthDayOrdinal,
  OnMonthDayType,
  RepeatType,
  SendLaterRepeatType,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import { IListItem } from 'components/SelectList/ListItem/ListItem'

const operatorsObj: { [key in CampaignScheduleType]: string } = {
  [CampaignScheduleType.Before]: 'Before',
  [CampaignScheduleType.On]: 'On',
  [CampaignScheduleType.After]: 'After',
}
export const getCampaignScheduleTypeTitle = (key: CampaignScheduleType) =>
  operatorsObj[key]

export const operatorsItems: IListItem<CampaignScheduleType>[] = Object.entries(
  operatorsObj
).map(([key, value]) => ({ id: key, text: value }))

const periodsObj: { [key in CampaignDelayPeriod]: string } = {
  [CampaignDelayPeriod.Minute]: 'Minute',
  [CampaignDelayPeriod.Day]: 'Day',
  [CampaignDelayPeriod.Hour]: 'Hour',
  [CampaignDelayPeriod.Month]: 'Month',
  [CampaignDelayPeriod.Week]: 'Week',
  [CampaignDelayPeriod.Year]: 'Year',
}

export const getCampaignDelayPeriodTitle = (key: CampaignDelayPeriod) =>
  periodsObj[key]

export const periodsItems: IListItem<CampaignDelayPeriod>[] = Object.entries(
  periodsObj
).map(([key, value]) => ({ id: key, text: value }))

export const everyPeriodOptions: IOption<RepeatType>[] = [
  {
    title: EveryPeriodEnum.Day,
    value: RepeatType.Daily,
  },
  {
    title: EveryPeriodEnum.Week,
    value: RepeatType.Weekly,
  },
  {
    title: EveryPeriodEnum.Month,
    value: RepeatType.Monthly,
  },
]
export const everyPeriodOptionsMap = new Map(
  everyPeriodOptions.map((period) => [period.value, period])
)
export const repeatsOptions2: IOption<SendLaterRepeatType>[] = [
  {
    title: 'Never',
    value: SendLaterRepeatType.Never,
  },
  {
    title: 'Every day',
    value: SendLaterRepeatType.EveryDay,
  },
  {
    title: 'Every week',
    value: SendLaterRepeatType.EveryWeek,
  },
  {
    title: 'Every month',
    value: SendLaterRepeatType.EveryMonth,
  },
  {
    title: 'Custom',
    value: SendLaterRepeatType.Custom,
  },
]

export const repeatsOptions2map = new Map(
  repeatsOptions2.map((opt) => [opt.value, opt])
)
export const onTheOrderOptions: IOption[] = [
  {
    title: 'First',
    value: OnMonthDayOrdinal.First,
  },
  {
    title: 'Second',
    value: OnMonthDayOrdinal.Second,
  },
  {
    title: 'Third',
    value: OnMonthDayOrdinal.Third,
  },
  {
    title: 'Fourth',
    value: OnMonthDayOrdinal.Fourth,
  },
  {
    title: 'Last',
    value: OnMonthDayOrdinal.Last,
  },
]
export const monthlyToggleOptions: IOption[] = [
  {
    title: 'Each',
    value: CustomMonthRepeatType.Each,
  },
  {
    title: 'On the',
    value: CustomMonthRepeatType.OnThe,
  },
]
export const DaysWeeklyStringObj = [
  {
    key: DayOfWeek.Sunday,
    short: 'Su',
    full: 'Sunday',
  },
  {
    key: DayOfWeek.Monday,
    short: 'Mo',
    full: 'Monday',
  },
  {
    key: DayOfWeek.Tuesday,
    short: 'Tu',
    full: 'Tuesday',
  },
  {
    key: DayOfWeek.Wednesday,
    short: 'We',
    full: 'Wednesday',
  },
  {
    key: DayOfWeek.Thursday,
    short: 'Th',
    full: 'Thursday',
  },
  {
    key: DayOfWeek.Friday,
    short: 'Fr',
    full: 'Friday',
  },
  {
    key: DayOfWeek.Saturday,
    short: 'Sa',
    full: 'Saturday',
  },
]
export const DaysWeeklyStringObjMap = new Map(
  DaysWeeklyStringObj.map((day) => [day.key, day])
)
export const onTheDayOptions: IOption<OnMonthDayType>[] = [
  {
    title: 'Sunday',
    value: OnMonthDayType.Sunday,
  },
  {
    title: 'Monday',
    value: OnMonthDayType.Monday,
  },
  {
    title: 'Tuesday',
    value: OnMonthDayType.Tuesday,
  },
  {
    title: 'Wednesday',
    value: OnMonthDayType.Wednesday,
  },
  {
    title: 'Thursday',
    value: OnMonthDayType.Thursday,
  },
  {
    title: 'Friday',
    value: OnMonthDayType.Friday,
  },
  {
    title: 'Saturday',
    value: OnMonthDayType.Saturday,
  },
  {
    title: 'Day',
    value: OnMonthDayType.Day,
  },
]

export const DaysMonthly = Array(31)
  .fill(0)
  .map((val, index) => index + 1)
