import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'

type IPreviewReplyProps = {
  text?: string
}

export const PreviewSchedule = ({
  text = 'Immediately',
}: IPreviewReplyProps) => {
  return (
    <div className={styles.center}>
      <Typography variant={'s4-medium'} color={'--primary-color-gray-1'}>
        {text}
      </Typography>
    </div>
  )
}
