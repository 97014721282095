// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Yh4Q8GhyAY8nHJtqUPZo{z-index:2;position:relative}.AVM4dtBW6M1ppgs9DQMo{width:536px;padding:16px}.uTyc_k3SyYBExM0TyCht{display:flex;margin-bottom:16px}.UWIj4mqFzGAEhISvowaZ{display:flex;gap:8px;align-items:center;margin-block:16px}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/ShortenLink/ShortenLinkTriggerTooltip/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,SAAA,CACA,iBAAA,CAEF,sBACE,WAAA,CACA,YAAA,CAEF,sBACE,YAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,iBAAA","sourcesContent":["@import \"src/styles/mixin\";\n\n.wrap{\n  z-index: 2;\n  position: relative;\n}\n.content{\n  width: 536px;\n  padding: 16px;\n}\n.header{\n  display: flex;\n  margin-bottom: 16px;\n}\n\n.wrapSwitch{\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  margin-block: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `Yh4Q8GhyAY8nHJtqUPZo`,
	"content": `AVM4dtBW6M1ppgs9DQMo`,
	"header": `uTyc_k3SyYBExM0TyCht`,
	"wrapSwitch": `UWIj4mqFzGAEhISvowaZ`
};
export default ___CSS_LOADER_EXPORT___;
