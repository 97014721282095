import { makeAutoObservable } from 'mobx'
import { IOption } from 'src/types/IOption'
import listsStore from 'store/contacts/lists/listsStore'
import { IItem } from 'components/NewTable/cell/ItemsRender/ItemsRender'
import { itemToOption } from 'store/contacts/functions'
import { IListItem } from 'components/SelectList/ListItem/ListItem'

export class DropdownContactListsStore {
  constructor() {
    makeAutoObservable(this)
  }
  selectedOptions: IOption[] = []

  get selectedContactsCount(): number {
    return this.selectedContactListsOptions.reduce(
      (acc, val) => acc + Number(val.count),
      0
    )
  }
  get selectedContactListsIds() {
    return this.selectedContactListsOptions.map((list) => +list.value)
  }
  get selectedSegmentsIds() {
    return this.selectedSegmentsOptions.map((list) => +list.value)
  }
  get selectedContactIds() {
    return this.selectedContactOptions.map((list) => +list.value)
  }
  get selectedContactListsOptions() {
    return this.selectedOptions.filter((option) => option.group === 'list')
  }
  get selectedSegmentsOptions() {
    return this.selectedOptions.filter((option) => option.group === 'segment')
  }
  get selectedContactOptions() {
    return this.selectedOptions.filter((option) => option.group === 'contact')
  }
  onDeleteTag = (id: string | number, group = 'list') => {
    this.selectedOptions = this.selectedOptions.filter((opt) =>
      opt.group === group ? opt.value !== id : true
    )
  }

  onSelectOptions = (options: IOption[]) => {
    this.selectedOptions = options
  }

  setInitListsIds = (ids: number[]) => {
    this.selectedOptions = []
    ids.forEach(async (id) => {
      const option = await listsStore.getListOption(id)
      if (option) {
        this.selectedOptions = [...this.selectedOptions, option]
      }
    })
  }

  setInitItems = async (items: IItem[]) => {
    const selectedOptions: IOption[] = []

    const res = await Promise.all(
      items.map(async (item) => {
        if (item.group === 'list') {
          return listsStore.getListOption(item.id as number)
        } else {
          return itemToOption(item)
        }
      })
    )

    res.forEach((option) => {
      if (option) {
        selectedOptions.push(option)
      }
    })

    this.selectedOptions = selectedOptions
  }
}
