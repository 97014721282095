import { createContext, useContext } from 'react'
import { ContactPreviewStore } from '../store/ContactPreviewStore'

export const ContactPreviewContext = createContext<ContactPreviewStore | null>(
  null
)

export const useContactPreviewContext = () => {
  const context = useContext(ContactPreviewContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with ContactPreviewContext'
    )

  return context
}
