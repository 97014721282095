import { useEffect } from 'react'
import {
  ContentType,
  useGetBroadcastMessageStatisticsQuery,
} from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { useBroadcastDetailContext } from 'src/pages/main/broadcasts/broadcastDetail/broadcastDetail'
import { MessageStatisticCards } from 'components/MessageStatisticCards/MessageStatisticCards'

export const AdditionalContent = observer(() => {
  const context = useBroadcastDetailContext()

  const { tableStore, broadcast } = context
  const { data, refetch } = useGetBroadcastMessageStatisticsQuery({
    variables: {
      uuid: broadcast?.uuid,
    },
  })

  useEffect(() => {
    context.setMessageStatistics(data?.getBroadcastMessageStatistics || null)
  }, [data])

  useEffect(() => {
    refetch().catch(console.error)
  }, [tableStore.refreshTrigger])

  return (
    <MessageStatisticCards
      messageStatistic={data?.getBroadcastMessageStatistics}
      type={broadcast?.type || ContentType.Sms}
      tableStore={tableStore}
      cardFilter={(card) =>
        broadcast?.status === 'Finished' || broadcast?.status === 'Archived'
          ? card.status !== 'pendingCount'
          : true
      }
    />
  )
})
