import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import {
  ILabelFieldProps,
  LabelField,
} from 'widgets/ContactPreview/ui/LabelField/LabelField'
import { ContactsFill, Mail, Phone, User } from 'components/Icon'
import { Typography } from 'shared/ui/Typography'
import * as React from 'react'
import { ReactNode } from 'react'
import { useContactPreviewContext } from 'widgets/ContactPreview/context/context'
import { AppSymbols } from 'src/util/symbols'
import { Button } from 'shared/ui/Button/Button'

const Row = ({
  labelFieldProps,
  textValue,
  valueContent,
}: {
  labelFieldProps: ILabelFieldProps
  textValue?: string | null
  valueContent?: ReactNode
}) => (
  <div className={styles.row}>
    <LabelField {...labelFieldProps} textWidth={100} />
    {valueContent || (
      <Typography isEllipsis>{textValue || AppSymbols.dash}</Typography>
    )}
  </div>
)

export const InfoCard = observer(() => {
  const {
    firstName,
    lastName,
    formattedPhoneNumber,
    email,
    listItems,
    customFields,
  } = useContactPreviewContext()

  return (
    <div className={styles.infoCard}>
      <Row
        labelFieldProps={{
          LeftIcon: User,
          text: 'First name',
        }}
        textValue={firstName}
      />
      <Row
        labelFieldProps={{
          LeftIcon: User,
          text: 'Last name',
        }}
        textValue={lastName}
      />
      <Row
        labelFieldProps={{
          LeftIcon: Phone,
          text: 'Phone',
        }}
        textValue={formattedPhoneNumber}
      />
      <Row
        labelFieldProps={{
          LeftIcon: Mail,
          text: 'Email',
        }}
        textValue={email}
      />
      <Row
        labelFieldProps={{
          LeftIcon: ContactsFill,
          text: 'Lists',
          marginTop: listItems.length ? 4 : undefined,
        }}
        valueContent={
          listItems.length && (
            <div className={styles.lists}>
              {listItems.map((item) => (
                <Button
                  key={item.id}
                  variant={'x-small'}
                  LeftIcon={item.dropdownButtonProps?.LeftIcon}
                  text={item.text}
                  count={item.dropdownButtonProps?.count}
                />
              ))}
            </div>
          )
        }
      />

      {customFields.map((field) => (
        <Row
          key={field.id}
          labelFieldProps={{
            LeftIcon: field.LeftIcon,
            text: field.label,
          }}
          textValue={field.value}
        />
      ))}
    </div>
  )
})
