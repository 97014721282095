import styles from './styles.module.scss'
import audioStore from 'store/audioStore/audioStore'
import { observer } from 'mobx-react-lite'
import { RinglessStore } from 'store/ringlessStore/ringlessStore'
import { AddAudioBtn } from 'components/CreateElement/AddAudioBtn'

export const RinglessCard = observer(
  ({ ringlessStore }: { ringlessStore: RinglessStore }) => {
    const addAudioForRinglessVoiceemail = () => {
      audioStore.onOpenModal({
        successUploadFIleCallback: ringlessStore.setRinglessVoicemailAudio,
      })
    }
    return (
      <div className={styles.ringless}>
        <div className={styles.label}>
          <span className={'bold'}>Ringless voicemail</span>
          <span className={'s2 gray1'}>
            Select the message that will go straight to voicemail without
            calling
          </span>
        </div>
        <AddAudioBtn
          audioFile={ringlessStore.ringlessVoicemailAudio}
          onAddAudio={addAudioForRinglessVoiceemail}
          onRemove={() => ringlessStore.setRinglessVoicemailAudio(null)}
          from={'ringless'}
        />
      </div>
    )
  }
)
