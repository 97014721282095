import { FC } from 'react'
import {
  DefaultAudioCard,
  IDefaultAudioCardProps,
} from 'components/CreateElement/AudioCard/DefaultAudioCard/DefaultAudioCard'
import {
  AttachmentAudioCard,
  IAttachmentAudioCardProps,
} from 'components/CreateElement/AudioCard/AttachmentAudioCard/AttachmentAudioCard'

type IAudioCardProps = IDefaultAudioCardProps | IAttachmentAudioCardProps

export const AudioCard: FC<IAudioCardProps> = (props) => {
  if (!props.audio) {
    return <></>
  }
  if (props.variant === 'attachment') {
    return <AttachmentAudioCard {...props} />
  }
  return <DefaultAudioCard {...props} />
}
