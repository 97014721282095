import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { IntegrationsOAuth } from 'src/pages/settings/integrations/integrationsOAuth'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import auth from 'store/auth'
import { AuthorizedApp } from 'src/app/AuthorizedApp/AuthorizedApp'
import { UnauthorizedApp } from 'src/app/UnauthorizedApp/UnauthorizedApp'
import { usePostHog } from 'posthog-js/react'
import { RedirectAfterLogin } from 'src/pages/redirectAfterLogin/RedirectAfterLogin'
import { AppApolloProvider } from 'src/providers/AppApolloProvider'

export const PublicApp = observer(() => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const posthog = usePostHog()

  useEffect(() => {
    if (analytics.page) {
      analytics.page()
    }
    if (posthog) {
      posthog.capture('$pageview')
    }
  }, [pathname])
  useEffect(() => {
    if (searchParams) {
      const assumeToken = searchParams.get('assumeToken')
      const stripeCoupon = searchParams.get('c')

      if (assumeToken) {
        auth.setAssumeToken(assumeToken)
        navigate(RoutesEnum.broadcasts)
      }
      if (stripeCoupon) {
        auth.setStripeCoupon(stripeCoupon)
      }
      const lcCompanyId = searchParams.get('lcCompanyId')
      const lcLocationId = searchParams.get('lcLocationId')
      if (lcCompanyId && lcLocationId) {
        localStorage.setItem('lcCompanyId', lcCompanyId)
        localStorage.setItem('lcLocationId', lcLocationId)
      }
    }
  }, [searchParams])

  return auth.isAuth ? (
    <AppApolloProvider>
      <Routes>
        <Route
          path={RoutesEnum.settings + '/' + RoutesEnum.integrations + '/:type'}
          element={<IntegrationsOAuth />}
        />
        <Route path={'*'} element={<AuthorizedApp />} />
      </Routes>
    </AppApolloProvider>
  ) : (
    <Routes>
      <Route
        path={RoutesEnum.settings + '/' + RoutesEnum.integrations + '/:type'}
        element={<RedirectAfterLogin />}
      />
      <Route path={'*'} element={<UnauthorizedApp />} />
    </Routes>
  )
})
