import styles from './styles.module.scss'
import { FC, ReactNode } from 'react'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/ui/Typography'

export const DetailRow = ({
  leftContent,
  rightContent,
  rightText,
  left,
}: {
  leftContent?: ReactNode
  left?: {
    text: string
    Icon: FC
  }
  rightContent?: ReactNode
  rightText?: ReactNode
}) => (
  <div className={styles.rowTable}>
    <div className={styles.left}>
      {left ? (
        <>
          <Icon color={'--primary-color-gray-1'}>
            <left.Icon />
          </Icon>
          <Typography variant={'s1-regular'} color={'--primary-color-gray-1'}>
            {left.text}
          </Typography>
        </>
      ) : (
        leftContent
      )}
    </div>
    <div className={styles.right}>
      {rightText ? (
        <Typography variant={'s1-regular'}>{rightText}</Typography>
      ) : (
        rightContent
      )}
    </div>
  </div>
)
