import { observer } from 'mobx-react-lite'
import { Preview } from 'components/Preview/Preview'

import { useCreateBroadcastContext } from 'src/pages/main/broadcasts/createBroadcast/context/CreateBroadcastContext'

export const PreviewBroadcast = observer(() => {
  const newBroadCastStore = useCreateBroadcastContext()
  return (
    <Preview
      message={newBroadCastStore.previewMessage}
      scheduleText={newBroadCastStore.scheduleStore.sendTimeString}
    />
  )
})
