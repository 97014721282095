import { makeAutoObservable } from 'mobx'
import { UnsubscribeDigitOptions } from 'store/voiceStore/types'
import {
  LiveAnswerFragmentFragment,
  LiveAnswerInput,
  VoiceMailFragmentFragment,
  VoiceMailInput,
  VoiceMessageDetailsFragment,
  VoiceMessageInput,
} from 'src/generated/graphql'
import { Maybe } from 'graphql/jsutils/Maybe'
import { IAudioItem } from 'src/types/IAudioItem'
import billingStore from 'store/settings/billing/billingStore'
import companyStore from 'store/settings/company/companyStore'

export class VoiceStore {
  constructor() {
    makeAutoObservable(this)
  }
  liveAnswerEnabled = false
  transferCallEnabled = false
  voiceMailEnabled = false
  selectedUnsubscribeDigit: number = UnsubscribeDigitOptions[9].value
  selectedTransferDigit: number = UnsubscribeDigitOptions[1].value
  liveAnswerEnabledAudio: IAudioItem | null = null
  voiceMailEnabledAudio: IAudioItem | null = null
  transferNumber = ''

  get disabledCreate() {
    if (!this.liveAnswerEnabled && !this.voiceMailEnabled) {
      return true
    }
    if (this.liveAnswerEnabled) {
      if (!this.liveAnswerEnabledAudio) {
        return true
      }
      if (
        this.transferCallEnabled &&
        (!this.transferNumber || this.transferNumber.includes('_'))
      ) {
        return true
      }
    }

    if (this.voiceMailEnabled && !this.voiceMailEnabledAudio) {
      return true
    }

    return false
  }

  get voiceMessageInput(): VoiceMessageInput {
    return {
      liveAnswer: this.liveAnswer,
      voiceMail: this.voiceMail,
    }
  }

  get voiceMessageInit(): VoiceMessageDetailsFragment {
    return {
      liveAnswer: this.liveAnswerInit,
      voiceMail: this.voiceMailInit,
    }
  }

  get liveAnswerInit(): LiveAnswerFragmentFragment {
    return {
      liveAnswerEnabled: this.liveAnswerEnabled,
      audioId: this.liveAnswerEnabledAudio?.id,
      liveAnswer: this.liveAnswerEnabledAudio,
      transferCallEnabled: this.transferCallEnabled,
      transferCallDigit: this.selectedTransferDigit,
      transferCallNumber: this.transferNumber,
      unsubscribeDigit: this.selectedUnsubscribeDigit,
    }
  }

  get liveAnswer(): LiveAnswerInput {
    return {
      liveAnswerEnabled: this.liveAnswerEnabled,
      audioId: this.liveAnswerEnabledAudio?.id,
      transferCallEnabled: this.transferCallEnabled,
      transferCallDigit: this.selectedTransferDigit,
      transferCallNumber: this.transferNumber,
      unsubscribeDigit: this.selectedUnsubscribeDigit,
    }
  }

  get voiceMail(): VoiceMailInput {
    return {
      audioId: this.voiceMailEnabledAudio?.id,
      voiceMailEnabled: this.voiceMailEnabled,
    }
  }

  get voiceMailInit(): VoiceMailFragmentFragment {
    return {
      voiceMail: this.voiceMailEnabledAudio,
      audioId: this.voiceMailEnabledAudio?.id,
      voiceMailEnabled: this.voiceMailEnabled,
    }
  }
  setInit = (voiceMessage?: Maybe<VoiceMessageDetailsFragment>) => {
    this.liveAnswerEnabled =
      voiceMessage?.liveAnswer?.liveAnswerEnabled || false
    this.liveAnswerEnabledAudio = voiceMessage?.liveAnswer
      ?.liveAnswer as IAudioItem

    this.transferCallEnabled =
      voiceMessage?.liveAnswer?.transferCallEnabled || false
    this.selectedTransferDigit =
      UnsubscribeDigitOptions.find(
        (opt) => opt.value === voiceMessage?.liveAnswer?.transferCallDigit
      )?.value || UnsubscribeDigitOptions[1].value
    this.transferNumber = voiceMessage?.liveAnswer?.transferCallNumber || ''

    this.selectedUnsubscribeDigit =
      UnsubscribeDigitOptions.find(
        (opt) => opt.value === voiceMessage?.liveAnswer?.unsubscribeDigit
      )?.value || UnsubscribeDigitOptions[0].value

    this.voiceMailEnabled = voiceMessage?.voiceMail?.voiceMailEnabled || false
    this.voiceMailEnabledAudio = voiceMessage?.voiceMail
      ?.voiceMail as IAudioItem
  }

  setLiveAnswerEnabled = () => {
    this.liveAnswerEnabled = !this.liveAnswerEnabled
  }
  setTransferNumber = (number: string) => {
    this.transferNumber = number
  }
  setTransferCallEnabled = () => {
    const onTurnOn = () => {
      this.transferCallEnabled = !this.transferCallEnabled
    }
    if (!this.transferCallEnabled && !billingStore.autoRecharge) {
      companyStore.setTurnOnAutoRechargeModal('callTransfer', onTurnOn)
      return
    }
    onTurnOn()
  }
  setvoiceMailEnabled = () => {
    this.voiceMailEnabled = !this.voiceMailEnabled
  }
  setSelectedUnsubscribeDigit = (value: number) => {
    this.selectedUnsubscribeDigit = value
  }
  setSelectedTransferDigit = (value: number) => {
    this.selectedTransferDigit = value
  }
  setliveAnswerEnabledAudio = (audio: IAudioItem | null) => {
    this.liveAnswerEnabledAudio = audio
  }

  setvoiceMailEnabledAudio = (audio: IAudioItem | null) => {
    this.voiceMailEnabledAudio = audio
  }
}
