import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { RoutesEnum, UnauthorizedRoutes } from 'src/pages/routes'
import { renderRoute } from 'src/util/route'
import { unauthorizedRoutes } from 'src/app/UnauthorizedApp/routes'
import { useEffect } from 'react'
import { Migration } from 'src/pages/auth/migration/migration'
import TagManager from 'react-gtm-module'
import auth from 'store/auth'
import { firstPromoter } from 'src/scripts/firstPromoter'
import { NoOrganizationModal } from 'src/Modals/NoOrganizationModal/NoOrganizationModal'
import { RedirectAfterLogin } from 'src/pages/redirectAfterLogin/RedirectAfterLogin'
import configStore from 'store/configStore'
import { usePostHog } from 'posthog-js/react'

export const UnauthorizedApp = () => {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.GTM_ID || '',
    })
    auth.setInitTagManager()
    if (analytics.reset) {
      analytics.reset()
    }
    if (analytics.identify) {
      analytics.reset()
      analytics.identify()
    }
    if (!auth.initFirstPromoter) {
      firstPromoter()
    }
  }, [])

  const postHog = usePostHog()

  useEffect(() => {
    auth.setAuthError('')
    if (postHog) {
      if (!configStore.signUpFlow) {
        postHog.onFeatureFlags(function () {
          const signUpFlow = postHog.getFeatureFlag('SignUp3')
          if (signUpFlow) {
            configStore.setSignUpFlow(signUpFlow)
          }
        })
      } else {
        postHog.featureFlags.override({ SignUp3: configStore.signUpFlow })
      }
    }
  }, [postHog])

  useEffect(() => {
    if (configStore.signUpFlow === 'B') {
      auth.setForceRegister()
    }
  }, [configStore.signUpFlow])
  return (
    <>
      <NoOrganizationModal />
      <Routes>
        <Route path={RoutesEnum.inviteExist} element={<RedirectAfterLogin />} />
        <Route path={UnauthorizedRoutes.migration} element={<Migration />} />
        <Route element={<AuthPage />}>
          {unauthorizedRoutes.map(renderRoute)}
        </Route>
        <Route path="*" element={<Navigate to={UnauthorizedRoutes.login} />} />
      </Routes>
    </>
  )
}
