import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import uiStore from 'store/uiStore'
import { acceptedCreate } from 'src/pages/settings/billing/functions/acceptedCreate'
import { useBroadcastFunctions } from 'src/pages/main/broadcasts/hooks/useBroadcastFunctions'
import {
  Status,
  useCreateBroadcastMutation,
  useUpdateBroadcastMutation,
} from 'src/generated/graphql'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { CreateTestItBroadcastBtn } from 'src/pages/main/broadcasts/components/CreateTestItBroadcastBtn'
import { CloseCreateBtn } from 'components/Page/components/CloseCreateBtn'
import billingStore from 'store/settings/billing/billingStore'
import companyStore from 'store/settings/company/companyStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { useCreateBroadcastContext } from 'src/pages/main/broadcasts/createBroadcast/context/CreateBroadcastContext'
import { AppElements } from 'src/enums/appElements'
import classNames from 'classnames'
import configStore from 'store/configStore'
import { ShortLinkRequiredStore } from 'widgets/ShortLinkRequiredModal/ShortLinkRequiredStore'

export const ActionsContent = observer(() => {
  const [openTest, setOpenTest] = useState(false)
  const { onDelete, onArchive } = useBroadcastFunctions()
  const navigate = useNavigate()
  const newBroadCastStore = useCreateBroadcastContext()
  const { setTrigger, trigger, onTriggerClick, showTT, setShowTT } =
    useFixedTooltip()

  const [createBroadcast] = useCreateBroadcastMutation()
  const [updateBroadcast] = useUpdateBroadcastMutation()

  const onSaveDraft = async () => {
    try {
      if (newBroadCastStore.edit) {
        await updateBroadcast({
          variables: {
            broadcastInput: {
              ...newBroadCastStore.broadcastInput,
              status: Status.Draft,
            },
          },
        })
      } else {
        await createBroadcast({
          variables: {
            broadcastInput: {
              ...newBroadCastStore.broadcastInput,
              status: Status.Draft,
            },
          },
        })
      }
      navigate(RoutesEnum.broadcasts)
      return
    } catch (e) {
      console.error(e)
    }
  }

  const onReview = () => {
    if (newBroadCastStore.checkIsShowShortLinkRequiredModal()) {
      return new ShortLinkRequiredStore(onSaveDraft)
    } else {
      newBroadCastStore.setOpenReviewModal()
    }
  }

  const onCreate = async () => {
    const onTurnOn = async () => {
      if (newBroadCastStore.edit) {
        onReview()
      } else {
        acceptedCreate({
          limitFor: AppElements.Broadcast,
          acceptedCB: onReview,
        })
      }
    }

    if (
      !billingStore.autoRecharge &&
      newBroadCastStore.broadcastInput.voiceMessage?.liveAnswer
        ?.transferCallEnabled
    ) {
      companyStore.setTurnOnAutoRechargeModal('broadcastCreate', onTurnOn)
      return
    }

    await onTurnOn()
  }
  const handleArchive = async () => {
    await onArchive([newBroadCastStore.broadcastInput.id])
    navigate(RoutesEnum.broadcasts)
  }
  return (
    <div
      className={classNames(styles.actionsWrap, {
        [styles.isDataCollection]: configStore.isDataCollection,
      })}
    >
      {!openTest && (
        <div className={styles.left}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onCreate}
            disabled={newBroadCastStore.disabledCreate}
          >
            {newBroadCastStore.edit && newBroadCastStore.status !== Status.Draft
              ? 'Save'
              : uiStore.isMobile
              ? 'Create'
              : newBroadCastStore.createBtnText}
          </Button>
          <CloseCreateBtn fromActions />
        </div>
      )}
      {newBroadCastStore.edit && !openTest ? (
        <>
          <FixedTooltip
            visible={showTT}
            trigger={trigger}
            white
            noArrow
            position={TTPositionEnum.topRight}
            addLeftPosition={8}
          >
            <div className={styles.menuWrap}>
              <MenuBtn
                type={
                  newBroadCastStore.activeTab === 0
                    ? MenuEnum.testMessage
                    : MenuEnum.testAudio
                }
                onClick={() => {
                  setOpenTest(true)
                  setShowTT(false)
                }}
              />
              {newBroadCastStore.broadcastInput.status !== Status.Draft && (
                <MenuBtn type={MenuEnum.archive} onClick={handleArchive} />
              )}
              <MenuBtn
                type={MenuEnum.delete}
                onClick={() => onDelete(newBroadCastStore.broadcastInput.id)}
              />
            </div>
          </FixedTooltip>
          <Button
            ref={setTrigger}
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onTriggerClick}
            icon
          >
            <More />
          </Button>
        </>
      ) : (
        <CreateTestItBroadcastBtn
          setOpenTest={() => setOpenTest(!openTest)}
          openTest={openTest}
          newBroadCastStore={newBroadCastStore}
        />
      )}
    </div>
  )
})
