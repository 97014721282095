import styles from './styles.module.scss'
import { TestItBtn } from 'components/TestItBtn/TestItBtn'
import { TypeContent } from 'components/TypeContent/TypeContent'
import { IBroadcastFull } from 'src/types/IBroadcast'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { ContentType } from 'src/generated/graphql'
import { DetailTypeContent } from 'components/DetailsModalContent/DetailTypeContent'
import { UserDateContent } from 'components/Date/UserDateContent'
import { DetailRow } from 'components/DetailsModalContent/DetailRow/DetailRow'
import { Typography } from 'shared/ui/Typography'
import { numberString } from 'src/util/functions'
import { getSendTextByDays } from 'components/Schedule/utils/getSendTextByDays'
import dayjs from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'
import user from 'store/user/user'
import { observer } from 'mobx-react-lite'
import { getEstimatedDurationResponse } from 'store/broadcasts/getEstimatedInfo'
import {
  transformRinglessMessageToInput,
  transformSMSToInput,
  transformVoiceMessageToInput,
} from 'src/util/transform'
import { ConversionContent } from 'components/DetailsModalContent/ConversionContent/ConversionContent'
import {
  Calendar,
  Chat,
  Clock,
  ContactsFill,
  Deliver,
  Phone,
  Settings,
  Status,
} from 'components/Icon'
import { ContactsPickerUiForTable } from 'widgets/ContactsPicker/ui/ContactsPickerUiForTable/ContactsPickerUiForTable'
import * as React from 'react'

export const DetailBroadcastContent = ({
  broadcast,
}: {
  broadcast: IBroadcastFull
}) => {
  return (
    <>
      <div className={styles.row}>
        <Typography variant={'h3-regular'}>{broadcast.name}</Typography>
        <TestItBtn
          testMessageInput={
            broadcast.type === ContentType.Sms && broadcast.smsMessage
              ? {
                  fromNumberId: broadcast.sendFromPhoneNumber?.id,
                  smsMessage: transformSMSToInput(broadcast.smsMessage),
                  toNumber: '',
                }
              : undefined
          }
          testCallMessageRequestInput={
            broadcast.type === ContentType.Voice && broadcast.voiceMessage
              ? {
                  voiceMessage: transformVoiceMessageToInput(
                    broadcast.voiceMessage
                  ),
                  toNumber: '',
                  fromNumberId: broadcast.sendFromPhoneNumber?.id,
                }
              : undefined
          }
          testRinglessMessageRequestInput={
            broadcast.type === ContentType.Ringless && broadcast.ringlessMessage
              ? {
                  ...transformRinglessMessageToInput(broadcast.ringlessMessage),
                  toNumber: '',
                  fromNumberId: broadcast.sendFromPhoneNumber?.id,
                }
              : undefined
          }
          fromAudio={broadcast.type !== ContentType.Sms}
        />
      </div>
      <div className={styles.grid}>
        <DetailRow
          left={{
            Icon: Status,
            text: 'Status',
          }}
          rightContent={
            broadcast?.simplifiedStatus && (
              <StatusContent status={broadcast?.simplifiedStatus} />
            )
          }
        />

        <DetailRow
          left={{
            Icon: Calendar,
            text: 'Send Time',
          }}
          rightText={<UserDateContent value={broadcast?.sendTime} />}
        />
        <SchedulingConfiguration broadcast={broadcast} />
        <EstimatedComplete broadcast={broadcast} />
        <DetailRow
          left={{
            Icon: ContactsFill,
            text: 'Send to',
          }}
          rightContent={
            <ContactsPickerUiForTable store={broadcast.contactsPickerStore} />
          }
        />
        <DetailRow
          left={{
            Icon: Chat,
            text: 'Est. msgs',
          }}
          rightText={numberString({
            val: broadcast.contactsCount?.active || 0,
          })}
        />

        <DetailRow
          left={{
            Icon: Phone,
            text: 'Send from',
          }}
          rightText={broadcast.sendFromPhoneNumber?.friendlyName}
        />

        <DetailRow
          left={{
            Icon: Deliver,
            text: 'Message type',
          }}
          rightContent={broadcast.type && <TypeContent type={broadcast.type} />}
        />
        {broadcast.conversion && (
          <ConversionContent conversion={broadcast.conversion} />
        )}
      </div>
      <DetailTypeContent
        smsMessage={broadcast.smsMessage}
        ringlessMessage={broadcast.ringlessMessage}
        voiceMessage={broadcast.voiceMessage}
      />
    </>
  )
}

const SchedulingConfiguration = observer(
  ({ broadcast }: { broadcast: IBroadcastFull }) => {
    if (!broadcast.advancedSchedulingConfig) {
      return null
    }

    const sendOn = broadcast.advancedSchedulingConfig.allowedDays ? (
      <Typography>{`Send ${getSendTextByDays(
        broadcast.advancedSchedulingConfig.allowedDays
      )}`}</Typography>
    ) : null

    const sendAt = broadcast.advancedSchedulingConfig.allowedDays ? (
      <Typography>{`At ${dayjs()
        .startOf('day')
        .add(broadcast.advancedSchedulingConfig.startTimeHour, 'hours')
        .add(broadcast.advancedSchedulingConfig.startTimeMinute, 'minutes')
        .format('h:mm a')}
                    ${AppSymbols.point} ${
        user.timezone?.zoneAbbreviation
      }`}</Typography>
    ) : null

    const sendTo = () => {
      const speedConfig = broadcast.advancedSchedulingConfig?.speedConfig
      if (speedConfig) {
        return (
          <Typography>{`To ${numberString({
            val: speedConfig.contactsAmount || 0,
          })} contacts every ${speedConfig.unitAmount} minutes`}</Typography>
        )
      }
      return (
        <Typography>{`To ${numberString({
          val: broadcast.advancedSchedulingConfig?.contactsPerDay || 0,
        })} contacts per day`}</Typography>
      )
    }

    return (
      <DetailRow
        left={{
          Icon: Settings,
          text: 'Configuration',
        }}
        rightContent={
          <>
            {sendOn}
            {sendAt}
            {sendTo()}
          </>
        }
      />
    )
  }
)

const EstimatedComplete = observer(
  ({ broadcast }: { broadcast: IBroadcastFull }) => {
    if (!broadcast.estimatedDuration) {
      return null
    }

    const info = getEstimatedDurationResponse(broadcast.estimatedDuration)

    return (
      <DetailRow
        left={{
          Icon: Clock,
          text: 'Est. complete',
        }}
        rightText={`${info.duration}, by ${info.by}`}
      />
    )
  }
)
