// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.f8BkwUIfQDanwu36Uz2V{padding-bottom:24px}.CrKCUo4XcGhmwbX3NzJg{margin-top:28px;padding-left:24px;margin-right:64px;display:flex;column-gap:8px;align-items:center}.Vxvj4qoBTXCWjCzKvZfw{width:100%;display:grid;grid-template-columns:1fr min-content;align-items:center}.lYJnKRXcNZSzStS8uC05{margin-top:32px;margin-left:64px;display:flex;column-gap:12px}`, "",{"version":3,"sources":["webpack://./src/pages/main/contacts/contctLists/DetailList/header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CAGF,sBACE,eAAA,CACA,iBAAA,CACA,iBAAA,CACA,YAAA,CACA,cAAA,CACA,kBAAA,CAEF,sBACE,UAAA,CACA,YAAA,CACA,qCAAA,CACA,kBAAA,CAEF,sBACE,eAAA,CACA,gBAAA,CACA,YAAA,CACA,eAAA","sourcesContent":[".wrap{\n  padding-bottom: 24px;\n}\n\n.content{\n  margin-top: 28px;\n  padding-left: 24px;\n  margin-right: 64px;\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n}\n.row{\n  width: 100%;\n  display: grid;\n  grid-template-columns: 1fr min-content;\n  align-items: center;\n}\n.actions{\n  margin-top: 32px;\n  margin-left: 64px;\n  display: flex;\n  column-gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `f8BkwUIfQDanwu36Uz2V`,
	"content": `CrKCUo4XcGhmwbX3NzJg`,
	"row": `Vxvj4qoBTXCWjCzKvZfw`,
	"actions": `lYJnKRXcNZSzStS8uC05`
};
export default ___CSS_LOADER_EXPORT___;
