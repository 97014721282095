import { BtnSize } from 'components/Button/Button'
import { observer } from 'mobx-react-lite'
import { useEvaluateFilterQuery } from 'src/generated/graphql'
import { useSegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { useSetData } from 'src/hooks/useSetData'
import { ContactsTable } from 'src/pages/main/contacts/ContactsTable/ContactsTable'
import contactStore from 'store/contacts/contactStore'
import styles from './styles.module.scss'
import * as React from 'react'
import { useEffect } from 'react'
import { numberString } from 'src/util/functions'
import { ActionsEnum } from 'components/Button/types'
import { TableBtn } from 'components/Button/TableBtn'
import { onExport } from 'src/actions/onExport'
import { api } from 'src/apiRest/api'

const NoSelectContent = observer(() => {
  const total = contactStore.tableStoreContacts.total
  return (
    <span className={'bold'}>
      Matched contacts - {numberString({ val: total })}
    </span>
  )
})

const RightStickyContent = observer(() => {
  const { filtersInputs } = useSegmentDetailContext()
  const handleExport = async () => {
    if (filtersInputs) {
      await onExport({
        info: `segment report`,
        request: api.postContactReport(filtersInputs),
      })
    }
  }
  return (
    <>
      {!!contactStore.tableStoreContacts.total && (
        <>
          <TableBtn
            medium
            action={ActionsEnum.export}
            textBtn={'Export report'}
            onAction={handleExport}
            extraBtnSize={BtnSize.small}
          />
        </>
      )}
    </>
  )
})

export const SegmentTableContact = observer(() => {
  const { filtersInputs, isHasNotEmpty, isHasEmpty } = useSegmentDetailContext()

  const queryResult = useEvaluateFilterQuery({
    variables: {
      page: contactStore.tableStoreContacts.paginationInput,
      filterValue: {
        filters: filtersInputs,
      },
    },
    fetchPolicy: 'network-only',
    skip: isHasEmpty,
  })

  useEffect(() => {
    if (filtersInputs) {
      contactStore.tableStoreContacts.setPage(0)
      contactStore.setContactFilter({
        filters: filtersInputs,
      })
    }
    return () => {
      contactStore.setContactFilter(null)
    }
  }, [filtersInputs])

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => contactStore.setSearchContactsData(data),
    refreshDeps: [contactStore.tableStoreContacts.refreshTrigger],
    disabledRefetch: !contactStore.tableStoreContacts.refreshTrigger,
  })

  if (!isHasNotEmpty) {
    return <></>
  }

  return (
    <ContactsTable
      noAdditionalContent
      noSelectContent={<NoSelectContent />}
      pageLoading={loading || refetchLoading}
      firstLoading={
        firstLoading ||
        (loading &&
          contactStore.tableStoreContacts.paginationInput.pageNumber === 0)
      }
      tableStore={contactStore.tableStoreContacts}
      withSearch={false}
      rightStickyContent={<RightStickyContent />}
      extraBtnSize={BtnSize.small}
      overrideWrapStickyClass={styles.overrideWrapStickyClass}
      withoutEmptyState
      noSortColumnsProps={contactStore.tableStoreContacts.columns
        .map((column) => column.id)
        .filter((id) => id.startsWith('custom'))}
    />
  )
})
