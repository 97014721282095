import styles from 'components/Preview/styles.module.scss'
import { MessageCardContent } from 'components/Preview/MessageCardContent'
import { observer } from 'mobx-react-lite'
import { VoiceStore } from 'store/voiceStore/voiceStore'

type PreviewVoiceContent = { store: VoiceStore; phone: string }

export const PreviewVoiceContent = observer(
  ({ store, phone }: PreviewVoiceContent) => {
    const {
      liveAnswerEnabled,
      voiceMailEnabled,
      liveAnswerEnabledAudio,
      voiceMailEnabledAudio,
    } = store
    return (
      <div className={styles.audioMessage}>
        {!liveAnswerEnabled && !voiceMailEnabled && (
          <span className={'s2 medium gray2'}>Add an audio</span>
        )}
        {liveAnswerEnabled && (
          <MessageCardContent
            title={'Live answer'}
            from={'liveAnswer'}
            audio={liveAnswerEnabledAudio}
            phone={phone}
          />
        )}
        {voiceMailEnabled && (
          <MessageCardContent
            title={'Voicemail'}
            from={'voicemail'}
            audio={voiceMailEnabledAudio}
            phone={phone}
          />
        )}
      </div>
    )
  }
)
