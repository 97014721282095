import styles from './styles.module.scss'
import { useState } from 'react'
import { ChromePicker } from 'react-color'
import { Input } from 'shared/ui/Input/Input'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'

export const InputColor = ({
  value,
  setValue,
}: {
  value: string
  setValue: (value: string) => void
}) => {
  const [open, setOpen] = useState(false)
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <MuiTooltip
          title={
            <ChromePicker
              color={value}
              onChange={(color) => setValue(color.hex)}
            />
          }
          padding={0}
          placement={'bottom-start'}
          open={open}
        >
          <Input
            leftContent={
              <div className={styles.color} style={{ background: value }} />
            }
            value={value}
            setValue={setValue}
            onFocus={() => setOpen(true)}
          />
        </MuiTooltip>
      </div>
    </ClickAwayListener>
  )
}
