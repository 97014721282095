import styles from './styles.module.scss'
import { CheckBox } from 'components/CheckBox/CheckBox'
import { NewLabel } from 'src/shared/ui/NewLabel/NewLabel'

type ICheckBoxBtnProps = {
  active: boolean
  toggleActive: () => void
  title: string
  isNew?: boolean
}

export const CheckBoxBtn = ({
  active,
  toggleActive,
  isNew,
  title,
}: ICheckBoxBtnProps) => {
  return (
    <div className={styles.btn}>
      <CheckBox
        checked={active}
        size={'small'}
        onChangeValue={toggleActive}
        boxSize={'32'}
      />
      <span>{title}</span>
      {isNew && <NewLabel marginLeft={8} />}
    </div>
  )
}
