// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HUJS27pdCbF4UzpfdDOg{display:grid;gap:32px;margin-top:32px}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectedFields/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".wrap{\n  display: grid;\n  gap: 32px;\n  margin-top: 32px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `HUJS27pdCbF4UzpfdDOg`
};
export default ___CSS_LOADER_EXPORT___;
