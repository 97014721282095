// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p1bgQfql6AWkIup3pd_f{position:relative;padding:4px 9px 7px 9px;background-color:var(--primary-color-white);border-radius:8px;width:176px;height:48px;border:1px solid var(--primary-color-gray-3)}.p1bgQfql6AWkIup3pd_f .rxobn8vd3aylh8ZFW6NP{display:none;position:absolute;right:4px;top:4px}.p1bgQfql6AWkIup3pd_f:hover .rxobn8vd3aylh8ZFW6NP{display:block}.W8aElUb9_FJwgsWUZ0Vk{display:grid;margin-bottom:4px}.mYnrwFnjTLugNIYX7Au7{height:16px;background-color:rgba(0,0,0,0)}`, "",{"version":3,"sources":["webpack://./src/components/CreateElement/AudioCard/AttachmentAudioCard/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,iBAAA,CACA,uBAAA,CACA,2CAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CACA,4CAAA,CAEA,4CACE,YAAA,CACA,iBAAA,CACA,SAAA,CACA,OAAA,CAGA,kDACE,aAAA,CAIN,sBACE,YAAA,CACA,iBAAA,CAGF,sBACE,WAAA,CACA,8BAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrap{\n  position: relative;\n  padding: 4px 9px 7px 9px;\n  background-color: var(--primary-color-white);\n  border-radius: 8px;\n  width: 176px;\n  height: 48px;\n  border: 1px solid var(--primary-color-gray-3);\n\n  .close{\n    display: none;\n    position: absolute;\n    right: 4px;\n    top: 4px;\n  }\n  &:hover{\n    .close{\n      display: block;\n    }\n  }\n}\n.header{\n  display: grid;\n  margin-bottom: 4px;\n\n}\n.player{\n  height: 16px;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `p1bgQfql6AWkIup3pd_f`,
	"close": `rxobn8vd3aylh8ZFW6NP`,
	"header": `W8aElUb9_FJwgsWUZ0Vk`,
	"player": `mYnrwFnjTLugNIYX7Au7`
};
export default ___CSS_LOADER_EXPORT___;
