import { makeAutoObservable, runInAction } from 'mobx'
import type { IListItem } from 'components/SelectList/ListItem/ListItem'
import {
  ConversionUrlMatchConfigInput,
  UpdateConversionRequestInput,
} from 'src/generated/graphql'
import { ConversionApi } from 'src/api/settings/conversions/api'

import alertStore from 'store/alertStore'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import {
  ConversionResponse,
  IConversion,
} from 'src/pages/settings/conversions/conversions/store/type'
import { nanoid } from 'nanoid'

export type INewConversionStoreProps = {
  onClose: () => void
  onSuccess: (response: ConversionResponse) => void
  conversion?: IConversion
}

export class NewConversionStore {
  onClose
  onSuccess
  constructor({ onClose, onSuccess, conversion }: INewConversionStoreProps) {
    this.onClose = onClose
    this.onSuccess = onSuccess
    if (conversion) {
      this.init(conversion)
    }

    makeAutoObservable(this)
  }

  id = null
  editConversion: IConversion | null = null

  get isEdit() {
    return !!this.id
  }

  init = (conversion: IConversion) => {
    this.editConversion = conversion
    this.id = conversion.id
    this.name = conversion.name || ''
    if (conversion.urlPatterns) {
      this.urlsMap = new Map(
        conversion.urlPatterns.map((url) => [
          url?.id || nanoid(),
          url?.pattern || '',
        ])
      )
    }
    // this.url = conversion.urlPattern || ''
    this.defaultAmount = conversion.defaultAmount || null
    this.conversionAttribute = conversion.uniqueHit ? 'one' : 'every'
  }

  get conversion(): UpdateConversionRequestInput {
    return {
      id: this.id || undefined,
      name: this.name,
      urlPatterns: this.urlPatterns.filter((item) => item.pattern?.trim()),
      defaultAmount: this.defaultAmount || null,
      uniqueHit: this.conversionAttribute === 'one',
    }
  }

  get disabledCreate() {
    if (!this.name.trim().length) return true
    if (!this.urlsValues.join('').trim().length) return true
    if (!this.currency) return true
    if (!this.conversionAttribute) return true
    return false
  }

  loading = false
  onCreate = async () => {
    try {
      this.loading = true
      const { data } = await ConversionApi.create({
        conversion: this.conversion,
      })
      if (data?.createConversion) {
        this.onSuccess(data.createConversion)
      }
      this.onClose()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }
  onSaveEdit = async () => {
    try {
      this.loading = true
      const { data } = await ConversionApi.update({
        conversion: this.conversion,
      })
      if (data?.updateConversion) {
        this.onSuccess(data.updateConversion)
      }
      alertStore.setAlert({
        type: AlertTypeEnum.success,
        title: 'Conversion updated',
      })
      this.onClose()
    } catch (e) {
      console.error(e)
    } finally {
      runInAction(() => {
        this.loading = false
      })
    }
  }

  name = ''
  setName = (name: string) => {
    this.name = name
  }

  urlsMap: Map<string | number, string> = new Map([[nanoid(), '']])
  setUrlValue = (key: string | number, value: string) => {
    this.urlsMap.set(key, value)
  }
  addUrl = () => {
    this.urlsMap.set(nanoid(), '')
  }
  removeUrl = (key: string | number) => {
    if (this.urlsMap.size === 1) {
      this.urlsMap.set(key, '')
    } else {
      this.urlsMap.delete(key)
    }
  }
  get urls() {
    return Array.from(this.urlsMap.entries())
  }
  get urlsValues() {
    return Array.from(this.urlsMap.values())
  }
  get urlPatterns(): Array<ConversionUrlMatchConfigInput> {
    return this.urls.map(([id, value]) => ({
      pattern: value,
      id: typeof id === 'number' ? id : undefined,
    }))
  }

  get disabledAddUrl() {
    return this.urlsMap.size >= 15
  }

  mapCurrencyOptions: Map<'USD' | 'CA', Omit<IListItem, 'isActive'>> = new Map([
    [
      'USD',
      {
        id: 'USD',
        text: 'USD - United States Dollar',
      },
    ],
    [
      'CA',
      {
        id: 'USD',
        text: 'CAD - Canadian Dollar',
      },
    ],
  ])
  currency: 'USD' | 'CA' | null = 'USD'
  get currencyTitle() {
    if (this.currency) {
      return this.mapCurrencyOptions.get(this.currency)?.text
    }
  }
  setCurrency = (value: typeof this.currency) => {
    this.currency = value
  }

  get currencyOptions(): IListItem[] {
    return Array.from(this.mapCurrencyOptions.values()).map((item) => ({
      ...item,
      isActive: item.id === this.currency,
    }))
  }

  defaultAmount: number | null = null
  setDefaultAmount = (value: typeof this.defaultAmount) => {
    this.defaultAmount = value
  }

  mapConversionAttributesOptions: Map<
    'one' | 'every',
    Omit<IListItem, 'isActive'>
  > = new Map([
    [
      'one',
      {
        id: 'one',
        text: 'Count one conversion per contact',
      },
    ],
    [
      'every',
      {
        id: 'every',
        text: 'Count every conversion',
      },
    ],
  ])
  conversionAttribute: 'one' | 'every' | null = null
  get conversionAttributeTitle() {
    if (this.conversionAttribute) {
      return this.mapConversionAttributesOptions.get(this.conversionAttribute)
        ?.text
    }
  }
  setConversionAttribute = (value: typeof this.conversionAttribute) => {
    this.conversionAttribute = value
  }

  get conversionAttributeOptions(): IListItem[] {
    return Array.from(this.mapConversionAttributesOptions.values()).map(
      (item) => ({
        ...item,
        isActive: item.id === this.conversionAttribute,
      })
    )
  }
}
