import {
  Maybe,
  PhoneNumber,
  PhoneType,
  VerificationStatus,
} from 'src/generated/graphql'
import { IOption } from 'src/types/IOption'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { RoutesEnum } from 'src/pages/routes'
import { LinkWrapper } from 'components/Wrappers/LinkWrapper'
import React, { MouseEventHandler } from 'react'
import numbersStore from 'store/settings/numbers/numbersStore'
import { DeleteCallerIdNumberBtn } from 'widgets/numbers/DeleteCallerIdNumberBtn/DeleteCallerIdNumberBtn'

export function numberToOption(
  phone: Maybe<PhoneNumber>,
  forBroadcast?: boolean
): IOption {
  const getOptionRightContent = () => {
    if (phone?.type === PhoneType.TollFree && forBroadcast) {
      if (
        phone?.verificationStatus === VerificationStatus.Unverified ||
        phone?.verificationStatus === VerificationStatus.Restricted
      ) {
        return (
          <MuiTooltip title={'Unverified'} placement={'top'} arrow>
            <div>
              <LinkWrapper to={RoutesEnum.settingsCompliance}>
                <StatusContent
                  status={VerificationStatus.Unverified}
                  text={'Unregistered'}
                  right
                  jsxText={<span className={'s2 error1'}>Unregistered</span>}
                />
              </LinkWrapper>
            </div>
          </MuiTooltip>
        )
      }
      if (phone?.verificationStatus === VerificationStatus.Blocked) {
        return (
          <MuiTooltip title={'Blocked'} placement={'top'} arrow>
            <div>
              <LinkWrapper to={RoutesEnum.settingsCompliance}>
                <StatusContent
                  status={VerificationStatus.Blocked}
                  text={numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                  right
                  jsxText={
                    <span className={'s2 error1'}>
                      {numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                    </span>
                  }
                />
              </LinkWrapper>
            </div>
          </MuiTooltip>
        )
      }
      if (
        phone?.verificationStatus === VerificationStatus.InReview ||
        phone?.verificationStatus === VerificationStatus.Pending
      ) {
        return (
          <MuiTooltip title={'In Review'} placement={'top'} arrow>
            <div>
              <StatusContent
                status={VerificationStatus.InReview}
                text={numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                jsxText={
                  <span className={'s2 gray1'}>
                    {numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                  </span>
                }
                right
              />
            </div>
          </MuiTooltip>
        )
      }
      if (phone?.verificationStatus === VerificationStatus.Verified) {
        return (
          <MuiTooltip title={'Verified'} placement={'top'} arrow>
            <div>
              <StatusContent
                status={VerificationStatus.Verified}
                text={numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                jsxText={
                  <span className={'s2 gray1'}>
                    {numbersStore.numbersUseCaseTitleMap.get(phone.id)}
                  </span>
                }
                right
              />
            </div>
          </MuiTooltip>
        )
      }
    }
    if (phone?.type === PhoneType.CallerId) {
      return <DeleteCallerIdNumberBtn phone={phone} />
    }
  }

  const getAction = (): MouseEventHandler | undefined => {
    if (forBroadcast) {
      if (phone?.verificationStatus === VerificationStatus.InReview) {
        return (e) => {
          e.stopPropagation()
          numbersStore.openPendingVerifiedNumberModal(phone.updatedAt)
        }
      }
      if (phone?.verificationStatus !== VerificationStatus.Verified) {
        return (e) => {
          e.stopPropagation()
          numbersStore.setOpenUnverifiedNumberModal(true)
        }
      }
    }
  }
  return {
    title: phone?.friendlyName || '',
    value: phone?.friendlyName || '',
    group: phone?.type,
    data: phone,
    onClick: getAction(),
    leftMark: true,
    optionRightContent: getOptionRightContent(),
  }
}
