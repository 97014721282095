import { ReactNode, useEffect, useRef } from 'react'
import { useDropdownContext } from 'components/Dropdown/context/context'
import {
  DropdownButton,
  IDropdownButtonProps,
} from 'shared/ui/DropdownButton/DropdownButton'
import { Check } from 'components/Icon'

export type IListItem<T = any, ID = any> = {
  id: ID
  group?: string
  text: ReactNode
  isActive?: boolean
  option?: HTMLOptionElement
  item?: T
  dropdownButtonProps?: Omit<IDropdownButtonProps, 'onClick' | 'text'>
}

type ListItemProps<T = any, ID = any> = {
  item: IListItem<T>
  onSelect: (id: ID, item: IListItem<T>) => void
  withoutMark?: boolean
  isScrollToSelectOnMount?: boolean
  disabledCloseDropdown?: boolean
}

export const ListItem = ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  item,
  onSelect,
  withoutMark,
  isScrollToSelectOnMount,
  disabledCloseDropdown,
}: ListItemProps<any, typeof item.id>) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const { text, isActive, dropdownButtonProps } = item
  const dropdownContext = useDropdownContext()
  const handleClick = () => {
    if (dropdownContext?.onClose && !disabledCloseDropdown) {
      dropdownContext.onClose()
    }
    onSelect(item.id, item)
  }
  useEffect(() => {
    if (isScrollToSelectOnMount && item.isActive) {
      if (ref.current) {
        ref.current.scrollIntoView()
      }
    }
  }, [isScrollToSelectOnMount])
  return (
    <DropdownButton
      ref={ref}
      text={text}
      RightIcon={!withoutMark && isActive && Check}
      rightIconProps={
        !withoutMark &&
        isActive && {
          color: '--primary-color-blue-1',
        }
      }
      onClick={handleClick}
      {...dropdownButtonProps}
    />
  )
}
