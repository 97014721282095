import styles from './style.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { Card } from 'components/Card/Card'
import React, { useEffect } from 'react'
import { SizedBox } from 'components/SizedBox'
import { observer } from 'mobx-react-lite'
import { AddContactsToListDropdown } from 'src/Modals/ImportContactModal/StepsContent/Step3/AddContactsToListDropdown'
import { useCreateKeywordContext } from 'src/pages/main/keywords/create/CreateKeywordState'
import { ActionsContent } from 'src/pages/main/keywords/create/createForm/ActionContent/ActionsContent'
import {
  Status,
  useGetExistingKeywordPhoneNumbersQuery,
} from 'src/generated/graphql'
import { Switch } from 'components/Switch/Switch'
import { AppIcon } from 'components/AppIcon/AppIcon'
import { ReactComponent as Info16 } from 'icons/16pxNoMask/Info.svg'
import { ColorsNames } from 'styles/variables'
import { Separator } from 'components/Separator/Separator'
import { Dropdown } from 'components/Dropdown/Dropdown'
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip'
import { AddNumber } from 'components/CreateElement/AddNumber'
import { ReactComponent as Phone } from 'icons/16px/Phone.svg'
import { StatusContent } from 'components/StatusContent/StatusContent'
import numbersStore from 'store/settings/numbers/numbersStore'
import classNames from 'classnames'
import uiStore from 'store/uiStore'
import { useElementName } from 'src/hooks/useElementName'
import { IBottomDropdownButtonProps } from 'components/Dropdown/types'
import { Advanced } from 'widgets/Advanced/Advanced'
import { Tabs } from 'components/Tabs/Tabs'
import { SMSContent } from 'components/CreateElement/SMSContent/SMSContent'
import { DataCapture } from 'widgets/DataCapture'
import { IKeywordType } from 'store/keywords/type'
import configStore from 'store/configStore'

export const NewKeyword = observer(({ fromModal }: { fromModal?: boolean }) => {
  const { data } = useGetExistingKeywordPhoneNumbersQuery()
  const createKeywordStore = useCreateKeywordContext()

  useEffect(() => {
    if (data) {
      createKeywordStore.setKeywordsPairsMapData(data)
    }
  }, [data])
  const { error, onBlur, onFocus } = useElementName(createKeywordStore)

  const BottomDropdownButton = (props: IBottomDropdownButtonProps) => {
    return (
      <div className={'stickyBottom'}>
        {numbersStore.isSMSNumber && !numbersStore.isFirstNumber && (
          <Separator marginBlock={8} />
        )}
        <AddNumber
          {...props}
          setPhone={(phone) => createKeywordStore.setSMSPhone(phone)}
        />
      </div>
    )
  }

  return (
    <Card
      width={600}
      noMobileRadius
      className={classNames(uiStore.isMobile && 'minHeight100')}
    >
      {!fromModal && (
        <span className={'gray1'}>
          {`${createKeywordStore.edit ? 'Edit' : 'New'} keyword`}
        </span>
      )}
      {fromModal && <SizedBox height={8} />}
      <div className={styles.inputWrap}>
        <InputText
          id={'name'}
          placeholder={'Keyword name'}
          withoutBorder
          header
          value={createKeywordStore.name}
          onChange={(event) => {
            createKeywordStore.setName(event.target.value)
          }}
          withoutClear
          autoComplete={'off'}
          focusOnMount={!createKeywordStore.edit}
          error={error}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <div className={styles.switch}>
          <span>
            {createKeywordStore.activeKeyword ? Status.Active : Status.Disabled}
          </span>
          <Switch
            active={createKeywordStore.activeKeyword}
            onChange={(value) => createKeywordStore.setActiveKeyword(value)}
            tabIndex={-1}
          />
        </div>
      </div>
      {createKeywordStore.edit &&
        createKeywordStore.keywordInput.status === Status.Draft && (
          <>
            <SizedBox height={12} />
            <StatusContent status={createKeywordStore.keywordInput.status} />
          </>
        )}
      {createKeywordStore.isAlreadyUse && (
        <div className={styles.warning}>
          <AppIcon color={ColorsNames.warning2}>
            <Info16 />
          </AppIcon>
          <span className={'s2 warning2'}>
            Keyword already in use. To create a new message select another phone
            number
          </span>
        </div>
      )}
      {createKeywordStore.isReserve && (
        <div className={styles.warning}>
          <AppIcon color={ColorsNames.warning2}>
            <Info16 />
          </AppIcon>
          <span className={'s2 warning2'}>
            This name can’t be used. Please choose another name
          </span>
        </div>
      )}
      <SizedBox height={32} />
      {configStore.isDataCapture && (
        <Tabs
          tabsItems={[
            { id: 'simple' as IKeywordType, label: 'Simple' },
            {
              id: 'data_capture' as IKeywordType,
              label: 'Data capture',
              isNew: true,
            },
          ]}
          activeTabId={createKeywordStore.keywordType}
          onTabClick={(_, item) => createKeywordStore.setKeywordType(item.id)}
          mb={16}
        />
      )}
      {createKeywordStore.keywordType === 'simple' && (
        <SMSContent
          store={createKeywordStore}
          withoutSendFrom
          withoutToolFreeError
        />
      )}
      {createKeywordStore.keywordType === 'data_capture' && (
        <DataCapture store={createKeywordStore.dataCaptureStore} />
      )}
      <SizedBox height={40} />
      <div className={styles.phoneWrap}>
        <Dropdown
          multi
          defaultOption={
            (createKeywordStore.phoneOptions.length === 1 &&
              createKeywordStore.phoneOptions[0]) ||
            undefined
          }
          selectedOptions={numbersStore.phonesWithGlobalNumbers.filter((opt) =>
            createKeywordStore.smsPhones.includes(opt.value)
          )}
          onMultiSelect={(options) =>
            createKeywordStore.setSMSPhones(options.map((opt) => opt.value))
          }
          labelInput={
            <div className={styles.row}>
              <span className={'s2 medium gray1'}>Phone number</span>
              <InfoTooltip
                // width={230}
                title={
                  <>
                    The phone number(s) contacts <br /> will text into to join
                    your list.
                  </>
                }
              />
            </div>
          }
          options={createKeywordStore.phoneOptions}
          BottomDropdownButton={BottomDropdownButton}
          renderWithPlusOptions
          leftIcon={
            <AppIcon>
              <Phone />
            </AppIcon>
          }
          dropDownContentWidth={450}
          grouping
        />
      </div>

      <SizedBox height={24} />
      <AddContactsToListDropdown
        store={createKeywordStore.dropdownContactListsStore}
      />
      <Advanced config={createKeywordStore} />
      <ActionsContent fromModal={fromModal} />
    </Card>
  )
})
