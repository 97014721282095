import { observer } from 'mobx-react-lite'
import { Tabs } from 'components/Tabs/Tabs'
import { useEffect, useState } from 'react'
import { useSetMember } from 'src/pages/settings/account/hooks/useSetMember'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { Header } from 'shared/ui/Header/Header'
import styles from './styles.module.scss'

export const Company = observer(() => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  useSetMember(true, pathname.includes('profile'))
  const onTabClick = (index: number) => {
    switch (index) {
      case 0: {
        return navigate(RoutesEnum.settings + '/' + RoutesEnum.company)
      }
      case 1: {
        return navigate(RoutesEnum.team)
      }
      default: {
        navigate(RoutesEnum.settings + '/' + RoutesEnum.company)
      }
    }
  }
  useEffect(() => {
    if (pathname.includes(RoutesEnum.team)) {
      setActiveTabIndex(1)
      return
    }
    setActiveTabIndex(0)
  }, [pathname])
  return (
    <>
      <Header title={'Company'} inContainer />
      <div className={styles.wrapTabs}>
        <Tabs
          tabs={['Profile', 'Team']}
          activeTabIndex={activeTabIndex}
          onTabClick={onTabClick}
        />
      </div>
      <Outlet />
    </>
  )
})
