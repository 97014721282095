import { observer } from 'mobx-react-lite'
import { useSearchListsAndContactsQuery } from 'src/generated/graphql'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { useMemo } from 'react'
import {
  contactListToIListItem,
  contactToIListItem,
  segmentToIListItem,
} from 'store/contacts/functions'
import styles from '../styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { BtnType, Button } from 'components/Button/Button'
import { useContactsPickerContext } from 'widgets/ContactsPicker/context/context'
import { PickerSelectList } from '../PickerSelectList'

export const AllContent = observer(() => {
  const {
    search,
    selectedContactsIds,
    selectedContactListsIds,
    selectedSegmentsIds,
    setContentType,
  } = useContactsPickerContext()
  const { data, loading } = useSearchListsAndContactsQuery({
    variables: {
      search: {
        searchPattern: search,
        excludedContactIds: selectedContactsIds,
        excludedListIds: selectedContactListsIds,
        excludedSegmentIds: selectedSegmentsIds,
      },
    },
  })
  const items: IListItem[] = useMemo(() => {
    const items: IListItem[] = []

    data?.searchListsAndContacts?.listings?.forEach((list) => {
      if (list) {
        items.push(contactListToIListItem(list))
      }
    })
    data?.searchListsAndContacts?.contacts?.forEach((contact) => {
      if (contact) {
        items.push(contactToIListItem(contact))
      }
    })

    data?.searchListsAndContacts?.segments?.forEach((segment) => {
      if (segment) {
        items.push(segmentToIListItem(segment))
      }
    })

    return items
  }, [data])

  return (
    <PickerSelectList
      loading={loading}
      items={items}
      groupContent={{
        Lists: (
          <div className={styles.wrapGroupLabel}>
            <Typography variant={'s2-medium'} color={'--primary-color-gray-1'}>
              Lists ({data?.searchListsAndContacts?.totalListings})
            </Typography>
            <Button
              typeBtn={BtnType.blueAction}
              onClick={() => setContentType('lists')}
            >
              Show all
            </Button>
          </div>
        ),
        Contacts: (
          <div className={styles.wrapGroupLabel}>
            <Typography variant={'s2-medium'} color={'--primary-color-gray-1'}>
              Contacts ({data?.searchListsAndContacts?.totalContacts})
            </Typography>
            <Button
              typeBtn={BtnType.blueAction}
              onClick={() => setContentType('contacts')}
            >
              Show all
            </Button>
          </div>
        ),
        Segments: (
          <div className={styles.wrapGroupLabel}>
            <Typography variant={'s2-medium'} color={'--primary-color-gray-1'}>
              Segments ({data?.searchListsAndContacts?.totalSegments})
            </Typography>
            <Button
              typeBtn={BtnType.blueAction}
              onClick={() => setContentType('segments')}
            >
              Show all
            </Button>
          </div>
        ),
      }}
    />
  )
})
