import { observer } from 'mobx-react-lite'
import styles from 'src/Modals/CreateTextToSpeechModal/styles.module.scss'
import { InputText } from 'components/Input/InputText/InputText'
import { Textarea } from 'components/Textarea/Textarea'
import { Icon } from 'components/Icon/Icon'
import { ChooseVoice } from 'src/Modals/CreateTextToSpeechModal/components/ChooseVoice/ChooseVoice'
import { Personalize } from 'components/Textarea/Personolize/Personalize'
import classNames from 'classnames'
import { secondToTime } from 'src/util/functions'
import { PreviewSpeech } from 'src/Modals/CreateTextToSpeechModal/components/PreviewSpeech/PreviewSpeech'
import React from 'react'
import { useCreateTextToSpeechModalContext } from 'src/Modals/CreateTextToSpeechModal/context/context'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'
import configStore from 'store/configStore'
import { Warning } from 'components/Icon'
import { Button } from 'shared/ui/Button/Button'

export const CreateTextToSpeechModalContent = observer(() => {
  const {
    name,
    setName,
    disabledCreate,
    duration,
    handleCreate,
    handleUpdate,
    loadingCreate,
    error,
    audioFileDuration,
    textareaStore,
    editAudio,
    selectedItem,
  } = useCreateTextToSpeechModalContext()
  const disabledPersonlolized = !selectedItem?.item?.langCode.startsWith('en')
  return (
    <div className={'mediumModal'}>
      <div className={styles.wrapInput}>
        <InputText
          withoutBorder
          placeholder={'Audio name'}
          value={name}
          onChange={(event) => setName(event.target.value)}
          withoutClear
          focusOnMount
        />
      </div>
      <div className={styles.wrapTextField}>
        <Textarea textareaStore={textareaStore} noActions noBorder />
        <div className={styles.textareaActions}>
          {error ? (
            <div className={'row8'}>
              <Icon color={'--states-color-error-1'}>
                <Warning />
              </Icon>
              <span className={'s2 error1'}>{error}</span>
            </div>
          ) : (
            <div className={'row8'}>
              <ChooseVoice />
              {configStore.dynamic_tts && (
                <Personalize
                  onAddPersonalize={textareaStore.onAddPersonalize}
                  isNew
                  disabled={disabledPersonlolized}
                  dropdownButtonProps={{
                    muiTooltipProps: disabledPersonlolized
                      ? {
                          title:
                            'Personalization is supported only for English language.',
                          arrow: true,
                          placement: 'top',
                        }
                      : undefined,
                  }}
                />
              )}
            </div>
          )}
          {!!duration && (
            <span className={classNames('s2 gray2', { error1: !!error })}>
              {(audioFileDuration && secondToTime(audioFileDuration)) ||
                `~${secondToTime(duration)}`}
            </span>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        {editAudio ? (
          <Button
            variant={'primary-medium'}
            disabled={disabledCreate}
            onClick={handleUpdate}
            loading={loadingCreate}
            text={'Save'}
          />
        ) : (
          <Button
            variant={'primary-medium'}
            disabled={disabledCreate}
            onClick={handleCreate}
            loading={loadingCreate}
            text={'Create'}
          />
        )}

        <Button
          variant={'secondary-medium'}
          onClick={createTextToSpeechStore.onClose}
          text={'Cancel'}
        />
        <div />
        <PreviewSpeech />
      </div>
    </div>
  )
})
