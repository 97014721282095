import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { IKeyword } from 'src/types/IKeyword'
import { RoutesEnum } from 'src/pages/routes'
import { useNavigate } from 'react-router-dom'

export const KeywordEditBtn = (props: ActionBtnProps<IKeyword>) => {
  const { row } = props
  const navigate = useNavigate()
  const onEdit = () => {
    if (row) {
      navigate(`${RoutesEnum.keywords}/${row.id}/edit`)
    }
  }
  return <TableBtn {...props} action={ActionsEnum.edit} onAction={onEdit} />
}
