import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { PersonalizeSelectStore } from 'widgets/PersonalizeSelect/store/PersonalizeSelectStore'
import { TextareaStore } from 'store/textareaStore'
import {
  SmsMessageInput,
  WorkflowActionRequestInput,
  WorkflowActionType,
} from 'src/generated/graphql'
import {
  CollectedFieldActionStore,
  ICollectedFieldActionInput,
} from 'widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction'

export interface ICollectedFieldInput {
  reply: SmsMessageInput
  actions: ICollectedFieldActionInput[]
  field?: string | null
}

export class CollectedFieldStore {
  personalizeSelectStore: PersonalizeSelectStore
  textareaStore: TextareaStore
  actions: CollectedFieldActionStore[] = []
  constructor(input?: ICollectedFieldInput) {
    makeAutoObservable(this)
    this.personalizeSelectStore = new PersonalizeSelectStore({
      excludeIds: ['phoneNumber'],
    })
    this.textareaStore = new TextareaStore()
    this.init(input)
  }

  get workflowActionRequestInput(): WorkflowActionRequestInput[] {
    const groupNumber = this.index

    const getElseAction = () => {
      if (this.actions[0].action === WorkflowActionType.StopWorkflow) {
        return [
          {
            type: WorkflowActionType.StopWorkflow,
            properties: null,
          },
        ]
      }
      if (this.actions[0].action === WorkflowActionType.SendMessage) {
        return [
          {
            type: WorkflowActionType.SendMessage,
            properties: {
              messageConfig: this.actions[0].textareaStore.smsMessageInput,
              messageType: 'SMS',
            },
          },
          {
            groupNumber,
            type: WorkflowActionType.WaitResponse,
            properties: {
              hours: this.actions[1].waitReplay,
            },
          },
          {
            type: WorkflowActionType.IfElse,
            properties: {
              elseActions: [
                {
                  groupNumber,
                  type: WorkflowActionType.StopWorkflow,
                  properties: null,
                },
              ],
              ifActions: [
                {
                  groupNumber,
                  type: WorkflowActionType.DoNothing,
                  properties: null,
                },
              ],
            },
          },
        ]
      }
    }
    return [
      {
        groupNumber,
        type: WorkflowActionType.WaitResponse,
        properties: {
          hours: this.actions[0].waitReplay,
        },
      },
      {
        groupNumber,
        type: WorkflowActionType.IfElse,
        properties: {
          ifActions: [
            {
              type: WorkflowActionType.DoNothing,
              properties: null,
            },
          ],
          elseActions: getElseAction(),
        },
      },
      {
        groupNumber,
        type: WorkflowActionType.UpdateContact,
        properties: {
          collectedField: this.personalizeSelectStore.itemId,
        },
      },
      {
        groupNumber,
        type: WorkflowActionType.SendMessage,
        properties: {
          messageConfig: this.textareaStore.smsMessageInput,
          messageType: 'SMS',
        },
      },
    ]
  }

  init = (input?: ICollectedFieldInput) => {
    if (input) {
      this.textareaStore.setSmsMessage(input.reply)
      this.actions = input.actions.map(this.getNewAction)
      if (input.field) {
        this.personalizeSelectStore.setItemId(input.field)
      }
    } else {
      this.actions = [this.getNewAction()]
    }
  }

  get input(): ICollectedFieldInput {
    return {
      reply: this.textareaStore.smsMessageInput,
      actions: this.actionsInputs,
      field: this.personalizeSelectStore.itemId,
    }
  }
  id = nanoid()

  index = 0
  setIndex = (value: number) => {
    this.index = value
  }

  expand = true
  toggleExpand = () => {
    this.expand = !this.expand
  }

  addNextAction = (id: string) => {
    const index = this.actions.findIndex((action) => action.id === id)
    this.actions.splice(index + 1, 0, this.getNewAction())
  }

  removeNextActins = (id: string) => {
    const index = this.actions.findIndex((action) => action.id === id)
    this.actions.splice(index + 1)
  }

  get actionsSize() {
    return this.actions.length
  }

  get actionsInputs() {
    return this.actions.map((action) => action.input)
  }

  getNewAction = (actionInput?: ICollectedFieldActionInput) => {
    return new CollectedFieldActionStore(
      this.addNextAction,
      this.removeNextActins,
      this.actionsSize >= 1,
      actionInput
    )
  }
}
