import { FC } from 'react'

type Props = {
  width?: number
  minWidth?: number
  height?: number
}
export const SizedBox: FC<Props> = ({
  width = 0,
  height = 0,
  minWidth,
  ...props
}) => <div style={{ width, height, minWidth }} {...props} />
