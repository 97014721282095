// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eE1E_HLdi7bZImBPGkUx{background:var(--secondary-color-purple-3);color:var(--secondary-color-purple-1);border-radius:6px;padding:2px 8px;margin-block:-2px}.eE1E_HLdi7bZImBPGkUx.VY112bW4Q7Qj_t3VcPL1{background:var(--primary-color-white)}.eE1E_HLdi7bZImBPGkUx.E_9Q9lWJcDrxvnQ07xbp{padding:0;top:6px;right:4px;position:absolute;width:4px;height:4px;background:var(--secondary-color-purple-1)}`, "",{"version":3,"sources":["webpack://./src/shared/ui/NewLabel/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,0CAAA,CACA,qCAAA,CACA,iBAAA,CACA,eAAA,CACA,iBAAA,CACA,2CACE,qCAAA,CAEF,2CACE,SAAA,CACA,OAAA,CACA,SAAA,CACA,iBAAA,CACA,SAAA,CACA,UAAA,CACA,0CAAA","sourcesContent":[".wrap{\n  background: var(--secondary-color-purple-3);\n  color: var(--secondary-color-purple-1);\n  border-radius: 6px;\n  padding: 2px 8px;\n  margin-block: -2px;\n  &.inverted{\n    background: var(--primary-color-white);\n  }\n  &.point{\n    padding: 0;\n    top: 6px;\n    right: 4px;\n    position: absolute;\n    width: 4px;\n    height: 4px;\n    background: var(--secondary-color-purple-1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `eE1E_HLdi7bZImBPGkUx`,
	"inverted": `VY112bW4Q7Qj_t3VcPL1`,
	"point": `E_9Q9lWJcDrxvnQ07xbp`
};
export default ___CSS_LOADER_EXPORT___;
