import { makeAutoObservable, reaction } from 'mobx'
import { RangeDatePickerStore } from 'store/rangeDatePickerStore/rangeDatePickerStore'
import { TableStatuses, TableStore } from 'components/NewTable/store/TableStore'
import { columnsDetailOverview } from 'components/NewTable/columns/campaigns/columnsDetailOverview'
import { ICampaign } from 'src/types/ICampaign'
import {
  CampaignAnalyticsStatisticsResponse,
  CampaignStepResponse,
  ContentType,
  GetCampaignStepMessagesQuery,
  MessageStatisticsResponse,
} from 'src/generated/graphql'
import { IStatisticMessage } from 'src/types/IDetailMessage'
import { messageStatisticsColumns } from 'components/NewTable/columns/messageStatisticsColumns'
import { nanoid } from 'nanoid'

class CampaignDetailStore {
  overviewRangeDatePickerStore: RangeDatePickerStore
  overViewTableStore: TableStore<ICampaign>
  stepStatisticTableStore!: TableStore<IStatisticMessage>

  campaign: ICampaign | null = null
  constructor() {
    makeAutoObservable(this)
    this.overviewRangeDatePickerStore = new RangeDatePickerStore()
    this.overViewTableStore = new TableStore({
      orderBy: 'addedTime',
      tableName: 'campaignsOverviewTable',
      columns: columnsDetailOverview,
    })
  }

  activeTabIndex = 0
  overviewMessageStatistics: CampaignAnalyticsStatisticsResponse | null = null
  stepMessageStatistics: MessageStatisticsResponse | null = null
  stepStatisticMessages: IStatisticMessage[] = []

  refreshTrigger = ''

  get activeStep(): CampaignStepResponse | null {
    if (!this.campaign) {
      return null
    }
    return this.campaign.campaignStepResponses[this.activeTabIndex - 1]
  }

  reset = () => {
    this.refreshTrigger = ''
    this.campaign = null
    this.activeTabIndex = 0
    this.overviewMessageStatistics = null
    this.stepMessageStatistics = null
    this.stepStatisticMessages = []
    this.stepStatisticTableStore = new TableStore({
      columns: messageStatisticsColumns({
        type: this.activeStep?.type || ContentType.Sms,
        status: TableStatuses.totalCount,
      }),
      tableName: 'CampaignsDetailTable',
      orderBy: 'state.timeToSend',
      withDataRange: true,
    })
  }

  setCampaign = (campaign: ICampaign) => {
    this.campaign = campaign
    if (campaign.shortUrlClickStatistics?.shortUrlVisits?.length) {
      this.overViewTableStore.setColumns(columnsDetailOverview)
    }

    reaction(
      () => this.stepStatisticTableStore.status,
      (status) => {
        this.stepStatisticTableStore.setColumns(
          messageStatisticsColumns({
            type: this.activeStep?.type || ContentType.Sms,
            status,
            withShortStatistic:
              !!this.stepMessageStatistics?.shortUrlClickStatistics
                ?.shortUrlVisits?.length,
          })
        )
      }
    )
  }

  setActiveTabIndex = (index: number) => {
    this.activeTabIndex = index
    if (this.activeStep) {
      this.stepStatisticTableStore.setColumns(
        messageStatisticsColumns({
          type: this.activeStep?.type || ContentType.Sms,
          status: TableStatuses.totalCount,
          withShortStatistic:
            !!this.stepMessageStatistics?.shortUrlClickStatistics
              ?.shortUrlVisits?.length,
        })
      )
      this.stepStatisticTableStore.onRefresh()
    }
  }

  setCampaignOverviewMessageStatistics = (
    messageStatistics: CampaignAnalyticsStatisticsResponse | null
  ) => {
    this.overviewMessageStatistics = messageStatistics
    if (messageStatistics?.shortUrlClickStatistics?.shortUrlVisits?.length) {
      this.overViewTableStore.setColumns(columnsDetailOverview)
    }
  }

  setStepMessageStatistics = (
    messageStatistics: MessageStatisticsResponse | null
  ) => {
    this.stepMessageStatistics = messageStatistics
    this.stepStatisticTableStore.setColumns(
      messageStatisticsColumns({
        type: this.activeStep?.type || ContentType.Sms,
        status: this.stepStatisticTableStore.status,
        withShortStatistic:
          !!messageStatistics?.shortUrlClickStatistics?.shortUrlVisits?.length,
      })
    )
  }

  setStepMessages = (data: GetCampaignStepMessagesQuery) => {
    this.stepStatisticTableStore.total =
      data.getCampaignStepMessages?.total || 0

    const iMessages: IStatisticMessage[] = []

    data.getCampaignStepMessages?.content?.forEach((message) => {
      if (message) {
        iMessages.push({
          id: message?.id,
          ...message,
        })
      }
    })
    this.stepStatisticMessages = iMessages
  }

  onRefresh = () => {
    this.refreshTrigger = nanoid()
  }
}

export default new CampaignDetailStore()
