import { RoutesEnum } from 'src/pages/routes'
import { INavItem, NavType } from 'components/NavBar/types'
import { AdminNotification } from 'components/NavBar/components/AdminNotification/AdminNotification'
import {
  Account,
  Audio,
  Billing,
  Broadcasts,
  Campaigns,
  Company,
  Contacts,
  Conversion,
  CRM,
  Dollar,
  HelpSupport,
  Import,
  Integrations,
  Keywords,
  Megaphone,
  Shield,
  TollFree,
  Triggers,
  Webforms,
} from 'components/Icon'

export const navItems: INavItem[] = [
  {
    text: 'Dashboard',
    route: RoutesEnum.dashboard,
    type: NavType.dashboard,
  },
  {
    separator: true,
    separatorMargin: 16,
    text: 'Broadcasts',
    route: RoutesEnum.broadcasts,
    LeftIcon: Broadcasts,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Campaigns',
    route: RoutesEnum.campaigns,
    LeftIcon: Campaigns,
    type: NavType.navLink,
    marginBottom: 4,
    isNew: true,
  },
  {
    text: 'Triggers',
    route: RoutesEnum.triggers,
    LeftIcon: Triggers,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Keywords',
    route: RoutesEnum.keywords,
    LeftIcon: Keywords,
    type: NavType.navLink,
    marginBottom: 4,
    isNew: true,
  },
  {
    text: 'Web Forms',
    route: RoutesEnum.webForms,
    LeftIcon: Webforms,
    type: NavType.navLink,
    marginBottom: 4,
  },
  // {
  //   text: 'Automations',
  //   route: RoutesEnum.automations,
  //   LeftIcon: Automations,
  //   type: NavType.navLink,
  //   marginBottom: 4,
  // },
  {
    text: 'Contacts',
    route: RoutesEnum.contacts,
    LeftIcon: Contacts,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Admin Panel',
    route: RoutesEnum.adminOrganizations,
    LeftIcon: CRM,
    type: NavType.navLink,
    separator: true,
    rightContent: <AdminNotification />,
    // marginBottom: 20,
  },
]

export const HelpNavItem: INavItem = {
  text: 'Help & Support',
  type: NavType.help,
  LeftIcon: HelpSupport,
}

export const ProductUpdatesNavItem: INavItem = {
  text: 'Product Updates',
  type: NavType.productUpdates,
  LeftIcon: Megaphone,
  marginBottom: 4,
}

export const SwitchOrganizationNavItem: INavItem = {
  text: 'Switch Organization',
  type: NavType.switchOrg,
  marginBottom: 4,
  separator: true,
}

export const navItemsSettings: INavItem[] = [
  {
    text: 'Settings',
    type: NavType.settings,
  },
  {
    separator: true,
    separatorMargin: 16,
    text: 'Account',
    route: RoutesEnum.account,
    LeftIcon: Account,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Company',
    route: RoutesEnum.company,
    LeftIcon: Company,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Compliance',
    route: RoutesEnum.compliance,
    LeftIcon: Shield,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Billing & Usage',
    route: RoutesEnum.billing,
    LeftIcon: Billing,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Numbers',
    route: RoutesEnum.numbers,
    LeftIcon: TollFree,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Integrations',
    isNew: true,
    route: RoutesEnum.integrations,
    LeftIcon: Integrations,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Conversions',
    isNew: true,
    route: RoutesEnum.conversions,
    LeftIcon: Conversion,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Audio',
    route: RoutesEnum.audio,
    LeftIcon: Audio,
    type: NavType.navLink,
    separatorBottom: true,
  },
  {
    text: 'Log Out',
    type: NavType.logout,
    marginBottom: 0,
  },
]

export const navItemsAdmin: INavItem[] = [
  {
    text: 'Admin Panel',
    type: NavType.settings,
    marginBottom: 20,
  },
  {
    text: 'Organizations',
    route: RoutesEnum.organizations,
    LeftIcon: Company,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Imports',
    route: RoutesEnum.imports,
    LeftIcon: Import,
    noMask: true,
    type: NavType.navLink,
    marginBottom: 4,
    rightContent: <AdminNotification isImports />,
  },
  {
    text: 'Custom Plans',
    route: RoutesEnum.customPlans,
    LeftIcon: Dollar,
    type: NavType.navLink,
    marginBottom: 4,
  },
  {
    text: 'Compliance',
    route: RoutesEnum.compliance,
    LeftIcon: Shield,
    type: NavType.navLink,
    marginBottom: 4,
    rightContent: <AdminNotification isCompliance />,
  },
]
