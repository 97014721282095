import createCampaignStore from 'store/campaigns/createCampaignStore'
import { Button } from 'shared/ui/Button/Button'
import styles from './styles.module.scss'

export const AddMessage = () => {
  return (
    <Button
      variant={'add'}
      text={'Add message'}
      onClick={createCampaignStore.addMessage}
      className={styles.btnWrap}
    />
  )
}
