// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R1B5eSfOCseflLc8vUFH{overflow:auto;padding-top:120px;padding-inline:16px;display:flex;justify-content:center;position:absolute;top:0;right:0;left:0;bottom:0;background-color:rgba(20,22,26,.2)}.R1B5eSfOCseflLc8vUFH.aEcKm7xksTCaBwcSleQz{padding:32px;align-items:center}@media(max-width: 800px){.R1B5eSfOCseflLc8vUFH{align-items:flex-end;padding:16px;width:100vw}}@media(max-height: 600px){.R1B5eSfOCseflLc8vUFH{align-items:flex-end;padding:16px;width:100vw}}`, "",{"version":3,"sources":["webpack://./src/components/Modal/components/Overflow/styles.module.scss"],"names":[],"mappings":"AACA,sBACE,aAAA,CACA,iBAAA,CACA,mBAAA,CACA,YAAA,CACA,sBAAA,CACA,iBAAA,CACA,KAAA,CACA,OAAA,CACA,MAAA,CACA,QAAA,CACA,kCAAA,CACA,2CACE,YAAA,CACA,kBAAA,CAGF,yBAjBF,sBAkBI,oBAAA,CACA,YAAA,CACA,WAAA,CAAA,CAGF,0BAvBF,sBAwBI,oBAAA,CACA,YAAA,CACA,WAAA,CAAA","sourcesContent":["@import \"src/styles/mixin\";\n.wrap{\n  overflow: auto;\n  padding-top: 120px;\n  padding-inline: 16px;\n  display: flex;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  background-color: rgba(20, 22, 26, 0.2);\n  &.center{\n    padding: 32px;\n    align-items: center;\n  }\n\n  @media (max-width: 800px) {\n    align-items: flex-end;\n    padding: 16px;\n    width: 100vw;\n\n  }\n  @media (max-height: 600px) {\n    align-items: flex-end;\n    padding: 16px;\n    width: 100vw;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `R1B5eSfOCseflLc8vUFH`,
	"center": `aEcKm7xksTCaBwcSleQz`
};
export default ___CSS_LOADER_EXPORT___;
