// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.juAg7JmYvzeTcxV0sc4y{background-color:var(--primary-color-gray-4);position:relative;justify-content:center;display:flex;gap:44px;flex-grow:1;overflow:scroll;min-width:988px}@media(max-width: 800px){.juAg7JmYvzeTcxV0sc4y{display:flex;min-width:unset}}@media(max-height: 600px){.juAg7JmYvzeTcxV0sc4y{display:flex;min-width:unset}}.YRi6N4JPnLWacOucGPqh{margin:64px 0 64px 64px}@media(max-width: 800px){.YRi6N4JPnLWacOucGPqh{margin:0}}@media(max-height: 600px){.YRi6N4JPnLWacOucGPqh{margin:0}}`, "",{"version":3,"sources":["webpack://./src/components/Page/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,4CAAA,CACA,iBAAA,CACA,sBAAA,CACA,YAAA,CACA,QAAA,CACA,WAAA,CACA,eAAA,CAEA,eAAA,CAEA,yBAXF,sBAYI,YAAA,CACA,eAAA,CAAA,CAEF,0BAfF,sBAgBI,YAAA,CACA,eAAA,CAAA,CAGJ,sBACE,uBAAA,CACA,yBAFF,sBAGI,QAAA,CAAA,CAEF,0BALF,sBAMI,QAAA,CAAA","sourcesContent":[".wrapCreatePage{\n  background-color: var(--primary-color-gray-4);\n  position: relative;\n  justify-content: center;\n  display: flex;\n  gap: 44px;\n  flex-grow: 1;\n  overflow: scroll;\n\n  min-width: 988px;\n\n  @media (max-width: 800px) {\n    display: flex;\n    min-width: unset;\n  }\n  @media (max-height: 600px) {\n    display: flex;\n    min-width: unset;\n  }\n}\n.wrapCreateElement{\n  margin: 64px 0 64px 64px;\n  @media (max-width: 800px) {\n    margin: 0;\n  }\n  @media (max-height: 600px) {\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapCreatePage": `juAg7JmYvzeTcxV0sc4y`,
	"wrapCreateElement": `YRi6N4JPnLWacOucGPqh`
};
export default ___CSS_LOADER_EXPORT___;
