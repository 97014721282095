import styles from './style.module.scss'
import React, { ClipboardEventHandler, useRef, useState } from 'react'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { placeCaretAtEnd } from 'src/util/functions'
import { observer } from 'mobx-react-lite'
import { Undo } from 'components/Icon'
import classNames from 'classnames'

export const EditableFieldText = observer(
  ({
    editableText,
    setEditableText,
    NOT_REQUIRED_TEXT,
    REQUIRED_TEXT,
    isRequiredTextAtEnd,
    bordered,
  }: {
    editableText: string
    setEditableText: (value: string) => void
    NOT_REQUIRED_TEXT: string
    REQUIRED_TEXT: string
    isRequiredTextAtEnd?: boolean
    bordered?: boolean
  }) => {
    const ref = useRef<HTMLSpanElement>(null)
    const [editableTextState, setEditableTextState] =
      React.useState<string>(editableText)
    const [focus, setFocus] = useState(false)

    const onPaste: ClipboardEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault()

      // get text representation of clipboard
      const text = e.clipboardData?.getData('text/plain')

      // insert text manually
      document.execCommand('insertHTML', false, text)
    }

    const handleChangeCompliance: React.FormEventHandler<HTMLDivElement> = (
      e
    ) => {
      setEditableText(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-expect-error
        e.target.innerText || ''
      )
    }

    const onRevertToDefault: React.MouseEventHandler = (e) => {
      e.stopPropagation()
      setEditableText(NOT_REQUIRED_TEXT)
      setEditableTextState(NOT_REQUIRED_TEXT)
      if (ref.current) {
        ref.current.innerHTML = NOT_REQUIRED_TEXT
        ref.current.innerText = NOT_REQUIRED_TEXT
        placeCaretAtEnd(ref.current)
      }
    }

    const onClickOnField = () => {
      if (isRequiredTextAtEnd) {
        ref.current && placeCaretAtEnd(ref.current)
      } else {
        ref.current?.focus()
      }
    }

    return (
      <div
        className={classNames(styles.wrap, {
          [styles.bordered]: bordered,
          [styles.focus]: focus,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className={styles.wrapEditContent} onClick={onClickOnField}>
          {!isRequiredTextAtEnd && (
            <span
              contentEditable={false}
              className={styles.required}
              data-required={true}
            >
              {REQUIRED_TEXT}
            </span>
          )}

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            ref={ref}
            contentEditable={true}
            onInput={handleChangeCompliance}
            className={styles.editContent}
            onPaste={onPaste}
            onClick={(event) => event.stopPropagation()}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {editableTextState}
          </span>
          {isRequiredTextAtEnd && (
            <span
              contentEditable={false}
              className={styles.required}
              data-required={true}
            >
              {REQUIRED_TEXT}
            </span>
          )}
        </div>
        {editableText !== NOT_REQUIRED_TEXT && (
          <div>
            <MuiTooltip
              title={'Revert to default message'}
              placement={'top-end'}
              arrow
            >
              <div>
                <Button
                  typeBtn={BtnType.tertiaryIcon}
                  size={BtnSize.small}
                  VectorIcon={Undo}
                  onClick={onRevertToDefault}
                />
              </div>
            </MuiTooltip>
          </div>
        )}
      </div>
    )
  }
)
