import { observer } from 'mobx-react-lite'
import styles from 'src/pages/main/webForms/create/createForm/Settings/styles.module.scss'
import { Switch } from 'components/Switch/Switch'
import { Typography } from 'shared/ui/Typography'
import { Label } from 'shared/ui/Label/Label'
import { InputColor } from 'shared/ui/Input/InputColor/InputColor'
import { CustomSubscribeButtonStore } from 'src/pages/main/webForms/create/createForm/Settings/CustomSubscribeButton/CustomSubscribeButtonStore'

export const CustomSubscribeButton = observer(
  ({ store }: { store: CustomSubscribeButtonStore }) => {
    const {
      isActive,
      setIsActive,

      buttonColor,
      setButtonColor,
      hoverButtonColor,
      setHoverButtonColor,
      disabledButtonColor,
      setDisabledButtonColor,
      textButtonColor,
      setTextButtonButtonColor,
    } = store
    return (
      <div className={styles.wrapSubscribeSettings}>
        <div className={styles.wrapSwitch}>
          <Switch active={isActive} onChange={setIsActive} />
          <Typography variant={'s1-regular'}>
            Customize subscribe button
          </Typography>
        </div>
        {isActive && (
          <div className={styles.subscribeSettings}>
            <div>
              <Label text={'Subscribe button color'} marginBottom={8} />
              <InputColor value={buttonColor} setValue={setButtonColor} />
            </div>
            <div>
              <Label text={'Hover effect color'} marginBottom={8} />
              <InputColor
                value={hoverButtonColor}
                setValue={setHoverButtonColor}
              />
            </div>
            <div>
              <Label text={'Button disabled effect color'} marginBottom={8} />
              <InputColor
                value={disabledButtonColor}
                setValue={setDisabledButtonColor}
              />
            </div>
            <div>
              <Label text={'Text color'} marginBottom={8} />
              <InputColor
                value={textButtonColor}
                setValue={setTextButtonButtonColor}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
)
