import { DataCaptureContext } from 'widgets/DataCapture/context/context'
import { DataCaptureStore } from 'widgets/DataCapture/store/DataCaptureStore'
import { DataCaptureUi } from 'widgets/DataCapture/ui/DataCaptureUi'

type IDataCaptureProps = {
  store: DataCaptureStore
}

export const DataCapture = ({ store }: IDataCaptureProps) => {
  return (
    <DataCaptureContext.Provider value={store}>
      <DataCaptureUi />
    </DataCaptureContext.Provider>
  )
}
