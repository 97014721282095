import styles from './styles.module.scss'
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/ui/Typography'
import { ForwardedRef, forwardRef, MouseEventHandler } from 'react'
import {
  InfoTooltip,
  InfoTooltipProps,
} from 'components/InfoTooltip/InfoTooltip'
import { Plus } from 'components/Icon'

export type IAddButtonProps = {
  text: string
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  className?: HTMLButtonElement['className']
  variant: 'add'
  infoTooltipProps?: InfoTooltipProps
  disabled?: boolean
}

export const AddButton = forwardRef(function addButton(
  { text, onClick, className, infoTooltipProps, disabled }: IAddButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <div className={styles.wrap}>
      <button
        className={classNames(styles.btnWrap, className)}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
      >
        <div className={styles.wrapPlus}>
          <Icon>
            <Plus />
          </Icon>
        </div>

        <Typography variant={'s1-bold'} className={styles.text}>
          {text}
        </Typography>
      </button>
      {infoTooltipProps && <InfoTooltip {...infoTooltipProps} />}
    </div>
  )
})
