import { observer } from 'mobx-react-lite'
import { useContactPreviewContext } from 'widgets/ContactPreview/context/context'
import styles from './styles.module.scss'
import { CalendarArrowRight, ContactsFill, Icon, User } from 'components/Icon'
import { Typography } from 'shared/ui/Typography'
import { StatusContent } from 'components/StatusContent/StatusContent'
import { LabelField } from 'widgets/ContactPreview/ui/LabelField/LabelField'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { AppSymbols } from 'src/util/symbols'
import user from 'store/user/user'
import { OptInRender } from 'components/NewTable/cell/OptInRender/OptInRender'
import * as React from 'react'
import { InfoCard } from 'widgets/ContactPreview/ui/InfoCard/InfoCard'

export const ContactPreviewUi = observer(() => {
  const {
    formattedPhoneNumber,
    fullName,
    status,
    createdAt,
    optIn,
    contactSource,
  } = useContactPreviewContext()
  return (
    <div className={styles.wrap}>
      <div className={styles.contactCard}>
        <div className={styles.avatar}>
          <Icon>
            <User />
          </Icon>
        </div>
        <div className={styles.info}>
          <Typography isEllipsis>{fullName}</Typography>
          <Typography variant={'s2-regular'} color={'--primary-color-gray-1'}>
            {formattedPhoneNumber}
          </Typography>
        </div>
      </div>

      <div className={styles.statusCard}>
        {status && <StatusContent status={status} />}
        <div className={styles.info}>
          <div className={styles.row}>
            <LabelField LeftIcon={CalendarArrowRight} text={'Added on'} />
            <Typography>
              {dayjs(createdAt).format(DayjsFormats.fullWithDash)}
            </Typography>
            <Typography color={'--primary-color-gray-1'}>
              {AppSymbols.point}
            </Typography>
            <Typography color={'--primary-color-gray-1'}>
              {user.timezoneShortName}
            </Typography>
          </div>
          <div className={styles.row}>
            <LabelField LeftIcon={ContactsFill} text={'Opted-in'} />
            {optIn && (
              <OptInRender optIn={optIn} contactSource={contactSource} />
            )}
          </div>
        </div>
      </div>
      <InfoCard />
    </div>
  )
})
