import { CreateStore } from 'components/CreateElement/store/createStore'
import { action, computed, makeObservable, observable } from 'mobx'
import { nanoid } from 'nanoid'
import {
  CampaignStepCreateRequestInput,
  CampaignStepResponse,
  CampaignStepUpdateRequestInput,
  ContentType,
} from 'src/generated/graphql'
import numbersStore from 'store/settings/numbers/numbersStore'
import { CampaignScheduleStore } from 'src/widgets/CampaignSchedule'

export class CampaignMessageStore extends CreateStore {
  scheduleStore: CampaignScheduleStore
  constructor() {
    super({
      createBtnText: '',
      placeholder: 'Write your message...',
    })

    this.scheduleStore = new CampaignScheduleStore()

    makeObservable(this, {
      element: observable,
      scheduleStore: observable,
      active: observable,
      expand: observable,
      id: observable,

      disabledCreate: computed,
      step: computed,
      updateStep: computed,
      stepError: computed,

      setActive: action.bound,
      setExpand: action.bound,
      setStep: action.bound,
      setDuplicate: action.bound,
    })
  }

  element = 'Campaign'

  active = true
  expand = true
  edit = false
  id: number | string = nanoid()

  get disabledCreate() {
    return false
  }

  get step(): CampaignStepCreateRequestInput {
    return {
      name: this.name,
      smsMessage:
        this.type === ContentType.Sms
          ? this.textareaStore.smsMessageInput
          : undefined,
      voiceMessage:
        this.type === ContentType.Voice
          ? this.voiceStore.voiceMessageInput
          : undefined,
      ringlessMessage:
        this.type === ContentType.Ringless
          ? this.ringlessStore.ringlessMessageInput
          : undefined,
      phoneNumberId:
        this.type === ContentType.Sms
          ? numbersStore.numbersMapByFriendly.get(this.smsPhone)?.id
          : numbersStore.numbersMapByFriendly.get(this.voicePhone)?.id,
      delayType: this.scheduleStore.sendTypeCampaign,
      delay: this.scheduleStore.delayInput,
    }
  }

  get updateStep(): CampaignStepUpdateRequestInput {
    return {
      ...this.step,
      id: typeof this.id === 'number' ? this.id : null,
    }
  }

  get stepError() {
    if (!this.name?.trim()) {
      return true
    }
    if (this.type === ContentType.Sms) {
      if (!this.smsPhone || this.smsPhone.includes('_')) {
        return true
      }
      if (this.textareaStore.isError) {
        return true
      }
      if (!this.textareaStore.text) {
        return true
      }
    }
    if (this.type === ContentType.Voice) {
      if (this.voiceStore.disabledCreate) {
        return true
      }
    }
    if (this.type === ContentType.Ringless) {
      if (this.ringlessStore.disabledCreate) {
        return true
      }
    }
    if (this.type === ContentType.Voice || this.type === ContentType.Ringless) {
      if (!this.voicePhone || this.voicePhone.includes('_')) {
        return true
      }
    }
  }

  setActive = (state: boolean) => {
    this.active = state
  }
  setExpand = (state: boolean) => {
    this.expand = state
  }

  setStep = (step: CampaignStepResponse) => {
    this.id = step.id
    this.name = step.name || ''
    this.type = step.type || ContentType.Sms
    this.expand = false
    this.textareaStore.setSmsMessage(step.smsMessage)

    this.scheduleStore.setCampaignDelay(
      step.delayType || undefined,
      step.delay || undefined
    )

    if (this.type === ContentType.Sms) {
      this.smsPhone = step.phoneNumber?.friendlyName || ''
    } else {
      if (this.type === ContentType.Voice) {
        this.voiceStore.setInit(step.voiceMessage)
      }
      if (this.type === ContentType.Ringless && step.ringlessMessage) {
        this.ringlessStore.setInit(step.ringlessMessage)
      }
      this.voicePhone = step.phoneNumber?.friendlyName || ''
    }
  }

  setDuplicate = (messageStore: CampaignMessageStore) => {
    this.id = nanoid()
    this.name = messageStore.name || ''
    this.type = messageStore.type
    this.expand = false
    this.textareaStore.setSmsMessage(messageStore.textareaStore.smsMessage)

    this.scheduleStore.setCampaignDelay(
      messageStore.step.delayType || undefined,
      messageStore.step.delay || undefined
    )

    if (this.type === ContentType.Sms) {
      this.smsPhone = messageStore.smsPhone
    } else {
      if (this.type === ContentType.Voice) {
        this.voiceStore.setInit(messageStore.voiceStore.voiceMessageInit)
      }
      if (this.type === ContentType.Ringless) {
        this.ringlessStore.setInit(
          messageStore.ringlessStore.ringlessMessageInit
        )
      }
      this.voicePhone = messageStore.voicePhone || ''
    }
    if (messageStore.trackConversionStore.conversion) {
      this.setAdvanced(true)
      this.trackConversionStore.init(
        messageStore.trackConversionStore.conversion
      )
    }
  }
}
