import styles from './styles.module.scss'
import { FixedTooltip } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import React, { FC, useEffect, useRef, useState } from 'react'
import { InputText } from 'components/Input/InputText/InputText'
import { ClassesEnum } from 'styles/classes'
import { useOverlayClick } from 'src/hooks/useOverlayClick'
import { Icon } from 'components/Icon/Icon'
import { Fallback } from 'components/Icon'

type Props = {
  visible: boolean
  triggerFocus?: boolean
  trigger: HTMLSpanElement | undefined
  onClose: () => void
  onEditPersonalize: (span: HTMLSpanElement | undefined, value: string) => void
}

export const PersonalizeTT: FC<Props> = ({
  visible,
  trigger,
  onClose,
  onEditPersonalize,
  triggerFocus,
}) => {
  const refHoverTarget = useRef(false)
  const refHoverTrigger = useRef(false)
  const refFocusInput = useRef(false)
  const [refInput, setRefInput] = useState<HTMLInputElement | null>(null)
  const [valueFallback, setValueFallback] = useState(
    trigger?.getAttribute('data-fallback') || ''
  )
  useEffect(() => {
    setValueFallback(trigger?.getAttribute('data-fallback') || '')
  }, [trigger])

  useOverlayClick(onClose, visible)
  const onEdit = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueFallback(e.target.value)
    onEditPersonalize(trigger, e.target.value)
  }
  const onKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onClose()
    }
  }
  const onFocusInput = () => {
    refFocusInput.current = true
  }
  const onBlurInput = () => {
    refFocusInput.current = false
  }

  useEffect(() => {
    const triggerLeave = () => {
      refHoverTrigger.current = false
    }
    const triggerEnter = () => {
      refHoverTrigger.current = true
    }
    if (trigger) {
      trigger.addEventListener('mouseleave', triggerLeave)
      trigger.addEventListener('mouseenter', triggerEnter)
    }
    if (!visible && trigger) {
      trigger.removeEventListener('mouseleave', triggerLeave)
      trigger.removeEventListener('mouseenter', triggerEnter)
    }
    if (!visible) {
      refHoverTrigger.current = false
      refHoverTarget.current = false
      refFocusInput.current = false
    }
  }, [trigger, visible])
  const onMouseEnter = () => {
    refHoverTarget.current = true
  }
  const onMouseLeave = () => {
    refHoverTarget.current = false
  }
  useEffect(() => {
    if (triggerFocus && visible) {
      refInput?.focus()
    }
  }, [triggerFocus, visible, refInput])
  return (
    <FixedTooltip
      visible={visible}
      trigger={trigger}
      white
      width={297}
      globalClasses={[ClassesEnum.p4]}
    >
      <div
        className={styles.wrapTT}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={styles.fallback}>
          <Icon fontSize={12}>
            <Fallback />
          </Icon>
          Fallback
        </div>
        <InputText
          placeholder={`If we can’t find ${trigger?.innerText}`}
          withoutBorder
          onKeyUp={onKeyUp}
          value={valueFallback}
          onChange={onEdit}
          withoutClear
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          setRefInput={setRefInput}
        />
      </div>
    </FixedTooltip>
  )
}
