// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KCQakODuH89e5KUdn3lx{display:grid;grid-template-columns:1fr 1fr;gap:16px;margin-block:16px}`, "",{"version":3,"sources":["webpack://./src/components/Textarea/ShortenLink/ShortenLinkTriggerTooltip/CustomUtmParams/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,QAAA,CACA,iBAAA","sourcesContent":[".wrapCustomParams{\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  gap: 16px;\n  margin-block: 16px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapCustomParams": `KCQakODuH89e5KUdn3lx`
};
export default ___CSS_LOADER_EXPORT___;
