import { AudioPlayer } from 'components/AudioPlayer/AudioPlayer'
import { FC } from 'react'
import styles from './styles.module.scss'
import { IAudioItem } from 'src/types/IAudioItem'
import { Typography } from 'shared/ui/Typography'
import { Icon } from 'components/Icon/Icon'
import { CloseCircle } from 'components/Icon'

export type IAttachmentAudioCardProps = {
  audio: IAudioItem
  onRemove?: (audio: IAudioItem) => void
  from: string
  variant: 'attachment'
}

export const AttachmentAudioCard: FC<IAttachmentAudioCardProps> = ({
  audio,
  onRemove,
  from,
}) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <Typography variant={'s2-regular'} isEllipsis>
          {audio.name}
        </Typography>
      </div>
      {onRemove && (
        <button onClick={() => onRemove(audio)} className={styles.close}>
          <Icon>
            <CloseCircle />
          </Icon>
        </button>
      )}
      <AudioPlayer
        src={audio.url || ''}
        id={audio.id + from}
        className={styles.player}
        fullWidth={true}
        maxCanvasWidth={'100%'}
      />
    </div>
  )
}
