import styles from './styles.module.scss'
import React, { CSSProperties, FC, useEffect, useRef } from 'react'
import classNames from 'classnames'
import {
  createElementFromHTML,
  getCaretIndex,
  placeCaretAtEnd,
  placeCaretAtStart,
  textToHtml,
} from 'src/util/functions'
import { observer } from 'mobx-react-lite'
import { useSetSMSData } from 'components/CreateElement/hooks/useSetSMSData'
import {
  Actions,
  ITextAreaActionsProps,
} from 'components/Textarea/actions/Actions'
import { useTextareaContext } from 'components/Textarea/context/context'

export type ITextareaProps = {
  compose?: boolean
  className?: string
  tabIndex?: number
  focusOnMount?: boolean
  noBorder?: boolean
  placeholder?: string
  textareaStyle?: CSSProperties
} & ITextAreaActionsProps

export const TextareaUi: FC<ITextareaProps> = observer(
  ({
    compose,
    className,
    tabIndex,
    focusOnMount,
    noBorder,
    placeholder = 'Write your message...',
    textareaStyle,
    ...props
  }) => {
    const textareaStore = useTextareaContext()
    const { setTooltips } = textareaStore
    const onLoadSMSData = () => {
      textareaStore.setTriggerMessageUpdate()
    }
    useSetSMSData(onLoadSMSData)

    const offsetRef = useRef(0)
    const focusNodeRef = useRef<Node | null>(null)

    const ref = useRef<HTMLDivElement>(null)

    const addContent = (content: string) => {
      const node = createElementFromHTML(content, true)
      if (!node) {
        return
      }
      let focusNodeLocal = focusNodeRef.current
      let offsetLocal = offsetRef.current

      if (ref.current) {
        if (!focusNodeLocal) {
          ref.current.focus()
          focusNodeLocal = getCaretIndex(ref.current).focusNode || null
          offsetLocal = getCaretIndex(ref.current).offset || 0
        }
        if (focusNodeLocal) {
          if (focusNodeLocal.textContent) {
            if (focusNodeLocal.nodeName === '#text') {
              const firstPart = focusNodeLocal.textContent.slice(0, offsetLocal)
              const firstNode = document.createElement('span')
              const firstNodeContent = createElementFromHTML(firstPart, true)
              if (firstNodeContent) {
                firstNode.appendChild(firstNodeContent)
              }

              const secondPart = focusNodeLocal.textContent.slice(offsetLocal)

              const secondNode = document.createElement('span')
              const secondNodeContent = createElementFromHTML(secondPart, true)
              if (secondNodeContent) {
                secondNode.appendChild(secondNodeContent)
              }

              const newNode = document.createElement('span')
              if (firstPart) {
                newNode.appendChild(firstNode)
              }
              newNode.appendChild(node)
              if (secondPart) {
                newNode.appendChild(secondNode)
              }
              if (newNode) {
                const parentNode = focusNodeLocal.parentNode
                if (parentNode) {
                  parentNode.replaceChild(newNode, focusNodeLocal)
                }
              }
              if (ref.current) {
                textareaStore.setMessageText(ref.current.innerHTML)
                try {
                  placeCaretAtStart(secondNode)
                } catch (e) {
                  console.error(e)
                }
              }
            } else {
              if (focusNodeLocal.firstChild?.nodeName === 'BR') {
                focusNodeLocal.removeChild(focusNodeLocal.firstChild)
              }
              focusNodeLocal.appendChild(node)
              textareaStore.setMessageText(ref.current.innerHTML)
              placeCaretAtEnd(node)
            }
          } else {
            if (focusNodeLocal.firstChild?.nodeName === 'BR') {
              focusNodeLocal.removeChild(focusNodeLocal.firstChild)
            }
            focusNodeLocal.appendChild(node)
            textareaStore.setMessageText(ref.current.innerHTML)
            if (focusNodeLocal) {
              placeCaretAtEnd(focusNodeLocal)
            } else {
              placeCaretAtEnd(ref.current)
            }
          }

          const testFocusNodeLocal =
            getCaretIndex(ref.current).focusNode || null
          if (!testFocusNodeLocal) {
            placeCaretAtEnd(ref.current)
          }
        }
      }
      textareaStore.setTriggerMessageUpdate()
    }

    useEffect(() => {
      if (ref.current) {
        textareaStore.setTextFieldElement(ref.current)
        textareaStore.setAddContent(addContent)
      }
    }, [])

    const onInput = () => {
      textareaStore.handleHasPersonalizes()
      if (ref.current) {
        if (ref.current.innerHTML === '<br>') {
          ref.current.innerHTML = ''
          textareaStore.setMessageText('')
        } else {
          textareaStore.setMessageText(ref.current.innerHTML)
        }
      }
    }

    const onPaste: React.ClipboardEventHandler<HTMLDivElement> = (e) => {
      onFocus()

      e.preventDefault()
      const text = e.clipboardData.getData('text/plain')
      addContent(textToHtml(text, false, true))
      if (ref.current) {
        setTooltips()
      }
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.ctrlKey && e.code === 'KeyC') {
        window.navigator.clipboard.writeText(textareaStore.text)
      }
    }

    useEffect(() => {
      if (ref.current) {
        ref.current.innerHTML = textareaStore.messageHtml
        setTooltips()
      }
    }, [textareaStore.triggerMessageUpdateText])

    useEffect(() => {
      if (focusOnMount && ref.current) {
        placeCaretAtEnd(ref.current)
      }
    }, [ref])

    const onFocus = () => {
      if (ref.current) {
        const { offset, focusNode } = getCaretIndex(ref.current)
        offsetRef.current = offset
        if (focusNode) {
          focusNodeRef.current = focusNode
        }
      }
    }

    return (
      <div
        className={classNames(
          styles.wrap,
          compose && styles.compose,
          noBorder && styles.noBorder,
          { [styles['data-capture']]: props.variant === 'data-capture' },
          className
        )}
      >
        <div
          tabIndex={tabIndex}
          ref={ref}
          onPaste={onPaste}
          onKeyDown={onKeyDown}
          onInput={onInput}
          contentEditable={true}
          className={classNames(
            styles.textarea,
            textareaStore.messageHtml && styles.withContent
          )}
          style={textareaStyle}
          onBlur={onFocus}
          onClick={onFocus}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          placeholder={placeholder}
        />

        <Actions {...props} />
      </div>
    )
  }
)
