// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NnlJbnLr5yuGaAw_0R7o{padding:16px;display:flex;flex-direction:column;width:100%;gap:12px;border-radius:8px;border:1px solid var(--primary-color-gray-3);background:var(--primary-color-white);cursor:default}.iKlolSewMkqcEjsIKWEt{width:100%;height:32px;display:flex;gap:12px;align-items:center}.iKlolSewMkqcEjsIKWEt .TZBXVyh9b5EPmpji6xw5{cursor:grab;display:flex;gap:8px;align-items:center;flex-grow:1}.GVRwGcJKHLMmngCYYW9b{display:flex;flex-direction:column;gap:4px}.bZNq5bt7ySeDVNlEGl5g{display:grid;gap:12px;grid-template-columns:80px 1fr}.bZNq5bt7ySeDVNlEGl5g .kLv0WKuQDT1CmrA1whd8{padding:8px;display:flex}.bZNq5bt7ySeDVNlEGl5g .RKfpb_BVwfOYM3Qkex9y{display:flex;width:100%}`, "",{"version":3,"sources":["webpack://./src/widgets/DataCapture/widtets/CaptureField/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA,CACA,iBAAA,CACA,4CAAA,CACA,qCAAA,CACA,cAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,QAAA,CACA,kBAAA,CAEA,4CACE,WAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CACA,WAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAIF,sBACE,YAAA,CACA,QAAA,CAEA,8BAAA,CACA,4CACE,WAAA,CACA,YAAA,CAEF,4CACE,YAAA,CACA,UAAA","sourcesContent":[".wrap{\n  padding: 16px;\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 12px;\n  border-radius: 8px;\n  border: 1px solid var(--primary-color-gray-3);\n  background: var(--primary-color-white);\n  cursor: default;\n}\n.header{\n  width: 100%;\n  height: 32px;\n  display: flex;\n  gap: 12px;\n  align-items: center;\n\n  .draggable{\n    cursor: grab;\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    flex-grow: 1;\n  }\n}\n\n.content{\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n\n}\n\n.filed{\n  display: grid;\n  gap: 12px;\n\n  grid-template-columns: 80px 1fr;\n  .left{\n    padding: 8px;\n    display: flex;\n  }\n  .right{\n    display: flex;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `NnlJbnLr5yuGaAw_0R7o`,
	"header": `iKlolSewMkqcEjsIKWEt`,
	"draggable": `TZBXVyh9b5EPmpji6xw5`,
	"content": `GVRwGcJKHLMmngCYYW9b`,
	"filed": `bZNq5bt7ySeDVNlEGl5g`,
	"left": `kLv0WKuQDT1CmrA1whd8`,
	"right": `RKfpb_BVwfOYM3Qkex9y`
};
export default ___CSS_LOADER_EXPORT___;
