import { observer } from 'mobx-react-lite'
import { useContactsPickerContext } from 'widgets/ContactsPicker/context/context'

import { ContactsContent } from 'widgets/ContactsPicker/ui/PickerList/variants/ContactsContent'
import { ListsContent } from 'widgets/ContactsPicker/ui/PickerList/variants/ListsContent'
import { SegmentsContent } from 'widgets/ContactsPicker/ui/PickerList/variants/SegmentsContent'
import { AllContent } from 'widgets/ContactsPicker/ui/PickerList/variants/AllContent'

export const PickerList = observer(() => {
  const { contentType } = useContactsPickerContext()
  if (contentType === 'all') {
    return <AllContent />
  }
  if (contentType === 'contacts') {
    return <ContactsContent />
  }
  if (contentType === 'lists') {
    return <ListsContent />
  }
  if (contentType === 'segments') {
    return <SegmentsContent />
  }

  return null
})
