import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { TableBtn } from 'components/Button/TableBtn'
import { ActionsEnum } from 'components/Button/types'
import { IKeyword } from 'src/types/IKeyword'
import { useRemoveKeywordsMutation } from 'src/generated/graphql'

export const KeywordDeleteBtn = (
  props: ActionBtnProps<IKeyword> & { id?: string; ids?: string[] }
) => {
  const { row, id, ids } = props

  const [removeKeywords] = useRemoveKeywordsMutation()
  const onDelete = async () => {
    try {
      const keywordIds =
        (row && [+row.id]) ||
        (id && [+id]) ||
        (ids && ids.map((id) => +id)) ||
        []
      if (keywordIds.length) {
        await removeKeywords({
          variables: {
            keywordIds,
          },
        })
        props.onSuccessAction?.()
      }
    } catch (e) {
      console.error(e)
    }
  }
  return <TableBtn {...props} action={ActionsEnum.delete} onAction={onDelete} />
}
