import { IListItem, ListItem } from 'components/SelectList/ListItem/ListItem'
import styles from './styles.module.scss'
import { ReactNode, useState } from 'react'
import { InView } from 'react-intersection-observer'
import { AbsoluteLoader } from 'components/Loader/AbsoluteLoader'
import { groupBy } from 'lodash'
import { Typography } from 'shared/ui/Typography'
import { Input } from 'shared/ui/Input/Input'
import { Search } from 'components/Icon'

export type SelectListProps<T = any, ID = any> = {
  options: Array<IListItem<T>>
  onSelect: (id: ID, item: IListItem<T>) => void
  withoutMark?: boolean
  isShowLoadingMore?: boolean
  handleLoadMore?: () => void
  bottomContent?: ReactNode
  topContent?: ReactNode
  isLocalSearch?: boolean
  isScrollToSelectOnMount?: boolean
  searchProps?: { search: string; setSearch: (search: string) => void }
  groupContent?: Record<string, ReactNode>
  disabledCloseDropdown?: boolean
}

export const SelectList = ({
  options,
  onSelect,
  withoutMark,
  bottomContent,
  isShowLoadingMore,
  handleLoadMore,
  searchProps,
  isLocalSearch,
  topContent,
  isScrollToSelectOnMount,
  groupContent,
  disabledCloseDropdown,
}: SelectListProps) => {
  const group = groupBy(options, 'group')
  const [localSearch, setLocalSearch] = useState('')

  const search = searchProps?.search ?? localSearch
  const handleSearch = searchProps?.setSearch ?? setLocalSearch

  return (
    <div className={styles.wrap}>
      {topContent}
      {(isLocalSearch || searchProps) && (
        <div className={styles.wrapSearch}>
          <Input
            size={'small'}
            LeftIcon={Search}
            value={search}
            setValue={handleSearch}
            placeholder={'Search'}
          />
        </div>
      )}
      {!!options.length && (
        <div className={styles.list}>
          {Object.entries(group).map(
            ([key, groupOptions]) =>
              !!groupOptions.length && (
                <div key={key} className={styles.group}>
                  {key !== 'undefined' &&
                    (groupContent?.[key] || (
                      <Typography
                        variant={'s2-medium'}
                        color={'--primary-color-gray-1'}
                        style={{ margin: 8 }}
                      >
                        {key}
                      </Typography>
                    ))}
                  {groupOptions
                    .filter((option) =>
                      String(option.text)
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                    .map((item) => (
                      <ListItem
                        key={item.id}
                        item={item}
                        onSelect={onSelect}
                        withoutMark={withoutMark}
                        isScrollToSelectOnMount={isScrollToSelectOnMount}
                        disabledCloseDropdown={disabledCloseDropdown}
                      />
                    ))}
                </div>
              )
          )}
          <InView onChange={(inView) => inView && handleLoadMore?.()}>
            {({ ref }) => (
              <div ref={ref}>
                {isShowLoadingMore && (
                  <div className={styles.wrapLoader}>
                    <AbsoluteLoader size={16} />
                  </div>
                )}
              </div>
            )}
          </InView>
        </div>
      )}

      {bottomContent && (
        <div className={styles.wrapBottom}>{bottomContent}</div>
      )}
    </div>
  )
}
