// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uLTReZUoYYmt9mq7LxVr{display:flex;flex-direction:column;overflow:auto;width:100%}.eceYFSsyn82VNCoI4nKM{display:flex;align-items:center}.eceYFSsyn82VNCoI4nKM .jVSK145ZvOpecwI8ue4N{color:var(--primary-color-gray-1);padding:20px 12px 20px 16px}.eceYFSsyn82VNCoI4nKM .MEde1Qpyij0VEibfBGtp{padding-block:10px;display:flex;flex-direction:column;gap:4px;overflow:hidden}.yn64Aed55wP0SUownGeq{width:100%;display:flex;flex-direction:column;gap:24px;padding:16px;border-radius:12px;background:var(--primary-color-gray-4)}.yn64Aed55wP0SUownGeq .MEde1Qpyij0VEibfBGtp{display:flex;flex-direction:column;gap:8px}.yn64Aed55wP0SUownGeq .MEde1Qpyij0VEibfBGtp .t9vIYCmVGpgETCMdUiK6{display:flex;gap:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/ContactPreview/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,aAAA,CACA,UAAA,CAGF,sBACE,YAAA,CACA,kBAAA,CACA,4CACE,iCAAA,CACA,2BAAA,CAEF,4CACE,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CACA,eAAA,CAIJ,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,QAAA,CACA,YAAA,CACA,kBAAA,CACA,sCAAA,CACA,4CACE,YAAA,CACA,qBAAA,CACA,OAAA,CACA,kEACE,YAAA,CACA,OAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  overflow: auto;\n  width: 100%;\n}\n\n.contactCard{\n  display: flex;\n  align-items: center;\n  .avatar{\n    color: var(--primary-color-gray-1);\n    padding: 20px 12px 20px 16px;\n  }\n  .info{\n    padding-block: 10px;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    overflow: hidden;\n  }\n}\n\n.statusCard{\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n  padding: 16px;\n  border-radius: 12px;\n  background: var(--primary-color-gray-4);\n  .info{\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n    .row{\n      display: flex;\n      gap: 8px;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `uLTReZUoYYmt9mq7LxVr`,
	"contactCard": `eceYFSsyn82VNCoI4nKM`,
	"avatar": `jVSK145ZvOpecwI8ue4N`,
	"info": `MEde1Qpyij0VEibfBGtp`,
	"statusCard": `yn64Aed55wP0SUownGeq`,
	"row": `t9vIYCmVGpgETCMdUiK6`
};
export default ___CSS_LOADER_EXPORT___;
