import styles from '../styles.module.scss'
import { Alert } from 'components/Alert/Alert'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { Typography } from 'shared/ui/Typography'
import { CopyBtn } from 'components/Button/CopyBtn'
import { BtnSize } from 'components/Button/Button'
import { conversionCodeScript } from 'widgets/conversions/ConversionCode/conversionCodeScript'

export const RevenueTracking = () => {
  const code = `<!-- Start of Call Loop Revenue Tracking Snippet -->
${conversionCodeScript}
<!-- End of Call Loop Revenue Tracking Snippet -->

<script>
//Example use in your code
new Promise((r) =>window.callLoop ? r() :  document.addEventListener('_cl_l', r)).then(() => {
     window.callLoop({
      revenue: null, //The value is expressed in USD, e.g. 1.99
     }) 
    })
</script>
`

  return (
    <>
      <div className={styles.content}>
        <Alert
          type={AlertTypeEnum.notify2}
          text={
            <>
              <b>
                You do not need to do this if your conversion is auto-triggered
                by the URL.
              </b>{' '}
              If you are unable to use the URL to detect conversions, then
              follow these instructions.
            </>
          }
        />
        <Typography variant={'p2-regular'}>
          To manually trigger a conversion, call the{' '}
          <Typography
            variant={'s2-medium'}
            className={styles.tag}
            fontFamily={'Roboto_Mono'}
          >
            track
          </Typography>{' '}
          method, in addition to the main javascript tracking code.
        </Typography>
        <div className={styles.code}>
          <Typography
            fontFamily={'Roboto_Mono'}
            variant={'p2-regular'}
            color={'--primary-color-gray-1'}
            tag={'pre'}
          >
            {code}
          </Typography>
        </div>
        <Typography variant={'p2-regular'}>
          The{' '}
          <Typography
            variant={'s2-medium'}
            className={styles.tag}
            fontFamily={'Roboto_Mono'}
          >
            value
          </Typography>{' '}
          attribute (optional) is expressed in USD. If omitted, the value will
          be set to the default value, or zero if the default is blank.
        </Typography>
      </div>
      <div className={styles.actions}>
        <CopyBtn content={code} size={BtnSize.medium} textBtn={'Copy code'} />
      </div>
    </>
  )
}
