import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { observer } from 'mobx-react-lite'
import { EditSegmentOrFilter } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/EditSegmentOrFilter'

export const EditItem = observer(({ item }: { item: IListItem }) => {
  if (item.group === 'Filters' || item.group === 'Segments') {
    return <EditSegmentOrFilter item={item} />
  }
  return null
})
