import { observer } from 'mobx-react-lite'
import { SendingSpeed } from 'src/widgets/SendingSpeed'
import { Separator } from 'components/Separator/Separator'
import styles from './styles.module.scss'
import { ActiveAdvancedScheduleStore } from 'src/widgets/AdvancedScheduling/ui/ActiveContent/store/ActiveAdvancedScheduleStore'
import { WeekDaysSelector } from 'src/widgets/WeekDaysSelector'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { Input } from 'src/shared/ui/Input/Input'
import { ContactsFill } from 'components/Icon'

export const ActiveContent = observer(
  ({ store }: { store: ActiveAdvancedScheduleStore }) => {
    const {
      isFocusOnMount,
      setContactsPerDayActiveInput,
      contactsCount,
      setContactsCount,
      weekDaysSelectorStore,
      startDate,
      setStartDate,
      sendingSpeedStore,
    } = store
    return (
      <>
        <div className={styles.wrap}>
          <Input
            isFocusOnMount={isFocusOnMount}
            toggleFocus={setContactsPerDayActiveInput}
            LeftIcon={ContactsFill}
            isNumber
            value={contactsCount}
            setValue={setContactsCount}
            rightContent={
              <span className={'s2 medium gray1 nowrap'}>contacts per day</span>
            }
          />
          <div className={styles.wrapDaySelector}>
            <WeekDaysSelector store={weekDaysSelectorStore} />
          </div>
          <TimePicker startTime={startDate} onChange={setStartDate} />
        </div>

        <Separator />
        <SendingSpeed store={sendingSpeedStore} />
      </>
    )
  }
)
