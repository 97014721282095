import { makeAutoObservable } from 'mobx'
import { CollectedFieldStore } from '../widgets/CollectedField'
import {
  WorkflowActionRequest,
  WorkflowActionRequestInput,
  WorkflowActionType,
  WorkflowRequest,
} from 'src/generated/graphql'
import { groupBy } from 'lodash'
import { ICollectedFieldActionInput } from 'widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction'

export class CollectedFieldsStore {
  constructor() {
    makeAutoObservable(this)
  }

  private _collectFieldsMap: Map<string, CollectedFieldStore> = new Map()

  get collectFields() {
    return Array.from(this._collectFieldsMap.values())
  }

  get workflowActionRequestInput(): WorkflowActionRequestInput[] {
    return this.collectFields.reduce<WorkflowActionRequestInput[]>(
      (acc, current) => acc.concat(...current.workflowActionRequestInput),
      []
    )
  }

  init = (response: WorkflowRequest) => {
    const groups = groupBy(response.actions, 'groupNumber')
    Object.values(groups).forEach((group, index) => {
      if (index) {
        const sendMessageWorkflow = group.find(
          (action) => action?.type === WorkflowActionType.SendMessage
        )
        const updateContactWorkflow = group.find(
          (action) => action?.type === WorkflowActionType.UpdateContact
        )
        const waitResponseWorkflow = group.find(
          (action) => action?.type === WorkflowActionType.WaitResponse
        )
        const ifElseActions: WorkflowActionRequest[] = group.find(
          (action) => action?.type === WorkflowActionType.IfElse
        )?.properties.elseActions
        const firstElseAction: WorkflowActionRequest = ifElseActions?.[0]

        let actions: ICollectedFieldActionInput[] = []

        if (firstElseAction.type === WorkflowActionType.SendMessage) {
          const elseActionWaitResponse = ifElseActions.find(
            (action) => action?.type === WorkflowActionType.WaitResponse
          )

          actions = [
            {
              waitReplay: waitResponseWorkflow?.properties.hours,
              type: WorkflowActionType.SendMessage,
              message: firstElseAction.properties?.messageConfig,
            },
            {
              type: WorkflowActionType.StopWorkflow,
              waitReplay: elseActionWaitResponse?.properties?.hours,
            },
          ]
        }
        if (firstElseAction.type === WorkflowActionType.StopWorkflow) {
          actions = [
            {
              waitReplay: waitResponseWorkflow?.properties.hours,
              type: WorkflowActionType.StopWorkflow,
            },
          ]
        }

        const newField = new CollectedFieldStore({
          reply: sendMessageWorkflow?.properties?.messageConfig,
          actions,
          field: updateContactWorkflow?.properties.collectedField,
        })
        this._collectFieldsMap.set(newField.id, newField)
      }
    })
  }

  addCollectField = () => {
    const newField = new CollectedFieldStore()
    this._collectFieldsMap.set(newField.id, newField)
  }

  onDuplicate = (id: string) => {
    const field = this._collectFieldsMap.get(id)
    if (field) {
      const newField = new CollectedFieldStore({
        ...field.input,
        field: undefined,
      })
      this._collectFieldsMap.set(newField.id, newField)
    }
  }

  onDelete = (id: string) => {
    this._collectFieldsMap.delete(id)
  }
}
