import { observer } from 'mobx-react-lite'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { useMemo } from 'react'
import {
  FilterCondition,
  FilterDataType,
  FilterFieldType,
} from 'src/generated/graphql'
import { CreateSegmentStore } from 'store/contacts/segment/createSegmentStore'
import { SegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { SegmentOrFilterContent } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/SegmentOrFilterContent'
import {
  filterToIListItem,
  ISimpleFilter,
  ISimpleSegment,
} from 'store/contacts/functions'

export const ListPreview = observer(
  ({ item }: { item: IListItem<ISimpleSegment | ISimpleFilter> }) => {
    const filterItem = filterToIListItem({
      id: 0,
      name: 'Filter',
      filters: [
        {
          field: 'LIST',
          dataType: FilterDataType.List,
          andIndex: 0,
          condition: FilterCondition.In,
          orIndex: 0,
          type: FilterFieldType.Contact,
          value: String(item.id),
        },
      ],
    })
    const store = useMemo(
      () =>
        new CreateSegmentStore({
          isCompact: true,
          isOnlyView: true,
          item: filterItem,
        }),
      [item]
    )
    return (
      <SegmentDetailContext.Provider value={store}>
        <SegmentOrFilterContent />
      </SegmentDetailContext.Provider>
    )
  }
)
