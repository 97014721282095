import styles from './styles.module.scss'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import { PickerList } from 'widgets/ContactsPicker/ui/PickerList/PickerList'
import { Input } from 'shared/ui/Input/Input'
import { Button } from 'shared/ui/Button/Button'
import { Close, Edit, Icon } from 'components/Icon'
import { useContactsPickerContext } from 'widgets/ContactsPicker/context/context'
import { observer } from 'mobx-react-lite'
import { Label } from 'shared/ui/Label/Label'
import { DropdownButton } from 'shared/ui/DropdownButton/DropdownButton'
import { useAddFiltersModalContext } from 'widgets/AddFiltersModal/context/context'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { ISimpleFilter, ISimpleSegment } from 'store/contacts/functions'

export const LeftContent = observer(() => {
  const { setEditItem, setSelectItem, activeItem, onAddFilter, onCancel } =
    useAddFiltersModalContext()
  const { search, setSearch, selectedItems, setContentType, onDeleteItem } =
    useContactsPickerContext()

  const onToggleOpen = (open: boolean) => {
    if (!open) {
      setContentType('all')
      setSearch('')
    }
  }

  const handleSelectItem = (item: IListItem) => {
    setSelectItem(item)
  }

  const handleEditItem = (item: IListItem<ISimpleSegment | ISimpleFilter>) => {
    if (item.group === 'Filters' || item.group === 'Segments') {
      setEditItem(item)
    }
  }

  const handleDeleteItem = (
    item: IListItem<ISimpleSegment | ISimpleFilter>
  ) => {
    if (
      activeItem &&
      `${item.group}_${item.id}` === `${activeItem.group}_${activeItem.id}`
    ) {
      onCancel()
    }

    onDeleteItem(item)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.wrapDropdown}>
        <DropdownField
          width={536}
          isFullWidth
          onToggleOpen={onToggleOpen}
          placement={'bottom-start'}
          dropdownContent={() => <PickerList />}
          triggerContent={() => (
            <Input
              value={search}
              setValue={setSearch}
              size={'medium'}
              placeholder={'Search list, segment or contact'}
            />
          )}
        />
      </div>
      {!!selectedItems.length && (
        <div className={styles.wrapLabel}>
          <Label text={'Selected'} />
        </div>
      )}

      <div className={styles.list}>
        {selectedItems.map((item) => (
          <div key={item.id} className={styles.wrapElement}>
            <DropdownButton
              LeftIcon={item.dropdownButtonProps?.LeftIcon}
              text={item.text}
              count={item.item.activeContactsCount}
              active={
                `${item.group}_${item.id}` ===
                `${activeItem?.group}_${activeItem?.id}`
              }
              textTypographyProps={{
                isEllipsis: true,
                className: styles.dropdownText,
              }}
              fullWidth
              onClick={() => handleSelectItem(item)}
              rightContentProps={{
                showRightContentOnlyByHover: true,
                rightContent: (
                  <>
                    {(item.group === 'Filters' ||
                      item.group === 'Segments') && (
                      <Button
                        variant={'icon'}
                        onClick={(event) => {
                          event.stopPropagation()
                          handleEditItem(item)
                        }}
                      >
                        <Icon>
                          <Edit />
                        </Icon>
                      </Button>
                    )}

                    <Button
                      variant={'icon'}
                      onClick={(event) => {
                        event.stopPropagation()
                        handleDeleteItem(item)
                      }}
                    >
                      <Icon>
                        <Close />
                      </Icon>
                    </Button>
                  </>
                ),
              }}
            />
          </div>
        ))}
        <div className={styles.wrapAdd}>
          <Button variant={'add'} text={'Add filter'} onClick={onAddFilter} />
        </div>
      </div>
    </div>
  )
})
