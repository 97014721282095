// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._HN2YrrUMG8NilGHMREx{height:fit-content;z-index:101;background-color:var(--primary-color-white);border-radius:20px;box-shadow:var(--shadow-1)}@media(max-width: 800px){._HN2YrrUMG8NilGHMREx{width:100%}}.E4KbjRJlyGBNmD2F9ycu{width:100%;display:flex;flex-direction:column;gap:8px}`, "",{"version":3,"sources":["webpack://./src/components/Modal/variants/NotificationModal/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,WAAA,CAGA,2CAAA,CAGA,kBAAA,CACA,0BAAA,CAGA,yBAZF,sBAaI,UAAA,CAAA,CAOJ,sBACE,UAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".wrap{\n  height: fit-content;\n  z-index: 101;\n  //min-width: 800px;\n  //position: absolute;\n  background-color: var(--primary-color-white);\n  //top: 120px;\n  //left: 50%;\n  border-radius: 20px;\n  box-shadow: var(--shadow-1);\n  //transform: translateX(-50%);\n\n  @media (max-width: 800px) {\n    width: 100%;\n  }\n  @media (max-height: 600px) {\n    //width: 100%;\n  }\n}\n\n.actions{\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `_HN2YrrUMG8NilGHMREx`,
	"actions": `E4KbjRJlyGBNmD2F9ycu`
};
export default ___CSS_LOADER_EXPORT___;
