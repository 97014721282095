import { observer } from 'mobx-react-lite'
import { useSegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import styles from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { ReactComponent as Plus } from 'icons/16px/Plus.svg'
import { SegmentFilterComponent } from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/SegmentFilter'
import { Filter } from 'store/contacts/segment/types'
import { EmptyFilter } from 'store/contacts/segment/filters/EmptyFilter'

export const FilterGroup = observer(
  ({
    filters,
    orIndex,
  }: {
    filters: Array<Filter | EmptyFilter>
    orIndex: number
  }) => {
    const { isOnlyView, addFiltersGroup, setFilter } = useSegmentDetailContext()
    const onOr = () => {
      addFiltersGroup(orIndex + 1)
    }
    const onAnd = () => {
      const newFilter = new EmptyFilter({
        orIndex: orIndex,
        andIndex: filters.length + 1,
      })
      setFilter(newFilter)
    }
    return (
      <div className={styles.card}>
        <div className={styles.filtersWrap}>
          {filters.map((filter) => (
            <SegmentFilterComponent
              key={`${filter.orIndex}and${filter.andIndex}`}
              filter={filter}
            />
          ))}
        </div>

        {!isOnlyView && (
          <div className={styles.cardActions}>
            <Button
              size={BtnSize.small}
              typeBtn={BtnType.secondaryGray}
              p12
              onClick={onAnd}
            >
              <Plus />
              And
            </Button>
            <Button
              size={BtnSize.small}
              typeBtn={BtnType.secondaryGray}
              p12
              onClick={onOr}
            >
              <Plus />
              Or
            </Button>
          </div>
        )}
      </div>
    )
  }
)
