// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D1i5TF40TyjXsMb0dzxv{display:flex;justify-content:center;padding:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/ContactsPicker/ui/TopContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,WAAA","sourcesContent":[".wrap{\n  display: flex;\n  justify-content: center;\n  padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `D1i5TF40TyjXsMb0dzxv`
};
export default ___CSS_LOADER_EXPORT___;
