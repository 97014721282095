import { Icon } from 'components/Icon/Icon'
import { Typography } from 'shared/ui/Typography'
import { DetailRow } from 'components/DetailsModalContent/DetailRow/DetailRow'
import styles from './styles.module.scss'
import { ConversionResponse } from 'src/pages/settings/conversions/conversions/store/type'
import { Conversion, Link } from 'components/Icon'

export const ConversionContent = ({
  conversion,
}: {
  conversion: ConversionResponse
}) => {
  return (
    <DetailRow
      left={{
        Icon: Conversion,
        text: 'Track conv.',
      }}
      rightContent={
        <div className={styles.wrap}>
          <div className={styles.row}>
            <Icon color={'--primary-color-gray-1'}>
              <Link />
            </Icon>
            <Typography>{conversion?.name}</Typography>
          </div>
          {/*<Typography className={styles.url} color={'--primary-color-gray-1'}>*/}
          {/*  {conversion?.urlPatterns}*/}
          {/*</Typography>*/}
        </div>
      }
    />
  )
}
