import { IBroadcast, IBroadcastFull } from 'src/types/IBroadcast'
import {
  BroadcastFullResponseFragment,
  BroadcastResponseFragment,
} from 'src/generated/graphql'
import { ContactsPickerStore } from 'widgets/ContactsPicker'

export const transformBroadcast = (
  broadcast: BroadcastResponseFragment
): IBroadcast => {
  const count =
    broadcast.contactCount +
    broadcast.listCount +
    broadcast.segmentCount +
    broadcast.filterCount -
    1
  const contactsPickerStore = new ContactsPickerStore({})
  if (broadcast.firstItemResponse) {
    contactsPickerStore.initByFirstItem(broadcast.firstItemResponse, count)
  }

  return {
    ...broadcast,
    id: String(broadcast.id),
    contactsPickerStore: contactsPickerStore,
    sendFromPhoneNumber: {
      id: broadcast.sendFromPhoneNumber?.id,
      phoneNumber: broadcast.sendFromPhoneNumber?.phoneNumber,
      friendlyName: broadcast.sendFromPhoneNumber?.friendlyName,
      verificationStatus: broadcast.sendFromPhoneNumber?.verificationStatus,
    },
  }
}

export const transformFullBroadcast = (
  broadcast: BroadcastFullResponseFragment
): IBroadcastFull => {
  const contactsPickerStore = new ContactsPickerStore({})
  contactsPickerStore.setInitItems(broadcast)

  return {
    ...broadcast,
    id: String(broadcast.id),
    contactsPickerStore,
    sendFromPhoneNumber: {
      ...broadcast.sendFromPhoneNumber,
      id: broadcast.sendFromPhoneNumber?.id,
    },
  }
}
