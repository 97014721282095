import styles from './styles.module.scss'
import {
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
} from 'react'

export type IIconBtnProps = PropsWithChildren<{
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  variant: 'icon'
}>

export const IconBtn = forwardRef(function xSmall(
  { onClick, children }: IIconBtnProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <button ref={ref} onClick={onClick} className={styles.icon}>
      {children}
    </button>
  )
})
