// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nnVDXkImaENm3L0v6ySr{display:flex;width:100%;gap:8px;height:24px;align-items:center}.Z4m20h0N3oenxda7RS6I{width:100%;text-align:left}`, "",{"version":3,"sources":["webpack://./src/widgets/PersonalizeSelect/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,OAAA,CACA,WAAA,CACA,kBAAA,CAIF,sBACE,UAAA,CACA,eAAA","sourcesContent":[".trigger{\n  display: flex;\n  width: 100%;\n  gap: 8px;\n  height: 24px;\n  align-items: center;\n\n}\n\n.text{\n  width: 100%;\n  text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trigger": `nnVDXkImaENm3L0v6ySr`,
	"text": `Z4m20h0N3oenxda7RS6I`
};
export default ___CSS_LOADER_EXPORT___;
