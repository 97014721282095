import { makeAutoObservable } from 'mobx'
import { IModal } from 'src/widgets/ModalContainer/store/types'

class ModalStore {
  constructor() {
    makeAutoObservable(this)
  }

  modalsMap = new Map<string, IModal>()

  get modals() {
    return Array.from(this.modalsMap.values())
  }

  addModal = (modal: IModal) => {
    this.modalsMap.set(modal.id, modal)
  }

  removeModal = (id: string) => {
    this.modalsMap.delete(id)
  }

  closeModal = (id: string) => {
    const modal = this.modalsMap.get(id)
    if (modal && 'onClose' in modal && modal?.onClose) {
      modal?.onClose()
    } else {
      this.removeModal(id)
    }
  }
}

export default new ModalStore()
