import React, {
  ButtonHTMLAttributes,
  FC,
  ForwardedRef,
  forwardRef,
  MouseEventHandler,
  ReactNode,
  useState,
} from 'react'
import { Icon } from 'components/Icon/Icon'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { Loader } from 'components/Loader/Loader'
import { NewLabel } from 'shared/ui/NewLabel/NewLabel'

export type IDefaultBtnProps = {
  variant:
    | 'primary-medium'
    | 'primary-small'
    | 'secondary-medium'
    | 'quaternary-medium'
    | 'tertiary-small'
    | 'inactive-small'
    | 'inverted-mini'
  text?: ReactNode
  LeftIcon?: FC
  loading?: boolean
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  className?: ButtonHTMLAttributes<HTMLButtonElement>['className']
  disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled']
  advanced?: boolean
  fullWidth?: boolean
  active?: boolean
  isNew?: boolean
  withUiLoading?: boolean
}

export const DefaultBtn = forwardRef(function ForwardedDefaultBtn(
  {
    variant,
    LeftIcon,
    text,
    onClick,
    className,
    loading: loadingProps,
    advanced,
    fullWidth,
    active,
    withUiLoading,
    isNew,
    ...props
  }: IDefaultBtnProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const [uiLoading, setUiLoading] = useState(false)

  const loading = loadingProps || uiLoading

  const type = variant?.split('-')[0]
  const size = variant?.split('-')[1]

  const getIndeterminateColor = () => {
    if (type === 'primary') {
      return '--primary-color-white'
    }
    if (type === 'secondary') {
      return '--primary-color-gray-2'
    }
  }

  const getLeftIconContent = () => {
    if (size === 'mini' && loading) {
      return (
        <Loader
          size={12}
          indeterminateColor={'--primary-color-white'}
          determinateColor={'--primary-color-gray-1'}
        />
      )
    }
    if (LeftIcon) {
      return (
        <Icon
          fontSize={size === 'mini' && text ? 12 : undefined}
          className={styles.icon}
        >
          <LeftIcon />
        </Icon>
      )
    }
  }

  const handleClick: MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (loading) return
    if (withUiLoading) {
      try {
        setUiLoading(true)
        await onClick?.(e)
        setUiLoading(false)
      } catch (e) {
        console.error(e)
      }
    } else {
      onClick?.(e)
    }
  }

  return (
    <button
      onClick={handleClick}
      className={classNames(
        styles.wrap,
        {
          [styles[`type__${type}`]]: type,
          [styles[`size__${size}`]]: size,
          [styles.advanced]: advanced,
          [styles.fullWidth]: fullWidth,
          [styles.loadingState]: loading,
          [styles.active]: active,
        },
        className
      )}
      {...props}
      ref={ref}
    >
      {loading && size !== 'mini' && (
        <div className={styles.loading}>
          <Loader size={16} indeterminateColor={getIndeterminateColor()} />
        </div>
      )}
      {getLeftIconContent()}
      {text && (
        <Typography
          variant={size === 'mini' ? 's1-regular' : 's1-bold'}
          isUnsetColor
          className={styles.text}
        >
          {text}
        </Typography>
      )}
      {isNew && <NewLabel isPoint={size === 'small'} />}
    </button>
  )
})
