import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { ISimpleFilter, ISimpleSegment } from 'store/contacts/functions'
import { observer } from 'mobx-react-lite'
import { ContactPreview } from 'widgets/ContactPreview'
import { ViewSegmentOrFilter } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/SegmentOrFilter/ViewSegmentOrFilter'
import { ListPreview } from 'widgets/AddFiltersModal/ui/Include/RightContent/ui/ListPreview/ListPreview'

export const ViewItem = observer(
  ({ item }: { item: IListItem<ISimpleSegment | ISimpleFilter> }) => {
    if (item.group === 'Filters' || item.group === 'Segments') {
      return <ViewSegmentOrFilter item={item} />
    }
    if (item.group === 'Contacts') {
      return <ContactPreview id={item.id} />
    }
    if (item.group === 'Lists') {
      return <ListPreview item={item} />
    }
  }
)
