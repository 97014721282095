import { makeAutoObservable } from 'mobx'
import { nanoid } from 'nanoid'
import { PersonalizeSelectStore } from 'widgets/PersonalizeSelect/store/PersonalizeSelectStore'
import { TextareaStore } from 'store/textareaStore'
import {
  CustomFieldType,
  FieldCaptureFragment,
  FieldCaptureInput,
} from 'src/generated/graphql'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { ICustomField } from 'src/types/ICustomField'

export class CaptureFieldStore {
  personalizeSelectStore
  textareaStore
  constructor() {
    this.personalizeSelectStore = new PersonalizeSelectStore({
      excludeIds: ['phoneNumber'],
      onSelectItem: this.onSelectPersonalizeItem,
      disabledTypes: [CustomFieldType.DateTime],
    })

    this.textareaStore = new TextareaStore()
    makeAutoObservable(this)
  }
  init = (fieldCapture: FieldCaptureFragment) => {
    this.index = fieldCapture.index
    if (fieldCapture.fieldName) {
      this.personalizeSelectStore.setItemId(fieldCapture.fieldName)
    }
    if (fieldCapture.smsConfig) {
      this.textareaStore.setSmsMessage(fieldCapture.smsConfig)
    }
  }
  id = nanoid()
  index = 0

  setIndex = (index: number) => {
    this.index = index
  }

  onSelectPersonalizeItem = (item: IListItem<ICustomField>) => {
    if (item.id === 'firstName') {
      this.textareaStore.setText("What's your first name?")
    }
    if (item.id === 'lastName') {
      this.textareaStore.setText("What's your last name?")
    }
    if (item.id === 'email') {
      this.textareaStore.setText("What's your best email?")
    }
    setTimeout(() => {
      this.textareaStore.setFocus()
    })
  }
  get fieldCaptureInput(): FieldCaptureInput {
    return {
      fieldName: this.personalizeSelectStore.itemId,
      index: this.index,
      smsConfig: this.textareaStore.workflowSmsConfigInput,
    }
  }
  get disabledCreate() {
    if (!this.fieldCaptureInput.fieldName) {
      return true
    }
    if (!this.textareaStore.smsMessageInput.text) {
      return true
    }
    if (this.textareaStore.isError) {
      return true
    }
  }
}
