import styles from './styles.module.scss'
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'
import { ReactNode } from 'react'
import { Alert, Check, Info1, Tick } from 'components/Icon'

type Props = {
  type?:
    | 'info'
    | 'success'
    | 'warning'
    | 'upgrade'
    | 'infoWarning1'
    | 'infoError1'
  icon?: ReactNode
  fontSize?: number
}

export const ModalIcon = ({ type = 'success', icon, fontSize }: Props) => {
  const getIcon = (): ReactNode => {
    if (type === 'success') {
      return <Check />
    }
    if (type === 'warning') {
      return <Alert />
    }
    if (type === 'upgrade') {
      return <Tick />
    }
    return <Info1 />
  }

  const getFontSize = () => {
    if (type === 'upgrade') {
      return 32
    }
  }
  return (
    <div className={classNames(styles.iconWrap, styles[type])}>
      <div className={classNames(styles.icon, styles[type])}>
        <Icon fontSize={fontSize || getFontSize()}>{icon || getIcon()}</Icon>
      </div>
    </div>
  )
}
