import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import {
  DefaultModal,
  IDefaultModalProps,
} from 'components/Modal/variants/DefaultModal/DefaultModal'
import {
  INotificationModalProps,
  NotificationModal,
} from 'components/Modal/variants/NotificationModal/NotificationModal'

export type IModalProps = IDefaultModalProps | INotificationModalProps

export const Modal: FC<IModalProps> = observer((props) => {
  if (props.variant === 'notification') {
    return <NotificationModal {...props} />
  }
  return <DefaultModal {...props} />
})
