import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as More } from 'icons/16pxNoMask/More.svg'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { ClickAwayListener } from '@mui/material'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { SmallBtn } from 'components/Button/SmallBtn'
import { observer } from 'mobx-react-lite'
import { ActionsEnum } from 'components/Button/types'
import { useWebFormFunctions } from 'src/pages/main/webForms/hooks/useWebFormFunctions'
import { Status } from 'src/generated/graphql'
import { IWebForm } from 'src/types/IWebForm'

export const HoverRowComponent = observer(
  ({ row, setHover }: { row: IWebForm; setHover: (val: boolean) => void }) => {
    const { onEdit, onDuplicate, onDelete, onTestWebForm, onGetCodeWebForm } =
      useWebFormFunctions(row)

    const [open, setOpen] = useState(false)
    const handleTooltipClose = () => {
      setOpen(false)
    }

    useEffect(() => {
      setHover(open)
    }, [open])

    return (
      <>
        <SmallBtn
          type={ActionsEnum.testWebWorm}
          onClick={() => onTestWebForm()}
        />
        {row.status !== Status.Draft && (
          <>
            <SmallBtn type={ActionsEnum.getCode} onClick={onGetCodeWebForm} />
            <SmallBtn type={ActionsEnum.edit} onClick={onEdit} />
            <MuiTooltip title={'More'} placement={'top'} arrow>
              <div>
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <MuiTooltip
                      title={
                        <>
                          <MenuBtn
                            type={MenuEnum.duplicate}
                            onClick={onDuplicate}
                          />
                          {/*<MenuBtn type={MenuEnum.analytics} />*/}
                          <MenuBtn
                            type={MenuEnum.delete}
                            onClick={() => onDelete([row.id])}
                          />
                        </>
                      }
                      placement={'bottom-end'}
                      open={open}
                      onClose={handleTooltipClose}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      white
                      width={148}
                    >
                      <div>
                        <Button
                          size={BtnSize.small}
                          hoverGray2
                          onClick={() => setOpen(!open)}
                          active={open}
                        >
                          <More />
                        </Button>
                      </div>
                    </MuiTooltip>
                  </div>
                </ClickAwayListener>
              </div>
            </MuiTooltip>
          </>
        )}

        {row.status === Status.Draft && (
          <>
            <SmallBtn type={ActionsEnum.edit} onClick={onEdit} />
            <SmallBtn type={ActionsEnum.duplicate} onClick={onDuplicate} />
            <SmallBtn
              type={ActionsEnum.delete}
              onClick={() => onDelete([row.id])}
            />
          </>
        )}
      </>
    )
  }
)
