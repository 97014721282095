import styles from './styles.module.scss'
import React, { useState } from 'react'
import { Button } from 'shared/ui/Button/Button'
import { TriggerTooltip } from 'shared/ui/TriggerTooltip/TriggerTooltip'
import { ShortenLinkTriggerTooltip } from 'components/Textarea/ShortenLink/ShortenLinkTriggerTooltip/ShortenLinkTriggerTooltip'
import { useTextareaContext } from 'components/Textarea/context/context'
import { Edit, Link } from 'components/Icon'

type ShortenLinkTooltipProps = {
  currentHoverLink: HTMLAnchorElement | undefined
  onClickShortenLink: (link?: string) => Promise<void>
  refHoverLink: React.MutableRefObject<boolean>
  setCurrentHoverLink: React.Dispatch<
    React.SetStateAction<HTMLAnchorElement | undefined>
  >
}

export const ShortenLinkTooltip = ({
  currentHoverLink,
  onClickShortenLink,
  refHoverLink,
  setCurrentHoverLink,
}: ShortenLinkTooltipProps) => {
  const textareaStore = useTextareaContext()
  const [loading, setLoading] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    await onClickShortenLink()
    setLoading(false)
  }

  const onLeaveTooltip = () => {
    if (!loading) {
      refHoverLink.current = false
      setCurrentHoverLink(undefined)
    }
  }

  const handleEdit = () => {
    setEditVisible(true)
  }

  const handleEditVisibleFromEdit = (value: boolean) => {
    if (!value) {
      refHoverLink.current = false
      setCurrentHoverLink(undefined)
    }
    setEditVisible(value)
  }

  const onAddShortenLink = (link: string) => {
    return onClickShortenLink(link)
  }

  return (
    <>
      <ShortenLinkTriggerTooltip
        parent={textareaStore.textFieldElement}
        initLink={currentHoverLink?.getAttribute('href') || ''}
        onAddShortenLink={onAddShortenLink}
        visible={editVisible}
        setVisible={handleEditVisibleFromEdit}
        trigger={currentHoverLink}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-32, 8],
            },
          },
        ]}
      />
      <TriggerTooltip
        visible={editVisible ? false : undefined}
        trigger={currentHoverLink}
        onLeaveTooltip={onLeaveTooltip}
        refVisibleTooltip={refHoverLink}
        color={'black'}
        component={
          <div className={styles.wrap}>
            <Button
              variant={'inverted-mini'}
              LeftIcon={Link}
              text={'Shorten link'}
              loading={loading}
              onClick={handleClick}
            />
            <Button
              variant={'inverted-mini'}
              LeftIcon={Edit}
              onClick={handleEdit}
            />
          </div>
        }
        arrow
      />
    </>
  )
}
