import { AccountIntegrationType } from 'src/generated/graphql'
import { ReactNode } from 'react'
import {
  CampaignLogo,
  Drip,
  HubSpotLogo,
  KeapLogo,
  Plus,
  Zapier,
} from 'components/Icon'

export const getAccountIntegrationTypeIIcon = (
  type: AccountIntegrationType
): ReactNode => {
  const obj: { [key in AccountIntegrationType]: ReactNode } = {
    [AccountIntegrationType.Custom]: <Plus />,
    [AccountIntegrationType.ActiveCampaign]: <CampaignLogo />,
    [AccountIntegrationType.HubSpot]: <HubSpotLogo />,
    [AccountIntegrationType.Keap]: <KeapLogo />,
    [AccountIntegrationType.Drip]: <Drip />,
    [AccountIntegrationType.Zapier]: <Zapier />,
    [AccountIntegrationType.LeadConnector]: <Plus />,
  }
  return obj[type]
}
