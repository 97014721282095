import styles from './styles.module.scss'
import React from 'react'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import { TTPositionEnum } from 'components/Tooltip/FixedTooltip/FixedTooltip'
import {
  Status,
  useCreateKeywordMutation,
  useUpdateKeywordMutation,
} from 'src/generated/graphql'
import { useCreateKeywordContext } from 'src/pages/main/keywords/create/CreateKeywordState'
import keywordsStore from 'store/keywords/keywordsStore'
import classNames from 'classnames'
import { CloseCreateBtn } from 'components/Page/components/CloseCreateBtn'
import { useApolloError } from 'src/hooks/useApolloError'
import { Button } from 'shared/ui/Button/Button'
import { ShortLinkRequiredStore } from 'widgets/ShortLinkRequiredModal/ShortLinkRequiredStore'
import { KeywordTestBtn } from 'src/pages/main/keywords/actions/KeywordTestBtn'
import { Actions } from 'components/Actions/Actions'
import { KeywordDeleteBtn } from 'src/pages/main/keywords/actions/KeywordDeleteBtn'
import { IKeyword } from 'src/types/IKeyword'

export const ActionsContent = observer(
  ({ fromModal }: { fromModal?: boolean }) => {
    const [createKeywordNutation, { error: errorCreate }] =
      useCreateKeywordMutation()
    const [updateKeywordNutation, { error: errorUpdate }] =
      useUpdateKeywordMutation()
    const createKeywordStore = useCreateKeywordContext()

    const navigate = useNavigate()

    const { ErrorAlert } = useApolloError(errorCreate || errorUpdate)

    const navigateToKeywords = () => {
      uiStore.setNextRoute('')
      navigate(RoutesEnum.keywords)
    }

    const onCreate = async (isDraft?: boolean) => {
      try {
        await (createKeywordStore.edit
          ? updateKeywordNutation
          : createKeywordNutation)({
          variables: {
            keywordInput: {
              ...createKeywordStore.keywordInput,
              status:
                (isDraft && Status.Draft) ||
                (createKeywordStore.activeKeyword
                  ? Status.Active
                  : Status.Disabled),
            },
          },
        })
        keywordsStore.tableStore.onRefresh()

        if (fromModal) {
          keywordsStore.keywordModalCB &&
            keywordsStore.keywordModalCB(
              createKeywordStore.dropdownContactListsStore.selectedOptions
            )
          keywordsStore.setOpenKeywordModal(false)
        } else {
          navigateToKeywords()
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onSaveDraft = async () => {
      return onCreate(true)
    }

    const handleCreate = () => {
      if (createKeywordStore.checkIsShowShortLinkRequiredModal()) {
        return new ShortLinkRequiredStore(onSaveDraft)
      } else {
        return onCreate()
      }
    }

    const onCancel = () => {
      if (fromModal) {
        keywordsStore.setOpenKeywordModal(false)
      } else {
        if (uiStore.isBeforeUnload) {
          uiStore.setOpenCancelCreateModal(true)
        } else {
          navigate(-1)
        }
      }
    }

    return (
      <div className={classNames(styles.wrap, fromModal && styles.fromModal)}>
        <ErrorAlert />
        <div className={classNames(styles.actionsWrap)}>
          <div className={styles.left}>
            <Button
              variant={'primary-medium'}
              onClick={handleCreate}
              disabled={createKeywordStore.disabledCreate}
              withUiLoading
              text={
                createKeywordStore.edit
                  ? createKeywordStore.keywordInput.status === Status.Draft
                    ? 'Create keyword'
                    : 'Save'
                  : createKeywordStore.createBtnText
              }
            />
            {createKeywordStore.edit &&
            createKeywordStore.keywordInput.status === Status.Draft ? (
              <Button
                variant={'secondary-medium'}
                onClick={onSaveDraft}
                withUiLoading
                text={'Update draft'}
              />
            ) : (
              <CloseCreateBtn onCancel={onCancel} fromActions />
            )}
          </div>

          {createKeywordStore.edit && !createKeywordStore.openTest ? (
            <Actions<IKeyword>
              actions={[
                () => (
                  <KeywordTestBtn
                    phonesProps={createKeywordStore.phones}
                    isNeedSave={
                      !createKeywordStore.edit ||
                      createKeywordStore.isHaveChanges
                    }
                    status={createKeywordStore.status}
                    menu
                  />
                ),
                (props) => (
                  <KeywordDeleteBtn
                    {...props}
                    id={createKeywordStore.editId}
                    onSuccessAction={() => navigate(RoutesEnum.keywords)}
                  />
                ),
              ]}
              visibleActionsCount={0}
              position={TTPositionEnum.topRight}
            />
          ) : (
            <>
              <KeywordTestBtn
                fromCreate
                phonesProps={createKeywordStore.phones}
                isNeedSave={
                  !createKeywordStore.edit || createKeywordStore.isHaveChanges
                }
                status={createKeywordStore.status}
              />
            </>
          )}
        </div>
      </div>
    )
  }
)
