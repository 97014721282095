import { makeAutoObservable } from 'mobx'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import {
  contactListToIListItem,
  contactToIListItem,
  filterToIListItem,
  ISimpleContact,
  ISimpleContactList,
  ISimpleFilter,
  ISimpleSegment,
  segmentToIListItem,
} from 'store/contacts/functions'
import { Maybe } from 'graphql/jsutils/Maybe'
import { AddFiltersModalStore } from 'widgets/AddFiltersModal'
import {
  BroadcastFirstItemResponse,
  FilterFragment,
  FilterInput,
  FilterValueInput,
} from 'src/generated/graphql'
import { nanoid } from 'nanoid'

export class ContactsPickerStore {
  withoutAddFilters = false
  disabledCloseDropdown = false
  constructor({
    withoutAddFilters,
    disabledCloseDropdown,
  }: {
    withoutAddFilters?: boolean
    disabledCloseDropdown?: boolean
  }) {
    this.withoutAddFilters = withoutAddFilters || false
    this.disabledCloseDropdown = disabledCloseDropdown || false
    makeAutoObservable(this)
  }

  contentType: 'all' | 'lists' | 'contacts' | 'segments' = 'all'
  setContentType = (type: typeof this.contentType) => {
    this.contentType = type
  }
  search = ''
  setSearch = (value: string) => {
    this.search = value
  }

  selectedItemsMap: Map<string, IListItem> = new Map()
  setSelectedItemsMap = (map: Map<string, IListItem>) => {
    this.selectedItemsMap = map
  }
  get selectedItems() {
    return Array.from(this.selectedItemsMap.values())
  }
  visibleItemsMap: Map<string, boolean> = new Map()
  get lastVisibleId() {
    let lastVisibleId = ''
    this.visibleItemsMap.forEach((value, key) => {
      if (value) {
        lastVisibleId = key
      }
    })
    return lastVisibleId
  }
  get invisibleItems() {
    const items: IListItem[] = []
    this.visibleItemsMap.forEach((value, key) => {
      if (!value) {
        const item = this.selectedItemsMap.get(key)
        if (item) {
          items.push(item)
        }
      }
    })
    return items
  }

  get selectedContactListsItems() {
    return this.selectedItems.filter((item) => item.group === 'Lists')
  }
  get selectedContactListsIds() {
    return this.selectedContactListsItems.map((item) => item.id)
  }

  get selectedContactsItems() {
    return this.selectedItems.filter((item) => item.group === 'Contacts')
  }
  get selectedContactsIds() {
    return this.selectedContactsItems.map((item) => item.id)
  }
  get selectedSegmentsItems() {
    return this.selectedItems.filter((item) => item.group === 'Segments')
  }
  get selectedSegmentsIds() {
    return this.selectedSegmentsItems.map((item) => item.id)
  }

  get sourceFilters() {
    const filters: FilterInput[][] = []
    const filtersListItems: IListItem<ISimpleFilter>[] =
      this.selectedItems.filter((item) => item.group === 'Filters')
    filtersListItems.map((item) => {
      if (item.item?.filters) {
        filters.push(item.item?.filters)
      }
    })
    return filters
  }

  get isEmpty() {
    return !this.selectedItemsMap.size
  }

  get filterValue(): FilterValueInput {
    return {
      listIds: this.selectedContactListsIds,
      contactIds: this.selectedContactsIds,
      segmentIds: this.selectedSegmentsIds,
      sourceFilters: this.sourceFilters,
    }
  }

  onSelectItem = (id: any, item: IListItem) => {
    this.setSearch('')
    this.selectedItemsMap.set(`${item.group}_${id}`, item)
    this.visibleItemsMap.set(`${item.group}_${id}`, true)
  }

  onDeleteItem = (item: IListItem) => {
    this.selectedItemsMap.delete(`${item.group}_${item.id}`)
  }

  setInitItems = ({
    lists,
    segments,
    contacts,
    filterValue,
  }: {
    lists?: Maybe<ISimpleContactList>[] | null
    segments?: Maybe<ISimpleSegment>[] | null
    contacts?: Maybe<ISimpleContact>[] | null
    filterValue?: {
      sourceFilters?: Maybe<Maybe<FilterFragment>[]>[] | null
    } | null
  }) => {
    if (lists) {
      lists.forEach((list) => {
        if (list) {
          this.onSelectItem(list.id, contactListToIListItem(list))
        }
      })
    }
    if (segments) {
      segments.forEach((segment) => {
        if (segment) {
          this.onSelectItem(segment.id, segmentToIListItem(segment))
        }
      })
    }
    if (contacts) {
      contacts.forEach((contact) => {
        if (contact) {
          this.onSelectItem(contact.id, contactToIListItem(contact))
        }
      })
    }
    if (filterValue) {
      filterValue.sourceFilters?.map((filters, index) => {
        if (filters) {
          const filteredFilters: FilterFragment[] = []
          filters.forEach((item) => {
            if (item) {
              filteredFilters.push(item)
            }
          })
          const newItem = filterToIListItem({
            id: nanoid(),
            name: `Filter ${index + 1}`,
            filters: filteredFilters,
          })
          this.onSelectItem(newItem.id, newItem)
        }
      })
    }
  }

  count = 0
  initByFirstItem = (
    firstItemResponse?: BroadcastFirstItemResponse,
    count?: number
  ) => {
    this.count = count || 0
    if (firstItemResponse?.type === 'CONTACT') {
      this.onSelectItem(
        firstItemResponse.id,
        contactToIListItem(firstItemResponse)
      )
    }
    if (firstItemResponse?.type === 'LIST') {
      this.onSelectItem(
        firstItemResponse.id,
        contactListToIListItem(firstItemResponse)
      )
    }
    if (firstItemResponse?.type === 'SEGMENT') {
      this.onSelectItem(
        firstItemResponse.id,
        segmentToIListItem(firstItemResponse)
      )
    }
    if (firstItemResponse?.type === 'FILTER') {
      const newItem = filterToIListItem({
        id: nanoid(),
        name: `Filter 1`,
        filters: [],
      })
      this.onSelectItem(newItem.id, newItem)
    }
  }

  onAddNewFilters = () => {
    new AddFiltersModalStore(this)
  }
}
