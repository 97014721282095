// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ooZSM69HjPt1RQSRRyfL{display:flex;flex-direction:column;flex-grow:1;overflow:auto;position:relative}.LvdY2KjFqrTu7F6AeWwN{top:0}`, "",{"version":3,"sources":["webpack://./src/widgets/AddFiltersModal/ui/Contacts/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,aAAA,CACA,iBAAA,CAGF,sBACE,KAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  overflow: auto;\n  position: relative;\n}\n\n.overrideWrapStickyClass{\n  top: 0\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `ooZSM69HjPt1RQSRRyfL`,
	"overrideWrapStickyClass": `LvdY2KjFqrTu7F6AeWwN`
};
export default ___CSS_LOADER_EXPORT___;
