import styles from './styles.module.scss'
import classNames from 'classnames'
import { Button } from 'components/Button/Button'
import { CSSProperties, ReactNode } from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { NewLabel } from 'shared/ui/NewLabel/NewLabel'

export type ITabItem<T> = { id: T; label: string | ReactNode; isNew?: boolean }

type Props<T> = {
  tabs?: Array<string | ReactNode>
  tabsItems?: Array<ITabItem<T>>
  activeTabIndex?: number
  activeTabId?: T
  onTabClick: (index: number, item: ITabItem<T>) => void
  mb?: number
  comingSoonTabs?: number[]
  paddingInline?: CSSProperties['paddingInline']
  className?: string
}

export const Tabs = <T,>({
  tabs,
  tabsItems,
  activeTabIndex,
  mb,
  onTabClick,
  comingSoonTabs,
  paddingInline,
  activeTabId,
  className,
}: Props<T>) => {
  const renderTabs: Array<ITabItem<T>> =
    tabsItems || tabs?.map((tab) => ({ id: tab as T, label: tab })) || []
  return (
    <div
      className={classNames(styles.wrap, className)}
      style={{ marginBottom: mb, paddingInline }}
    >
      {renderTabs.map((item, index) => (
        <MuiTooltip
          key={index}
          title={
            (comingSoonTabs &&
              comingSoonTabs.includes(index) &&
              'Coming soon') ||
            ''
          }
          arrow
          placement={'top'}
        >
          <div className={styles.wrapBtn}>
            <Button
              key={index}
              className={classNames(
                styles.tab,
                (index === activeTabIndex || activeTabId === item.id) &&
                  styles.active
              )}
              onClick={() => onTabClick(index, item)}
              disabled={comingSoonTabs && comingSoonTabs.includes(index)}
            >
              {item.label}
              {item.isNew && <NewLabel />}
            </Button>
          </div>
        </MuiTooltip>
      ))}
      <div className={styles.bottomBorder} />
    </div>
  )
}
