import styles from './styles.module.scss'
import { MouseEventHandler, PropsWithChildren } from 'react'
import classNames from 'classnames'

type IOverflowProps = PropsWithChildren<{
  onOverlayClick: MouseEventHandler<HTMLDivElement>
  zIndex?: number
  isCenter?: boolean
}>

export const Overflow = ({
  onOverlayClick,
  children,
  zIndex,
  isCenter,
}: IOverflowProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classNames(styles.wrap, { [styles.center]: isCenter })}
      onClick={onOverlayClick}
      style={{ zIndex }}
    >
      {children}
    </div>
  )
}
