import parse from 'html-react-parser'
import customFieldsStore from 'store/contacts/customFields/customFieldsStore'
import { AccountIntegrationType } from 'src/generated/graphql'
import triggersStore from 'store/triggers/triggersStore'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { replaceLink } from 'src/util/replaceLink'

export function bytesToSize(bytes: number) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes == 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}

export const replaceSpace = (str: string) => str.replace(/&nbsp;/g, ' ')

export function placeCaretAtEnd(el: HTMLDivElement | Node) {
  if (el instanceof HTMLDivElement) {
    el.focus()
  }
  if (
    typeof window.getSelection != 'undefined' &&
    typeof document.createRange != 'undefined'
  ) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(false)
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}

export function placeCaretAtStart(el: HTMLDivElement | Node) {
  if (el instanceof HTMLDivElement) {
    el.focus()
  }
  if (
    typeof window.getSelection != 'undefined' &&
    typeof document.createRange != 'undefined'
  ) {
    const range = document.createRange()
    range.selectNodeContents(el)
    range.collapse(true)
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}

export function getCaretIndex(element: HTMLDivElement): {
  offset: number
  focusNode: Node | null | undefined
} {
  let position = 0
  const isSupported = typeof window.getSelection !== 'undefined'
  if (isSupported) {
    const selection = window.getSelection()
    if (selection?.rangeCount !== 0) {
      const range = window.getSelection()?.getRangeAt(0)
      if (!range) {
        return {
          offset: 0,
          focusNode: null,
        }
      }
      const preCaretRange = range.cloneRange()
      preCaretRange.selectNodeContents(element)
      preCaretRange.setEnd(range.endContainer, range.endOffset)
      position = preCaretRange.toString().length
      return {
        offset:
          selection?.focusNode?.nodeName === 'DIV'
            ? position
            : selection?.focusOffset || 0,
        focusNode: selection?.focusNode,
      }
    }
  }
  return {
    offset: 0,
    focusNode: null,
  }
}
export function placeCaretAtPosition(node: Node, position: number) {
  if (
    typeof window.getSelection != 'undefined' &&
    typeof document.createRange != 'undefined'
  ) {
    const range = document.createRange()
    range.setStart(node, position)
    range.collapse(false)
    const sel = window.getSelection()
    if (sel) {
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}

type NumberStringProps = {
  val: number
  maximumFractionDigits?: number
  minimumFractionDigits?: number
  currency?: Currencies | string
}

export enum Currencies {
  usd = 'USD',
}

export const numberString = ({
  val,
  maximumFractionDigits = undefined,
  minimumFractionDigits = undefined,
  currency,
}: NumberStringProps) =>
  new Intl.NumberFormat('en-US', {
    style: currency ? 'currency' : undefined,
    currency,
    minimumFractionDigits,
    maximumFractionDigits:
      currency && maximumFractionDigits === undefined
        ? 2
        : maximumFractionDigits,
  }).format(val)

export const compactFormatter = new Intl.NumberFormat('en', {
  notation: 'compact',
})

export const secondToMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const minutesString = minutes < 10 ? `0${minutes}` : minutes
  const sec = Math.round(seconds % 60)
  const secondsString = sec < 10 ? `0${sec}` : sec
  return `${minutesString || '00'}:${secondsString || '00'}`
}

export const secondToTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
  const minutesString = minutes ? `${minutes}m` : ''
  const sec = Math.round(seconds % 60)
  const secondsString = sec ? `${sec}s` : ''
  return `${minutesString} ${secondsString}`.trim()
}

export const getAbsoluteDay = (date: Date) =>
  Math.floor(+date / 1000 / 60 / 60 / 24)

export function descendingComparator(a: any, b: any, orderBy: string) {
  if (orderBy.includes('.')) {
    const split = orderBy.split('.')
    if (split.length === 2) {
      if (b[split[0]][split[1]] < a[split[0]][split[1]]) {
        return -1
      }
      if (b[split[0]][split[1]] > a[split[0]][split[1]]) {
        return 1
      }
      return 0
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator(
  order: string,
  orderBy: string
): (a: any, b: any) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(
  array: Array<any>,
  comparator: (a: any, b: any) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function htmlToText(text: string): string {
  let parse1 = parse(replaceSpace(text))
  const customParse = (el: JSX.Element | string | JSX.Element[]): string => {
    if (Array.isArray(el)) {
      return el.map(customParse).join('')
    }
    if (typeof el === 'string') {
      return el
    }
    if (el.type === 'span' && el.props.className?.includes('personalize')) {
      const key = el.props['data-key'] || ''
      const fallback = el.props['data-fallback']
        ? `!${el.props['data-fallback']}`
        : ''

      const name = String(key).startsWith('merge.')
        ? `?${el.props.children}`
        : ''

      return `{${key}${fallback}${name}}`
    }
    if (el.type === 'a') {
      return `${el.props.children}`
    }
    if (el.type === 'br') {
      return '\n'
    }
    if (!el.props?.children) {
      return String(el)
    }
    if (el.type === 'div' && el.props.children?.type === 'br') {
      return '\n'
    }
    if (el.type === 'div') {
      return '\n' + customParse(el.props.children)
    }
    return customParse(el.props.children)
  }
  if (Array.isArray(parse1)) {
    parse1 = parse1.map(customParse).join('')
  }
  return customParse(parse1)
}

const integrationsKeys = Object.values(AccountIntegrationType)

const personalizeKeyToName = (key: string) => {
  if (key === 'firstName') {
    return 'First Name'
  }
  if (key === 'lastName') {
    return 'Last Name'
  }
  if (key === 'email') {
    return 'Email'
  }
  if (key === 'phoneNumber') {
    return 'Phone'
  }
  if (key.startsWith('merge.')) {
    return key
  }
  if (key.startsWith('custom.')) {
    return customFieldsStore.getCustomNameByKey(key)
  }
  if (key.startsWith('hubspot.')) {
    return triggersStore.hubspotCustomFieldsMap.get(key)?.name
  }
  if (key.startsWith('keap.')) {
    return triggersStore.keapFieldsMap.get(key)?.name
  }
  if (key.startsWith('activecampaign.')) {
    return triggersStore.activeCampaignFieldsMap.get(key)?.name
  }
  const split = key.split('.')
  const splitKey = split[0] as AccountIntegrationType
  if (integrationsKeys.includes(splitKey)) {
    return triggersStore.getIntegrationPersonalizeNameByKey(splitKey, key)
  }
  return ''
}
export function textToHtml(
  text: string,
  replaceNewRow?: boolean,
  extraSpace?: boolean
) {
  const withLinks = text
    .split(/\n/)
    .map((text) =>
      text
        .split(' ')
        .map((word) => replaceLink(word, extraSpace))
        .join(' ')
    )
    .join('\n')
  const replacer = (match: string) => {
    const newText = match.replace(/{|}/g, '')
    const split = newText.split('!')
    const personalizeKey = split && split[0]

    const part2 = split && split[1]
    const split2 = part2?.split('?')
    const fallback = (split2 && split2[0]) || ''
    const isMerge = newText.startsWith('merge.')
    const personalizeName = isMerge
      ? split2 && split2[1]
      : personalizeKeyToName(personalizeKey)
    if (personalizeName) {
      return `<span class='personalize' contenteditable='false' data-key=${personalizeKey} data-merge=${isMerge} ${
        fallback ? `data-fallback='${fallback}'` : ''
      }  >${personalizeName}</span>`
    } else {
      return match
    }
  }

  if (replaceNewRow) {
    return withLinks.replace(/{.*?}/g, replacer).replace(/\n/g, '<br/>')
  }

  return withLinks.replace(/{.*?}/g, replacer)
}

export function createElementFromHTML(htmlString: string, noTrim?: boolean) {
  const span = document.createElement('span')
  span.innerHTML = noTrim ? htmlString : htmlString.trim()
  return span
}

export const objToMap = <T>(
  obj: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    [key: T]: string
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  } // @ts-ignore
) => new Map<T, string>(Object.entries(obj))

export const generateName = (
  name: string,
  format: DayjsFormats = DayjsFormats.full
) => name + ' at ' + dayjs().format(format)
