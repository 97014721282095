import React, { FC } from 'react'
import styles from './styles.module.scss'
import { TemplateCard } from 'src/widgets/MessageTemplate/MessageTemplatesModal/Templates/TemplateCard'
import { IMessageTemplate } from 'src/widgets/MessageTemplate'
import { observer } from 'mobx-react-lite'
import { TextareaStore } from 'store/textareaStore'

type Props = {
  templates: IMessageTemplate[]
  withoutMore?: boolean
  store: TextareaStore
  onScroll?: React.UIEventHandler<HTMLDivElement>
}

export const Templates: FC<Props> = observer(
  ({ templates, withoutMore, store, onScroll }) => (
    <div className={styles.wrap} onScroll={onScroll}>
      {templates.map((template) => (
        <TemplateCard
          key={template.id}
          template={template}
          withoutMore={withoutMore}
          store={store}
        />
      ))}
    </div>
  )
)
