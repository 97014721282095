import { ColumnType } from 'components/NewTable/types'
import { IConversionList } from 'src/pages/settings/conversions//conversions/store/type'
import { numberString } from 'src/util/functions'
import { AppSymbols } from 'src/util/symbols'
import { UrlsCell } from 'widgets/conversions/ConversionsTable/ui/UrlsCell/UrlsCell'

export const getColumns = (): ColumnType<IConversionList>[] => [
  { id: 'name', label: 'Name' },
  {
    id: 'urlPatterns',
    label: 'URLs',
    format: (_, { urlPatterns }) => <UrlsCell urlPatterns={urlPatterns} />,
  },
  {
    id: 'defaultAmount',
    label: 'Default value',
    format: (val, { defaultAmount }) =>
      val
        ? numberString({ val: defaultAmount || 0, currency: 'USD' })
        : AppSymbols.dash,
  },
  {
    id: 'totalHits',
    label: 'Converted',
    format: (val, { totalHits }) => numberString({ val: totalHits }),
  },
  {
    id: 'conversion',
    label: 'Conversion rate',
    format: (val, { conversion }) =>
      `${numberString({
        val: conversion || 0,
        maximumFractionDigits: 2,
      })}%`,
  },
  {
    id: 'revenue',
    label: 'Revenue',
    format: (val, { revenue }) =>
      typeof val === 'number'
        ? numberString({ val: revenue || 0, currency: 'USD' })
        : AppSymbols.dash,
  },
]
