import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'

type IPreviewReplyProps = {
  text?: string
  placeholder?: string
}

export const PreviewReply = ({ text, placeholder }: IPreviewReplyProps) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.reply}>
        <Typography
          variant={'s3-medium'}
          className={styles.text}
          placeholder={placeholder}
        >
          {text}
        </Typography>
      </div>
    </div>
  )
}
