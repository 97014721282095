// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.urp1RB4iGuYo_4rSy8_6{max-width:512px}.eYtFlSNIIY2ZCrjxpBTn{padding:0 32px 32px 32px}.Z6DKJKNxHWrJqH8oQ3lI{min-width:280px;padding:16px;display:flex;justify-content:center;align-items:center;flex-direction:column}.Z6DKJKNxHWrJqH8oQ3lI p{padding:0 8px;text-align:center}.l7u5nxrx3mIRqFgeH63M{display:flex;flex-direction:column;flex-grow:1}.P6_KVY2z2aPc0LD3lPO1{display:flex;flex-direction:column;flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/pages/settings/account/routes/PrivacyAccount/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,wBAAA,CAEF,sBACE,eAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,wBACE,aAAA,CACA,iBAAA,CAGJ,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,WAAA","sourcesContent":[".wrap{\n  max-width: 512px;\n}\n.modalContent{\n  padding: 0 32px 32px 32px;\n}\n.checkModal{\n  min-width: 280px;\n  padding: 16px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  p{\n    padding: 0 8px;\n    text-align: center;\n  }\n}\n.wrapPrivacyTab{\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}\n.wrapPrivacyContent{\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `urp1RB4iGuYo_4rSy8_6`,
	"modalContent": `eYtFlSNIIY2ZCrjxpBTn`,
	"checkModal": `Z6DKJKNxHWrJqH8oQ3lI`,
	"wrapPrivacyTab": `l7u5nxrx3mIRqFgeH63M`,
	"wrapPrivacyContent": `P6_KVY2z2aPc0LD3lPO1`
};
export default ___CSS_LOADER_EXPORT___;
