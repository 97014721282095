import { useTextareaContext } from 'components/Textarea/context/context'
import { observer } from 'mobx-react-lite'
import { TextareaMediaModal } from 'components/Textarea/TextareaMediaModal'
import React from 'react'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { Typography } from 'shared/ui/Typography'
import { Button } from 'shared/ui/Button/Button'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import { SelectList } from 'components/SelectList/SelectList'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import audioStore from 'store/audioStore/audioStore'
import { Media, Microphone, Unassigned } from 'components/Icon'

export const AddMedia = observer(() => {
  const textareaStore = useTextareaContext()
  const { setActiveMedia } = textareaStore

  const [isOpen, setIsOpen] = React.useState(false)

  const options: IListItem[] = [
    {
      id: 'addImage',
      text: 'Add image',
      dropdownButtonProps: {
        LeftIcon: Media,
      },
    },
    {
      id: 'addAudio',
      text: 'Add audio',
      dropdownButtonProps: {
        isNew: true,
        LeftIcon: Microphone,
      },
    },
  ]
  const onSelect = (id: string) => {
    if (id === 'addImage') {
      setActiveMedia(true)
    }
    if (id === 'addAudio') {
      audioStore.onOpenModal({
        disabledDTTS: true,
        disabledWav: true,
        successUploadFIleCallback: textareaStore.addAudio,
      })
    }
  }
  return (
    <>
      <TextareaMediaModal textareaStore={textareaStore} />
      <MuiTooltip
        title={
          isOpen
            ? ''
            : [
                'Add media',
                <br key={'br'} />,
                <Typography key={'span'} color={'--primary-color-gray-2'}>
                  png, jpg, gif, mp3 - up to 5MB
                </Typography>,
              ]
        }
        placement={'top'}
        arrow
      >
        <div>
          <DropdownField
            placement={'bottom-start'}
            dropdownContent={() => (
              <SelectList options={options} onSelect={onSelect} />
            )}
            triggerContent={(open) => {
              setIsOpen(open)
              return (
                <Button
                  variant={'tertiary-small'}
                  LeftIcon={Unassigned}
                  active={open}
                  isNew
                />
              )
            }}
            width={200}
          />
        </div>
      </MuiTooltip>
    </>
  )
})
