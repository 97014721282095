// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kYIytS6NDtbtelZKRqLz{display:grid;gap:16px}.KJ2gaGmDx7K4CqP_usE3{display:grid;grid-template-columns:1fr 1fr 1fr;gap:12px}.p54zg60ZAHCXyrgLIEHb{margin-top:40px}`, "",{"version":3,"sources":["webpack://./src/pages/auth/signUp/register/RegisterCompanyInfo/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,QAAA,CAGF,sBACE,YAAA,CACA,iCAAA,CACA,QAAA,CAEF,sBACE,eAAA","sourcesContent":[".wrapFields{\n  display: grid;\n  gap: 16px;\n}\n\n.wrapInputs{\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 12px;\n}\n.actions{\n  margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapFields": `kYIytS6NDtbtelZKRqLz`,
	"wrapInputs": `KJ2gaGmDx7K4CqP_usE3`,
	"actions": `p54zg60ZAHCXyrgLIEHb`
};
export default ___CSS_LOADER_EXPORT___;
