import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
// import { ReactComponent as StatusIcon } from 'icons/16px/Status/Status.svg'
// import { Dropdown } from 'components/Dropdown/Dropdown'
// import { IOption } from 'components/Dropdown/types'
// import { StatusContent } from 'components/StatusContent/StatusContent'
// import { Status } from 'src/generated/graphql'
import keywordsStore from 'store/keywords/keywordsStore'
import { CreateElementBtn } from 'components/Button/CreateElementBtn'
import { AppElements } from 'src/enums/appElements'
import { KeywordDeleteBtn } from 'src/pages/main/keywords/actions/KeywordDeleteBtn'

// const options: IOption[] = [
//   {
//     title: <StatusContent status={Status.Active} />,
//     value: Status.Active,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <StatusContent status={Status.Disabled} />,
//     value: Status.Disabled,
//     padding: '0 8px 0 0',
//   },
//   {
//     title: <StatusContent status={Status.Draft} />,
//     value: Status.Draft,
//     padding: '0 8px 0 0',
//   },
// ]

export const Filters = observer(() => {
  return (
    <div className={styles.wrapActions}>
      <CreateElementBtn appElement={AppElements.Keyword} />
      {!!keywordsStore.tableStore.checkedRowsIds.length && (
        <>
          <KeywordDeleteBtn
            ids={keywordsStore.tableStore.checkedRowsIds}
            onSuccessAction={() => {
              keywordsStore.delete(keywordsStore.tableStore.checkedRowsIds)
              keywordsStore.tableStore.onRefresh()
              keywordsStore.tableStore.setCheckedRows([])
            }}
            medium
          />
        </>
      )}
      {/*<Dropdown*/}
      {/*  dropDownContentWidth={196}*/}
      {/*  options={options}*/}
      {/*  selectedOptions={keywordsStore.tableStore.currentStatuses}*/}
      {/*  onMultiSelect={(options) =>*/}
      {/*    keywordsStore.tableStore.setCurrentStatus(options)*/}
      {/*  }*/}
      {/*  leftIcon={<StatusIcon />}*/}
      {/*  multi*/}
      {/*  unit={'status'}*/}
      {/*  units={'Status'}*/}
      {/*/>*/}
    </div>
  )
})
