import styles from './styles.module.scss'
import { Label } from 'shared/ui/Label/Label'
import { Input } from 'shared/ui/Input/Input'
import React, { Dispatch, SetStateAction } from 'react'
import { capitalize } from 'lodash'

export const customUtmInitParams = {
  utm_source: '',
  utm_medium: '',
  utm_campaign: '',
  utm_content: '',
}

type ICustomUtmParamsProps = {
  paramsValues: typeof customUtmInitParams
  setParamsValues: Dispatch<SetStateAction<typeof customUtmInitParams>>
}

export const CustomUtmParams = ({
  paramsValues,
  setParamsValues,
}: ICustomUtmParamsProps) => {
  const getParam = (key: keyof typeof paramsValues) => {
    const label = key.replace('utm_', '')
    return {
      label: capitalize(label),
      placeholder: capitalize(label),
      key: key,
      value: paramsValues[key],
      setValue: (value: string) =>
        setParamsValues((prevState) => ({ ...prevState, [key]: value })),
    }
  }

  const params = Object.keys(paramsValues).map((key) =>
    getParam(key as keyof typeof paramsValues)
  )
  return (
    <div className={styles.wrapCustomParams}>
      {params.map(({ label, value, setValue, placeholder, key }) => (
        <div key={key}>
          <Label text={label} marginBottom={8} />
          <Input
            value={value}
            setValue={setValue}
            placeholder={placeholder}
            withClear
          />
        </div>
      ))}
    </div>
  )
}
