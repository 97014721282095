import { observer } from 'mobx-react-lite'
import { Label } from 'shared/ui/Label/Label'
import { Input } from 'shared/ui/Input/Input'
import React, { useState } from 'react'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import styles from './styles.module.scss'
import { DropdownButton } from 'shared/ui/DropdownButton/DropdownButton'
import { EditableFieldText } from 'components/CreateElement/SMSContent/EditableFieldText/EditableFieldText'
import { NOT_REQUIRED_WEB_FORM_COMPLIANCE_TEXT } from 'src/static/constants'
import { CustomSubscribeButton } from 'src/pages/main/webForms/create/createForm/Settings/CustomSubscribeButton/CustomSubscribeButton'
import { AngleDown, AngleUp } from 'components/Icon'

export const Settings = observer(() => {
  const [collapsed, setCollapsed] = useState(false)
  const {
    subscribeBtnText,
    setSubscribeText,
    privacyUrl,
    setPrivacyUrl,
    termsUrl,
    setTermsUrl,
    editableComplianceText,
    setEditableComplianceText,
    customSubscribeButtonStore,
  } = useCreateWebFromContext()
  return (
    <div className={styles.wrap}>
      <div>
        <DropdownButton
          text={'Settings'}
          isNew
          rightContentProps={{ RightIcon: collapsed ? AngleUp : AngleDown }}
          onClick={() => setCollapsed(!collapsed)}
        />
      </div>
      {collapsed && (
        <>
          <div>
            <Label text={'Subscribe button text'} marginBottom={8} />
            <Input
              value={subscribeBtnText}
              setValue={setSubscribeText}
              placeholder={'Enter text'}
              withClear
            />
            <CustomSubscribeButton store={customSubscribeButtonStore} />
          </div>

          <div>
            <Label
              text={'Compliance text'}
              marginBottom={8}
              infoTooltipProps={{
                title: 'Compliance language for your webform.',
              }}
            />
            <EditableFieldText
              editableText={editableComplianceText}
              setEditableText={setEditableComplianceText}
              REQUIRED_TEXT={`By checking this box and clicking “${subscribeBtnText}” you agree with Terms and Privacy`}
              NOT_REQUIRED_TEXT={NOT_REQUIRED_WEB_FORM_COMPLIANCE_TEXT}
              bordered
            />
          </div>

          <div>
            <Label text={'Terms and Conditions URL'} marginBottom={8} />
            <Input
              value={termsUrl}
              setValue={setTermsUrl}
              placeholder={'https://'}
            />
          </div>
          <div>
            <Label text={'Privacy Policy URL'} marginBottom={8} />
            <Input
              value={privacyUrl}
              setValue={setPrivacyUrl}
              placeholder={'https://'}
            />
          </div>
        </>
      )}
    </div>
  )
})
