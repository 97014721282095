import { useEffect } from 'react'
import {
  AccountIntegrationType,
  useSetupIntegrationMutation,
} from 'src/generated/graphql'
import integrationsStore from 'store/settings/integrationsStore'
import alertStore from 'store/alertStore'
import { capitalize } from 'lodash'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'

export function useSetupLeadConnector() {
  const [setupIntegration] = useSetupIntegrationMutation()
  useEffect(() => {
    const lcCompanyId = localStorage.getItem('lcCompanyId')
    const lcLocationId = localStorage.getItem('lcLocationId')
    if (lcCompanyId && lcLocationId) {
      setupIntegration({
        variables: {
          input: {
            oAuth2Integration: {
              lcCompanyId,
              lcLocationId,
              type: AccountIntegrationType.LeadConnector,
            },
          },
        },
      })
        .then((res) => {
          if (res.data?.setupIntegration) {
            integrationsStore.setAccountIntegrationsData(
              res.data?.setupIntegration
            )
          }
          if (
            res.data?.setupIntegration?.OAuth2Integrations?.find(
              (item) => item?.type === AccountIntegrationType.LeadConnector
            )?.connected
          ) {
            alertStore.setAlert({
              title: `${capitalize(
                AccountIntegrationType.LeadConnector
              )} connected!`,
              type: AlertTypeEnum.success,
            })
          }
        })
        .catch((e) => {
          if (
            e instanceof Error &&
            e.message.includes('is already connected')
          ) {
            alertStore.disabledErrorAlert(e.message)
          } else {
            console.error(e)
          }
        })
        .finally(() => {
          localStorage.removeItem('lcCompanyId')
          localStorage.removeItem('lcLocationId')
        })
    }
  }, [])
}
