import { observer } from 'mobx-react-lite'
import { AddCollectField } from './AddCollectField/AddCollectField'
import styles from './styles.module.scss'
import { CollectedField } from '../widgets/CollectedField'
import { useCollectedFieldsStoreContext } from '../context/context'

export const CollectedFieldsUi = observer(() => {
  const { collectFields } = useCollectedFieldsStoreContext()
  return (
    <>
      {!!collectFields.length && (
        <div className={styles.wrap}>
          {collectFields.map((filed, index) => (
            <CollectedField key={filed.id} store={filed} index={index + 1} />
          ))}
        </div>
      )}
      <AddCollectField />
    </>
  )
})
