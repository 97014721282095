import { createContext, useContext } from 'react'
import { CollectedFieldsStore } from 'widgets/CollectedFields/store/CollectedFieldsStore'

export const CollectedFieldsStoreContext =
  createContext<CollectedFieldsStore | null>(null)

export const useCollectedFieldsStoreContext = () => {
  const context = useContext(CollectedFieldsStoreContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with CollectedFieldsStoreContext'
    )

  return context
}
