import { observer } from 'mobx-react-lite'
import { Header } from './Header/Header'
import { Actions } from './Actions/Actions'
import styles from './styles.module.scss'
import { Content } from './Content/Content'

export const CollectedFieldUi = observer(() => {
  return (
    <div className={styles.wrap}>
      <Header />
      <Content />
      <Actions />
    </div>
  )
})
