import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'

export type HeaderProps = {
  title: string
  rightContent?: ReactNode
  style?: CSSProperties
  inContainer?: boolean
}

export const Header = ({
  title,
  rightContent,
  style,
  inContainer,
}: HeaderProps) => (
  <div
    className={classNames(styles.wrap, {
      [styles.inContainer]: inContainer,
    })}
    style={style}
  >
    <div className={styles.left}>
      {title && <Typography variant="h1-semiBold">{title}</Typography>}
    </div>
    <div className={styles.right}>{rightContent}</div>
  </div>
)
