import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'
import { makeAutoObservable } from 'mobx'
import { CollectedFieldsStore } from 'widgets/CollectedFields'
import {
  WorkflowActionType,
  WorkflowRequest,
  WorkflowRequestInput,
} from 'src/generated/graphql'

export class BroadcastWorkflowStore {
  collectedFieldsStore: CollectedFieldsStore

  constructor(private createBroadCastStore: CreateBroadCastStore) {
    makeAutoObservable(this)
    this.collectedFieldsStore = new CollectedFieldsStore()
  }

  get input(): WorkflowRequestInput | undefined {
    if (this.createBroadCastStore.type === 'SMS') {
      if (this.collectedFieldsStore.collectFields.length) {
        return {
          actions: [
            {
              groupNumber: 0,
              type: WorkflowActionType.SendMessage,
              properties: {
                messageConfig:
                  this.createBroadCastStore.textareaStore.smsMessageInput,
                messageType: 'SMS',
              },
            },
            ...this.collectedFieldsStore.workflowActionRequestInput,
          ],
        }
      }
    }
  }

  init = (response: WorkflowRequest) => {
    this.collectedFieldsStore.init(response)
  }
}
