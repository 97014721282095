import { makeAutoObservable } from 'mobx'
import { CaptureFieldStore } from 'widgets/DataCapture/widtets/CaptureField'
import { ComplianceStore } from 'components/CreateElement/SMSContent/ComplianceContent/store/ComplianceStore'
import { TextareaStore } from 'store/textareaStore'
import {
  DataCaptureConfigFragment,
  DataCaptureConfigInput,
  FieldCaptureInput,
} from 'src/generated/graphql'

export class DataCaptureStore {
  itemsMap: Map<string, CaptureFieldStore>
  complianceStore
  thankYouMessageStore
  constructor() {
    this.itemsMap = new Map()
    this.addItem()
    this.complianceStore = new ComplianceStore()
    this.thankYouMessageStore = new TextareaStore()
    makeAutoObservable(this)
  }

  init = (config: DataCaptureConfigFragment) => {
    this.itemsMap.clear()
    config.fieldCaptures?.map((filed) => {
      const newItem = new CaptureFieldStore()
      if (filed) {
        newItem.init(filed)
      }
      this.itemsMap.set(newItem.id, newItem)
    })
    this.thankYouMessageStore.setSmsMessage(config.lastReplyConfig)
    const message = config.fieldCaptures?.[0]?.smsConfig
    if (message) {
      if (message.complianceText) {
        this.complianceStore.setNoneRequiredComplianceText(
          message.complianceText
        )
      }
      this.complianceStore.setCompliance(message.compliance)
    }
  }

  get dataCaptureConfigInput(): DataCaptureConfigInput {
    return {
      fieldCaptures: this.fieldCapturesInput,
      lastReplyConfig: this.thankYouMessageStore.workflowSmsConfigInput,
      retriesCount: 0,
    }
  }

  get fieldCapturesInput(): Array<FieldCaptureInput> {
    return this.items.map((item, index) => ({
      ...item.fieldCaptureInput,
      smsConfig: {
        ...item.fieldCaptureInput.smsConfig,
        compliance: index === 0 ? this.complianceStore.compliance : false,
        complianceText: this.complianceStore.nonRequiredComplianceText,
      },
    }))
  }
  get disabledCreate() {
    if (this.items.filter((item) => item.disabledCreate).length) {
      return true
    }
  }

  get items() {
    return Array.from(this.itemsMap.values())
  }

  addItem = () => {
    const newItem = new CaptureFieldStore()
    this.itemsMap.set(newItem.id, newItem)
  }

  get orderItems() {
    return this.items.map((filed) => filed.id)
  }

  deleteItem = (id: string) => {
    this.itemsMap.delete(id)
  }

  setOrderItems = (order: string[]) => {
    const fields: CaptureFieldStore[] = []
    order.forEach((id) => {
      const field = this.itemsMap.get(id)
      if (field) {
        fields.push(field)
      }
    })
    this.itemsMap = new Map(fields.map((filed) => [filed.id, filed]))
  }

  isSmartRetry = false
  toggleSmartRetry = () => {
    this.isSmartRetry = !this.isSmartRetry
  }
  retry = 2
  setRetry = (value: number) => {
    this.retry = value
  }

  get hasShortLinks() {
    if (this.thankYouMessageStore.hasShortLinks) {
      return true
    }
    let hasShortLinks = false
    this.items.forEach((item) => {
      if (item.textareaStore.hasShortLinks) {
        hasShortLinks = true
      }
    })
    return hasShortLinks
  }
}
