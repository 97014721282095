import { makeAutoObservable } from 'mobx'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import { nanoid } from 'nanoid'

export class ShortLinkRequiredStore {
  constructor(private onSaveDraft: () => Promise<void>) {
    makeAutoObservable(this)
    this.openModal()
  }

  modalId = nanoid()
  onClose = () => {
    modalStore.removeModal(this.modalId)
  }
  openModal = () => {
    modalStore.addModal({
      variant: 'notification',
      title: 'Short link required for conversion tracking',
      subTitle:
        'Conversion tracking requires a short link in your message. Add a short link or disable conversion tracking to continue.',
      id: this.modalId,
      type: 'info',
      actions: {
        buttons: [
          {
            variant: 'primary-medium',
            text: 'Save as draft',
            withUiLoading: true,
            onClick: async () => {
              try {
                await this.onSaveDraft()
                modalStore.closeModal(this.modalId)
              } catch (e) {
                console.error(e)
              }
            },
          },
          {
            variant: 'secondary-medium',
            text: 'Back',
            onClick: () => {
              modalStore.closeModal(this.modalId)
            },
          },
        ],
      },
    })
  }
}
