import { ContactFragmentFragment } from 'src/generated/graphql'
import { makeAutoObservable } from 'mobx'
import { AppSymbols } from 'src/util/symbols'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { contactListToIListItem } from 'store/contacts/functions'
import { FC } from 'react'
import { Calendar, Link, Number, Text } from 'components/Icon'
import dayjs, { DayjsFormats } from 'lib/dayjs'
import { numberString } from 'src/util/functions'

type ICustomFiled = {
  LeftIcon: FC
  label: string
  value: string
  id: string
}

export class ContactPreviewStore {
  constructor(private origin: ContactFragmentFragment) {
    makeAutoObservable(this)
  }

  get fullName() {
    return (
      `${this.origin.firstName} ${this.origin.lastName}`.trim() ||
      AppSymbols.dash
    )
  }

  get formattedPhoneNumber() {
    return this.origin.formattedPhoneNumber
  }

  get status() {
    return this.origin.status
  }

  get createdAt() {
    return this.origin.createdAt
  }
  get optIn() {
    return this.origin.optIn
  }
  get contactSource() {
    return this.origin.contactSource
  }

  get firstName() {
    return this.origin.firstName
  }
  get lastName() {
    return this.origin.lastName
  }
  get email() {
    return this.origin.email
  }

  get listItems() {
    const listItems: IListItem[] = []
    this.origin.listContacts?.forEach((item) => {
      if (item?.listing) {
        listItems.push(contactListToIListItem(item.listing))
      }
    })
    return listItems
  }

  get customFields(): ICustomFiled[] {
    const customFields: ICustomFiled[] = []
    this.origin.customFields?.forEach((item) => {
      if (item) {
        const getData = (): ICustomFiled | undefined => {
          if (item.field?.type === 'DATE' && item.date) {
            return {
              id: item.field.key || '',
              LeftIcon: Calendar,
              label: item.field.name || '',
              value:
                (item.date && dayjs(item.date).format(DayjsFormats.date)) ||
                AppSymbols.dash,
            }
          }
          if (item.field?.type === 'DATE_TIME' && item.date) {
            return {
              id: item.field.key || '',
              LeftIcon: Calendar,
              label: item.field.name || '',
              value:
                (item.date &&
                  dayjs(item.date).format(DayjsFormats.fullWithAt)) ||
                AppSymbols.dash,
            }
          }
          if (item.field?.type === 'URL' && item.text) {
            return {
              id: item.field.key || '',
              LeftIcon: Link,
              label: item.field.name || '',
              value: item.text,
            }
          }
          if (item.field?.type === 'TEXT' && item.text) {
            return {
              id: item.field.key || '',
              LeftIcon: Text,
              label: item.field.name || '',
              value: item.text,
            }
          }
          if (item.field?.type === 'NUMBER' && item.number) {
            return {
              id: item.field.key || '',
              LeftIcon: Number,
              label: item.field.name || '',
              value: numberString({ val: item.number }),
            }
          }
        }

        const field = getData()
        if (field) {
          customFields.push(field)
        }
      }
    })
    return customFields
  }
}
