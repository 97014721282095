import { Dropdown } from 'components/Dropdown/Dropdown'
import { observer } from 'mobx-react-lite'
import { SelectFilter } from 'store/contacts/segment/filters/SelectFilter'
import { DropdownProps } from 'components/Dropdown/types'

type Props = {
  filter: SelectFilter
  readonly: boolean
}

export const SelectFilterContent = observer(({ filter, readonly }: Props) => {
  const multiProps: DropdownProps<any> = {
    withSearch: true,
    tagsNoWrap: true,
    multi: true,
    selectedValues: filter.values,
    onMultiSelectValues: filter.setValues,
    selectedTag: true,
    withSelectALl: true,
    onDeleteTag: (id) => filter.onDeleteId(id as string),
  }
  const singleProps: DropdownProps<any> = {
    selectedValue: filter.value,
    onSelectValue: filter.setValue,
  }
  const props: DropdownProps<any> = filter.isMulti ? multiProps : singleProps
  return (
    <Dropdown
      options={filter.options}
      placeholder={'Select'}
      plusItemsProps={{
        withoutSearch: true,
        withoutLabel: true,
      }}
      readonly={readonly}
      {...props}
    />
  )
})
