import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { Tag } from 'components/Tag/Tag'
import { Conversion } from 'src/generated/graphql'
import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'

export const UrlsCell = ({
  urlPatterns,
}: {
  urlPatterns: Conversion['urlPatterns']
}) => {
  return (
    <div className={styles.wrap}>
      <Typography isEllipsis>{urlPatterns?.[0]?.pattern}</Typography>

      {urlPatterns?.length && urlPatterns?.length > 1 && (
        <MuiTooltip
          title={
            <div className={styles.wrapUrls}>
              {urlPatterns.slice(1).map((url) => (
                <Typography key={url?.id} color={'--primary-color-white'}>
                  {url?.pattern}
                </Typography>
              ))}
            </div>
          }
          placement={'top'}
          arrow
          maxWidth={400}
        >
          <div>
            <Tag text={`+${urlPatterns?.length - 1}`} />
          </div>
        </MuiTooltip>
      )}
    </div>
  )
}
