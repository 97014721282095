// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QghHF9CpQu3wrVo4NvdA{display:flex;gap:8px;align-items:center;overflow:hidden}.feubK4PTOQUtRWvbqWeK{display:flex;flex-direction:column;gap:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/conversions/ConversionsTable/ui/UrlsCell/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,OAAA,CACA,kBAAA,CACA,eAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".wrap{\n  display: flex;\n  gap: 8px;\n  align-items: center;\n  overflow: hidden;\n}\n.wrapUrls{\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `QghHF9CpQu3wrVo4NvdA`,
	"wrapUrls": `feubK4PTOQUtRWvbqWeK`
};
export default ___CSS_LOADER_EXPORT___;
