// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DaxIjieP91IpW8PbtbQP{padding:28px 32px;display:flex;flex-direction:column;gap:8px}.h83AFJaTP6BXJWiednkE{border-radius:8px;background:var(--primary-color-blue-1);padding:4px;min-width:24px;height:24px;display:flex;align-items:center;justify-content:center;color:var(--primary-color-white)}.h83AFJaTP6BXJWiednkE.mqVtwmP9NCaWPntTgsSw{background:var(--primary-color-blue-5);color:var(--primary-color-blue-1)}.YUpcPiAiM_nRpHMAnp4q{display:grid;grid-template-columns:max-content 1fr;gap:8px}`, "",{"version":3,"sources":["webpack://./src/widgets/CollectedFields/widgets/CollectedField/ui/Header/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,iBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,iBAAA,CACA,sCAAA,CACA,WAAA,CACA,cAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gCAAA,CACA,2CACE,sCAAA,CACA,iCAAA,CAIJ,sBACE,YAAA,CACA,qCAAA,CACA,OAAA","sourcesContent":[".wrap{\n  padding: 28px 32px;\n  display: flex;\n  flex-direction: column;\n  gap: 8px;\n}\n\n.number{\n  border-radius: 8px;\n  background: var(--primary-color-blue-1);\n  padding: 4px;\n  min-width: 24px;\n  height: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: var(--primary-color-white);\n  &.secondary{\n    background: var(--primary-color-blue-5);\n    color: var(--primary-color-blue-1);\n  }\n}\n\n.actions{\n  display: grid;\n  grid-template-columns: max-content 1fr;\n  gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `DaxIjieP91IpW8PbtbQP`,
	"number": `h83AFJaTP6BXJWiednkE`,
	"secondary": `mqVtwmP9NCaWPntTgsSw`,
	"actions": `YUpcPiAiM_nRpHMAnp4q`
};
export default ___CSS_LOADER_EXPORT___;
