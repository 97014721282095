import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthPage } from 'components/AuhPage/AuthPage'
import { NoCompleteRoutes } from 'src/pages/routes'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { RegisterCompanyInfo } from 'src/pages/auth/signUp/register/RegisterCompanyInfo/RegisterCompanyInfo'
import { RegisterMemberInfo } from 'src/pages/auth/signUp/register/RegisterMemberInfo'
import { RegisterLayout } from 'src/pages/auth/signUp/register/layout/RegisterLayout'

export const RegisterRoutes = observer(() => {
  return (
    <Routes>
      <Route element={<AuthPage />}>
        <Route element={<RegisterLayout />}>
          <Route
            path={NoCompleteRoutes.register1}
            element={<RegisterCompanyInfo />}
          />
          <Route
            path={NoCompleteRoutes.register2}
            element={<RegisterMemberInfo />}
          />
        </Route>
        <Route
          path="*"
          element={<Navigate to={NoCompleteRoutes.register1} />}
        />
      </Route>
    </Routes>
  )
})
