import React, {
  CSSProperties,
  FC,
  ForwardedRef,
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import styles from './styles.module.scss'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import classNames from 'classnames'
import { Icon } from 'components/Icon/Icon'
import { XSmall } from 'components/Icon'

type Props<T> = {
  size?: 'small' | 'medium'
  value: T
  setValue?: (val: T) => void
  LeftIcon?: FC
  leftContent?: ReactNode
  isNumber?: boolean
  rightContent?: ReactNode
  placeholder?: string
  isReadOnly?: boolean
  isFocusOnMount?: boolean
  toggleFocus?: (value: boolean) => void
  numericFormatProps?: NumericFormatProps
  withClear?: boolean
  disabled?: boolean
  width?: CSSProperties['width']
  error?: ReactNode
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  wrapClassname?: string
}

// eslint-disable-next-line react/display-name
export const Input = forwardRef(
  (
    {
      size,
      isNumber,
      LeftIcon,
      leftContent,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value,
      setValue,
      rightContent,
      placeholder = 'Enter value',
      isReadOnly,
      isFocusOnMount,
      toggleFocus,
      numericFormatProps,
      withClear,
      width,
      disabled,
      error,
      onFocus,
      onBlur,
      wrapClassname,
    }: Props<typeof value>,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [focus, setFocus] = useState(false)
    const refInput = useRef<HTMLInputElement | null>(null)

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
      setFocus(true)
      toggleFocus && toggleFocus(true)
      onFocus?.(e)
    }
    const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
      setFocus(false)
      toggleFocus && toggleFocus(false)
      onBlur?.(e)
    }

    useEffect(() => {
      if (refInput.current && isFocusOnMount) {
        refInput.current.focus()
      }
    }, [isFocusOnMount])

    const isShowClearBtn = withClear && value

    return (
      <div
        className={classNames(
          styles.wrap,
          {
            [styles.focus]: focus && !isReadOnly,
            [styles.disabled]: disabled,
            [styles.readonly]: isReadOnly,
            [styles.error]: error,
            [styles[`size__${size}`]]: size,
          },
          wrapClassname
        )}
        style={{ width }}
        ref={ref}
      >
        {leftContent}
        {LeftIcon && (
          <Icon color={'--primary-color-gray-1'}>
            <LeftIcon />
          </Icon>
        )}
        {isNumber ? (
          <NumericFormat
            getInputRef={refInput}
            value={value}
            thousandSeparator
            onValueChange={(values) => setValue && setValue(values.floatValue)}
            placeholder={placeholder}
            className={styles.input}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={isReadOnly}
            {...numericFormatProps}
          />
        ) : (
          <input
            ref={refInput}
            value={value}
            onChange={(event) => setValue && setValue(event.target.value)}
            placeholder={placeholder}
            className={styles.input}
            onFocus={handleFocus}
            onBlur={handleBlur}
            readOnly={isReadOnly}
          />
        )}

        {rightContent && (
          <div className={styles.wrapRightContent}>{rightContent}</div>
        )}
        {isShowClearBtn && (
          <button
            className={styles.clear}
            onClick={(e) => {
              e.stopPropagation()
              setValue?.('')
              refInput.current?.focus()
            }}
          >
            <Icon fontSize={24}>
              <XSmall />
            </Icon>
          </button>
        )}
      </div>
    )
  }
)
