import classNames from 'classnames'
import styles from './styles.module.scss'
import { ReactComponent as Close } from 'icons/16px/CloseRed.svg'
import { ReactComponent as Info } from 'icons/16px/Info-1.svg'
import { ReactComponent as AlertIcon } from 'icons/16px/Alert.svg'
import { ReactComponent as Warning } from 'icons/16pxNoMask/Warning.svg'
import { ReactComponent as Sent } from 'icons/16pxNoMask/Status/Sent.svg'
import { ReactComponent as Check } from 'icons/16pxNoMask/Check.svg'
import { ReactComponent as Info16 } from 'icons/16pxNoMask/Info.svg'
import { ReactComponent as Sad } from 'icons/16px/Sad.svg'
import { ReactComponent as FailedRed } from 'icons/16pxNoMask/Status/Failed_red.svg'
import { FC } from 'react'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ColorsNames } from 'styles/variables'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { IAlert } from 'src/types/IAlert'

export const Alert: FC<IAlert> = ({
  type = AlertTypeEnum.info,
  title,
  text,
  rightContent,
  noIcon,
  small,
  titleContent,
  global,
  compact,
  paddingRight,
  className,
  marginBottom,
}) => {
  return (
    <div
      className={classNames(
        styles.infoWrap,
        styles[type],
        small && styles.small,
        global && styles.globalAlert,
        compact && styles.compact,
        className
      )}
      style={{ paddingRight, marginBottom }}
    >
      {!noIcon && (
        <div className={styles.infoIconWrap}>
          {type === AlertTypeEnum.error && (global ? <FailedRed /> : <Close />)}
          {(type === AlertTypeEnum.info || type === AlertTypeEnum.notify2) && (
            <Info />
          )}
          {type === AlertTypeEnum.warning && <AlertIcon />}
          {type === AlertTypeEnum.warning1 && <Warning />}
          {type === AlertTypeEnum.delete && <Sad />}
          {type === AlertTypeEnum.success && <Sent />}
          {type === AlertTypeEnum.success3 && (
            <AppIcon type={IIconType.stroke} color={ColorsNames.white}>
              <Check />
            </AppIcon>
          )}
          {type === AlertTypeEnum.blue3 && <Info16 />}
        </div>
      )}
      <div className={styles.contentWrap}>
        {title && <p className={'par2 bold blue4'}>{title}</p>}
        {titleContent}
        {text && <p className={'par2 black'}>{text}</p>}
      </div>
      <div className={styles.right}>{rightContent}</div>
    </div>
  )
}
