import { FC } from 'react'
import DatePickerLib from 'react-datepicker'

type Props = {
  startDate: Date | null
  onChange: (val: Date | null) => void
  dateFormat?: string
  readonly?: boolean
  wrapperClassName?: string
}

export const DatePickerWithoutTZ: FC<Props> = ({
  startDate,
  onChange,
  dateFormat = 'MMM dd, yyyy',
  readonly,
  wrapperClassName,
}) => {
  return (
    <DatePickerLib
      placeholderText={'Select date'}
      selected={startDate}
      onChange={onChange}
      dateFormat={dateFormat}
      readOnly={readonly}
      wrapperClassName={wrapperClassName}
    />
  )
}
