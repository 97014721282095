import { makeAutoObservable } from 'mobx'
import { TableStore } from 'components/NewTable/store/TableStore'
import { campaignColumns } from 'components/NewTable/columns/campaigns/campaignColumns'
import { ICampaign } from 'src/types/ICampaign'
import { CampaignResponse, GetAllCampaignsQuery } from 'src/generated/graphql'
import contactStore from 'store/contacts/contactStore'
import { transformCampaign } from 'store/campaigns/transformCampaign'

class CampaignsStore {
  tableStore: TableStore<ICampaign>
  constructor() {
    this.tableStore = new TableStore({
      orderBy: 'createdAt',
      tableName: 'CampaignsTable',
      columns: campaignColumns,
      defaultHiddenColumn: ['contactsListsIds', 'unsubscribed'],
    })
    makeAutoObservable(this)
  }
  campaignsMap: Map<string, ICampaign> = new Map()
  campaignDetailModal: ICampaign | null = null

  get openCampaignDetailModal() {
    return !!this.campaignDetailModal
  }

  get campaigns() {
    return Array.from(this.campaignsMap.values())
  }
  delete(ids: string[]) {
    ids.forEach((id) => {
      this.campaignsMap.delete(id)
    })
  }

  setData = (data: GetAllCampaignsQuery) => {
    this.tableStore.setTotal(data.getAllCampaigns?.total || 0)
    this.campaignsMap.clear()

    data.getAllCampaigns?.content?.forEach((item) => {
      if (item) {
        const newItem = transformCampaign(item as CampaignResponse)
        this.campaignsMap.set(newItem.id, newItem)
        if (item.campaignListResponses) {
          contactStore.addCampaignListResponse(item.campaignListResponses)
        }
      }
    })
  }

  setCampaignDetailModal(item: ICampaign | null) {
    this.campaignDetailModal = item
  }
}

export default new CampaignsStore()
