import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import { WrapTagRender } from 'components/Dropdown/TagsRender/WrapTagRender'
import { TagsRenderProps } from 'components/Dropdown/TagsRender/TagsRender'
import { IOption } from 'src/types/IOption'
import { PlusItemsProps } from 'components/PlusItems/PlusItems'

export const OptionTagWrapper = ({
  onDeleteTag,
  option,
  index,
  length,
  tagsNoWrap,
  showInput,
  inputComponent,
  ...props
}: TagsRenderProps & {
  inputComponent: ReactNode
  length: number
  index: number
  option: IOption
  invisibleOptions: IOption[]
  setInvisibleOptions: Dispatch<SetStateAction<IOption[]>>
  plusItemsProps?: PlusItemsProps
}) => {
  const isShowInput = () => {
    if (tagsNoWrap) {
      return false
    }
    return index === length - 1 && showInput
  }
  return (
    <>
      <WrapTagRender
        option={option}
        index={index}
        onDeleteTag={onDeleteTag}
        {...props}
        length={length}
      />
      {isShowInput() && inputComponent}
    </>
  )
}
