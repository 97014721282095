import { observer } from 'mobx-react-lite'
import { InputCollapsableCheckbox } from 'components/Input/InputCollapsableCheckbox/InputCollapsableCheckbox'
import { EditableFieldText } from 'components/CreateElement/SMSContent/EditableFieldText/EditableFieldText'
import { Separator } from 'components/Separator/Separator'
import React, { ReactNode } from 'react'
import { appLinks } from 'src/util/links'
import { ComplianceStore } from 'components/CreateElement/SMSContent/ComplianceContent/store/ComplianceStore'

type IComplianceContentProps = {
  store: ComplianceStore
  complianceMessageTT?: ReactNode
}

const defaultComplianceMessageTT = (
  <>
    Append compliance language <br /> to your SMS text.{' '}
    <a
      className={'s1 medium'}
      href={appLinks.callLoopCompliance}
      target={'_blank'}
      onClick={(e) => e.stopPropagation()}
      rel="noreferrer"
    >
      Learn more.
    </a>
  </>
)

export const ComplianceContent = observer(
  ({
    store,
    complianceMessageTT = defaultComplianceMessageTT,
  }: IComplianceContentProps) => {
    return (
      <>
        <InputCollapsableCheckbox
          label={'Compliance message'}
          tooltipTitle={complianceMessageTT}
          checked={store.compliance}
          onChecked={() => {
            store.setCompliance(!store.compliance)
          }}
          collapsableContent={
            <EditableFieldText
              editableText={store.nonRequiredComplianceText}
              setEditableText={store.setNoneRequiredComplianceText}
              REQUIRED_TEXT={store.REQUIRED_COMPLIANCE_TEXT}
              NOT_REQUIRED_TEXT={store.NOT_REQUIRED_COMPLIANCE_TEXT}
            />
          }
          styleProps={{
            margin: '6px -12px',
          }}
        />
        <Separator />
      </>
    )
  }
)
