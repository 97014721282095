import { observer } from 'mobx-react-lite'
import { AddContactsBy } from 'components/AddContactsBy/AddContactsBy'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import styles from './styles.module.scss'
import { Button } from 'shared/ui/Button/Button'
import classNames from 'classnames'
import { InView } from 'react-intersection-observer'
import { InvisibleTooltipBtn } from './InvisibleTooltipBtn/InvisibleTooltipBtn'
import { useContactsPickerContext } from 'widgets/ContactsPicker/context/context'
import { PickerList } from 'widgets/ContactsPicker/ui/PickerList/PickerList'
import { Label } from 'shared/ui/Label/Label'
import { Close, Icon } from 'components/Icon'

export const ContactsPickerUi = observer(() => {
  const {
    search,
    setSearch,
    selectedItems,
    setContentType,
    onDeleteItem,
    visibleItemsMap,
    invisibleItems,
    lastVisibleId,
  } = useContactsPickerContext()

  const onToggleOpen = (open: boolean) => {
    if (!open) {
      setContentType('all')
      setSearch('')
    }
  }

  return (
    <>
      <Label text={'Send to'} marginBottom={8} />
      <DropdownField
        isFullWidth
        isSameWidth
        placement={'top'}
        dropdownContent={() => <PickerList />}
        onToggleOpen={onToggleOpen}
        triggerContent={(open) => (
          <div
            className={classNames(styles.wrapInput, {
              [styles.focus]: open,
            })}
          >
            <div className={styles.left}>
              <div className={styles.wrapTags}>
                {selectedItems.map((item) => (
                  <InView
                    onChange={(inView) =>
                      visibleItemsMap.set(`${item.group}_${item.id}`, inView)
                    }
                    key={item.id}
                    threshold={1}
                  >
                    {({ ref }) => (
                      <div
                        ref={ref}
                        key={item.id}
                        className={styles.wrapElement}
                      >
                        <Button
                          variant={'x-small'}
                          LeftIcon={item.dropdownButtonProps?.LeftIcon}
                          text={item.text}
                          count={item.dropdownButtonProps?.count}
                          rightContent={
                            <Button
                              variant={'icon'}
                              onClick={() => {
                                onDeleteItem(item)
                              }}
                            >
                              <Icon>
                                <Close />
                              </Icon>
                            </Button>
                          }
                        />
                        {!!invisibleItems.length &&
                          `${item.group}_${item.id}` === lastVisibleId && (
                            <InvisibleTooltipBtn />
                          )}
                      </div>
                    )}
                  </InView>
                ))}
                <input
                  type="text"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  placeholder={'Select'}
                />
              </div>
            </div>

            <AddContactsBy noWebForm noKeyword />
          </div>
        )}
      />
    </>
  )
})
