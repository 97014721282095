// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tzuLE5bSIu0qiNiiaT8g{max-height:400px;overflow:auto}.cXsIW0xYFaVukVlgPEl2{display:flex;flex-direction:column}.cXsIW0xYFaVukVlgPEl2 .AhED4IwbYXzBxrMMf65Q{margin-top:8px;padding:8px}.cXsIW0xYFaVukVlgPEl2 .Riu0rGzQDQPFoHTsEQwX{padding:4px}`, "",{"version":3,"sources":["webpack://./src/widgets/ContactsPicker/ui/InvisibleTooltipBtn/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,gBAAA,CACA,aAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,4CACE,cAAA,CACA,WAAA,CAGF,4CACE,WAAA","sourcesContent":[".body{\n  max-height: 400px;\n  overflow: auto;\n}\n\n.wrapGroup{\n  display: flex;\n  flex-direction: column;\n  .title{\n    margin-top: 8px;\n    padding: 8px;\n\n  }\n  .button{\n    padding: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `tzuLE5bSIu0qiNiiaT8g`,
	"wrapGroup": `cXsIW0xYFaVukVlgPEl2`,
	"title": `AhED4IwbYXzBxrMMf65Q`,
	"button": `Riu0rGzQDQPFoHTsEQwX`
};
export default ___CSS_LOADER_EXPORT___;
