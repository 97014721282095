import { makeAutoObservable } from 'mobx'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import { ReviewBroadcast } from 'src/pages/main/broadcasts/createBroadcast/ReviewBroadcast/ReviewBroadcast'
import { GetBroadcastEstimatedPriceQuery } from 'src/generated/graphql'
import billingStore from 'store/settings/billing/billingStore'
import { CreateBroadCastStore } from 'store/broadcasts/createBroadCastStore'
import broadcastsStore from 'store/broadcasts/broadcastsStore'

export class ReviewBroadcastStore {
  constructor(public newBroadCastStore: CreateBroadCastStore) {
    makeAutoObservable(this)
    this.openModal()
  }

  get sendTimeString() {
    return this.newBroadCastStore.scheduleStore.sendTimeString
  }

  get broadcastInput() {
    return this.newBroadCastStore.broadcastInput
  }
  get edit() {
    return !!this.broadcastInput.id
  }

  get isDisableByLimit() {
    return !!(
      billingStore.clSubscription?.limits?.broadcasts &&
      billingStore.clSubscription?.limits?.broadcasts <=
        broadcastsStore.tableStore.total &&
      !this.edit
    )
  }

  modalId = 'ReviewBroadcast'
  onClose = () => {
    modalStore.removeModal(this.modalId)
  }
  openModal = () => {
    modalStore.addModal({
      id: this.modalId,
      title: 'Review and schedule',
      onClose: this.onClose,
      children: <ReviewBroadcast store={this} />,
    })
  }

  totalContacts = 0
  totalCredits = 0
  broadcastAllowed = true
  broadcastAllowedMessage = ''

  get neededCredits() {
    if (billingStore.autoRecharge && !billingStore.isTrial) {
      return 0
    }
    return this.totalCredits - billingStore.currentCredits
  }

  setEstimate = (data: GetBroadcastEstimatedPriceQuery) => {
    this.totalContacts =
      data.getBroadcastEstimatedPrice?.activeContactsCount || 0
    this.totalCredits = data.getBroadcastEstimatedPrice?.totalPrice || 0
    this.broadcastAllowed =
      data.getBroadcastEstimatedPrice?.broadcastAllowed || false
    this.broadcastAllowedMessage =
      data.getBroadcastEstimatedPrice?.message || ''
  }
}
