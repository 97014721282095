import { observer } from 'mobx-react-lite'
import styles from 'src/pages/superAdmin/modals/CustomPlan/ui/styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { Input } from 'shared/ui/Input/Input'
import { numberString } from 'src/util/functions'
import { ChargeObject } from 'src/generated/graphql'
import { useCustomPlanContext } from 'src/pages/superAdmin/modals/CustomPlan/context/context'
import configStore from 'src/store/configStore'

export const CustomPrice = observer(
  ({ type, title }: { type: ChargeObject; title: string }) => {
    const {
      customPricesMap,
      customPricesCostMap,
      costCredit,
      setCustomPriceCreditValue,
      setCustomPriceCostValue,
    } = useCustomPlanContext()

    const creditValue = customPricesMap.get(type)
    const creditPlaceholder = configStore.defaultChargeMapPrices.get(type)

    const costValue = customPricesCostMap.get(type)
    const costPlaceholder = configStore.defaultChargeMapPrices.get(type)

    const setCreditValue = (value: number) => {
      setCustomPriceCreditValue(type, value)
    }

    const setCostValue = (value: number) => {
      setCustomPriceCostValue(type, value)
    }

    return (
      <div className={styles.row} key={type}>
        <Typography>{title}</Typography>
        <div className={styles.inputs}>
          <Input
            value={creditValue}
            setValue={setCreditValue}
            isNumber
            numericFormatProps={{ allowNegative: false, decimalScale: 4 }}
            placeholder={
              creditPlaceholder
                ? numberString({
                    val: creditPlaceholder,
                    maximumFractionDigits: 4,
                  })
                : undefined
            }
          />
          <Input
            value={costValue}
            setValue={setCostValue}
            isNumber
            numericFormatProps={{
              allowNegative: false,
              prefix: '$',
              decimalScale: 4,
            }}
            placeholder={
              costPlaceholder
                ? numberString({
                    val: costPlaceholder * costCredit,
                    currency: 'USD',
                    maximumFractionDigits: 4,
                  })
                : undefined
            }
          />
        </div>
      </div>
    )
  }
)
