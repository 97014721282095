import styles from './styles.module.scss'
import { Tag } from 'components/Tag/Tag'
import { observer } from 'mobx-react-lite'
import { PlusItems } from 'components/PlusItems/PlusItems'
import { IOption } from 'src/types/IOption'
import { FC } from 'react'
import { ContactsFill, IIconProps } from 'components/Icon'

export type IItem = {
  name: string
  id: string | number
  count?: number
  IconFC?: FC
  iconProps?: IIconProps
  group: 'contact' | 'list' | 'segment'
  backgroundColor?: IOption['backgroundColor']
  title?: IOption['title']
}

type ItemsRenderProps = {
  items: IItem[]
  itemsCount?: number
  onHoverItemsCount?: () => void
}

export const ItemsRender = observer(
  ({ items, itemsCount, onHoverItemsCount }: ItemsRenderProps) => {
    const IconFC = items[0]?.IconFC
    return (
      <div className={styles.wrapLists}>
        {!!items.length && items[0] && (
          <Tag
            text={items[0]?.name || 'Customers'}
            leftIcon={{ children: (IconFC && <IconFC />) || <ContactsFill /> }}
            maxWidth={200}
            // count={items[0]?.count}
          />
        )}
        {((items?.length > 1 && items[1]) ||
          !!(itemsCount && itemsCount > 0)) && (
          <PlusItems
            items={items.slice(1)}
            itemsCount={itemsCount}
            onHoverPlus={onHoverItemsCount}
          />
        )}
      </div>
    )
  }
)
