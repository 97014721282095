import NewTable from 'components/NewTable/NewTable'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Filters } from 'src/pages/main/contacts/segments/SegmentsTable/Filters/Filters'
import { EmptyState } from 'components/EmptyState/EmptyState'
import * as React from 'react'
import { useState } from 'react'
import { ISegment } from 'src/types/ISegment'
import segmentStore from 'store/contacts/segment/segmentStore'
import { useGetSegmentsQuery } from 'src/generated/graphql'
import { getSegmentActions } from 'src/pages/main/contacts/segments/functions/getSegmentActions'
import { useSetData } from 'src/hooks/useSetData'
import { EmptyStateStatistic } from 'components/EmptyState/EmptyStateStatistic'
import { AppElements } from 'src/enums/appElements'

export const SegmentsTable = observer(() => {
  const { tableStore } = segmentStore
  const queryResult = useGetSegmentsQuery({
    variables: {
      page: tableStore.paginationInput,
      searchPattern: tableStore.search,
    },
  })
  const [rows, setRows] = useState<ISegment[]>([])
  const navigate = useNavigate()

  const onRowClick = (id: string) => {
    navigate(String(id))
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => {
      if (data) {
        tableStore.setTotal(data?.getSegments?.total)
        const items: ISegment[] = []
        data.getSegments?.content?.forEach((item) => {
          if (item) {
            items.push({ ...item, id: item.id })
          }
        })
        setRows(items)
      }
    },
    refreshDeps: [tableStore.refreshTrigger],
  })

  if (!loading && !rows.length && !tableStore.search.trim()) {
    return (
      <EmptyState
        element={AppElements.Segment}
        description={'Improve reach rate with contact filtering'}
        heightV2
      />
    )
  }

  return (
    <NewTable<ISegment>
      withCheckbox
      columns={tableStore.visibleColumns}
      rows={rows.map((item) => {
        return {
          ...item,
          actions: getSegmentActions(item),
        }
      })}
      emptyContent={
        rows.length ? undefined : (
          <EmptyStateStatistic description={'No segments matching'} />
        )
      }
      leftStickyContent={<Filters />}
      withSearch
      onRowClick={onRowClick}
      withCalcMaxHeight
      tableStore={tableStore}
      pageLoading={loading || refetchLoading}
      firstLoading={firstLoading}
    />
  )
})
