import styles from './styles.module.scss'
import React from 'react'
import presentationStore from 'store/presentationStore'
import classNames from 'classnames'

export const SampleImageCard = ({
  url,
  className,
}: {
  url: string
  className: string
}) => {
  const onClick = () => {
    presentationStore.setSampleImageUrl(url)
  }
  return (
    <div className={classNames(styles.imgWrap, className)} onClick={onClick}>
      <div style={{ backgroundImage: `url(${url})` }} className={styles.img} />
    </div>
  )
}
