import { observer } from 'mobx-react-lite'
import { Modal } from 'components/Modal/Modal'
import modalStore from 'src/widgets/ModalContainer/store/modalStore'

export const ModalContainer = observer(() => {
  return (
    <>
      {modalStore.modals.map((modal) => {
        if (modal.variant === 'notification') {
          return <Modal key={modal.id} {...modal} />
        }
        return (
          <Modal
            key={modal.id}
            onClose={() => modalStore.closeModal(modal.id)}
            open={true}
            {...modal}
          />
        )
      })}
    </>
  )
})
