import styles from './styles.module.scss'
import { VCardGallery } from 'components/VCard/VCardGallery/VCardGallery'
import { ImageCard } from 'components/ImageCard/ImageCard'
import presentationStore from 'store/presentationStore'
import React, { FC } from 'react'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { Asset, ContactCardFragment } from 'src/generated/graphql'
import { IAudioItem } from 'src/types/IAudioItem'
import { AudioCard } from 'components/CreateElement/AudioCard/AudioCard'

type Props = {
  onDeleteVCard?: (id: number) => void
  removeAttachImages?: (ids: string[]) => void
  small?: boolean
  medium?: boolean
  fromTemplate?: boolean
  attachImages?: Asset[]
  attachAudioItems?: IAudioItem[]
  onRemoveAudio?: (audio: IAudioItem) => void
  vCards?: ContactCardFragment[]
  stopPropagation?: boolean
}
export const AttachmentGallery: FC<Props> = observer(
  ({
    onDeleteVCard,
    attachImages,
    attachAudioItems,
    removeAttachImages,
    small,
    // fromTemplate,
    vCards,
    medium,
    stopPropagation,
    onRemoveAudio,
  }) => {
    return (
      <div
        className={classNames(
          styles.imagesWrap,
          small && styles.small,
          medium && styles.medium
        )}
      >
        {!!vCards?.length &&
          vCards.map((vCard) => (
            <VCardGallery
              key={vCard.id}
              onDelete={onDeleteVCard}
              small={small}
              vCard={vCard}
              stopPropagation={stopPropagation}
            />
          ))}
        {attachImages?.map((img, index) => (
          <ImageCard
            small={small}
            medium={medium}
            img={img}
            key={img.id}
            removeAttachImages={removeAttachImages}
            onImageClick={(e) => {
              if (stopPropagation) {
                e.stopPropagation()
              }
              presentationStore.setCurrentIndex(index)
              presentationStore.setPresentationImages(attachImages)
            }}
          />
        ))}
        {attachAudioItems?.map((audio) => (
          <AudioCard
            key={audio.id}
            audio={audio}
            onRemove={onRemoveAudio}
            variant={'attachment'}
            from={'AttachmentGallery'}
          />
        ))}
      </div>
    )
  }
)
