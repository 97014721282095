import { observer } from 'mobx-react-lite'
import { SelectList } from 'components/SelectList/SelectList'
import { Input } from 'shared/ui/Input/Input'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import React, { useEffect } from 'react'
import { DropdownButton } from 'shared/ui/DropdownButton/DropdownButton'
import { useTrackConversionContext } from 'widgets/TrackConversion/context/context'
import { useGetConversionsQuery } from 'src/generated/graphql'
import { openNewConversionModal } from 'src/features/conversions/openNewConversionModal'
import { Typography } from 'shared/ui/Typography'
import { Currencies, numberString } from 'src/util/functions'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { IConversion } from 'src/pages/settings/conversions/conversions/store/type'
import { AngleDown, AngleUp, Plus } from 'components/Icon'

export const ConversionSelect = observer(() => {
  const {
    conversion,
    page,
    search,
    setData,
    onSelect,
    conversions,
    setConversion,
    isShowLoadingMore,
    handleLoadMore,
  } = useTrackConversionContext()
  const { data, refetch } = useGetConversionsQuery({
    variables: {
      page,
      searchPattern: search,
    },
  })

  useEffect(() => {
    if (data) {
      setData(data)
    }
  }, [data])

  return (
    <DropdownField
      isSameWidth
      isFullWidth
      withToggleOpen
      dropdownContent={(_: boolean, setOpen) => (
        <SelectList
          handleLoadMore={handleLoadMore}
          isShowLoadingMore={isShowLoadingMore}
          options={conversions.map(
            (item): IListItem<IConversion> => ({
              id: item.id,
              item: item,
              text: item.name,
              isActive: item.id === conversion?.id,
              dropdownButtonProps: {
                rightContentProps: {
                  rightContent: !!item.defaultAmount && (
                    <Typography>
                      {numberString({
                        val: item.defaultAmount,
                        currency: Currencies.usd,
                      })}
                    </Typography>
                  ),
                },

                // secondaryText: item.urlPattern || '',
              },
            })
          )}
          onSelect={onSelect}
          bottomContent={
            <DropdownButton
              text={'New conversion'}
              fullWidth
              primary
              LeftIcon={Plus}
              onClick={() => {
                setOpen(false)
                openNewConversionModal((response) => {
                  setConversion(response)
                  refetch()
                })
              }}
            />
          }
        />
      )}
      triggerContent={(open) => (
        <Input
          isReadOnly
          value={conversion?.name}
          placeholder={'Select'}
          rightContent={
            <>
              {conversion?.defaultAmount && (
                <Typography color={'--primary-color-gray-1'}>
                  {numberString({
                    val: conversion?.defaultAmount,
                    currency: Currencies.usd,
                  })}
                </Typography>
              )}
              <Icon color={'--primary-color-gray-1'} fontSize={16}>
                {open ? <AngleUp /> : <AngleDown />}
              </Icon>
            </>
          }
        />
      )}
    />
  )
})
