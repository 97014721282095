import alertStore from 'store/alertStore'
import { ApolloError } from '@apollo/client'

export const handleCardError = (
  error: ApolloError,
  cb: (error: string) => void
) => {
  const userMessage = error?.graphQLErrors[0].extensions?.userMessage as string
  if (userMessage || error.message.includes('Your card was declined.')) {
    cb(userMessage || 'Please provide another credit card')
    alertStore.disabledErrorAlert(error.message)
  }
}
