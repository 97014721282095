import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { ReactComponent as Close } from 'icons/16px/CloseBlack.svg'
import { Button } from 'components/Button/Button'
import React, { FC, useState } from 'react'
import classNames from 'classnames'
import createVCardStore from 'store/settings/createVCardStore'
import { ContactCardFragment, MemberRole } from 'src/generated/graphql'
import user from 'store/user/user'

type Props = {
  onDelete?: (id: number) => void
  width?: number
  small?: boolean
  vCard: ContactCardFragment
  stopPropagation?: boolean
}

export const VCardGallery: FC<Props> = observer(
  ({ stopPropagation, onDelete, width, small, vCard }) => {
    const isDisabled =
      user.member?.role !== MemberRole.Owner &&
      vCard.member?.id !== user.member?.id
    const [hover, setHover] = useState(false)
    const onMouseEnter = () => {
      setHover(true)
    }
    const onMouseLeave = () => {
      setHover(false)
    }
    const onCardClick = (e: React.MouseEvent) => {
      if (stopPropagation) {
        e.stopPropagation()
      }
      createVCardStore.openModal(vCard)
    }
    const handleDelete = (e: React.MouseEvent) => {
      e.stopPropagation()
      onDelete && onDelete(vCard.id)
    }
    const split = vCard.name?.split(' ') || []
    const logoString =
      (split[0] && split[0][0]) || ('' + split[1] && split[1][0]) || '' || ''
    return (
      <div
        className={classNames(styles.wrap, small && styles.small)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onCardClick}
        style={{ width }}
      >
        <div className={styles.logo}>
          <span>{logoString}</span>
        </div>
        <div className={styles.content}>
          <p className={'s2 ellipsisNowrap'}>{vCard.name}</p>
          <span className={'s2 gray1'}>{vCard.formattedPhoneNumber}</span>
        </div>

        {hover && onDelete && !isDisabled && (
          <div className={styles.closeX}>
            <Button onClick={handleDelete}>
              <Close />
            </Button>
          </div>
        )}
      </div>
    )
  }
)
