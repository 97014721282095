import { useEffect } from 'react'
import { useGetConversionsTotalStatisticsQuery } from 'src/generated/graphql'
import { observer } from 'mobx-react-lite'
import { useConversionsTableContext } from 'widgets/conversions/ConversionsTable/context/context'
import { DetailCardsWrapper } from 'components/DetailCard/DetailCardsWrapper'
import { detailsCards } from 'components/DetailCard/variants'
import { Currencies, numberString } from 'src/util/functions'

export const AdditionalContent = observer(() => {
  const { tableStore } = useConversionsTableContext()

  const { data, refetch } = useGetConversionsTotalStatisticsQuery({
    variables: {
      dateRange: tableStore.rangeDatePickerStore.dateRange,
    },
  })

  useEffect(() => {
    refetch().catch(console.error)
  }, [tableStore.refreshTrigger])

  return (
    <DetailCardsWrapper
      cards={[
        detailsCards.custom({
          title: 'Conversions',
          count: `${numberString({
            val: data?.getConversionsTotalStatistics?.total || 0,
          })}`,
          hidePercent: true,
        }),
        detailsCards.custom({
          title: 'Avg conversion rate',
          count: `${numberString({
            val: data?.getConversionsTotalStatistics?.percentage || 0,
            maximumFractionDigits: 2,
          })}%`,
          hidePercent: true,
        }),
        detailsCards.custom({
          title: 'Revenue',
          count: numberString({
            val: data?.getConversionsTotalStatistics?.revenue || 0,
            currency: Currencies.usd,
          }),
          hidePercent: true,
        }),
        detailsCards.custom({
          title: 'Cost',
          count: `${numberString({
            val: data?.getConversionsTotalStatistics?.totalCosts || 0,
            currency: Currencies.usd,
            maximumFractionDigits: 2,
          })}`,
          hidePercent: true,
        }),
        detailsCards.custom({
          title: 'ROI',
          count: `${numberString({
            val: data?.getConversionsTotalStatistics?.roi || 0,
            maximumFractionDigits: 1,
          })}%`,
          hidePercent: true,
        }),
      ]}
      noSelect
    />
  )
})
