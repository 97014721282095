import { Button } from 'components/Button/Button'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import DatePickerLib from 'react-datepicker'
import { FC, useEffect } from 'react'
import styles from './styles.module.scss'
import { ReactComponent as Calendar } from 'icons/16px/Calendar.svg'
import { ReactComponent as AngleUp } from 'icons/16px/Angle-Up.svg'
import { ReactComponent as AngleDown } from 'icons/16px/Angle-Down.svg'
import dayjs from 'lib/dayjs'
import classNames from 'classnames'
import { RangeDatePickerStore } from 'store/rangeDatePickerStore/rangeDatePickerStore'
import { observer } from 'mobx-react-lite'
import { rangeOptions } from 'store/rangeDatePickerStore/types'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'

type Props = {
  rangeDatePickerStore: RangeDatePickerStore
  position?: TTPositionEnum
  noLeftBar?: boolean
  withTime?: boolean
  timeFormat?: string
  dateFormat?: string
  fullWidth?: boolean
  readonly?: boolean
}

export const RangeDatePicker: FC<Props> = observer(
  ({
    rangeDatePickerStore,
    position = TTPositionEnum.bottomLeft,
    noLeftBar,
    withTime,
    timeFormat = '[at] h:mm A',
    dateFormat = 'MMM D, YYYY',
    fullWidth,
    readonly,
  }) => {
    const { trigger, showTT, setTrigger, onTriggerClick } = useFixedTooltip()

    const onChange = (dates: [Date, Date]) => {
      const [start, end] = dates
      if (!withTime) {
        start && start.setHours(0, 0, 0)
        end && end.setHours(23, 59, 59, 999)
      }

      rangeDatePickerStore.setStartDate(start)
      rangeDatePickerStore.setEndDate(end)
      rangeDatePickerStore.selectedPeriod &&
        rangeDatePickerStore.setSelectedPeriod(null)
      if (rangeDatePickerStore.allTime) {
        rangeDatePickerStore.setAllTime(false)
      }
    }

    useEffect(() => {
      if (rangeDatePickerStore.selectedPeriod) {
        rangeDatePickerStore.setStartDate(
          new Date(rangeDatePickerStore.selectedPeriod.value[0])
        )
        rangeDatePickerStore.setEndDate(
          new Date(rangeDatePickerStore.selectedPeriod.value[1])
        )
      }
    }, [rangeDatePickerStore.selectedPeriod])

    return (
      <>
        <div ref={setTrigger}>
          <Button
            onClick={readonly ? undefined : onTriggerClick}
            className={classNames(styles.dropdown, showTT && styles.active, {
              [styles.readonly]: readonly,
            })}
            fullWidth={fullWidth}
          >
            <div className={styles.row}>
              <Calendar />
              {rangeDatePickerStore.allTime ? (
                <span>All time</span>
              ) : (
                <>
                  <span>
                    {dayjs(rangeDatePickerStore.startDate).format(dateFormat)}{' '}
                    {withTime && (
                      <span>{`${dayjs(
                        rangeDatePickerStore.startTime || new Date()
                      ).format(timeFormat)}`}</span>
                    )}{' '}
                    - {dayjs(rangeDatePickerStore.endDate).format(dateFormat)}{' '}
                    {withTime && (
                      <span>{`${dayjs(
                        rangeDatePickerStore.endTime || new Date()
                      ).format(timeFormat)}`}</span>
                    )}
                  </span>
                </>
              )}
            </div>
            {!readonly && <>{showTT ? <AngleUp /> : <AngleDown />}</>}
          </Button>
        </div>

        <FixedTooltip
          trigger={trigger}
          visible={showTT}
          noArrow
          white
          position={position}
        >
          <div
            className={classNames(
              styles.wrapContent,
              noLeftBar && styles.noLeftBar
            )}
          >
            {!noLeftBar && (
              <div className={styles.options}>
                {rangeOptions.map((option) => (
                  <Button
                    key={option.title}
                    menuBtn
                    hoverGray
                    onClick={() => {
                      rangeDatePickerStore.setAllTime(false)
                      rangeDatePickerStore.setSelectedPeriod(option)
                    }}
                    active={
                      rangeDatePickerStore.selectedPeriod?.title ===
                      option.title
                    }
                  >
                    {option.title}
                  </Button>
                ))}
                <Button
                  menuBtn
                  hoverGray
                  onClick={() => {
                    rangeDatePickerStore.setAllTime(true)
                    rangeDatePickerStore.setStartDate(null)
                    rangeDatePickerStore.setEndDate(null)
                  }}
                  active={rangeDatePickerStore.allTime}
                >
                  All time
                </Button>
              </div>
            )}
            <DatePickerLib
              onChange={onChange}
              inline
              selected={rangeDatePickerStore.startDate}
              startDate={rangeDatePickerStore.startDate}
              endDate={rangeDatePickerStore.endDate}
              selectsRange
              monthsShown={2}
              renderCustomHeader={({
                monthDate,
                customHeaderCount,
                decreaseMonth,
                increaseMonth,
              }) => (
                <div>
                  <button
                    aria-label="Previous Month"
                    className={
                      'react-datepicker__navigation react-datepicker__navigation--previous'
                    }
                    style={
                      customHeaderCount === 1
                        ? { visibility: 'hidden' }
                        : undefined
                    }
                    onClick={() => {
                      decreaseMonth()
                      decreaseMonth()
                    }}
                  >
                    <span
                      className={
                        'react-datepicker__navigation-icon react-datepicker__navigation-icon--previous'
                      }
                    >
                      {'<'}
                    </span>
                  </button>
                  <span className="react-datepicker__current-month">
                    {monthDate.toLocaleString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </span>
                  <button
                    aria-label="Next Month"
                    className={
                      'react-datepicker__navigation react-datepicker__navigation--next'
                    }
                    style={
                      customHeaderCount === 0
                        ? { visibility: 'hidden' }
                        : undefined
                    }
                    onClick={() => {
                      increaseMonth()
                      increaseMonth()
                    }}
                  >
                    <span
                      className={
                        'react-datepicker__navigation-icon react-datepicker__navigation-icon--next'
                      }
                    >
                      {'>'}
                    </span>
                  </button>
                </div>
              )}
            />
          </div>
          {withTime && (
            <div className={styles.wrapTimes}>
              <div className={classNames(styles.timePicker, styles.left)}>
                <TimePicker
                  startTime={rangeDatePickerStore.startTime || new Date()}
                  onChange={(time) => rangeDatePickerStore.setStartTime(time)}
                />
                <div className={styles.border} />
              </div>
              <div className={styles.timePicker}>
                <TimePicker
                  startTime={rangeDatePickerStore.endTime || new Date()}
                  onChange={(time) => rangeDatePickerStore.setEndTime(time)}
                />
              </div>
            </div>
          )}
        </FixedTooltip>
      </>
    )
  }
)
