import { createElement } from 'react'
import { ITypographyProps } from 'shared/ui/Typography/types'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { typographyDefaultTag } from 'shared/ui/Typography/variants'

export const Typography = ({
  variant = 's1-regular',
  color,
  isLink,
  tag,
  isEllipsis,
  isUnsetColor,
  fontFamily,
  action,
  ...props
}: ITypographyProps) => {
  const type = variant?.split('-')[0]
  const weight = variant?.split('-')[1]

  return createElement(tag || typographyDefaultTag[type], {
    color,
    ...props,
    className: classNames(
      styles.common,
      {
        [styles[`type__${type}`]]: type,
        [styles[`weight__${weight}`]]: weight,
        [styles[`fontFamily__${fontFamily}`]]: fontFamily,
        [styles[`action__${action}`]]: action,
        [styles.link]: isLink,
        [styles.ellipsis]: isEllipsis,
        [styles.unsetColor]: isUnsetColor,
      },
      props.className
    ),
  })
}
