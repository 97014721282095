// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yPzW8oz22pCwqaEOWQkh{padding:12px;display:flex;column-gap:8px;width:200px;background:var(--primary-color-gray-4);border-radius:16px;align-items:center;margin-top:8px}`, "",{"version":3,"sources":["webpack://./src/components/Preview/DisabledMessage/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,cAAA,CACA,WAAA,CACA,sCAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA","sourcesContent":[".wrap{\n  padding: 12px;\n  display: flex;\n  column-gap: 8px;\n  width: 200px;\n  background: var(--primary-color-gray-4);\n  border-radius: 16px;\n  align-items: center;\n  margin-top: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `yPzW8oz22pCwqaEOWQkh`
};
export default ___CSS_LOADER_EXPORT___;
