import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import billingStore from 'store/settings/billing/billingStore'
import styles from './styles.module.scss'
import { Alert } from 'components/Alert/Alert'
import { useEffect, useState } from 'react'
import { SizedBox } from 'components/SizedBox'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { InputCheckbox } from 'components/Input/InputCheckbox/InputCheckbox'
import { appLinks } from 'src/util/links'
import { Li } from 'components/Ul/Li'
import { Ul } from 'components/Ul/Ul'
import {
  CancellationReasonType,
  useCancelSubscriptionMutation,
} from 'src/generated/graphql'
import { AlertTypeEnum } from 'src/enums/AlertTypeEnum'
import { CancellationFeedbackContent } from 'components/Feedbackontent/CancellationFeedbackContent'

export const ContinueCancelSubscriptionModal = observer(() => {
  const [cancelSubscription, { loading }] = useCancelSubscriptionMutation()
  const [step, setStep] = useState(0)
  const [agree, setAgree] = useState(false)
  const [cancellationReason, setCancellationReason] =
    useState<CancellationReasonType | null>(null)
  const [comment, setComment] = useState('')
  const isTrial = billingStore.isTrial
  const onClose = () => {
    billingStore.setOpenContinueCancelSubscriptionModal(false)
  }
  const onContinue = async () => {
    if (step === 0) {
      // if (
      //   selectedAnswer === FeedbackEnum.budgetReasons &&
      //   billingStore.clSubscription?.price?.planName !== 'ProMonthly250' &&
      //   billingStore.clSubscription?.price?.planName !== 'PayAsYouGo'
      // ) {
      //   onClose()
      //   upgradePlanStore.setOpenUpgradePlanModal(true, true)
      // } else {
      //   setStep(1)
      // }
      setStep(1)
    } else {
      if (!cancellationReason) {
        return
      }
      try {
        const { data } = await cancelSubscription({
          variables: {
            userInput: {
              comment,
              cancellationReason,
            },
          },
        })
        if (data?.cancelSubscription) {
          billingStore.setClSubscription(data?.cancelSubscription)
          billingStore.setOpenContinueCancelSubscriptionModal(false)
          billingStore.setOpenSuccessCanceledSubscriptionModal(true)
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  useEffect(() => {
    setStep(0)
    setAgree(false)
  }, [billingStore.openContinueCancelSubscriptionModal])
  return (
    <Modal
      open={billingStore.openContinueCancelSubscriptionModal}
      onClose={onClose}
      title={'Cancel subscription'}
    >
      <div className={'mediumModal'}>
        <Alert
          title={"We're sorry you decided to cancel your subscription"}
          type={AlertTypeEnum.delete}
          text={
            step === 0
              ? 'Before you go we value your feedback. Please let us know, what is your main reason for cancelling.'
              : 'You can still enjoy all Call Loop features until your current plan expires. After your plan is canceled, you have 30 days to reopen your account. Overwise, your data will be permanently deleted.'
          }
        />
        <SizedBox height={24} />
        {step === 0 ? (
          <CancellationFeedbackContent
            selectedAnswer={cancellationReason}
            setSelectedAnswer={setCancellationReason}
            withoutBudgetReason={isTrial}
            comment={comment}
            setComment={setComment}
            isRequiredComment
          />
        ) : (
          <div>
            <div className={styles.card}>
              <p className={'par2 bold mb16'}>
                In 30 days, this action will permanently delete…
              </p>
              <Ul>
                <Li>
                  <p className={'par2'}>Your profile</p>
                </Li>
                <Li>
                  <p className={'par2'}>All of your contacts</p>
                </Li>
                <Li>
                  <p className={'par2'}>All of your credits</p>
                </Li>
                <Li>
                  <p className={'par2'}>All of your messages</p>
                </Li>
              </Ul>
            </div>
            <InputCheckbox
              className={styles.checkbox}
              label={
                <div className={styles.titleContent}>
                  I agree to the{' '}
                  <a
                    target={'_blank'}
                    href={appLinks.tos}
                    onClick={(event) => event.stopPropagation()}
                    rel="noreferrer"
                  >
                    <span className={'link ml4 medium'}>
                      Terms and Conditions
                    </span>
                  </a>
                  , to delete my account
                </div>
              }
              checked={agree}
              onChecked={() => setAgree(!agree)}
            />
          </div>
        )}

        <div className={styles.actions}>
          <Button
            typeBtn={BtnType.primary}
            size={BtnSize.medium}
            onClick={onClose}
          >
            {step === 0
              ? `Keep my ${isTrial ? 'trial' : 'current plan'}`
              : 'Cancel'}
          </Button>
          <Button
            typeBtn={BtnType.secondaryGray}
            size={BtnSize.medium}
            onClick={onContinue}
            disabled={
              step === 0
                ? !cancellationReason || !comment.trim().length
                : !agree
            }
            loading={loading}
          >
            {step === 0 ? 'Continue with cancellation' : 'Cancel subscription'}
          </Button>
        </div>
      </div>
    </Modal>
  )
})
