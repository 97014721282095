import { getCssColor, IColor } from 'styles/colors'
import { CSSProperties } from 'react'

export const Separator = ({
  color = '--primary-color-gray-3' as IColor,
  width = '100%',
  marginLeft,
  marginBlock,
  marginBottom,
}: {
  color?: IColor
  width?: CSSProperties['width']
  marginBottom?: CSSProperties['marginBottom']
  marginBlock?: CSSProperties['marginBlock']
  marginLeft?: CSSProperties['marginLeft']
}) => (
  <div
    style={{
      height: 1,
      width,
      background: getCssColor(color),
      marginLeft,
      marginBlock,
      marginBottom,
    }}
  />
)
