import { Icon } from 'components/Icon/Icon'
import { observer } from 'mobx-react-lite'
import { Tabs } from 'components/Tabs/Tabs'
import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { VoicePreviewPlayer } from 'src/Modals/CreateTextToSpeechModal/components/ChooseVoice/VoicePreviewPlayer'
import { useCreateTextToSpeechModalContext } from 'src/Modals/CreateTextToSpeechModal/context/context'
import { AngleDown, AngleUp, Recording } from 'components/Icon'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import { SelectList } from 'components/SelectList/SelectList'
import { DropdownButton } from 'shared/ui/DropdownButton/DropdownButton'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import {
  IResponseVoice,
  IResponseVoiceType,
} from 'src/Modals/CreateTextToSpeechModal/store/type'
import { FlagByLangCode } from 'shared/ui/Flag/FlagByLangCode'

export const ChooseVoice = observer(() => {
  const {
    setActiveTabIndex,
    activeTabIndex,
    onSelectItem,
    standardLengthString,
    premiumLengthString,
    voices,
    selectedItem,
    audio,
    voiceMap,
    textareaStore,
  } = useCreateTextToSpeechModalContext()

  const voiceToVoiceItem = (voice: IResponseVoice): IListItem => ({
    id: voice.voiceId,
    text: voice.voiceName,
    isActive: voice.voiceId === selectedItem?.id,
    item: voice,
    dropdownButtonProps: {
      disabled:
        textareaStore.hasPersonalizes && !voice.langCode.startsWith('en'),
      muiTooltipProps:
        textareaStore.hasPersonalizes && !voice.langCode.startsWith('en')
          ? {
              title: 'Personalization is supported only for English language.',
              placement: 'left',
              arrow: true,
            }
          : undefined,
      leftContent: (
        <>
          <VoicePreviewPlayer voice={voice} />
          <FlagByLangCode langCode={voice.langCode} />
        </>
      ),
    },
  })

  const options: IListItem[] = voices.map(voiceToVoiceItem)

  useEffect(() => {
    if (options.length && !selectedItem) {
      const voice = audio?.ttsMessage?.voice
      const TTSVoiceType =
        voice?.type ||
        (window.localStorage.getItem('TTSVoiceType') as IResponseVoiceType)

      const TTSVoiceId =
        voice?.pollyId || window.localStorage.getItem('TTSVoiceId')
      if (TTSVoiceType && TTSVoiceId) {
        const savedVoice = voiceMap
          .get(TTSVoiceType as IResponseVoiceType)
          ?.find((voice) => voice.voiceId === TTSVoiceId)

        if (savedVoice) {
          setActiveTabIndex(TTSVoiceType === 'STANDARD' ? 0 : 1)
          const item = voiceToVoiceItem(savedVoice)
          onSelectItem(item.id, item)
          return
        }
      }
      onSelectItem(options[0].id, options[0])
    }
  }, [options, selectedItem, audio])

  return (
    <>
      <DropdownField
        isFullWidth
        withToggleOpen
        placement={'bottom-start'}
        dropdownContent={() => (
          <SelectList
            isScrollToSelectOnMount
            isLocalSearch
            options={options}
            onSelect={onSelectItem}
            topContent={
              <Tabs
                tabs={[
                  `Basic ${standardLengthString}`,
                  `Premium ${premiumLengthString}`,
                ]}
                activeTabIndex={activeTabIndex}
                onTabClick={setActiveTabIndex}
                className={styles.tabs}
              />
            }
          />
        )}
        triggerContent={(open) => (
          <DropdownButton
            leftContent={
              <>
                <Icon>
                  <Recording />
                </Icon>
                <FlagByLangCode langCode={selectedItem?.item?.langCode} />
              </>
            }
            text={selectedItem?.text as string}
            rightContentProps={open ? AngleUp : AngleDown}
          />
        )}
      />
    </>
  )
})
