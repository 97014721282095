// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BTZADO45U7zB4u0plvcC{display:flex;justify-content:flex-end}.sjvYNG8E8Oph3OnuLdd8{margin-bottom:4px;border-radius:16px;background:var(--primary-color-blue-3);display:inline-flex;padding:12px;flex-direction:column;align-items:flex-start;gap:8px}.sjvYNG8E8Oph3OnuLdd8 ._OgjL4J1TUqL2wt6Q0Yl{color:var(--primary-color-white)}.sjvYNG8E8Oph3OnuLdd8 ._OgjL4J1TUqL2wt6Q0Yl[placeholder]:empty::before{color:var(--primary-color-blue-6);content:attr(placeholder)}`, "",{"version":3,"sources":["webpack://./src/components/Preview/PreviewReply/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,wBAAA,CAGF,sBACE,iBAAA,CACA,kBAAA,CACA,sCAAA,CACA,mBAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,OAAA,CACA,4CACE,gCAAA,CACA,uEACE,iCAAA,CACA,yBAAA","sourcesContent":[".wrap{\n  display: flex;\n  justify-content: flex-end;\n}\n\n.reply{\n  margin-bottom: 4px;\n  border-radius: 16px;\n  background: var(--primary-color-blue-3);\n  display: inline-flex;\n  padding: 12px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 8px;\n  .text{\n    color: var(--primary-color-white);\n    &[placeholder]:empty::before {\n      color: var(--primary-color-blue-6);\n      content: attr(placeholder);\n    }\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `BTZADO45U7zB4u0plvcC`,
	"reply": `sjvYNG8E8Oph3OnuLdd8`,
	"text": `_OgjL4J1TUqL2wt6Q0Yl`
};
export default ___CSS_LOADER_EXPORT___;
