import { AddFiltersModalUi } from 'widgets/AddFiltersModal/ui/AddFiltersModalUi'
import { type AddFiltersModalStore } from 'widgets/AddFiltersModal/store/AddFiltersModalStore'
import { AddFiltersModalContext } from 'widgets/AddFiltersModal/context/context'
import { ContactsPickerContext } from '../ContactsPicker'

export const AddFiltersModal = ({ store }: { store: AddFiltersModalStore }) => {
  return (
    <AddFiltersModalContext.Provider value={store}>
      <ContactsPickerContext.Provider value={store.contactsPickerStore}>
        <AddFiltersModalUi />
      </ContactsPickerContext.Provider>
    </AddFiltersModalContext.Provider>
  )
}
