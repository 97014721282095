import classNames from 'classnames'
import styles from 'components/Input/InputText/styles.module.scss'
import { ReactComponent as Point } from 'assets/icons/16px/Bullet Point.svg'
import { ReactComponent as Check } from 'assets/icons/16px/Check.svg'
import { CSSProperties, FC, ReactNode } from 'react'
import { AppIcon, IIconType } from 'components/AppIcon/AppIcon'
import { ActionsEnum } from 'components/Button/types'
import { ColorsNames } from 'styles/variables'
import { ITypographyProps, Typography } from 'shared/ui/Typography'

type Props = {
  text: string
  neutral?: boolean
  isValid?: boolean
  mt?: number
  rightContent?: ReactNode
  typographyProps?: ITypographyProps
  style?: CSSProperties
}

export const ValidItem: FC<Props> = ({
  text,
  neutral,
  isValid,
  mt,
  rightContent,
  typographyProps,
  style,
}) => (
  <div
    className={classNames(
      styles.li,
      !neutral && isValid && styles.positive,
      !neutral && !isValid && styles.negative
    )}
    style={{ marginTop: mt, ...style }}
  >
    {neutral ? (
      <Point />
    ) : isValid ? (
      <Check />
    ) : (
      <AppIcon
        icon={ActionsEnum.close}
        color={ColorsNames.error1}
        type={IIconType.stroke}
      />
    )}
    <Typography
      variant={'s2-medium'}
      color={
        neutral
          ? '--primary-color-gray-1'
          : isValid
          ? '--states-color-success-1'
          : '--states-color-error-1'
      }
      {...typographyProps}
    >
      {text}
    </Typography>
    {rightContent}
  </div>
)
