import { observer } from 'mobx-react-lite'
import { Preview } from 'components/Preview/Preview'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import { PreviewSchedule } from 'components/Preview/PreviewSchedule/PreviewSchedule'
import { PreviewSMSContent } from 'components/Preview/PreviewSMSContent/PreviewSMSContent'
import { UiPreviewSMSContent } from 'components/Preview/PreviewSMSContent/UiPreviewSMSContent'
import { PreviewReply } from 'components/Preview/PreviewReply/PreviewReply'

export const PreviewWebFrom = observer(() => {
  const { textareaStore, smsPhone, doubleOptIn, fullDoubleOptInText } =
    useCreateWebFromContext()
  return (
    <Preview
      customContent={
        <>
          <PreviewSchedule />
          {doubleOptIn && (
            <>
              <UiPreviewSMSContent
                phone={smsPhone}
                text={fullDoubleOptInText}
              />
              <PreviewReply text={'Yes'} />
            </>
          )}
          <PreviewSMSContent phone={smsPhone} store={textareaStore} />
        </>
      }
    />
  )
})
