import styles from './styles.module.scss'
import React from 'react'
import { observer } from 'mobx-react-lite'
import { useCreateWebFromContext } from 'src/pages/main/webForms/create/CreateWebformState'
import webFormStore from 'store/webForms/webFormStore'
import uiStore from 'store/uiStore'
import { useNavigate } from 'react-router-dom'
import { RoutesEnum } from 'src/pages/routes'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { MenuBtn, MenuEnum } from 'components/Button/MenuBtn'
import { useWebFormFunctions } from 'src/pages/main/webForms/hooks/useWebFormFunctions'
import {
  Status,
  useCreateWebFormMutation,
  useUpdateWebFormMutation,
} from 'src/generated/graphql'
import { acceptedCreate } from 'src/pages/settings/billing/functions/acceptedCreate'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import { CloseCreateBtn } from 'components/Page/components/CloseCreateBtn'
import { AppElements } from 'src/enums/appElements'
import { useApolloError } from 'src/hooks/useApolloError'
import { Button } from 'shared/ui/Button/Button'
import { ShortLinkRequiredStore } from 'widgets/ShortLinkRequiredModal/ShortLinkRequiredStore'
import { More } from 'components/Icon'

export const ActionsContent = observer(
  ({ fromModal }: { fromModal?: boolean }) => {
    const createWebFormStore = useCreateWebFromContext()
    const [createWebForm, { loading: loadingCreate, error: errorCreate }] =
      useCreateWebFormMutation()
    const [updateWebForm, { loading: loadingUpdate, error: errorUpdate }] =
      useUpdateWebFormMutation()
    const { setTrigger, trigger, onTriggerClick, showTT, setShowTT } =
      useFixedTooltip()
    const { onTestWebForm, onDelete } = useWebFormFunctions()
    const navigate = useNavigate()

    const { ErrorAlert } = useApolloError(errorCreate || errorUpdate)

    const onSave = async (isDraft?: boolean) => {
      try {
        if (createWebFormStore.edit) {
          await updateWebForm({
            variables: {
              input: {
                ...createWebFormStore.webFormInput,
                status: (isDraft && Status.Draft) || Status.Active,
              },
            },
          })
        } else {
          await createWebForm({
            variables: {
              input: {
                ...createWebFormStore.webFormInput,
                status:
                  (isDraft && Status.Draft) ||
                  createWebFormStore.webFormInput.status,
              },
            },
          })
        }
        webFormStore.tableStore.onRefresh()
        if (fromModal) {
          webFormStore.webFormModalCB &&
            webFormStore.webFormModalCB(
              createWebFormStore.dropdownContactListsStore.selectedOptions
            )
          webFormStore.setOpenWebFormModal(false)
        } else {
          uiStore.setNextRoute('')
          navigate(RoutesEnum.webForms)
        }
      } catch (e) {
        console.error(e)
      }
    }

    const onSaveDraft = () => onSave(true)

    const handleCreate = () => {
      if (createWebFormStore.checkIsShowShortLinkRequiredModal()) {
        return new ShortLinkRequiredStore(onSaveDraft)
      } else {
        return onSave()
      }
    }

    const onCreate = () => {
      if (createWebFormStore.edit) {
        handleCreate()
      } else {
        acceptedCreate({
          limitFor: AppElements.WebForm,
          acceptedCB: handleCreate,
        })
      }
    }

    const handleTestWebFrom = () => {
      setShowTT(false)
      onTestWebForm(createWebFormStore.testWebForm)
    }

    const onCancel = () => {
      if (fromModal) {
        webFormStore.setOpenWebFormModal(false)
      } else {
        if (uiStore.isBeforeUnload) {
          uiStore.setOpenCancelCreateModal(true)
        } else {
          navigate(-1)
        }
      }
    }
    return (
      <div className={styles.wrap}>
        <ErrorAlert />
        <div className={styles.actionsWrap}>
          <div className={styles.left}>
            <Button
              variant={'primary-medium'}
              onClick={onCreate}
              disabled={createWebFormStore.disabledCreate}
              loading={loadingCreate || loadingUpdate}
              text={
                createWebFormStore.edit
                  ? createWebFormStore.webFormInput.status === Status.Draft
                    ? 'Create web form'
                    : 'Save'
                  : createWebFormStore.createBtnText
              }
            ></Button>
            {<CloseCreateBtn onCancel={onCancel} fromActions />}
          </div>
          {createWebFormStore.edit ? (
            <>
              <FixedTooltip
                visible={showTT}
                trigger={trigger}
                white
                noArrow
                position={TTPositionEnum.topRight}
                addLeftPosition={8}
              >
                <div className={styles.menuWrap}>
                  <MenuBtn
                    type={MenuEnum.testWebForm}
                    onClick={handleTestWebFrom}
                  />
                  <MenuBtn
                    type={MenuEnum.delete}
                    onClick={() =>
                      onDelete([createWebFormStore.webFormInput.id])
                    }
                  />
                </div>
              </FixedTooltip>
              <Button
                ref={setTrigger}
                variant={'secondary-medium'}
                onClick={onTriggerClick}
                LeftIcon={More}
              />
            </>
          ) : (
            <div />
          )}
        </div>
      </div>
    )
  }
)
