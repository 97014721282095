import { BroadcastsTable } from 'src/pages/main/broadcasts/BroadcastsTable/BroadcastsTable'
import { Header } from 'shared/ui/Header/Header'

export const Broadcasts = () => {
  return (
    <>
      <Header title={'Broadcasts'} />
      <BroadcastsTable />
    </>
  )
}
