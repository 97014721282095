import { FC } from 'react'
import { ActionBtnProps } from 'components/NewTable/types'
import { Status } from 'src/generated/graphql'
import { IKeyword } from 'src/types/IKeyword'
import { KeywordEditBtn } from 'src/pages/main/keywords/actions/KeywordEditBtn'
import { KeywordToggleActiveBtn } from 'src/pages/main/keywords/actions/KeywordToggleActiveBtn'
import { KeywordDuplicateBtn } from 'src/pages/main/keywords/actions/KeywordDuplicateBtn'
import { KeywordDeleteBtn } from 'src/pages/main/keywords/actions/KeywordDeleteBtn'
import { KeywordDetailsBtn } from 'src/pages/main/keywords/actions/KeywordDetailsBtn'

export const getKeywordActions = (
  item: IKeyword,
  config: {
    onSuccessDelete?: () => void
  }
): Array<FC<ActionBtnProps<IKeyword>>> => {
  const toggleActive: FC<ActionBtnProps<IKeyword>> = (props) =>
    KeywordToggleActiveBtn({ ...props, row: item })
  const edit: FC<ActionBtnProps<IKeyword>> = (props) =>
    KeywordEditBtn({ ...props, row: item })
  const duplicate: FC<ActionBtnProps<IKeyword>> = (props) =>
    KeywordDuplicateBtn({ ...props, row: item })
  const deleteKeyword: FC<ActionBtnProps<IKeyword>> = (props) =>
    KeywordDeleteBtn({
      ...props,
      row: item,
      onSuccessAction: config?.onSuccessDelete,
    })

  const detailKeyword: FC<ActionBtnProps<IKeyword>> = (props) =>
    KeywordDetailsBtn({ ...props, row: item })

  if (item?.status === Status.Draft) {
    return [edit, duplicate, deleteKeyword]
  }

  return [toggleActive, detailKeyword, edit, duplicate, deleteKeyword]
}
