import { Button } from 'shared/ui/Button/Button'
import React from 'react'
import styles from './styles.module.scss'
import modalStore from 'widgets/ModalContainer/store/modalStore'
import { IDefaultBtnProps } from 'shared/ui/Button/DefaultBtn/DefaultBtn'

export type IActionsProps = {
  buttons?: IDefaultBtnProps[]
  isPrimaryClose?: boolean
  isSecondaryClose?: boolean
  custom?: React.ReactNode
}

export const Actions = ({
  custom,
  buttons,
  isPrimaryClose,
  isSecondaryClose,
  id,
}: IActionsProps & { id: string }) => {
  const onClose = () => {
    modalStore.closeModal(id)
  }
  return (
    <div className={styles.actions}>
      {custom}
      {buttons?.map((item, index) => (
        <Button key={index} fullWidth {...item} />
      ))}

      {(isPrimaryClose || isSecondaryClose) && (
        <Button
          variant={isPrimaryClose ? 'primary-medium' : 'secondary-medium'}
          onClick={onClose}
          fullWidth
          text={'Close'}
        />
      )}
    </div>
  )
}
