import { CollectedFieldActionStore } from 'widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction/store/collectedFieldActionStore'
import { CollectedFieldActionContext } from 'widgets/CollectedFields/widgets/CollectedField/widgets/CollectedFieldAction/context/context'
import { CollectedFieldActionUi } from './ui/CollectedFieldActionUi'

export const CollectedFieldAction = ({
  store,
}: {
  store: CollectedFieldActionStore
}) => (
  <CollectedFieldActionContext.Provider value={store}>
    <CollectedFieldActionUi />
  </CollectedFieldActionContext.Provider>
)
