import { observer } from 'mobx-react-lite'
import { Preview } from 'components/Preview/Preview'
import { useCreateKeywordContext } from 'src/pages/main/keywords/create/CreateKeywordState'
import { PreviewSMSContent } from 'components/Preview/PreviewSMSContent/PreviewSMSContent'
import { Fragment } from 'react'
import { PreviewReply } from 'components/Preview/PreviewReply/PreviewReply'
import { PreviewSchedule } from 'components/Preview/PreviewSchedule/PreviewSchedule'

export const PreviewKeyword = observer(() => {
  const { name, dataCaptureStore, textareaStore, smsPhones, keywordType } =
    useCreateKeywordContext()

  const { items, thankYouMessageStore, complianceStore } = dataCaptureStore
  return (
    <Preview
      customContent={
        <>
          <PreviewReply text={name} placeholder={'Keyword name'} />
          <PreviewSchedule />
          {keywordType === 'simple' && (
            <PreviewSMSContent
              phone={smsPhones[0]}
              store={textareaStore}
              placeholder={'Auto-confirmation message...'}
            />
          )}

          {keywordType === 'data_capture' && (
            <>
              {items.map((item, index) => (
                <Fragment key={item.id}>
                  <PreviewSMSContent
                    phone={smsPhones[0]}
                    store={item.textareaStore}
                    placeholder={'Data capture question...'}
                    customCompliance={{
                      compliance:
                        index === 0 ? complianceStore.compliance : false,
                      complianceText: complianceStore.complianceText,
                    }}
                  />
                  <PreviewReply placeholder={'Reply'} />
                </Fragment>
              ))}
              {thankYouMessageStore.isNotEmpty && (
                <PreviewSMSContent
                  phone={smsPhones[0]}
                  store={thankYouMessageStore}
                />
              )}
            </>
          )}
        </>
      }
    />
  )
})
