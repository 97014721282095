import { makeAutoObservable } from 'mobx'
import { ChargeObject, GetConfigurationQuery } from 'src/generated/graphql'
import shortenLinkStore from 'store/shortenLinkStore'
import cookie from 'js-cookie'
import { CookieEnum } from 'src/enums/CookieEnum'

class ConfigStore {
  constructor() {
    makeAutoObservable(this)
  }

  isInit = false
  twilioCallRecordingNumber = ''

  dynamic_tts = false
  isDataCollection = false
  isDataCapture = false

  signUpFlow: 'B' | null | string = cookie.get(CookieEnum.Experiment) || null
  defaultChargeMapPrices: Map<ChargeObject, number> = new Map()

  setData = (data: GetConfigurationQuery) => {
    this.isInit = true
    this.twilioCallRecordingNumber =
      data.getConfiguration?.twilioCallRecordingNumber || ''
    shortenLinkStore.setShortUrlDomains(data.getConfiguration?.shortUrlDomains)

    data.getConfiguration?.defaultChargeObjectPrices?.forEach((price) => {
      if (price?.chargeObject) {
        this.defaultChargeMapPrices.set(price.chargeObject, price.creditPrice)
      }
    })
  }

  setIsDynamicTts = (value: boolean) => {
    this.dynamic_tts = value
  }

  setIsDataCollection = (value: boolean) => {
    this.isDataCollection = value
  }

  setIsDataCapture = (value: boolean) => {
    this.isDataCapture = value
  }

  setSignUpFlow = (value?: string | boolean) => {
    this.signUpFlow = value === 'B' ? 'B' : 'control'
    cookie.set(CookieEnum.Experiment, this.signUpFlow, { expires: 7 })
  }
}

export default new ConfigStore()
