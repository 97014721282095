import { IntegrationsCard } from 'src/pages/settings/integrations/content/card/IntegrationsCard'
import styles from './styles.module.scss'
import { integrationCards } from 'src/pages/settings/integrations/content/integrationCards'
import { observer } from 'mobx-react-lite'
import { useSetupIntegrations } from 'src/pages/settings/integrations/hooks/useSetupIntegrations'

export const IntegrationsContent = observer(() => {
  const { loading, accountIntegrationType: loadingIntegrationType } =
    useSetupIntegrations()

  return (
    <div className={styles.wrap}>
      {integrationCards.map((card) => (
        <IntegrationsCard
          key={card.title}
          {...card}
          loading={loading}
          loadingIntegrationType={loadingIntegrationType}
        />
      ))}
    </div>
  )
})
