// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YiPuxoCouOURZ14B5RCc{display:flex;column-gap:4px;align-items:center}.WGLQLRkkx4SClrKCLhiK{width:24px;height:24px;background-color:var(--primary-color-gray-3);display:flex;align-items:center;justify-content:center;margin-right:4px;border-radius:8px}.WGLQLRkkx4SClrKCLhiK.tiVJXQ5FkMnsuwvyUvMJ{background-color:var(--primary-color-blue-1)}.d4urQ6VKK0Q0eh4yeQhj{min-height:max-content;overflow-x:auto;margin-top:20px;margin-bottom:32px;margin-inline:64px}`, "",{"version":3,"sources":["webpack://./src/pages/main/campaigns/detail/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,cAAA,CACA,kBAAA,CAEF,sBACE,UAAA,CACA,WAAA,CACA,4CAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,gBAAA,CACA,iBAAA,CACA,2CACE,4CAAA,CAIJ,sBACE,sBAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,kBAAA","sourcesContent":[".tabItem{\n  display: flex;\n  column-gap: 4px;\n  align-items: center;\n}\n.counter{\n  width: 24px;\n  height: 24px;\n  background-color: var(--primary-color-gray-3);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-right: 4px;\n  border-radius: 8px;\n  &.active{\n    background-color: var(--primary-color-blue-1);\n  }\n}\n\n.wrapTabs{\n  min-height: max-content;\n  overflow-x: auto;\n  margin-top: 20px;\n  margin-bottom: 32px;\n  margin-inline: 64px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabItem": `YiPuxoCouOURZ14B5RCc`,
	"counter": `WGLQLRkkx4SClrKCLhiK`,
	"active": `tiVJXQ5FkMnsuwvyUvMJ`,
	"wrapTabs": `d4urQ6VKK0Q0eh4yeQhj`
};
export default ___CSS_LOADER_EXPORT___;
