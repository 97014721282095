import React, { ForwardedRef, forwardRef } from 'react'
import {
  AddButton,
  IAddButtonProps,
} from 'shared/ui/Button/AddButton/AddButton'
import {
  DefaultBtn,
  IDefaultBtnProps,
} from 'shared/ui/Button/DefaultBtn/DefaultBtn'
import {
  IXSmallButtonProps,
  XSmallButton,
} from 'shared/ui/Button/XSmallButton/XSmallButton'
import { IconBtn, IIconBtnProps } from 'shared/ui/Button/IconBtn/IconBtn'

export type IButtonProps =
  | IDefaultBtnProps
  | IAddButtonProps
  | IXSmallButtonProps
  | IIconBtnProps

export const Button = forwardRef(function button(
  props: IButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  if (props.variant === 'add') {
    return <AddButton ref={ref} {...props} />
  }
  if (props.variant === 'x-small') {
    return <XSmallButton ref={ref} {...props} />
  }
  if (props.variant === 'icon') {
    return <IconBtn ref={ref} {...props} />
  }
  return <DefaultBtn ref={ref} {...props} />
})
