import { observer } from 'mobx-react-lite'
import { useGetShortMyOrganizationListsQuery } from 'src/generated/graphql'
import { IListItem } from 'components/SelectList/ListItem/ListItem'
import { useMemo } from 'react'
import { contactListToIListItem } from 'store/contacts/functions'
import styles from '../styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { useContactsPickerContext } from 'widgets/ContactsPicker/context/context'
import { PickerSelectList } from '../PickerSelectList'

export const ListsContent = observer(() => {
  const { search } = useContactsPickerContext()
  const { data, loading } = useGetShortMyOrganizationListsQuery({
    variables: {
      page: {
        pageNumber: 0,
        pageSize: 20,
      },
      listingSearch: {
        searchPattern: search,
      },
    },
  })
  const items: IListItem[] = useMemo(() => {
    const items: IListItem[] = []

    data?.getMyOrganizationLists?.content?.forEach((list) => {
      if (list) {
        items.push(contactListToIListItem(list))
      }
    })

    return items
  }, [data])

  return (
    <PickerSelectList
      loading={loading}
      items={items}
      groupContent={{
        Lists: (
          <div className={styles.wrapGroupLabel}>
            <Typography variant={'s2-medium'} color={'--primary-color-gray-1'}>
              Lists ({data?.getMyOrganizationLists?.total})
            </Typography>
          </div>
        ),
      }}
    />
  )
})
