import { observer } from 'mobx-react-lite'
import styles from './styles.module.scss'
import { Typography } from 'shared/ui/Typography'
import { useCollectedFieldContext } from '../../context/context'
import React from 'react'
import { PersonalizeSelect } from 'widgets/PersonalizeSelect'
import classNames from 'classnames'

export const Header = observer(() => {
  const { index, personalizeSelectStore, expand } = useCollectedFieldContext()
  return (
    <div className={styles.wrap}>
      <Typography color={'--primary-color-gray-1'}>
        Collect contact data
      </Typography>
      <div className={styles.actions}>
        <div
          className={classNames(styles.number, { [styles.secondary]: !expand })}
        >
          <Typography variant={'s1-medium'} isUnsetColor>
            {index}
          </Typography>
        </div>
        <PersonalizeSelect
          store={personalizeSelectStore}
          triggerType={'button'}
        />
      </div>
    </div>
  )
})
