import { BtnSize, BtnType, Button } from 'components/Button/Button'
import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import { CreateStoreTypes } from 'components/CreateElement/store/types'
import { NewTemplate } from 'src/widgets/MessageTemplate/MessageTemplatesModal/MyTemplatesTab/NewTemplate/NewTemplate'
import messageTemplateStore from 'src/widgets/MessageTemplate/store/MessageTemplateStore'
import { Icon } from 'components/Icon/Icon'
import { SearchInput } from 'components/Input/InputText/SearchInput'
import React from 'react'
import { MyTemplatesList } from 'src/widgets/MessageTemplate/MessageTemplatesModal/MyTemplatesTab/MyTemplatesList/MyTemplatesList'
import { Plus } from 'components/Icon'
import { TextareaStore } from 'store/textareaStore'

export const MyTemplatesTab = observer(
  ({ store }: { store: TextareaStore }) => {
    const {
      isCreateTemplate,
      editTemplate,
      setIsCreateTemplate,
      search,
      setSearch,
    } = messageTemplateStore

    if (isCreateTemplate || editTemplate) {
      return (
        <div className={styles.wrap}>
          <NewTemplate editTemplate={editTemplate} />
        </div>
      )
    }
    return (
      <>
        <div className={styles.actions}>
          <Button
            size={BtnSize.small}
            typeBtn={BtnType.primary}
            p12
            onClick={() => setIsCreateTemplate(true)}
          >
            <Icon color={'--primary-color-white'}>
              <Plus />
            </Icon>
            Add template
          </Button>
          <SearchInput value={search} onSearch={setSearch} />
        </div>
        <MyTemplatesList store={store} />
      </>
    )
  }
)
