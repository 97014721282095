import styles from './styles.module.scss'
import { MuiTooltip } from 'components/Tooltip/MuiTooltip'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as CustomColumn } from 'icons/16px/Customize Columns.svg'
import { DragList } from 'components/DragList/DragList'
import { ClickAwayListener } from '@mui/material'
import { useState } from 'react'
import { ReactComponent as DragIcon } from 'icons/16px/Drag Drop.svg'
import { Switch } from 'components/Switch/Switch'
import { observer } from 'mobx-react-lite'
import { TableStore } from 'components/NewTable/store/TableStore'
import { RequiredFields } from 'components/NewTable/types'
import { useClickAway } from 'src/hooks/useClickAway'

type Props<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  tableStore: TableStore<T>
}

export function SettingBtn<T>({ tableStore }: Props<T>) {
  const [open, setOpen] = useState(false)
  const modalRoot = document.getElementById('modal-root')

  const clickAwayRef = useClickAway<HTMLDivElement>(() => {
    if (!open) return

    setOpen(false)
  }, modalRoot)

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div ref={clickAwayRef}>
        <MuiTooltip
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          title={<Content<T> tableStore={tableStore} />}
          white
          width={240}
          placement={'bottom-end'}
          open={open}
        >
          <div>
            <Button
              size={BtnSize.small}
              hoverGray2
              onClick={() => setOpen(true)}
            >
              <CustomColumn />
            </Button>
          </div>
        </MuiTooltip>
      </div>
    </ClickAwayListener>
  )
}

const Content = observer(
  <T extends RequiredFields<T>>({ tableStore }: Props<T>) => {
    return (
      <DragList
        addTopPosition={16}
        fixed
        order={tableStore.columnOrderIds}
        setOrder={(order) => tableStore.setColumnOrder(order)}
        elements={tableStore.columns.map((column) => ({
          id: column.id as string,
          component: (
            <div className={styles.settingItem}>
              <DragIcon />
              <div className={styles.title}>
                <span>{column.label}</span>
              </div>
              <Switch
                active={
                  !!tableStore.columnsVisibleState.get(column.id as string)
                }
                onChange={() =>
                  tableStore.changeVisibleColumn(
                    column.id as string,
                    !tableStore.columnsVisibleState.get(column.id as string)
                  )
                }
              />
            </div>
          ),
        }))}
      />
    )
  }
)
