// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cpcgYPsmr1Sa7VfkfLzw{display:flex;justify-content:space-between;height:24px;align-items:center;padding-inline:8px;margin-bottom:4px}.kAbW_zuAzll_tdgv74mo{justify-content:flex-start}.kAbW_zuAzll_tdgv74mo .Vog5DNtF6JPRAMBnM1aY{flex-grow:unset}`, "",{"version":3,"sources":["webpack://./src/widgets/ContactsPicker/ui/PickerList/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,WAAA,CACA,kBAAA,CACA,kBAAA,CACA,iBAAA,CAGF,sBACE,0BAAA,CACA,4CACE,eAAA","sourcesContent":[".wrapGroupLabel{\n  display: flex;\n  justify-content: space-between;\n  height: 24px;\n  align-items: center;\n  padding-inline: 8px;\n  margin-bottom: 4px;\n}\n\n.newFilterBtn{\n  justify-content: flex-start;\n  .left{\n    flex-grow: unset;\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapGroupLabel": `cpcgYPsmr1Sa7VfkfLzw`,
	"newFilterBtn": `kAbW_zuAzll_tdgv74mo`,
	"left": `Vog5DNtF6JPRAMBnM1aY`
};
export default ___CSS_LOADER_EXPORT___;
