import { ActionBtnProps } from 'components/NewTable/types'
import * as React from 'react'
import { IKeyword } from 'src/types/IKeyword'
import { Switch } from 'components/Switch/Switch'
import {
  KeywordFragmentFragment,
  Status,
  useDisableKeywordMutation,
  useEnableKeywordMutation,
} from 'src/generated/graphql'
import { transformKeyword } from 'src/pages/main/keywords/functions/transformKeyword'
import keywordsStore from 'store/keywords/keywordsStore'
import { Box } from '@mui/material'
import { Typography } from 'shared/ui/Typography'

export const KeywordToggleActiveBtn = (props: ActionBtnProps<IKeyword>) => {
  const { row } = props
  const [enableKeyword, { loading: loadingEnable }] = useEnableKeywordMutation()
  const [disableKeyword, { loading: loadingDisable }] =
    useDisableKeywordMutation()
  const onSwitch = async () => {
    try {
      let response: KeywordFragmentFragment | null = null
      if (row?.status === Status.Active) {
        const { data } = await disableKeyword({
          variables: {
            id: row.id,
          },
        })
        if (data?.disableKeyword) {
          response = data.disableKeyword
        }
      }
      if (row?.status === Status.Disabled) {
        const { data } = await enableKeyword({
          variables: {
            id: row.id,
          },
        })
        if (data?.enableKeyword) {
          response = data.enableKeyword
        }
      }
      if (response) {
        const keyword = transformKeyword(response)
        keywordsStore.setKeyword(keyword)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const btn = (
    <Switch
      active={row?.status === Status.Active}
      onChange={onSwitch}
      marginInline={8}
      disabled={loadingEnable || loadingDisable}
    />
  )

  if (props.medium) {
    return (
      <Box display={'flex'} alignItems={'center'}>
        {props.medium && <Typography>{row?.status}</Typography>}
        {btn}
      </Box>
    )
  }

  return btn
}
