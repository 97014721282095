import { User } from 'components/Icon'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { FC } from 'react'
import {
  FixedTooltip,
  TTPositionEnum,
} from 'components/Tooltip/FixedTooltip/FixedTooltip'
import { PersonalizeContent } from 'components/Textarea/Personolize/PersonalizeContent/PersonalizeContent'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { useFixedTooltip } from 'src/hooks/useFixedTooltip'
import {
  DropdownButton,
  IDropdownButtonProps,
} from 'shared/ui/DropdownButton/DropdownButton'
import { Button } from 'shared/ui/Button/Button'

type Props = {
  onAddPersonalize: (name: string, key: string) => void
  disabled?: boolean
  withTooltip?: boolean
  isNew?: boolean
  dropdownButtonProps?: IDropdownButtonProps
}

export const Personalize: FC<Props> = ({
  onAddPersonalize,
  disabled,
  withTooltip,
  isNew,
  dropdownButtonProps,
}) => {
  const { trigger, showTT, setTrigger, onTriggerClick, setShowTT } =
    useFixedTooltip()

  const onAdd = (name: string, key: string) => {
    onAddPersonalize(name, key)
    setShowTT(false)
  }

  return (
    <>
      <FixedTooltip
        trigger={trigger}
        visible={showTT}
        white
        noArrow
        width={300}
        position={TTPositionEnum.bottomLeft}
        addLeftPosition={-8}
        noOpacity
      >
        <PersonalizeContent onAddPersonalize={onAdd} />
      </FixedTooltip>
      {withTooltip ? (
        <Tooltip
          text={
            <div>
              Personalize
              <br />
              {disabled && (
                <span className={classNames('s2 gray2', styles.subText)}>
                  To use merge fields you must first select the trigger app
                </span>
              )}
            </div>
          }
          disableVisible={showTT}
        >
          <div ref={setTrigger}>
            <Button
              LeftIcon={User}
              onClick={onTriggerClick}
              disabled={disabled}
              className={styles.btn}
              variant={'tertiary-small'}
            />
          </div>
        </Tooltip>
      ) : (
        <div ref={setTrigger}>
          <DropdownButton
            onClick={onTriggerClick}
            disabled={disabled}
            isNew={isNew}
            text={'Personalize'}
            LeftIcon={User}
            {...dropdownButtonProps}
          />
        </div>
      )}
    </>
  )
}
