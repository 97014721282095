import { observer } from 'mobx-react-lite'
import { KeywordsTable } from 'src/pages/main/keywords/KeywordsTable/KeywordsTable'
import { Header } from 'shared/ui/Header/Header'

export const Keywords = observer(() => {
  return (
    <>
      <Header title={'Keywords'} />
      <KeywordsTable />
    </>
  )
})
