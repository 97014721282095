import NewTable from 'components/NewTable/NewTable'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Filters } from './Filters/Filters'
import { Status, useGetAllKeywordsQuery } from 'src/generated/graphql'
import keywordsStore from 'store/keywords/keywordsStore'
import { EmptyState } from 'components/EmptyState/EmptyState'
import { EmptyStateSearch } from 'components/EmptyState/EmptyStateSearch'
import { noSortsColumns } from 'components/NewTable/columns/keywords/columnsKeywords'
import { useSetData } from 'src/hooks/useSetData'
import { IKeyword } from 'src/types/IKeyword'
import { AppElements } from 'src/enums/appElements'
import { getKeywordActions } from 'src/pages/main/keywords/functions/getKeywordActions'

export const KeywordsTable = observer(() => {
  const { tableStore, keywords } = keywordsStore

  const queryResult = useGetAllKeywordsQuery({
    variables: {
      page: tableStore.paginationInput,
      searchPattern: tableStore.search,
    },
  })
  const navigate = useNavigate()
  const onRowClick = (id: string, row: IKeyword) => {
    if (row.status === Status.Draft) {
      navigate(id + '/edit')
    } else {
      navigate(id)
    }
  }

  const { loading, refetchLoading, firstLoading } = useSetData({
    queryResult,
    setData: (data) => keywordsStore.setData(data),
    refreshDeps: [keywordsStore.tableStore.refreshTrigger],
  })

  if (!firstLoading && !keywords.length && !tableStore.search) {
    return (
      <EmptyState
        element={AppElements.Keyword}
        description={'Use keywords to capture leads'}
      />
    )
  }

  return (
    <>
      <NewTable<IKeyword>
        noSortsColumns={noSortsColumns}
        withCheckbox
        columns={tableStore.visibleColumns}
        rows={keywords.map((keyword) => ({
          ...keyword,
          actions: getKeywordActions(keyword, {
            onSuccessDelete: () => {
              keywordsStore.delete([keyword.id])
              keywordsStore.tableStore.onRefresh()
              keywordsStore.tableStore.setCheckedRows(
                keywordsStore.tableStore.checkedRows.filter(
                  (keyword) => keyword.id !== keyword.id
                )
              )
            },
          }),
        }))}
        leftStickyContent={<Filters />}
        withSearch
        onRowClick={onRowClick}
        emptyContent={
          !keywords.length ? (
            <EmptyStateSearch element={AppElements.Keyword} />
          ) : undefined
        }
        tableStore={tableStore}
        pageLoading={loading || refetchLoading}
        firstLoading={firstLoading}
        withCalcMaxHeight
      />
    </>
  )
})
