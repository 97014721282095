import { ModalIcon } from 'components/ModalIcon/ModalIcon'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { FC, ReactNode } from 'react'
import { Clock } from 'components/Icon'

export type IInfoModal = {
  title: ReactNode
  subTitle?: ReactNode
  onClose?: () => void
}

export const InfoModal: FC<IInfoModal> = ({ onClose, title, subTitle }) => {
  return (
    <div className={'pModalInfo'}>
      <ModalIcon type={'info'} icon={<Clock />} fontSize={20} />
      <h3>{title}</h3>
      {subTitle && <p className={'par2 gray1'}>{subTitle}</p>}
      <Button
        typeBtn={BtnType.primary}
        size={BtnSize.medium}
        onClick={onClose}
        fullWidth
      >
        Close
      </Button>
    </div>
  )
}
