import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Counter } from 'components/Counter/Counter'
import {
  getCampaignDelayPeriodTitle,
  periodsItems,
} from 'components/Schedule/store/mockData'
import { CampaignDelayPeriod } from 'src/generated/graphql'
import dayjs from 'lib/dayjs'
import { TimePicker } from 'components/TimePicker/TimePicker'
import { useCampaignScheduleContext } from 'src/widgets/CampaignSchedule'
import { SelectList } from 'components/SelectList/SelectList'
import { Input } from 'shared/ui/Input/Input'
import { Icon } from 'components/Icon/Icon'
import { DropdownField } from 'shared/ui/DropdownField/DropdownField'
import { AngleDown, AngleUp } from 'components/Icon'

export const LaterContentCampaign = observer(() => {
  const scheduleStore = useCampaignScheduleContext()
  useEffect(() => {
    if (
      scheduleStore.laterPeriod !== CampaignDelayPeriod.Hour &&
      scheduleStore.laterPeriod !== CampaignDelayPeriod.Minute &&
      !scheduleStore.laterDate
    ) {
      scheduleStore.setLaterDate(
        dayjs().startOf('day').add(12, 'hours').toDate()
      )
    }
  }, [scheduleStore.activeSendLater, scheduleStore.laterPeriod])

  if (!scheduleStore.activeSendLater) {
    return <></>
  }
  return (
    <>
      <div className={styles.wrap}>
        <Counter
          count={scheduleStore.laterCount}
          onMinus={() =>
            scheduleStore.setLaterCount(+scheduleStore.laterCount - 1)
          }
          onPlus={() =>
            scheduleStore.setLaterCount(+scheduleStore.laterCount + 1)
          }
          onChange={scheduleStore.setLaterCount}
        />
        <DropdownField
          isSameWidth
          isFullWidth
          dropdownContent={() => (
            <SelectList
              options={periodsItems}
              onSelect={scheduleStore.setLaterPeriod}
            />
          )}
          triggerContent={(open) => (
            <Input
              isReadOnly
              value={getCampaignDelayPeriodTitle(scheduleStore.laterPeriod)}
              placeholder={'Select'}
              rightContent={
                <Icon color={'--primary-color-gray-1'} fontSize={16}>
                  {open ? <AngleUp /> : <AngleDown />}
                </Icon>
              }
            />
          )}
        />
        {scheduleStore.laterPeriod !== CampaignDelayPeriod.Hour &&
          scheduleStore.laterPeriod !== CampaignDelayPeriod.Minute && (
            <div className={styles.time}>
              <TimePicker
                startTime={scheduleStore.laterDate}
                onChange={(time) => scheduleStore.setLaterDate(time)}
              />
            </div>
          )}
      </div>
    </>
  )
})
