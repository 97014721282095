import { DatePicker } from 'components/DatePicker/DatePicker'
import { observer } from 'mobx-react-lite'
import { Button } from 'components/Button/Button'
import styles from './styles.module.scss'

export const DateField = observer(
  ({
    title,
    required,
    date = null,
    onSetDate,
    fullWidth,
  }: {
    title: string
    required?: boolean
    date?: Date | null
    onSetDate: (date: Date | null) => void
    fullWidth?: boolean
  }) => {
    const onRemove = () => {
      onSetDate(null)
    }
    return (
      <div>
        <div className={'row-between'}>
          <div className={'flex'}>
            <span className={'s2 medium gray1 mb8'}>{title}</span>
            &nbsp;
            {required && <span className={'s2 medium error1'}>*</span>}
          </div>
          {date && (
            <Button onClick={onRemove}>
              <span className={'s2 bold blue3 link'}>Remove</span>
            </Button>
          )}
        </div>

        <DatePicker
          startDate={date}
          onChange={onSetDate}
          wrapperClassName={fullWidth && styles.fullWidth}
        />
      </div>
    )
  }
)
