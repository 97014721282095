import { Modal } from 'components/Modal/Modal'
import { observer } from 'mobx-react-lite'
import createTextToSpeechStore from 'src/Modals/CreateTextToSpeechModal/store/createTextToSpeechStore'
import { NewLabel } from 'src/shared/ui/NewLabel/NewLabel'
import React from 'react'
import { CreateTextToSpeechModalContext } from 'src/Modals/CreateTextToSpeechModal/context/context'
import { CreateTextToSpeechModalContent } from 'src/Modals/CreateTextToSpeechModal/CreateTextToSpeechModalContent'

export const CreateTextToSpeechModal = observer(() => {
  const { openModal, createTextToSpeechModalStore, onClose } =
    createTextToSpeechStore

  return (
    <Modal
      open={openModal}
      onClose={onClose}
      title={
        <>
          Text-to-speech
          <NewLabel marginLeft={14} />
        </>
      }
    >
      <CreateTextToSpeechModalContext.Provider
        value={createTextToSpeechModalStore}
      >
        <CreateTextToSpeechModalContent />
      </CreateTextToSpeechModalContext.Provider>
    </Modal>
  )
})
