import { createContext, useContext } from 'react'
import { DataCaptureStore } from 'widgets/DataCapture/store/DataCaptureStore'

export const DataCaptureContext = createContext<DataCaptureStore | null>(null)

export const useDataCaptureContext = () => {
  const context = useContext(DataCaptureContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with DataCaptureContext'
    )

  return context
}
