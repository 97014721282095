import { createContext, useContext } from 'react'
import { PersonalizeSelectStore } from 'widgets/PersonalizeSelect/store/PersonalizeSelectStore'

export const PersonalizeSelectContext =
  createContext<PersonalizeSelectStore | null>(null)

export const usePersonalizeSelectContext = () => {
  const context = useContext(PersonalizeSelectContext)

  if (context === null)
    throw new Error(
      'You have forgotten to wrap your root component with PersonalizeSelectContext'
    )

  return context
}
