import styles from './styles.module.scss'
import { BtnSize, BtnType, Button } from 'components/Button/Button'
import { RoutesEnum } from 'src/pages/routes'
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useSegmentDetailContext } from 'src/pages/main/contacts/segments/DetailSegment/Layout/SegmentDetailLayout'
import { useEffect } from 'react'
import { FilterGroup } from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/FiltersGroup'
import { SegmentTableContact } from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/SegmentTableContact/SegmentTableContact'
import segmentStore from 'store/contacts/segment/segmentStore'
import { useGetCustomFields } from 'src/pages/main/contacts/customFields/hooks/useGetCustomFields'

export const SegmentContent = observer(() => {
  useGetCustomFields()
  const { onSave, filtersGroup, disabledCreate, isEdit, loading } =
    useSegmentDetailContext()

  const navigate = useNavigate()

  const onSuccess = () => {
    navigate(RoutesEnum.contacts + '/' + RoutesEnum.segments)
  }

  const onCreate = () => onSave(onSuccess)

  useEffect(() => {
    segmentStore.loadAvailableData()
  }, [])

  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <div className={styles.cardWrap}>
          {Object.entries(filtersGroup).map(([orIndex, filters]) => (
            <FilterGroup key={orIndex} filters={filters} orIndex={+orIndex} />
          ))}
        </div>
        <div className={styles.actions}>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.primary}
            disabled={disabledCreate}
            onClick={onCreate}
            loading={loading}
          >
            {isEdit ? 'Save' : 'Create Segment'}
          </Button>
          <Button
            size={BtnSize.medium}
            typeBtn={BtnType.secondaryGray}
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className={styles.wrapTable}>
        <SegmentTableContact />
      </div>
    </div>
  )
})
