// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GzCqC83bNMi4WhZzHWhL{display:grid;flex-direction:column;row-gap:8px;border-bottom-left-radius:32px}.jvbMxYwTA9HKNkem7PvM{display:flex;width:200px;padding:12px;flex-direction:column;align-items:flex-start;gap:8px;border-radius:16px;background:var(--Primary-color-grey-4, #F3F5F8)}.USmLqwEtCQ4qSqjbuaGE{color:var(--primary-color-gray-1)}.USmLqwEtCQ4qSqjbuaGE:empty:after{content:"Your number"}.pVhROMorVz1rBpeQIJHF{white-space:pre-wrap;word-break:break-word}.pVhROMorVz1rBpeQIJHF[placeholder]:empty::before{color:var(--primary-color-gray-2);content:attr(placeholder)}`, "",{"version":3,"sources":["webpack://./src/components/Preview/PreviewSMSContent/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,8BAAA,CAEF,sBACE,YAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,OAAA,CACA,kBAAA,CACA,+CAAA,CAEF,sBACE,iCAAA,CAEE,kCACE,qBAAA,CAIN,sBACE,oBAAA,CACA,qBAAA,CACA,iDACE,iCAAA,CACA,yBAAA","sourcesContent":[".wrap{\n  display: grid;\n  flex-direction: column;\n  row-gap: 8px;\n  border-bottom-left-radius: 32px;\n}\n.message{\n  display: flex;\n  width: 200px;\n  padding: 12px;\n  flex-direction: column;\n  align-items: flex-start;\n  gap: 8px;\n  border-radius: 16px;\n  background: var(--Primary-color-grey-4, #F3F5F8);\n}\n.phone{\n  color: var(--primary-color-gray-1);\n  &:empty{\n    &:after{\n      content: 'Your number';\n    }\n  }\n}\n.text{\n  white-space: pre-wrap;\n  word-break: break-word;\n  &[placeholder]:empty::before {\n    color: var(--primary-color-gray-2);\n    content: attr(placeholder);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `GzCqC83bNMi4WhZzHWhL`,
	"message": `jvbMxYwTA9HKNkem7PvM`,
	"phone": `USmLqwEtCQ4qSqjbuaGE`,
	"text": `pVhROMorVz1rBpeQIJHF`
};
export default ___CSS_LOADER_EXPORT___;
