// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.muDXw_LkqlY461UdHwco{display:flex;flex-direction:column;width:1000px;height:562px;padding:0 32px 32px 32px}.GBHsJqHW0nZl_9PACWvw{width:100%;overflow:auto;display:flex;flex-grow:1;padding-block:24px 32px}.KtCSSwZN4w_C_zaQ0Fmb{display:flex;gap:12px}`, "",{"version":3,"sources":["webpack://./src/widgets/AddFiltersModal/ui/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,YAAA,CACA,wBAAA,CAEF,sBACE,UAAA,CACA,aAAA,CACA,YAAA,CACA,WAAA,CACA,uBAAA,CAEF,sBACE,YAAA,CACA,QAAA","sourcesContent":[".wrap{\n  display: flex;\n  flex-direction: column;\n  width: 1000px;\n  height: 562px;\n  padding: 0 32px 32px 32px;\n}\n.content{\n  width: 100%;\n  overflow: auto;\n  display: flex;\n  flex-grow: 1;\n  padding-block: 24px 32px;\n}\n.actions{\n  display: flex;\n  gap: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": `muDXw_LkqlY461UdHwco`,
	"content": `GBHsJqHW0nZl_9PACWvw`,
	"actions": `KtCSSwZN4w_C_zaQ0Fmb`
};
export default ___CSS_LOADER_EXPORT___;
