import { observer } from 'mobx-react-lite'
import { Input } from 'shared/ui/Input/Input'
import { useNewConversionContext } from 'widgets/conversions/NewConversion/context/context'
import { Label } from 'shared/ui/Label/Label'

export const Name = observer(() => {
  const { name, setName, isEdit } = useNewConversionContext()
  return (
    <div>
      <Label text={'Name'} marginBottom={8} />
      <Input
        value={name}
        setValue={setName}
        placeholder={'Name your conversion'}
        isFocusOnMount={!isEdit}
        withClear
      />
    </div>
  )
})
