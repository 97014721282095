import { Dropdown } from 'components/Dropdown/Dropdown'
import { observer } from 'mobx-react-lite'
import { FilterCondition } from 'src/generated/graphql'
import styles from './styles.module.scss'
import { OptInFilter } from 'store/contacts/segment/filters/OptInFilter'
import { KeywordDropdown } from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/content/OptIntFilterContent/KeywordDropdown'
import { WebFormDropdown } from 'src/pages/main/contacts/segments/DetailSegment/components/SegmentContent/content/OptIntFilterContent/WebFormDropdown'

type Props = {
  filter: OptInFilter
  readonly: boolean
}

export const OptIntFilterContent = observer(({ filter, readonly }: Props) => {
  return (
    <>
      {filter.condition === FilterCondition.Exists ? (
        <div />
      ) : (
        <div className={(filter.isKeyword || filter.isWebForm) && styles.optIn}>
          <Dropdown
            options={filter.options}
            placeholder={'Select'}
            selectedValue={filter.value}
            onSelectValue={filter.setValue}
            readonly={readonly}
          />
          {filter.isKeyword && <KeywordDropdown filter={filter} />}
          {filter.isWebForm && <WebFormDropdown filter={filter} />}
        </div>
      )}
    </>
  )
})
