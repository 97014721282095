import styles from './styles.module.scss'
import { Link, useNavigate } from 'react-router-dom'
import { BtnSize, Button } from 'components/Button/Button'
import { ReactComponent as Angle } from 'icons/16px/Angle-Left.svg'
import { ReactComponent as Star } from 'icons/16pxNoMask/Star.svg'
import { observer } from 'mobx-react-lite'
import { RoutesEnum } from 'src/pages/routes'
import { StatusContent } from 'components/StatusContent/StatusContent'
import * as React from 'react'
import { useKeywordDetailContext } from 'src/pages/main/keywords/detail/keywordDetailPage'
import { getKeywordActions } from 'src/pages/main/keywords/functions/getKeywordActions'
import { Actions } from 'components/Actions/Actions'

export const HeaderContent = observer(() => {
  const navigate = useNavigate()
  const { keyword, totalCharged } = useKeywordDetailContext()

  const actions = keyword
    ? getKeywordActions(keyword, {
        onSuccessDelete: () => navigate(RoutesEnum.keywords),
      })
    : []
  if (!keyword) {
    return <></>
  }
  return (
    <div className={styles.header}>
      <Link to={RoutesEnum.keywords}>
        <div className={styles.subtitle}>
          <span>Keywords</span>
        </div>
      </Link>
      <div className={styles.nav}>
        <div className={styles.left}>
          <div>
            <Button hoverGray size={BtnSize.small} onClick={() => navigate(-1)}>
              <Angle />
            </Button>
          </div>
          <h1>{keyword?.name}</h1>
        </div>
        <div className={styles.right}>
          <Actions actions={actions} visibleActionsCount={3} />
        </div>
      </div>
      <div className={styles.rowInfo}>
        {keyword?.status && (
          <>
            <StatusContent status={keyword?.status} />
            <div className={'point'} />
          </>
        )}
        <div className={styles.creditCount}>
          <Star />
          <span>{totalCharged} credits</span>
        </div>
      </div>
    </div>
  )
})
