import { CollectedFieldUi } from './ui/CollectedFieldUi'
import { CollectedFieldContext } from './context/context'
import { CollectedFieldStore } from './store/CollectedFieldStore'
import { useEffect } from 'react'

type ICollectedFieldProps = {
  store: CollectedFieldStore
  index: number
}

export const CollectedField = ({ store, index }: ICollectedFieldProps) => {
  useEffect(() => {
    store.setIndex(index)
  }, [index])
  return (
    <CollectedFieldContext.Provider value={store}>
      <CollectedFieldUi />
    </CollectedFieldContext.Provider>
  )
}
