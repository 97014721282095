import styles from './styles.module.scss'
import { observer } from 'mobx-react-lite'
import uiStore from 'store/uiStore'
import { ReactNode, useEffect } from 'react'
import { IPreviewMessage } from 'components/Preview/types'
import { PreviewSchedule } from 'components/Preview/PreviewSchedule/PreviewSchedule'
import { PreviewSMSContent } from 'components/Preview/PreviewSMSContent/PreviewSMSContent'
import { PreviewVoiceContent } from 'components/Preview/PreviewVoiceContent'
import { PreviewRinglessContent } from 'components/Preview/PreviewRinglessContent/PreviewRinglessContent'

export const Preview = observer(
  ({
    message,
    customContent,
    scheduleText = 'Immediately',
  }: {
    message?: IPreviewMessage
    scheduleText?: string
    customContent?: ReactNode
  }) => {
    useEffect(() => {
      if (uiStore.isMobile) {
        uiStore.setMobilePage('Preview messages')
        uiStore.setCloseAction(() => uiStore.setIsPreview(false))
      }
      return () => {
        if (uiStore.isMobile) {
          uiStore.setMobilePage('')
          uiStore.setCloseAction(null)
        }
      }
    }, [uiStore.isMobile])
    return (
      <div className={styles.wrap}>
        <div className={styles.content}>
          <div className={styles.innerContent}>
            <div className={styles.innerContentBody}>
              {customContent}
              {!customContent && (
                <>
                  <PreviewSchedule text={scheduleText} />
                  {message?.type === 'SMS' && (
                    <PreviewSMSContent
                      phone={message.phone}
                      store={message.textareaStore}
                    />
                  )}
                  {message?.type === 'Voice' && (
                    <PreviewVoiceContent
                      phone={message.phone}
                      store={message.voiceStore}
                    />
                  )}
                  {message?.type === 'Ringless' && (
                    <PreviewRinglessContent
                      phone={message.phone}
                      store={message.ringlessStore}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          {uiStore.isMobile && uiStore.mobileActions && (
            <div className={styles.wrapActions}>{uiStore.mobileActions}</div>
          )}
        </div>
      </div>
    )
  }
)
